export const categories = [
  'Concerts',
  'Pop-ups',
  'Food & Drink',
  'Festivals',
  'Fashion',
  'Parties',
  'Gaming',
  'Meet-ups',
  'Gigs',
  'Workshops and classes',
  'Art',
  'Theatre',
  'Business',
  'Galas',
  'Charity',
  'Wellness',
  'Fine dining',
  'Motives',
  'Sports',
  'Comedy'
];
