import { Link } from 'react-router-dom';
import { To } from 'history';
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';
import { useSupabase } from 'contexts/supabase';

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { session } = useSupabase();

  return (
    <ButtonBase disableRipple {...(session && { component: Link, to: !to ? APP_DEFAULT_PATH : to, sx })}>
      {isIcon ? <LogoIcon /> : <Logo />}
    </ButtonBase>
  );
};

export default LogoSection;
