import { createBrowserRouter } from 'react-router-dom';
import Login from 'pages/login/login';
import Forgot from 'pages/forgot/forgot';
import AuthResetSuccess from 'pages/auth/reset-success';
import AuthCodeVerification from 'pages/auth/code-verification';
import AuthCreateNewPassword from 'pages/auth/create-new-password';
import DashboardLayout from 'layout/Dashboard';
import DashboardDefault from 'pages/dashboard/default';
import AuthLayout from './authLayout';
import RootLayout from './rootLayout';
import CheckMail from 'pages/auth/check-mail';
import PrivateRoute from './privateRoute';
import Subscriptions from 'pages/profile/tabs/subscriptions';
import PartnerInformation from 'pages/profile/tabs/partnerInformation';
import Events from 'pages/profile/tabs/events';
import Team from 'pages/profile/tabs/team';
import Settings from 'pages/profile/tabs/settings';
import {
  ROUTE_ASSET_LIBRARY,
  ROUTE_BULK_REQUESTS,
  ROUTE_CENE_PLUS_REQUESTS,
  ROUTE_CHECK_EMAIL,
  ROUTE_CODE_VERIFICATION,
  ROUTE_CREATE_NEW_PASSWORD,
  ROUTE_CUSTOMER,
  ROUTE_CUSTOMER_EVENTS_AND_PURCHASES,
  ROUTE_CUSTOMER_GUESTS,
  ROUTE_CUSTOMER_SETTINGS,
  ROUTE_CUSTOMER_USER_INFORMATION,
  ROUTE_CUSTOMERS,
  ROUTE_DASHBOARD,
  ROUTE_DEFAULT,
  ROUTE_EVENT,
  ROUTE_EVENT_OFFLINE_ORDER,
  ROUTE_EVENTS,
  ROUTE_FLOORPLAN_REQUESTS,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
  ROUTE_MANAGE_TEAM,
  ROUTE_MEMBERS,
  ROUTE_ONBOARDING,
  ROUTE_PARTNER,
  ROUTE_PARTNER_EVENTS,
  ROUTE_PARTNER_INFORMATION,
  ROUTE_PARTNER_SETTINGS,
  ROUTE_PARTNER_SUBSCRIPTIONS,
  ROUTE_PARTNER_TEAM,
  ROUTE_PARTNERS,
  ROUTE_PARTNERS_REQUESTS,
  ROUTE_PROFILE,
  ROUTE_PROFILE_INFORMATION,
  ROUTE_PROFILE_SUBSCRIPTIONS,
  ROUTE_RESET_SUCCESS,
  ROUTE_TRANSACTION,
  ROUTE_VENUE,
  ROUTE_VENUES_AND_FLOORPLANS,
  ROUTE_WELCOME,
  ROUTE_WELCOME_AFTER_ONBOARDING,
  ROUTER_EVENT_EDIT,
  ROUTER_EVENT_ENTRY_MANAGER,
  ROUTER_EVENT_GUESTLISTS,
  ROUTER_EVENT_MANAGE_EVENT_VISIBILITY,
  ROUTER_EVENT_OVERVIEW,
  ROUTER_EVENT_PROMO_CODES,
  ROUTER_EVENT_REQUESTS,
  ROUTER_EVENT_SALES_AND_TRANSACTIONS,
  ROUTER_EVENT_TABLES_AND_GUESTS_MANAGEMENT,
  ROUTER_EVENT_TICKET_BUYERS,
  ROUTER_VENUE_FLOORPLAN_ASSIGN,
  ROUTER_VENUE_FLOORPLAN_LOADER,
  ROUTER_VENUE_FLOORPLAN_VIEW,
  ROUTER_VENUE_FLOORPLANS,
  ROUTER_VENUE_NEW_FLOORPLAN,
  ROUTER_VENUE_OVERVIEW,
  ROUTER_VENUE_PARTNERS
} from 'constants/routes';

import PartnersRequestsPage from 'pages/partnersManagement/partnersRequests/partnerRequests';
import PartnersPage from 'pages/partnersManagement/partners/index';
import {
  DefaultRedirectCustomer,
  DefaultRedirectEventDetails,
  DefaultRedirectPartner,
  DefaultRedirectUser,
  DefaultRedirectVenueView
} from './defaultRedirect';
import PartnerProfile from 'pages/profile/partnerProfile/partnerProfile';
import UserProfile from 'pages/profile/userProfile/userProfile';
import WelcomePage from 'pages/onboarding/welcomePage';
import SplashPage from 'pages/onboarding/splashPage';
import OnboardingPage from 'pages/onboarding/onbordingPage';
import { BulkRequests } from 'pages/events/bulkRequests/bulkRequests';
import TeamPageContext from 'pages/teamManagement/team/teamPage';
import CustomersPage from 'pages/customersManagement/customers';
import CenePlusRequestsPage from 'pages/customersManagement/cenePlusRequests';
import CustomerProfile from 'pages/customersManagement/customers/profile/customerProfile';
import UserInformation from 'pages/customersManagement/customers/profile/tabs/userInformation';
import EventsAndPurchases from 'pages/customersManagement/customers/profile/tabs/eventsAndPurchases';
import Guests from 'pages/customersManagement/customers/profile/tabs/guests';
import CustomerSettings from 'pages/customersManagement/customers/profile/tabs/settings';
import CeneEventsPage from 'pages/eventManagement/ceneEvents';
import EventDetailsView from 'pages/eventManagement/ceneEvents/eventDetails';
import PromocodesPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/promocodes';
import OverviewPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/overview';
import TicketBuyersPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/ticketBuyers';
import TablesAndGuestManagement from 'pages/eventManagement/ceneEvents/eventDetails/tabs/tablesAndGuestManagement';
import EntryManagerPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/entryManager';
import RequestsPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/requests';
import GuestlistsPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/guestlists';
import SalesAndTransactionsPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/salesAndTransactions';
import ManageEventVisibilityPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/manageEventVisibility';
import TransactionPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/salesAndTransactions/transaction';
import ManageTeamPage from 'pages/eventManagement/ceneEvents/eventDetails/tabs/entryManager/manageTeam';
import AssetLibraryPage from 'pages/assetsLibrary';
import VenuesAndFloorplansPage from 'pages/eventManagement/venuesAndFloorplans';
import VenueDetails from 'pages/eventManagement/venuesAndFloorplans/details';
import OverviewSection from 'pages/eventManagement/venuesAndFloorplans/details/overview';
import PartnersSection from 'pages/eventManagement/venuesAndFloorplans/details/partners';
import FloorplanPage from 'pages/eventManagement/venuesAndFloorplans/details/floorplan';
import LoadingPage from 'pages/eventManagement/venuesAndFloorplans/details/loading';
import AssignFloorplanPage from 'pages/eventManagement/venuesAndFloorplans/details/assign';
import ViewFloorplanPage from 'pages/eventManagement/venuesAndFloorplans/details/floorplanView';
import FloorplansPage from 'pages/eventManagement/venuesAndFloorplans/details/floorplans';
import { Event } from 'pages/eventManagement/event';
import { OfflineOrder } from 'pages/eventManagement/offlineOrder';

const router = createBrowserRouter(
  [
    { path: ROUTE_WELCOME, element: <WelcomePage /> },
    { path: ROUTE_WELCOME_AFTER_ONBOARDING, element: <SplashPage /> },
    { path: ROUTE_ONBOARDING, element: <OnboardingPage /> },
    { path: ROUTE_CREATE_NEW_PASSWORD, element: <AuthCreateNewPassword /> },
    {
      path: ROUTE_HOME,
      element: <AuthLayout />,
      children: [
        // Auth
        {
          path: ROUTE_HOME,
          element: <Login />
        },
        {
          path: ROUTE_FORGOT_PASSWORD,
          element: <Forgot />
        },
        { path: ROUTE_RESET_SUCCESS, element: <AuthResetSuccess /> },
        {
          path: ROUTE_CODE_VERIFICATION,
          element: <AuthCodeVerification />
        },
        {
          path: ROUTE_CHECK_EMAIL,
          element: <CheckMail />
        }
      ]
    },

    {
      element: <RootLayout />,
      path: '/',
      children: [
        {
          path: '/',
          element: <DashboardLayout />,
          children: [
            {
              path: ROUTE_DASHBOARD,
              element: <DashboardDefault />
            },
            {
              path: ROUTE_PARTNERS_REQUESTS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'partners_requests:r']}>
                  <PartnersRequestsPage />
                </PrivateRoute>
              )
            },
            {
              path: ROUTE_CUSTOMERS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'customers:r']}>
                  <CustomersPage />
                </PrivateRoute>
              )
            },

            {
              path: ROUTE_CENE_PLUS_REQUESTS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'ceneplus_requests:r']}>
                  <CenePlusRequestsPage />
                </PrivateRoute>
              )
            },

            {
              path: ROUTE_MEMBERS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'partner_users:r']}>
                  <TeamPageContext />
                </PrivateRoute>
              )
            },

            {
              path: ROUTE_EVENTS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'events:r', 'events:c']}>
                  <CeneEventsPage />
                </PrivateRoute>
              )
            },
            {
              path: ROUTER_EVENT_EDIT,
              element: (
                <PrivateRoute allowedPermissions={['*', 'events:r', 'events:c']}>
                  <Event />
                </PrivateRoute>
              )
            },
            {
              path: ROUTE_EVENT_OFFLINE_ORDER,
              element: (
                <PrivateRoute allowedPermissions={['*', 'events:r', 'events:c']}>
                  <OfflineOrder />
                </PrivateRoute>
              )
            },
            {
              path: ROUTE_BULK_REQUESTS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'events:r', 'events:c']}>
                  <BulkRequests />
                </PrivateRoute>
              )
            },
            // {
            //   path: 'venues',

            //   children: [
            //     {
            //       index: true,
            //       element: (
            //         <PrivateRoute allowedPermissions={['*', 'floorplans:r']}>
            //           <VenuesPage />
            //         </PrivateRoute>
            //       )
            //     },
            //     {
            //       path: ':id/floorplans',
            //       element: (
            //         <PrivateRoute allowedPermissions={['*', 'floorplans:r']}>
            //           <FloorplansPage />
            //         </PrivateRoute>
            //       )
            //     }
            //   ]
            // },
            {
              path: ROUTE_PARTNERS,
              element: (
                <PrivateRoute allowedPermissions={['*', 'partners:r']}>
                  <PartnersPage />
                </PrivateRoute>
              )
            },
            {
              path: ROUTE_PARTNER,
              element: <PartnerProfile />,
              children: [
                {
                  index: true,
                  element: <DefaultRedirectPartner />
                },
                {
                  path: ROUTE_PARTNER_INFORMATION,
                  element: <PartnerInformation />
                },
                {
                  path: ROUTE_PARTNER_SUBSCRIPTIONS,
                  element: <Subscriptions />
                },
                {
                  path: ROUTE_PARTNER_EVENTS,
                  element: <Events />
                },
                {
                  path: ROUTE_PARTNER_TEAM,
                  element: <Team />
                },
                {
                  path: ROUTE_PARTNER_SETTINGS,
                  element: <Settings />
                }
              ]
            },
            {
              path: ROUTE_CUSTOMER,
              element: <CustomerProfile />,
              children: [
                {
                  index: true,
                  element: <DefaultRedirectCustomer />
                },
                {
                  path: ROUTE_CUSTOMER_USER_INFORMATION,
                  element: <UserInformation />
                },
                {
                  path: ROUTE_CUSTOMER_EVENTS_AND_PURCHASES,
                  element: <EventsAndPurchases />
                },
                {
                  path: ROUTE_CUSTOMER_GUESTS,
                  element: <Guests />
                },
                {
                  path: ROUTE_CUSTOMER_SETTINGS,
                  element: <CustomerSettings />
                }
              ]
            },
            {
              path: ROUTE_PROFILE,
              element: <UserProfile />,
              children: [
                {
                  index: true,
                  element: <DefaultRedirectUser />
                },
                {
                  path: ROUTE_PROFILE_INFORMATION,
                  element: <PartnerInformation />
                },
                {
                  path: ROUTE_PROFILE_SUBSCRIPTIONS,
                  element: <Subscriptions />
                }
              ]
            },
            {
              path: ROUTE_EVENT,
              element: <EventDetailsView />,
              children: [
                {
                  index: true,
                  element: <DefaultRedirectEventDetails />
                },
                { path: ROUTER_EVENT_OVERVIEW, element: <OverviewPage /> },
                { path: ROUTER_EVENT_TICKET_BUYERS, element: <TicketBuyersPage /> },
                { path: ROUTER_EVENT_TABLES_AND_GUESTS_MANAGEMENT, element: <TablesAndGuestManagement /> },
                { path: ROUTER_EVENT_ENTRY_MANAGER, element: <EntryManagerPage /> },
                { path: ROUTER_EVENT_REQUESTS, element: <RequestsPage /> },
                { path: ROUTER_EVENT_SALES_AND_TRANSACTIONS, element: <SalesAndTransactionsPage /> },
                { path: ROUTER_EVENT_GUESTLISTS, element: <GuestlistsPage /> },
                { path: ROUTER_EVENT_PROMO_CODES, element: <PromocodesPage /> },
                { path: ROUTER_EVENT_MANAGE_EVENT_VISIBILITY, element: <ManageEventVisibilityPage /> }
              ]
            },
            {
              path: ROUTE_TRANSACTION,
              element: <TransactionPage />
            },
            {
              path: ROUTE_MANAGE_TEAM,
              element: <ManageTeamPage />
            },
            {
              path: ROUTE_ASSET_LIBRARY,
              element: <AssetLibraryPage />
            },
            {
              path: ROUTE_FLOORPLAN_REQUESTS,
              element: <AssetLibraryPage />
            },
            {
              path: ROUTE_DEFAULT,
              element: <AssetLibraryPage />
            },

            {
              path: ROUTE_VENUE,
              element: <VenueDetails />,
              children: [
                {
                  index: true,
                  element: <DefaultRedirectVenueView />
                },
                { path: ROUTER_VENUE_OVERVIEW, element: <OverviewSection /> },
                { path: ROUTER_VENUE_PARTNERS, element: <PartnersSection /> },
                {
                  path: ROUTER_VENUE_FLOORPLANS,
                  element: <FloorplansPage />
                }
              ]
            },
            { path: ROUTER_VENUE_NEW_FLOORPLAN, element: <FloorplanPage /> },
            { path: ROUTER_VENUE_FLOORPLAN_LOADER, element: <LoadingPage /> },
            { path: ROUTER_VENUE_FLOORPLAN_ASSIGN, element: <AssignFloorplanPage /> },
            { path: ROUTER_VENUE_FLOORPLAN_VIEW, element: <ViewFloorplanPage /> },
            { path: ROUTE_VENUES_AND_FLOORPLANS, element: <VenuesAndFloorplansPage /> }
          ]
        }
      ]
    }
  ],
  { basename: process.env.REACT_APP_BASE_NAME }
);

export default router;
