import { Alert, FormControlLabel, InputLabel, Radio, RadioGroup, Stack, Switch, Typography } from '@mui/material';
import { PaymentCard } from 'components/PaymentCard';
import ShapeButton from 'components/button/button';
import { EventSchema } from '../event.schema';
import { useFormikContext } from 'formik';

interface IEventPaymentMethods {
  onNext: () => void;
  onPrev: () => void;
}

export const EventPaymentMethods = ({ onNext, onPrev }: IEventPaymentMethods) => {
  const formik = useFormikContext<EventSchema>();
  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];

  const validationStepForMultipleTypes =
    formik.values.eventType === 'both' &&
    Boolean(
      (!formik.values.cene.isPaymentNaira && !formik.values.cene.isPaymentOutsideNigeria) ||
        (!formik.values['cene+'].isPaymentNaira && !formik.values['cene+'].isPaymentOutsideNigeria)
    );

  const ceneValidation = Boolean(!formik.values.cene.isPaymentNaira && !formik.values.cene.isPaymentOutsideNigeria);

  const cenePlusValidation = Boolean(!formik.values['cene+'].isPaymentNaira && !formik.values['cene+'].isPaymentOutsideNigeria);

  const validationStep = Boolean(!values.isPaymentNaira && !values.isPaymentOutsideNigeria);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Checkout and Payment Options
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Cene offers multiple payment options for a smooth and secure purchasing experience, making it easy for your attendees to complete
        their transactions in their local currencies.
      </Typography>
      <Stack spacing={1} mt={4}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          Enable International Transactions For Users Outside Nigeria.
        </InputLabel>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Switch
            sx={{ marginLeft: 0 }}
            checked={values.isPaymentNaira}
            onChange={() => {
              formik.setFieldValue(`${formType}.isPaymentNaira`, !values.isPaymentNaira);
            }}
          />
          <Typography color="info">Choose payment gateway for your fans.</Typography>
        </Stack>
        {values.isPaymentNaira && (
          <>
            <Stack flexDirection="row">
              <PaymentCard method="stripe" active={values.isPaymentNaira} />
            </Stack>
            <Stack mt={2}>
              <RadioGroup row value={values.paymentNaira} onChange={(_, value) => formik.setFieldValue(`${formType}.paymentNaira`, value)}>
                <FormControlLabel value="USD" control={<Radio />} label="USD" />
                <FormControlLabel value="GBP" control={<Radio />} label="GBP" />
                <FormControlLabel value="EUR" control={<Radio />} label="EUR" />
              </RadioGroup>
            </Stack>
          </>
        )}
      </Stack>
      <Stack spacing={1} mt={3}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          Enable Payments in Naira
        </InputLabel>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Switch
            sx={{ marginLeft: 0 }}
            checked={values.isPaymentOutsideNigeria}
            onChange={() => {
              formik.setFieldValue(`${formType}.isPaymentOutsideNigeria`, !values.isPaymentOutsideNigeria);
            }}
          />
          <Typography color="info">Choose payment gateway for your fans.</Typography>
        </Stack>
        {values.isPaymentOutsideNigeria && (
          <Stack flexDirection="row" gap={2}>
            <PaymentCard
              onChange={() => {
                formik.setFieldValue(`${formType}.paymentOutsideNigeria`, 'paystack');
              }}
              method="paystack"
              active={values.paymentOutsideNigeria === 'paystack'}
            />
            <PaymentCard
              onChange={() => {
                formik.setFieldValue(`${formType}.paymentOutsideNigeria`, 'flutterwave');
              }}
              method="flutterwave"
              active={values.paymentOutsideNigeria === 'flutterwave'}
            />
          </Stack>
        )}
      </Stack>
      {formik.values.eventType === 'both' && ((formType === 'cene' && cenePlusValidation) || (formType === 'cene+' && ceneValidation)) && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          To continue, you need to fix the errors in the {formType === 'cene' ? 'Cene+' : 'Cene'} form
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={validationStepForMultipleTypes || validationStep} handleClick={onNext} width="100%">
          Next step
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
