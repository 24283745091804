import { Divider } from '@mui/material';
import EventInsightsSection from './cene/eventInsights';
import EventOverviewTable, { ITicketsSold } from './cene/table';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router';
import CircularLoader from 'components/CircularLoader';
import { useLocation } from 'react-router';
import {
  handleCalculateAllTickets,
  handleCalculateAllTicketsCost,
  handleCalculateRevenueGenerated,
  handleCalculateTicketsAvailable
} from 'supabase/util';
import EventInsightsSectionCenePlus from './cene+/eventInsights';
import EventOverviewTableCenePlus, { IFloorPlan } from './cene+/table';

interface EventResponse {
  tickets: ITicketsSold[];
}

interface ZoneResponse {
  tickets: IFloorPlan[];
}

interface LocationState {
  eventType: string;
}

export interface IEventInsights {
  ticketsSold: number;
  revenueGenerated: number;
  ticketsAvailable: number;
  ticketsShared: number;
  numOfTickets: number;
  allTicketsCost: number;
}

export interface IEventInsightsSectionProp {
  insights: IEventInsights;
  eventId: string;
  tickets: ITicketsSold[];
}

export interface ICeneOverviewTable {
  ceneAvailableTickets: number;
}

const OverviewPage = () => {
  const [tickets, setTickets] = useState<ITicketsSold[]>([]);
  const [zoneDetails, setZoneDetails] = useState<IFloorPlan[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const state = location.state as LocationState;
  const [eventInsights, setEventInsights] = useState<IEventInsights>({
    ticketsSold: 0,
    revenueGenerated: 0,
    ticketsAvailable: 0,
    numOfTickets: 0,
    ticketsShared: 0,
    allTicketsCost: 0
  });

  useEffect(() => {
    if (id) fetchTicketsById();
  }, [id]);

  const getCeneEventInsights = async (tkts: ITicketsSold[]): Promise<IEventInsights> => {
    // Get tickets sold count
    const { count: ticketsSold } = await supabase
      .from('tickets')
      .select('*', { count: 'exact', head: true })
      .eq('event_id', id)
      .not('customer_id', 'is', null);
    const numOfTickets = await handleCalculateAllTickets(id); //both available and not-avail
    const allTicketsCost = await handleCalculateAllTicketsCost(id, tkts); //both available and not-avail

    const [revenueGenerated, ticketsAvailable] = await Promise.all([
      handleCalculateRevenueGenerated(id, tkts).catch(() => 0),
      handleCalculateTicketsAvailable(id, ticketsSold || 0).catch(() => 0)
    ]);

    return {
      ticketsSold: ticketsSold || 0,
      revenueGenerated: revenueGenerated || 0,
      ticketsAvailable: ticketsAvailable || 0,
      numOfTickets: numOfTickets || 0,
      allTicketsCost: allTicketsCost || 0,
      ticketsShared: 0
    };
  };

  // Type guard to check if the response is ZoneResponse
  function isZoneResponse(data: EventResponse | ZoneResponse): data is ZoneResponse {
    return (data as ZoneResponse).tickets[0]?.floorplan !== undefined;
  }

  const fetchTicketsById = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('events').select('tickets').eq('id', id).returns<EventResponse[] | ZoneResponse[]>();

      if (data && data[0]?.tickets && typeof data[0].tickets !== 'undefined') {
        //if it is CENE+ event
        if (isZoneResponse(data[0])) {
          setZoneDetails(data[0].tickets);

          //call insight data here
        } else {
          setTickets(data[0].tickets);

          //call insight data
          const insightData = await getCeneEventInsights(data[0].tickets);
          setEventInsights(insightData);
        }

        // console.log('cene+', data[0]);
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return isLoading ? (
    <CircularLoader />
  ) : (
    <>
      {state?.eventType === 'cene' && typeof state?.eventType !== 'undefined' ? (
        <EventInsightsSection insights={eventInsights} eventId={id || ''} tickets={tickets} />
      ) : (
        <EventInsightsSectionCenePlus insights={eventInsights} eventId={id || ''} tickets={tickets} />
      )}
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      {state?.eventType === 'cene' && typeof state?.eventType !== 'undefined' ? (
        <EventOverviewTable solds={tickets} eventId={id || ''} />
      ) : (
        <EventOverviewTableCenePlus solds={zoneDetails} eventId={id || ''} />
      )}
    </>
  );
};

export default OverviewPage;
