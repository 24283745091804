import MainCard from 'components/MainCard';
import { useOutletContext } from 'react-router';
import CustomerForm from '../customer.form';
import { User } from 'types/user';

const UserInformation = () => {
  const { customer } = useOutletContext<{ customer: User }>();
  return (
    <MainCard title="User Information" contentSX={{ padding: 0 }}>
      <CustomerForm currentObject={customer} />
    </MainCard>
  );
};

export default UserInformation;
