import * as Yup from 'yup';

const socialLinkRegex = {
  tiktok: /^(https?:\/\/)?(www\.)?tiktok\.com(\/[A-Za-z0-9_-]+)?\/?$/,
  instagram: /^(https?:\/\/)?(www\.)?instagram\.com(\/[A-Za-z0-9_.]+)?\/?$/,
  twitter: /^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)(\/[A-Za-z0-9_]+)?\/?$/,
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com(\/[A-Za-z0-9_.]+)?\/?$/,
  website: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?$/
};

export const socialMedia = Yup.object().shape({
  social_handles: Yup.object().shape({
    tiktok: Yup.string().matches(socialLinkRegex.tiktok, 'Invalid TikTok profile URL'),
    instagram: Yup.string().matches(socialLinkRegex.instagram, 'Invalid Instagram profile URL'),
    twitter: Yup.string().matches(socialLinkRegex.twitter, 'Invalid X profile URL'),
    facebook: Yup.string().matches(socialLinkRegex.facebook, 'Invalid Facebook profile URL'),
    website: Yup.string().matches(socialLinkRegex.website, 'Invalid Website URL')
  })
});

export type SocialMediaSchema = Yup.InferType<typeof socialMedia>;
