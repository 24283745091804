import { Stack, TableCell, TableSortLabel } from '@mui/material';

interface HeaderCellsProps<K> {
  columns: Array<{ id: K; title: string; sortable?: boolean }>;
  sortConfig?: { key: K; direction: 'asc' | 'desc' };
  position?: boolean;
  onRequestSort?: (key: K) => void;
}

export const HeaderCells = <K,>({ columns, sortConfig, onRequestSort, position }: HeaderCellsProps<K>) => {
  return (
    <>
      {columns.map(({ id, title, sortable }) => (
        <TableCell key={title} sortDirection={sortConfig?.key === id ? sortConfig.direction : false} sx={{ backgroundColor: '#000' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            {sortable ? (
              <TableSortLabel
                active={sortConfig?.key === id}
                direction={sortConfig?.key === id ? sortConfig.direction : 'asc'}
                onClick={() => onRequestSort && onRequestSort(id)}
              >
                {title}
              </TableSortLabel>
            ) : (
              <Stack sx={{ width: '100%', textAlign: position ? 'end' : 'start' }}>{title}</Stack>
            )}
          </Stack>
        </TableCell>
      ))}
    </>
  );
};
