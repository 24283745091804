import { Divider, Grid, Stack, Typography } from '@mui/material';

import MainCard from 'components/MainCard';

import ProfileTab from './profileNavTab';

import { InstagramOutlined, FacebookOutlined, TwitterOutlined } from '@ant-design/icons';

import { PartnerProfile } from 'types/partnerProfile';

import UserAvatar from './userAvatar';

interface IProps {
  partner: PartnerProfile;
}

const ProfileTabs = ({ partner }: IProps) => {
  return (
    <>
      <MainCard>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Stack spacing={2.5} alignItems="center">
              <UserAvatar partner={partner} />

              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">
                  {partner.first_name} {partner.last_name}
                </Typography>
                <Typography color="secondary">{partner.company_name}</Typography>
              </Stack>
              <Stack direction="row" spacing={3} sx={{ '& svg': { fontSize: '1.15rem', cursor: 'pointer' } }}>
                {/* {partner.social_handles?.tiktok && (
                  <TikTokOutlined style={{ color: '#727272' }} onClick={() => window.open(partner.social_handles.tiktok, '_blank')} />
                )} */}
                {partner.social_handles?.instagram && (
                  <InstagramOutlined style={{ color: '#727272' }} onClick={() => window.open(partner.social_handles.instagram, '_blank')} />
                )}
                {partner.social_handles?.twitter && (
                  <TwitterOutlined style={{ color: '#727272' }} onClick={() => window.open(partner.social_handles.twitter, '_blank')} />
                )}
                {partner.social_handles?.facebook && (
                  <FacebookOutlined style={{ color: '#727272' }} onClick={() => window.open(partner.social_handles.facebook, '_blank')} />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={3} sx={{ display: { sm: 'block', md: 'none' } }} />
          <Grid item xs={12} sm={6} md={12}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">86</Typography>
                <Typography color="secondary">Events</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">40</Typography>
                <Typography color="secondary">Tickets sold</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">4.5K</Typography>
                <Typography color="secondary">Followers</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ProfileTab />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default ProfileTabs;
