import { useEffect, useState } from 'react';
import { ReactComponent as LoaderSVG } from '../../svg/Loader.svg';

import { Box, Stack, Typography } from '@mui/material';

const spinAnimation = {
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  animation: 'spin 1s linear infinite'
};

export const CustomLoader = ({ timeout = 2000, onComplete }: { timeout: number; onComplete: () => void }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalTime = 100;
    const increment = (intervalTime / timeout) * 100;

    const interval = setInterval(() => {
      setProgress((prev) => {
        const nextProgress = prev + increment;
        return nextProgress >= 100 ? 100 : nextProgress;
      });
    }, intervalTime);

    const timeoutId = setTimeout(() => {
      clearInterval(interval);
      setProgress(100);
      onComplete();
    }, timeout);

    return () => {
      clearInterval(interval);
      clearTimeout(timeoutId);
    };
  }, [timeout, onComplete]);

  return (
    <Stack alignItems="center">
      <Box
        component={LoaderSVG}
        sx={{
          width: 104,
          height: 104,
          ...spinAnimation
        }}
      />
      <Typography sx={{ mt: 6, fontSize: '21px', fontWeight: 300 }}>{`${Math.floor(progress)}%`}</Typography>
    </Stack>
  );
};
