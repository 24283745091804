import { Box, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { ReactComponent as TikTokSVG } from '../../../svg/tiktok.svg';
import { ReactComponent as InstagramSVG } from '../../../svg/instagram.svg';
import { ReactComponent as TwitterSVG } from '../../../svg/twitter.svg';
import { ReactComponent as FacebookSVG } from '../../../svg/facebook.svg';
import { ReactComponent as WebsiteSVG } from '../../../svg/website.svg';
import { socialMedia, SocialMediaSchema } from 'schemas/onboarding/socialMedia';
import { supabase } from 'supabase/supabase';
import { useFormik } from 'formik';
import usePartnerId from 'hooks/usePartnerId';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface FormProps {
  onNext: () => void;
  onSuccess: () => void;
  handleLinks: Dispatch<
    SetStateAction<{
      website: string;
      twitter: string;
      instagram: string;
      facebook: string;
      tiktok: string;
    }>
  >;
}

const SocialMediaForm = ({ onNext = () => null, handleLinks, onSuccess = () => null }: FormProps) => {
  const partnerId = usePartnerId();
  const [validatedLinks, setValidatedLinks] = useState({ website: '', twitter: '', instagram: '', facebook: '', tiktok: '' });

  const formik = useFormik<SocialMediaSchema>({
    initialValues: {
      social_handles: { website: '', twitter: '', instagram: '', facebook: '', tiktok: '' }
    },
    validationSchema: socialMedia,
    onSubmit: async (values) => {
      try {
        const { error } = await supabase
          .from('partners')
          .update([{ ...values, is_onboarding_finished: true }])
          .eq('id', partnerId);

        if (error) {
          throw error;
        }

        onSuccess();
        onNext();
      } catch (error) {
        enqueueSnackbar('Error updating social media links', { variant: 'error' });
      }
    }
  });

  const { getFieldProps, handleSubmit, errors, touched, isValid, values } = formik;

  useEffect(() => {
    const updatedLinks = { ...validatedLinks };

    if (!errors.social_handles?.twitter && touched.social_handles?.twitter) {
      updatedLinks.twitter = values.social_handles.twitter as string;
    } else {
      updatedLinks.twitter = '';
    }

    if (!errors.social_handles?.instagram && touched.social_handles?.instagram) {
      updatedLinks.instagram = values.social_handles.instagram as string;
    } else {
      updatedLinks.instagram = '';
    }

    if (!errors.social_handles?.facebook && touched.social_handles?.facebook) {
      updatedLinks.facebook = values.social_handles.facebook as string;
    } else {
      updatedLinks.facebook = '';
    }

    if (!errors.social_handles?.tiktok && touched.social_handles?.tiktok) {
      updatedLinks.tiktok = values.social_handles.tiktok as string;
    } else {
      updatedLinks.tiktok = '';
    }
    if (!errors.social_handles?.website && touched.social_handles?.website) {
      updatedLinks.website = values.social_handles.website as string;
    } else {
      updatedLinks.website = '';
    }

    setValidatedLinks(updatedLinks);
  }, [errors, touched, values.social_handles]);

  useEffect(() => {
    handleLinks(validatedLinks);
  }, [validatedLinks, handleLinks]);

  return (
    <>
      <Grid container sx={{ marginTop: '32px', flexDirection: 'column', gap: '32px' }}>
        <Stack sx={{ gap: '16px' }}>
          <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#fff' }}>Link Your Social Profiles</Typography>
          <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 300, color: '#fff' }}>
            Amplify your brand reach by linking your social media profiles to your Cene organiser page!
          </Typography>
        </Stack>
        <Grid>
          <Stack>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              <Box sx={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <WebsiteSVG style={{ fill: '#8057DB' }} />
              </Box>
              Website
            </InputLabel>
            <TextField
              autoComplete="off"
              variant="standard"
              {...getFieldProps('social_handles.website')}
              error={Boolean(touched.social_handles?.website && errors.social_handles?.website)}
              helperText={touched.social_handles?.website && errors.social_handles?.website}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="Enter your Website URL"
            />
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              <TikTokSVG style={{ fill: '#8057DB' }} />
              TikTok
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              {...getFieldProps('social_handles.tiktok')}
              error={Boolean(touched.social_handles?.tiktok && errors.social_handles?.tiktok)}
              helperText={touched.social_handles?.tiktok && errors.social_handles?.tiktok}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="Enter your TikTok URL"
            />
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              <InstagramSVG style={{ fill: '#8057DB' }} />
              Instagram
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              {...getFieldProps('social_handles.instagram')}
              error={Boolean(touched.social_handles?.instagram && errors.social_handles?.instagram)}
              helperText={touched.social_handles?.instagram && errors.social_handles?.instagram}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="Enter your Instagram URL"
            />
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              <TwitterSVG style={{ fill: '#8057DB' }} />X
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              {...getFieldProps('social_handles.twitter')}
              error={Boolean(touched.social_handles?.twitter && errors.social_handles?.twitter)}
              helperText={touched.social_handles?.twitter && errors.social_handles?.twitter}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="Enter your X URL"
            />
          </Stack>
        </Grid>
        <Grid>
          <Stack>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              <FacebookSVG style={{ fill: '#8057DB' }} />
              Facebook
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              {...getFieldProps('social_handles.facebook')}
              error={Boolean(touched.social_handles?.facebook && errors.social_handles?.facebook)}
              helperText={touched.social_handles?.facebook && errors.social_handles?.facebook}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="Enter your Facebook URL"
            />
          </Stack>
        </Grid>
        <ShapeButton width="100%" type="submit" disabled={!isValid} handleClick={handleSubmit}>
          Continue
        </ShapeButton>
      </Grid>
    </>
  );
};

export default SocialMediaForm;
