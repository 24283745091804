import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';

interface PermissionsGroup {
  group: string;
  permissions: { permission: string }[];
}

const useFetchPermissions = () => {
  const [permissions, setPermissions] = useState<PermissionsGroup[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { data: fetchedPermissions } = await supabase.from('grouped_super_admin_permissions').select('*');

      setPermissions(fetchedPermissions as PermissionsGroup[]);
    };

    fetchPermissions();
  }, []);

  return permissions;
};

export default useFetchPermissions;
