import { Avatar, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { ReactComponent as TikTokSVG } from '../../../svg/tiktok.svg';
import { ReactComponent as InstagramSVG } from '../../../svg/instagram.svg';
import { ReactComponent as TwitterSVG } from '../../../svg/twitter.svg';
import { ReactComponent as FacebookSVG } from '../../../svg/facebook.svg';
import { ReactComponent as WebsiteSVG } from '../../../svg/website.svg';
import { PartnerProfile } from 'types/partnerProfile';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IProps {
  partner?: PartnerProfile;
  socialMediaLinks: {
    website: string;
    twitter: string;
    instagram: string;
    facebook: string;
    tiktok: string;
  };
}

const EventOrganizerSection = ({ partner, socialMediaLinks }: IProps) => {
  const handleClick = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Grid container sx={{ justifyContent: 'space-between', gap: '24px', mt: 5 }}>
      <Grid item width="212px">
        <Avatar
          alt="Partner avatar"
          variant="square"
          src={partner?.profile_picture_url}
          sx={{
            width: 212,
            height: 272,
            border: '1px solid #424242a4',
            backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.6))',
            backgroundColor: '#272727',
            backgroundBlendMode: 'multiply',
            color: '#3b3b3b',
            borderRadius: '20px 0'
          }}
        />
      </Grid>
      <Grid item sx={{ width: 'calc(100% - 240px)', display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <Typography sx={{ fontSize: '42px', lineHeight: '42px', fontWeight: 300, color: '#B0E1CD' }}>{partner?.company_name}</Typography>
        <Typography sx={{ fontSize: '21px', lineHeight: '21px', fontWeight: 300 }}>{partner?.description}</Typography>
        <List sx={{ display: 'flex', gap: '8px' }}>
          {socialMediaLinks.website && (
            <ListItem
              onClick={() => handleClick(socialMediaLinks.website)}
              sx={{
                flexDirection: 'column',
                cursor: 'pointer',
                border: '1px solid transparent',
                borderRadius: '20px 0',
                '&': {
                  '& svg': {
                    fill: '#D7C7FA'
                  }
                },
                '&:hover': {
                  border: '1px solid #5E5E5E',
                  borderRadius: '20px 0',
                  '& svg': {
                    fill: '#5E5E5E'
                  }
                }
              }}
            >
              <ListItemIcon sx={{ width: '25px', height: '24px', alignItems: 'center', justifyContent: 'center' }}>
                <WebsiteSVG />
              </ListItemIcon>
              <ListItemText>Website</ListItemText>
            </ListItem>
          )}
          {socialMediaLinks.tiktok && (
            <ListItem
              onClick={() => handleClick(socialMediaLinks.tiktok)}
              sx={{
                flexDirection: 'column',
                cursor: 'pointer',
                border: '1px solid transparent',
                borderRadius: '20px 0',
                '&': {
                  '& svg': {
                    fill: '#D7C7FA'
                  }
                },
                '&:hover': {
                  border: '1px solid #5E5E5E',
                  borderRadius: '20px 0',
                  '& svg': {
                    fill: '#5E5E5E'
                  }
                }
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center' }}>
                <TikTokSVG />
              </ListItemIcon>
              <ListItemText>TikTok</ListItemText>
            </ListItem>
          )}
          {socialMediaLinks.instagram && (
            <ListItem
              onClick={() => handleClick(socialMediaLinks.instagram)}
              sx={{
                flexDirection: 'column',
                cursor: 'pointer',
                border: '1px solid transparent',
                borderRadius: '20px 0',
                '&': {
                  '& svg': {
                    fill: '#D7C7FA'
                  }
                },
                '&:hover': {
                  border: '1px solid #5E5E5E',
                  borderRadius: '20px 0',
                  '& svg': {
                    fill: '#5E5E5E'
                  }
                }
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center' }}>
                <InstagramSVG />
              </ListItemIcon>
              <ListItemText>Instagram</ListItemText>
            </ListItem>
          )}
          {socialMediaLinks.twitter && (
            <ListItem
              onClick={() => handleClick(socialMediaLinks.twitter)}
              sx={{
                flexDirection: 'column',
                cursor: 'pointer',
                border: '1px solid transparent',
                borderRadius: '20px 0',
                '&': {
                  '& svg': {
                    fill: '#D7C7FA'
                  }
                },
                '&:hover': {
                  border: '1px solid #5E5E5E',
                  borderRadius: '20px 0',
                  '& svg': {
                    fill: '#5E5E5E'
                  }
                }
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center' }}>
                <TwitterSVG />
              </ListItemIcon>
              <ListItemText>X</ListItemText>
            </ListItem>
          )}
          {socialMediaLinks.facebook && (
            <ListItem
              onClick={() => handleClick(socialMediaLinks.facebook)}
              sx={{
                flexDirection: 'column',
                cursor: 'pointer',
                border: '1px solid transparent',
                borderRadius: '20px 0',
                '&': {
                  '& svg': {
                    fill: '#D7C7FA'
                  }
                },
                '&:hover': {
                  border: '1px solid #5E5E5E',
                  borderRadius: '20px 0',
                  '& svg': {
                    fill: '#5E5E5E'
                  }
                }
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center' }}>
                <FacebookSVG />
              </ListItemIcon>
              <ListItemText>Facebook</ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
      <Stack sx={{ flexDirection: 'row', gap: '12px', mt: '76px', maxWidth: '556px' }}>
        <InfoCircleOutlined style={{ fontSize: '24px', height: '24px', color: '#8057DB' }} />
        <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 300, color: '#b2b2b2' }}>
          By connecting your accounts, you may curate experiences in novel ways and establish a closer relationship with your followers.
        </Typography>
      </Stack>
    </Grid>
  );
};

export default EventOrganizerSection;
