import * as Yup from 'yup';
import { SubscribePlan } from 'types/partner';
import { emailRule, phoneNumberRule } from './validationRules';

const socialLinkRegex = {
  tiktok: /^(https?:\/\/)?(www\.)?tiktok\.com(\/[A-Za-z0-9_-]+)?\/?$/,
  instagram: /^(https?:\/\/)?(www\.)?instagram\.com(\/[A-Za-z0-9_.]+)?\/?$/,
  twitter: /^(https?:\/\/)?(www\.)?x\.com(\/[A-Za-z0-9_]+)?\/?$/,
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com(\/[A-Za-z0-9_.]+)?\/?$/
};

export const partnerFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: emailRule,
  phone_number: phoneNumberRule,
  company_name: Yup.string().required('Event company name is required'),
  date_founded: Yup.date(),
  subscription_plan_id: Yup.string()
    .oneOf([SubscribePlan.STARTER, SubscribePlan.PRO, SubscribePlan.PREMIUM], 'Invalid subscription plan')
    .required('Subscription plan is required'),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Invalid event website url'
  ),
  message: Yup.string(),
  subscribe_newsletters: Yup.boolean(),
  description: Yup.string().required('Description is required'),
  social_handles: Yup.object().shape({
    tiktok: Yup.string().matches(socialLinkRegex.tiktok, 'Invalid TikTok profile URL').nullable(),
    instagram: Yup.string().matches(socialLinkRegex.instagram, 'Invalid Instagram profile URL').nullable(),
    twitter: Yup.string().matches(socialLinkRegex.twitter, 'Invalid Twitter profile URL').nullable(),
    facebook: Yup.string().matches(socialLinkRegex.facebook, 'Invalid Facebook profile URL').nullable()
  })
});

export default partnerFormSchema;
