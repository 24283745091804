import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog = ({ open, title, description, onClose, onConfirm }: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        sx: {
          textAlign: 'center',
          border: '1px solid #434343',
          padding: '20px',
          backgroundImage: 'none',
          backgroundColor: 'black',
          color: 'white',
          width: '488px'
        }
      }}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: '#fff',
            borderColor: '#434343',
            '&:hover': {
              backgroundColor: '#121212',
              color: '#fff',
              borderColor: '#434343'
            }
          }}
        >
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
