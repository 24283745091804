import { DownloadOutlined } from '@ant-design/icons';
import { Stack, Tooltip } from '@mui/material';

import { CSVLink } from 'react-csv';
import { useTheme } from '@mui/material';

interface TableExportProps {
  data: any;
  filename: string;
}

const TableExport = ({ data, filename }: TableExportProps) => {
  const theme = useTheme();
  return (
    <Stack>
      <CSVLink data={data} filename={filename}>
        <Tooltip title="CSV Export">
          <DownloadOutlined
            style={{ fontSize: '24px', color: theme.palette.text.secondary, marginTop: 4, marginRight: 4, marginLeft: 4 }}
          />
        </Tooltip>
      </CSVLink>
    </Stack>
  );
};

export default TableExport;
