export const ROUTE_HOME = '/';

export const ROUTE_WELCOME = '/welcome';

export const ROUTE_ONBOARDING = '/onboarding';

export const ROUTE_WELCOME_AFTER_ONBOARDING = '/welcome-after-onboarding';

export const ROUTE_FORGOT_PASSWORD = '/forgot-password';

export const ROUTE_CREATE_NEW_PASSWORD = '/create-new-password';

export const ROUTE_RESET_SUCCESS = '/reset-success';

export const ROUTE_CODE_VERIFICATION = '/code-verification';

export const ROUTE_CHECK_EMAIL = '/check-email';

export const ROUTE_DASHBOARD = '/dashboard';

export const ROUTE_PARTNERS = '/partners';

export const ROUTE_PARTNERS_REQUESTS = '/partners-requests';

export const ROUTE_CUSTOMERS = '/customers';

export const ROUTE_CENE_PLUS_REQUESTS = '/cene-plus-requests';

export const ROUTE_MEMBERS = '/members';

export const ROUTE_EVENTS = '/events';

export const ROUTE_BULK_REQUESTS = '/bulk-requests';

export const ROUTE_CREATE_EVENT = '/create-event';

export const ROUTE_FLOORPLANS = '/floorplans';

export const ROUTE_PARTNER = '/partner/:id';

export const ROUTE_PARTNER_INFORMATION = ROUTE_PARTNER + '/partner-information';

export const ROUTE_PARTNER_SUBSCRIPTIONS = ROUTE_PARTNER + '/subscriptions';

export const ROUTE_PARTNER_EVENTS = ROUTE_PARTNER + '/events';

export const ROUTE_PARTNER_TEAM = ROUTE_PARTNER + '/team';

export const ROUTE_PARTNER_SETTINGS = ROUTE_PARTNER + '/settings';

export const ROUTE_PROFILE = '/profile/:id';

export const ROUTE_PROFILE_INFORMATION = ROUTE_PROFILE + '/partner-information';

export const ROUTE_PROFILE_SUBSCRIPTIONS = ROUTE_PROFILE + '/subscriptions';

export const ROUTE_CUSTOMER = '/customer/:id';
export const ROUTE_CUSTOMER_USER_INFORMATION = ROUTE_CUSTOMER + '/user-information';
export const ROUTE_CUSTOMER_EVENTS_AND_PURCHASES = ROUTE_CUSTOMER + '/events-and-purchases';
export const ROUTE_CUSTOMER_GUESTS = ROUTE_CUSTOMER + '/guests';
export const ROUTE_CUSTOMER_SETTINGS = ROUTE_CUSTOMER + '/settings';

export const ROUTE_EVENT = '/event/:id';
export const ROUTE_EVENT_OFFLINE_ORDER = '/event/:id/order_offline';
export const ROUTER_EVENT_EDIT = '/events/:id';

export const ROUTE_OVERVIEW = '/overview';
export const ROUTE_TICKET_BUYERS = '/ticket-buyers';
export const ROUTE_TABLES_AND_GUESTS_MANAGEMENT = '/tables-and-guests-management';
export const ROUTE_ENTRY_MANAGER = '/entry-manager';
export const ROUTE_REQUESTS = '/requests';
export const ROUTE_SALES_AND_TRANSACTIONS = '/sales-and-transactions';
export const ROUTE_GUESTLISTS = '/guestlists';
export const ROUTE_PROMO_CODES = '/promo-codes';
export const ROUTE_MANAGE_EVENT_VISIBILITY = '/manage-event-visibility';

export const ROUTER_EVENT_OVERVIEW = ROUTE_EVENT + ROUTE_OVERVIEW;
export const ROUTER_EVENT_TICKET_BUYERS = ROUTE_EVENT + ROUTE_TICKET_BUYERS;
export const ROUTER_EVENT_TABLES_AND_GUESTS_MANAGEMENT = ROUTE_EVENT + ROUTE_TABLES_AND_GUESTS_MANAGEMENT;
export const ROUTER_EVENT_ENTRY_MANAGER = ROUTE_EVENT + ROUTE_ENTRY_MANAGER;
export const ROUTER_EVENT_REQUESTS = ROUTE_EVENT + ROUTE_REQUESTS;
export const ROUTER_EVENT_SALES_AND_TRANSACTIONS = ROUTE_EVENT + ROUTE_SALES_AND_TRANSACTIONS;
export const ROUTER_EVENT_GUESTLISTS = ROUTE_EVENT + ROUTE_GUESTLISTS;
export const ROUTER_EVENT_PROMO_CODES = ROUTE_EVENT + ROUTE_PROMO_CODES;
export const ROUTER_EVENT_MANAGE_EVENT_VISIBILITY = ROUTE_EVENT + ROUTE_MANAGE_EVENT_VISIBILITY;

export const ROUTE_TRANSACTION = '/transaction/:id';

export const ROUTE_MANAGE_TEAM = '/manage-team/:id';

export const ROUTE_FLOORPLAN_REQUESTS = '/floorplan-requests';

export const ROUTE_ASSET_LIBRARY = '/asset-library';

export const ROUTE_DEFAULT = '/default';

export const ROUTE_VENUES_AND_FLOORPLANS = '/venues-and-floorplans';

export const ROUTE_VENUE = '/venue/:id';
export const ROUTER_VENUE_OVERVIEW = ROUTE_VENUE + ROUTE_OVERVIEW;
export const ROUTER_VENUE_PARTNERS = ROUTE_VENUE + '/partners';
export const ROUTER_VENUE_FLOORPLANS = ROUTE_VENUE + '/floorplans';

export const ROUTE_FLOORPLAN = '/floorplan/:floorplanId';
export const ROUTER_VENUE_NEW_FLOORPLAN = ROUTE_VENUE + '/new-floorplan';
export const ROUTER_VENUE_FLOORPLAN = ROUTE_VENUE + ROUTE_FLOORPLAN;
export const ROUTER_VENUE_FLOORPLAN_LOADER = ROUTER_VENUE_FLOORPLAN + '/loading';
export const ROUTER_VENUE_FLOORPLAN_ASSIGN = ROUTER_VENUE_FLOORPLAN + '/assign';
export const ROUTER_VENUE_FLOORPLAN_VIEW = ROUTER_VENUE_FLOORPLAN + '/view';
