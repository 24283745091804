import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, Card, Stack, IconButton } from '@mui/material';
import { FormLabel } from '@mui/material';
import { useState } from 'react';
import { DragAndDrop } from 'components/dragAndDrop/dragAndDrop';
import Avatar from 'components/@extended/Avatar';
import { CropedPicture, useCroper } from 'hooks/useCroper';
import ImageCropper from 'components/imageCropper/imageCropper';
import { uploadPictures } from 'utils/bucket';
import usePartnerId from 'hooks/usePartnerId';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { PartnerProfile } from 'types/partnerProfile';
import { ReactComponent as UploadSVG } from '../../../svg/upload-cloud.svg';
import { CircularProgress } from '@mui/material';

interface IProps {
  partner?: PartnerProfile;
  width?: string;
  onSuccess?: () => void;
}

const UserAvatar = ({ partner, width = '124px', onSuccess = () => null }: IProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCropModal, setOpenCropModal] = useState<string | null>(null);
  const [avatar, setAvatar] = useState<CropedPicture[]>([]);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(partner?.profile_picture_url || null);
  const { setFiles, error, removePicture, cropPicture } = useCroper({
    maxUploadFiles: 1,
    aspect: 3 / 4,
    onChange: (picture) => {
      setAvatar(picture);
    },
    initialPicture: avatar
  });

  const partnerId = usePartnerId();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const data = await uploadPictures(avatar);

      if (data?.[0]) {
        const pictureUrl = data[0];

        const { error } = await supabase.from('partners').update({ profile_picture_url: pictureUrl }).eq('id', partnerId);

        if (error) {
          throw error;
        }

        setAvatarUrl(pictureUrl);

        enqueueSnackbar('Profile avatar updated successfully.', { variant: 'success' });
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later.', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const openCropModal = (id: string) => setOpenCropModal(id);

  const getCopedPicture = avatar.find((picture) => picture.id === isOpenCropModal);

  const renderPictures = avatar.map((picture) => (
    <Stack key={picture.id} sx={{ height: 'auto', position: 'relative', backgroundColor: 'rgba(118, 118, 118, 0.3)', borderRadius: '4px' }}>
      <img src={picture.croped} alt={picture.id} style={{ display: 'block', maxWidth: '80px', height: 'auto' }} />
      <Stack direction="row" justifyContent="space-between">
        <IconButton color="error" onClick={() => removePicture(picture.id)}>
          <DeleteOutlined />
        </IconButton>
        <IconButton color="info" onClick={() => openCropModal(picture.id)}>
          <EditOutlined />
        </IconButton>
      </Stack>
    </Stack>
  ));

  return (
    <>
      <FormLabel
        htmlFor="change-avtar"
        onClick={handleOpen}
        sx={{
          position: 'relative',
          borderRadius: '20px 0',
          overflow: 'hidden',
          '&:hover .MuiBox-root': { opacity: 1 },
          cursor: 'pointer'
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width,
              height: width,
              border: '1px solid #424242a4',
              backgroundColor: '#1818188f',
              borderRadius: '20px 0'
            }}
          >
            <CircularProgress size={60} sx={{ color: '#8057DB' }} />
          </Box>
        ) : avatarUrl ? (
          <Avatar
            alt="Partner avatar"
            variant="square"
            src={avatarUrl}
            sx={{
              width,
              height: width,
              border: '1px solid #424242a4',
              backgroundColor: '#1818188f',
              color: '#3b3b3b',
              borderRadius: '20px 0'
            }}
          />
        ) : (
          <Box
            sx={{
              width,
              height: width,
              borderRadius: '30px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '8px',
              backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.6))',
              backgroundColor: '#8057DB',
              backgroundBlendMode: 'multiply',
              textAlign: 'center'
            }}
          >
            <UploadSVG />
            <Typography sx={{ fontSize: '14px', color: '#fff' }}>Upload Your Logo Here</Typography>
            <Typography sx={{ fontSize: '14px', color: '#fff', mt: '4px' }}>Upload</Typography>
          </Box>
        )}
      </FormLabel>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-avatar-dialog-title"
        aria-describedby="upload-avatar-dialog--description"
        sx={{
          '& .MuiDialog-paper': { minWidth: 350, backgroundColor: '#000000', color: '#FFFFFF' }
        }}
      >
        <DialogTitle id="upload-avatar-dialog-title">Crop Your Avatar</DialogTitle>

        <DialogContent>
          <DragAndDrop error={error} onUpload={setFiles} />
          {avatar.length !== 0 && (
            <Card variant="outlined" sx={{ marginTop: 2, padding: 1, display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {renderPictures}
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              color: '#fff',
              borderColor: '#434343',
              '&:hover': {
                backgroundColor: '#121212',
                color: '#fff',
                borderColor: '#434343'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleUpload}
            sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      {isOpenCropModal != null && (
        <ImageCropper
          crop={getCopedPicture?.crop}
          src={getCopedPicture?.original}
          onClose={() => setOpenCropModal(null)}
          onCropComplete={(croped) => {
            cropPicture(getCopedPicture?.id, croped);
            setOpenCropModal(null);
          }}
          aspect={3 / 4}
        />
      )}
    </>
  );
};

export default UserAvatar;
