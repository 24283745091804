import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { CheckOutlined } from '@ant-design/icons';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

interface IStepsProps {
  activeStep: number;
  onClickLabel: (index: number) => void;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'white'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'white'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'white',
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[200]
    })
  }
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme }) => ({
  color: '#8057DB',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#8057DB',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[200]
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#8057DB'
      }
    }
  ]
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <CheckOutlined className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

export const Steps = ({ activeStep = 0, onClickLabel }: IStepsProps) => {
  const steps = [
    'Your Cene',
    'About Event',
    'When and Where',
    'Find Your Tribe',
    'Doors and Lineup',
    'Checkout and Payment Options',
    'Tickets'
  ];

  return (
    <Stack
      sx={{
        width: '100%',
        paddingInline: '10px',
        paddingBlock: '15px',
        background: '#222222',
        borderTopLeftRadius: '20px',
        borderBottomRightRadius: '20px'
      }}
    >
      <Stepper activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map(
          (label, index) =>
            label && (
              <Step key={label}>
                <StepLabel
                  sx={{
                    cursor: 'pointer !important',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                  onClick={() => onClickLabel(index)}
                  StepIconComponent={QontoStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            )
        )}
      </Stepper>
    </Stack>
  );
};
