import { EditOutlined, UserOutlined } from '@ant-design/icons';
import {
  capitalize,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import ReusableTabs from 'components/tabs/reusableTabs';
import { DebouncedInput } from 'components/third-party/react-table';
import { ROUTE_TRANSACTION } from 'constants/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Transaction } from '.';
import { format } from 'date-fns';
import { Pagination } from '@mui/material';

export enum TransactionColumnId {
  ID = 'Order ID',
  CUSTOMER_NAME = 'customerName',
  ORDER_DATE = 'orderDate',
  TICKET_CATEGORY = 'ticketCategory',
  TICKETS_IN_ORDER = 'ticketsInOrder',
  PAID_VIA = 'paidVia',
  STATUS = 'status',
  PRICE = 'price',

  ACTIONS = 'actions'
}

interface TableColumn {
  id: TransactionColumnId;
  title: string;
  sortable: boolean;
  cell?: (transaction: Transaction) => React.ReactNode;
}

const transactionColumns: TableColumn[] = [
  {
    id: TransactionColumnId.CUSTOMER_NAME,
    title: 'Customer name',
    sortable: true,
    cell: (transaction) => transaction.customer_name
  },
  { id: TransactionColumnId.ID, title: 'Order ID', sortable: true, cell: (transaction) => transaction.id },
  {
    id: TransactionColumnId.ORDER_DATE,
    title: 'Order date',
    sortable: true,
    cell: (transaction) => format(new Date(transaction.orderDate), 'dd.MM.yyyy')
  },
  {
    id: TransactionColumnId.TICKET_CATEGORY,
    title: 'Ticket category',
    sortable: true,
    cell: (transaction) =>
      transaction.tickets
        .reduce((acc: string[], { ticket_name }) => {
          if (!acc.includes(ticket_name)) {
            acc.push(ticket_name);
          }
          return acc;
        }, [])
        .join(', ')
  },
  {
    id: TransactionColumnId.TICKETS_IN_ORDER,
    title: 'Tickets in order',
    sortable: true,
    cell: (transaction) => transaction.tickets.reduce((acc, { quantity }) => acc + quantity, 0)
  },
  { id: TransactionColumnId.PAID_VIA, title: 'Paid via', sortable: true, cell: (transaction) => capitalize(transaction.payment) },
  { id: TransactionColumnId.STATUS, title: 'Status', sortable: true, cell: (transaction) => transaction.status },
  { id: TransactionColumnId.PRICE, title: 'Price', sortable: true, cell: (transaction) => `${transaction.currency} ${transaction.amount}` }

  // { id: TransactionColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const getColumnValue = (column: TableColumn, table: Transaction): React.ReactNode => {
  return column.cell ? column.cell(table) : '';
};

interface TransactionRowsProps {
  transactions: Transaction[];
  onSuccess?: () => void;
}

export const getStatusBackgroundColor = (status: string) => {
  switch (status) {
    case 'pending':
      return '#FFFACD';
    case 'success':
      return '#C8E6C9';
    case 'failed':
      return '#FFCDD2';
    case 'Refunded':
      return '#BBDEFB';
    default:
      return 'transparent';
  }
};

const TransactionTableRows = ({ transactions = [], onSuccess = () => null }: TransactionRowsProps) => {
  const navigate = useNavigate();

  const renderCellContent = (column: TableColumn, transaction: Transaction) => {
    if (column.id === TransactionColumnId.STATUS) {
      return (
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize',
            padding: '8px 8px',
            fontWeight: 400,
            fontSize: '12px',
            backgroundColor: getStatusBackgroundColor(transaction.status),
            color: '#000',
            borderRadius: '4px'
          }}
        >
          {transaction.status}
        </Typography>
      );
    } else if (column.id === TransactionColumnId.TICKET_CATEGORY) {
      return (
        <Typography
          sx={{
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize',
            padding: '8px 8px',
            fontWeight: 400,
            fontSize: '12px',
            color: '#fff',
            borderRadius: '4px'
          }}
        >
          {transaction.tickets
            .reduce((acc: string[], { ticket_name }) => {
              if (!acc.includes(ticket_name)) {
                acc.push(ticket_name);
              }
              return acc;
            }, [])
            .join(', ')}
        </Typography>
      );
    } else if (column.id === TransactionColumnId.ACTIONS) {
      return (
        <>
          <Tooltip title="Info">
            <IconButton onClick={() => navigate(`${ROUTE_TRANSACTION.replace(':id', transaction.id)}`, { state: { transaction } })}>
              <UserOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton>
              <EditOutlined />
            </IconButton>
          </Tooltip>
        </>
      );
    }
    return getColumnValue(column, transaction);
  };

  return (
    <>
      {transactions.map((transaction) => (
        <TableRow key={transaction.id}>
          {transactionColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, transaction)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface TransactionsTableProps {
  isLoading?: boolean;
  transactions?: Transaction[];
  onSuccess?: () => void;
}

enum ITab {
  ALL = 'All',
  CONFIRMED = 'Success',
  CANCELLED = 'Failed',
  PENDING = 'Pending'
  // REFUND = 'Refunded'
}

const SalesAndTransactionsTable = ({ onSuccess = () => null, isLoading = false, transactions = [] }: TransactionsTableProps) => {
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [sortConfig, setSortConfig] = useState<{ key: TransactionColumnId; direction: 'asc' | 'desc' }>({
    key: TransactionColumnId.CUSTOMER_NAME,
    direction: 'asc'
  });
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, filter]);

  const filterTransactions = transactions.filter((transaction) => {
    const statusMatches = activeTab === ITab.ALL || transaction.status === activeTab.toLowerCase();

    const matchesFilter = transactionColumns.some((column) => {
      const value = getColumnValue(column, transaction);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    });

    return statusMatches && matchesFilter;
  });

  const sortedTransactions = [...filterTransactions].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = transactionColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    if (key === TransactionColumnId.ORDER_DATE) {
      const dateA = new Date(String(valueA));
      const dateB = new Date(String(valueB));
      return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    }

    if (key === TransactionColumnId.PRICE) {
      const extractPrice = (value: string): number => {
        if (value.includes('N')) {
          return parseFloat(value.replace(/[^0-9.-]+/g, '')) / 1634;
        } else if (value.includes('$')) {
          return parseFloat(value.replace(/[^0-9.-]+/g, ''));
        }
        return 0;
      };

      const priceA = extractPrice(String(valueA));
      const priceB = extractPrice(String(valueB));

      return direction === 'asc' ? priceA - priceB : priceB - priceA;
    }

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const totalPages = Math.ceil(filterTransactions.length / itemsPerPage);

  const paginatedTransactions = sortedTransactions?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const requestSort = (key: TransactionColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Sales and transactions</Typography>
      </Stack>
      <MainCard content={false}>
        <Stack sx={{ padding: 3 }}>
          <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />
        </Stack>

        <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 3.5, pl: 3 }}>
          <Stack width={300}>
            <DebouncedInput
              value={filter ?? ''}
              onFilterChange={(value) => setFilter(String(value))}
              placeholder="Search transaction..."
              sx={{ width: '300px !important' }}
            />
          </Stack>
        </Stack>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<TransactionColumnId> columns={transactionColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={transactionColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : transactions.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={transactionColumns.length}>
                    <Stack textAlign="center">No sales and transactions yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TransactionTableRows onSuccess={onSuccess} transactions={paginatedTransactions} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack sx={{ alignItems: 'center', py: 3 }}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
        </Stack>
      </MainCard>
    </>
  );
};

export default SalesAndTransactionsTable;
