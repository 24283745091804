import { isValidNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const memberInvitesSchema = Yup.object({
  members: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      job_title: Yup.string().required('Job title is required'),
      phone_number: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone number is invalid', (value) => {
          if (!value) return false;
          return isValidNumber(value);
        }),
      email: Yup.string()
        .email('Invalid email')
        .required('Email is required')
        .matches(
          /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email format. Please enter a valid email address.'
        )
        .min(1, 'At least one member is required')
    })
  )
});

export type MemberInvitesSchema = Yup.InferType<typeof memberInvitesSchema>;
