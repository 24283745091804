import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { PermissionsGroup } from 'types/role';

const useGetRolePermissions = (userId: string | undefined) => {
  const [permissions, setPermissions] = useState<PermissionsGroup[]>([]);
  const [loading, setLoading] = useState(true);
  const [tenantId, setTenantId] = useState('');

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true);
      try {
        const { data: user, error: userError } = await supabase.from('partner_users').select('partner_id').eq('user_id', userId).single();

        if (userError) throw userError;

        setTenantId(user.partner_id);

        const permissionsTable =
          user.partner_id === '00000000-0000-0000-0000-000000000000' ? 'grouped_super_admin_permissions' : 'grouped_admin_permissions';

        const { data: permissionsData, error: permissionsError } = await supabase.from(permissionsTable).select('*');

        if (permissionsError) throw permissionsError;

        setPermissions(permissionsData as PermissionsGroup[]);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [userId]);

  return { permissions, loading, tenantId };
};

export default useGetRolePermissions;
