import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import { EventLoading } from '../event/event.loading';
import EventDetails from '../ceneEvents/eventDetails/eventDetails';
import { Event, Ticket } from '@ceneteam/namespace';
import { SeatsioTables } from 'seatsio/tables';
import { HoldToken, Pricing, SeatingChart, SelectableObject } from '@seatsio/seatsio-react';
import ShapeButton from 'components/button/button';
import { SelectableModal } from './selectable.modal';
import { Timer } from './timer';
import { Stack } from '@mui/material';
import { seatsioInstance } from 'api/seatsio';
import { CartModal } from './cart.modal';
import { ArrowBack } from '@mui/icons-material';
import { ROUTER_EVENT_TABLES_AND_GUESTS_MANAGEMENT } from 'constants/routes';

export type Cart = Ticket & { selectableObject: SelectableObject; seatId: string };

export const OfflineOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [event, setEvent] = useState<Event | null>(null);
  const [seatsioSession, setSeatsioSession] = useState<HoldToken | null>(null);
  const [chart, setChart] = useState<SeatingChart | null>(null);
  const [holdToken, setHoldToken] = useState<HoldToken | null>(null);
  const [cart, setCart] = useState<Cart[]>([]);
  const [selectableModal, setSelectableModal] = useState<SelectableObject | null>(null);
  const [isOpenCartModal, setOpenCartModal] = useState(false);

  useEffect(() => {
    fetchEvent();
  }, [id]);

  const fetchEvent = async () => {
    try {
      const response = await seatsioInstance.post('/seatsio/hold-tokens', {
        expiresInMinutes: 60
      });

      setHoldToken(response.data);

      const { data } = await supabase.from('events').select('*').eq('id', id).single();

      setEvent(data as Event);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingEvent(false);
    }
  };

  if (loadingEvent || !holdToken) return <EventLoading title={'Event loading...'} />;

  const pricing = event?.tickets?.map((ticket) => ({
    category: ticket.name,
    ticketTypes: [{ ticketType: 'Seat', price: ticket.NGN || 0 }]
  }));

  const handleObjectClicked = (object: SelectableObject) => {
    setSelectableModal(object);
  };

  const handleAddToBasket = () => {
    if (!selectableModal || !event) return;

    (selectableModal as any)?.confirmSelection('Seat');

    const getTicketInformation = event.tickets?.find((item) => item.name === selectableModal.labels.section);

    setCart([
      ...cart,
      {
        quantity: 1,
        selectableObject: selectableModal,
        ...getTicketInformation
      } as Cart
    ]);

    setSelectableModal(null);
  };

  const handleTokenExpired = () => {
    /* Clear session */
    setSeatsioSession(null);

    /* Clear selectable object */
    setSelectableModal(null);

    setCart([]);
  };

  const handleRemoveItem = (id: string) => {
    console.log(id);

    chart?.deselectObjects([id]);
  };

  const handleChartRendered = (chart: SeatingChart) => {
    setChart(chart);
  };

  const handleDeselect = (selectedObject: SelectableObject & { seatId: string }) => {
    setCart(cart.filter((item) => item.seatId === selectedObject.seatId));
  };

  const handleInitSelectableObjects = (selectableObjects: SelectableObject[]) => {
    if (!event) return;

    if (selectableObjects.length === 0) return;

    // eslint-disable-next-line array-callback-return
    selectableObjects.map((item) => {
      const getTicketInformation = event.tickets?.find((item2) => item2.name === item.labels.section);

      setCart([
        ...cart,
        {
          quantity: 1,
          selectableObject: item,
          ...getTicketInformation
        } as Cart
      ]);
    });
  };

  return (
    <>
      <Stack flexDirection="row" alignItems="center" gap={2} sx={{ position: 'fixed', top: '6px', right: '20px', zIndex: 9999, gap: 2 }}>
        {seatsioSession && <Timer initial={seatsioSession.expiresInSeconds} />}
        <ShapeButton
          disabled={cart.length === 0}
          sx={{
            paddingInline: '20px'
          }}
          backgroundColor="#B6A5DC"
          bgHover="#9b8cbd"
          handleClick={() => setOpenCartModal(true)}
          width="auto"
          color="black"
        >
          Selected seats and tables: {cart.length}
        </ShapeButton>
      </Stack>
      <Stack width="200px" mb={2}>
        <ShapeButton
          sx={{
            paddingInline: '20px'
          }}
          backgroundColor="#B6A5DC"
          bgHover="#9b8cbd"
          handleClick={() => navigate(ROUTER_EVENT_TABLES_AND_GUESTS_MANAGEMENT.replace(':id', `${id}`))}
          width="auto"
          color="black"
        >
          <ArrowBack /> Back to event
        </ShapeButton>
      </Stack>
      <EventDetails event={event} />

      <SeatsioTables
        currencyLabel="₦"
        eventId={id as string}
        holdToken={(holdToken as any).holdToken}
        pricing={pricing as Pricing}
        onChartRendered={(chart) => handleChartRendered(chart)}
        onSessionInitialized={setSeatsioSession}
        onHoldTokenExpired={handleTokenExpired}
        onObjectClicked={handleObjectClicked}
        onListCategories={() => {}}
        onObjectDeselected={(obj) => handleDeselect(obj as SelectableObject & { seatId: string })}
        onInitSelectableObjects={handleInitSelectableObjects}
      />
      {isOpenCartModal && event && (
        <CartModal
          onClearCart={() => setCart([])}
          onRemove={handleRemoveItem}
          onCloseModal={() => setOpenCartModal(false)}
          event={event}
          cart={cart}
        />
      )}
      {selectableModal && (
        <SelectableModal
          onCloseModal={() => setSelectableModal(null)}
          onSelectObject={() => handleAddToBasket()}
          selectableObject={selectableModal}
        />
      )}
    </>
  );
};
