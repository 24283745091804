import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { CreditCardOutlined, UserOutlined } from '@ant-design/icons';

const getPathIndex = (pathname: string) => {
  if (pathname.includes('/subscriptions')) {
    return 1;
  } else {
    return 0;
  }
};

const ProfileTab = () => {
  const theme = useTheme();
  const { id: partnerId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(getPathIndex(pathname));

  const handleListItemClick = (index: number, route: string) => {
    setSelectedIndex(index);
    if (partnerId) {
      navigate(route.replace(':id', partnerId));
    }
  };

  useEffect(() => {
    setSelectedIndex(getPathIndex(pathname));
  }, [pathname]);

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={() => handleListItemClick(0, `/profile/${partnerId}/partner-information`)}
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#C4FAE4E5',
            color: '#000',
            '&:hover': { backgroundColor: '#c4fae469' },
            '& .MuiListItemIcon-root': {
              color: '#000'
            }
          }
        }}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Partner Information" />
      </ListItemButton>

      <ListItemButton
        selected={selectedIndex === 1}
        onClick={() => handleListItemClick(1, `/profile/${partnerId}/subscriptions`)}
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#C4FAE4E5',
            color: '#000',
            '&:hover': { backgroundColor: '#c4fae469' },
            '& .MuiListItemIcon-root': {
              color: '#000'
            }
          }
        }}
      >
        <ListItemIcon>
          <CreditCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Subscriptions" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
