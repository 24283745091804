import { useEffect, useState } from 'react';
import { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { v4 as uuidv4 } from 'uuid';

export type CropedPicture = {
  id: string;
  original: string;
  croped: string;
  crop: Crop;
  file: File;
};

interface IUseCroper {
  maxUploadFiles: number;
  aspect: number;
  onChange: (pictures: CropedPicture[]) => void;
  initialPicture: CropedPicture[];
}

export const useCroper = ({ initialPicture = [], maxUploadFiles = 100, aspect = 1, onChange }: IUseCroper) => {
  const [pictures, setPictures] = useState<CropedPicture[]>(initialPicture);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    onChange(pictures);
  }, [pictures]);

  const onSetFiles = (uploadedFiles: File[]) => {
    setError(null);

    if (uploadedFiles.length > maxUploadFiles || pictures.length + 1 > maxUploadFiles)
      return setError(`number of files cannot exceed ${maxUploadFiles} file(s)`);

    uploadedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = async () => {
        const { cropedImage, crop } = await initialCrop(reader.result as string);

        setPictures((prev) => [
          ...prev,
          {
            id: uuidv4(),
            original: reader.result as string,
            croped: cropedImage as string,
            crop,
            file
          }
        ]);
      };

      reader.readAsDataURL(file);
    });
  };

  const initialCrop = (imageSrc: string) => {
    return new Promise<{ cropedImage: string; crop: Crop }>((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        const crop = centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: image.width
            },
            aspect,
            image.width,
            image.height
          ),
          image.width,
          image.height
        );

        resolve({ cropedImage: getCropedImage(image, crop), crop });
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getCropedImage = (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropWidth = (crop.width / 100) * image.naturalWidth;
    const cropHeight = (crop.height / 100) * image.naturalHeight;
    const cropX = (crop.x / 100) * image.naturalWidth;
    const cropY = (crop.y / 100) * image.naturalHeight;
    canvas.width = Math.floor(cropWidth * scaleX * pixelRatio);
    canvas.height = Math.floor(cropHeight * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    return canvas.toDataURL('image/jpeg');
  };

  const cropPicture = (id: string | undefined, crop: Crop | undefined) => {
    if (!id || !crop) return;

    const getPicture = pictures.find((picture) => picture.id === id);

    const image = new Image();
    image.src = getPicture?.original as string;

    image.onload = () => {
      const croped = getCropedImage(image, crop);

      setPictures([
        ...pictures.map((picture) => {
          if (picture.id === id) {
            picture.id = uuidv4();
            picture.crop = crop;
            picture.croped = croped;
          }

          return picture;
        })
      ]);
    };
  };

  const removePicture = (id: string) => {
    setPictures(pictures.filter((picture) => picture.id !== id));
  };

  return {
    pictures,
    error,
    setFiles: onSetFiles,
    cropPicture,
    removePicture
  };
};
