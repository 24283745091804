import { Button, Grid, InputLabel, Stack, TextField, Alert, Divider, Typography, Chip, Box } from '@mui/material';
import { DatePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useFormik, Form, FormikProvider } from 'formik';

import { supabase } from 'supabase/supabase';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import partnerFormSchema from 'schemas/partnerFormSchema';
import { format, parse, parseISO } from 'date-fns';

import { PartnerProfile, SubscribePlan } from 'types/partnerProfile';
import { CloseOutlined } from '@ant-design/icons';
import { CustomInput } from 'components/customInput/customInput';

const getInitialValues = (partner: PartnerProfile | null): PartnerProfile => ({
  id: partner?.id ?? '',
  first_name: partner?.first_name ?? '',
  last_name: partner?.last_name ?? '',
  email: partner?.email ?? '',
  phone_number: partner?.phone_number ?? '',
  company_name: partner?.company_name ?? '',
  date_founded: partner?.date_founded ?? format(new Date(), 'yyyy-MM-dd'),
  subscription_plan_id: partner?.subscription_plan_id ?? SubscribePlan.STARTER,
  social_handles: partner?.social_handles ?? { twitter: '', instagram: '', facebook: '', tiktok: '' },
  description: partner?.description ?? ''
});

export type PartnerFormProps = { currentObject: PartnerProfile | null; onSuccess?: () => void };

const PartnerForm = ({ currentObject, onSuccess = () => null }: PartnerFormProps) => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);

  const currentUpdateDate = format(parseISO(currentObject?.updated_at as string), 'dd.MM.yyyy');

  const formik = useFormik({
    initialValues: {
      ...getInitialValues(currentObject),
      date_founded: currentObject?.date_founded || format(new Date(), 'yyyy-MM-dd'),
      subscription_plan_id: currentObject?.subscription_plan_id || SubscribePlan.STARTER
    },

    validationSchema: partnerFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        const { social_handles, ...rest } = values;

        if (currentObject) {
          const { error } = await supabase
            .from('partners')
            .update([
              {
                ...rest,
                social_handles
              }
            ])
            .eq('id', currentObject.id);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Partner updated successfully', { variant: 'success' });
        } else {
          const { error } = await supabase.from('partners').insert([
            {
              ...rest,
              social_handles
            }
          ]);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Partner created successfully', { variant: 'success' });
        }

        onSuccess();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const minDate = new Date();

  const handleCancel = () => {
    formik.resetForm({
      values: getInitialValues(currentObject)
    });
  };

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {supabaseError && (
              <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
                {supabaseError}
              </Alert>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="company_name" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Event Organiser Name
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="company_name"
                        placeholder="Enter event organizer name..."
                        {...getFieldProps('company_name')}
                        error={Boolean(touched.company_name && errors.company_name)}
                        helperText={touched.company_name && errors.company_name}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="date_founded" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Company Founded
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          defaultValue={minDate}
                          value={parse(formik.values.date_founded as string, 'yyyy-MM-dd', new Date())}
                          onChange={(newValue) => {
                            if (newValue) {
                              const formattedDate = format(newValue, 'yyyy-MM-dd');
                              formik.setFieldValue('date_founded', formattedDate);
                            } else {
                              return;
                            }
                          }}
                          format="dd.MM.yyyy"
                          slotProps={{
                            popper: {
                              sx: {
                                [`.${pickersLayoutClasses.root}`]: {
                                  marginBlock: '12px'
                                }
                              }
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="date_approved" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Date Approved
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          defaultValue={minDate}
                          value={currentObject?.updated_at ? parse(currentUpdateDate as string, 'dd.MM.yyyy', new Date()) : null}
                          format="dd.MM.yyyy"
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-disabled': {
                              backgroundColor: '#272727',
                              '& .MuiOutlinedInput-input': { opacity: '1', WebkitTextFillColor: '#fff', color: '#fff' }
                            }
                          }}
                          disabled
                          slotProps={{
                            popper: {
                              sx: {
                                [`.${pickersLayoutClasses.root}`]: {
                                  marginBlock: '12px'
                                }
                              }
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="first_name" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Contact First Name
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="first_name"
                        placeholder="Enter first name..."
                        {...getFieldProps('first_name')}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="last_name" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Contact Last Name
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="last_name"
                        placeholder="Enter last name..."
                        {...getFieldProps('last_name')}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phone_number" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Contact Phone Number
                      </InputLabel>

                      <TextField
                        fullWidth
                        id="phone_number"
                        placeholder="Enter phone number..."
                        {...getFieldProps('phone_number')}
                        error={Boolean(touched.phone_number && errors.phone_number)}
                        helperText={touched.phone_number && errors.phone_number}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Contact Email
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        placeholder="Enter e-mail..."
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="description" sx={{ fontSize: '16px' }}>
                        Company description
                      </InputLabel>
                      <Divider
                        sx={{
                          marginTop: '16px !important',
                          position: 'relative',
                          width: 'calc(100% + 40px)',
                          left: '-20px',
                          borderColor: '#262626'
                        }}
                      />
                      <CustomInput
                        multiline
                        placeholder="Enter partner description..."
                        required
                        label=""
                        name="description"
                        value={formik.values.description}
                        error={formik.touched.description ? formik.errors.description : null}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <Typography sx={{ fontSize: '16px' }}>Social Media Accounts</Typography>
                      <Divider
                        sx={{
                          marginTop: '16px !important',
                          position: 'relative',
                          width: 'calc(100% + 40px)',
                          left: '-20px',
                          borderColor: '#262626'
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="twitter" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Twitter
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="twitter"
                        placeholder="Enter Twitter handle..."
                        {...getFieldProps('social_handles.twitter')}
                        error={Boolean(touched.social_handles?.twitter && errors.social_handles?.twitter)}
                        helperText={touched.social_handles?.twitter && errors.social_handles?.twitter}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="instagram" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Instagram
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="instagram"
                        placeholder="Enter Instagram handle..."
                        {...getFieldProps('social_handles.instagram')}
                        error={Boolean(touched.social_handles?.instagram && errors.social_handles?.instagram)}
                        helperText={touched.social_handles?.instagram && errors.social_handles?.instagram}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="tiktok" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        TikTok
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="tiktok"
                        placeholder="Enter TikTok handle..."
                        {...getFieldProps('social_handles.tiktok')}
                        error={Boolean(touched.social_handles?.tiktok && errors.social_handles?.tiktok)}
                        helperText={touched.social_handles?.tiktok && errors.social_handles?.tiktok}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="facebook" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                        Facebook
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="facebook"
                        placeholder="Enter Facebook link..."
                        {...getFieldProps('social_handles.facebook')}
                        error={Boolean(touched.social_handles?.facebook && errors.social_handles?.facebook)}
                        helperText={touched.social_handles?.facebook && errors.social_handles?.facebook}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <Typography sx={{ fontSize: '16px' }}>Access Accounts</Typography>
                      <Divider
                        sx={{
                          marginTop: '16px !important',
                          position: 'relative',
                          width: 'calc(100% + 40px)',
                          left: '-20px',
                          borderColor: '#262626'
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                      <Chip
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ marginRight: 1 }}>Subscription_plan</Typography>
                            <CloseOutlined />
                          </Box>
                        }
                        variant="outlined"
                        sx={{ width: 'fit-content', borderColor: '#434343' }}
                      />
                      <Chip
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ marginRight: 1 }}>Cene Events</Typography>
                            <CloseOutlined />
                          </Box>
                        }
                        variant="outlined"
                        sx={{ width: 'fit-content', borderColor: '#434343' }}
                      />
                      <Chip
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ marginRight: 1 }}>Cene+ Events</Typography>
                            <CloseOutlined />
                          </Box>
                        }
                        variant="outlined"
                        sx={{ width: 'fit-content', borderColor: '#434343' }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item mt={2}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  sx={{
                    color: '#fff',
                    borderColor: '#434343',
                    '&:hover': {
                      backgroundColor: '#121212',
                      color: '#fff',
                      borderColor: '#434343'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default PartnerForm;
