export const convertToScaledValues = async (genderData: any[], max = 10) => {
  if (!genderData) return { Unknown: 0, Male: 0, Female: 0 };
  // Calculate the total count from the gender data
  const totalCount = genderData.reduce((total, gender) => total + gender.count, 0);

  // Map the gender data to include the scaled value
  return genderData.reduce((result, gender) => {
      const percentage = (gender.count / totalCount) * 100;
      const scaledValue = (percentage / 100) * max;
      return {
          ...result,
          [gender.gender]: scaledValue
      };
  }, {});
}
