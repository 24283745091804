import { ContainerOutlined } from '@ant-design/icons';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { TicketRequest } from '..';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { TicketRequestsStatus } from '@ceneteam/namespace';
import { useState, useEffect } from 'react';

interface FormProps {
  currentObject: TicketRequest | null;
  modalToggler?: () => void;
  onSuccess?: () => void;
}

interface AvailableStock {
  ticketName: string;
  stock: number;
  ticketId: string;
}

const AcceptRequestForm = ({ currentObject, modalToggler, onSuccess }: FormProps) => {
  const [availableTickets, setAvailableTickets] = useState<AvailableStock[]>([]);
  const [totalTicketQuantity, setTotalTicketQuantity] = useState(0);

  const formik = useFormik({
    initialValues: {
      customerName: currentObject?.customer_name || '',
      tickets:
        currentObject?.details.reduce(
          (acc, ticket) => {
            acc[ticket.ticket_name_id] = ticket.quantity;
            return acc;
          },
          {} as Record<string, number>
        ) || {}
    },
    onSubmit: async (values) => {
      if (!currentObject) return;

      const ticketsArray = Object.entries(values.tickets).map(([ticketId, quantity]) => {
        const ticket = availableTickets.find((t) => t.ticketId === ticketId);
        return {
          name: ticket?.ticketName || 'Unknown Ticket',
          quantity,
          ticket_name_id: ticketId
        };
      });

      try {
        const { error } = await supabase
          .from('ticket_requests')
          .update({ status: TicketRequestsStatus.Approved, details: ticketsArray })
          .eq('id', currentObject.id);

        if (error) {
          throw error;
        }

        onSuccess?.();
        modalToggler?.();
        enqueueSnackbar('Request approved successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error submitting request:', error);
        enqueueSnackbar('Something went wrong. Try again later.', { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    const fetchTicketStock = async () => {
      if (currentObject) {
        try {
          const { data, error } = await supabase.from('events').select('stock, tickets').eq('id', currentObject.event_id).single();

          if (error) throw error;

          if (data) {
            const ticketStockData = data.stock;
            const ticketsData = data.tickets;

            const ticketDetails = Object.keys(ticketStockData).map((ticketId) => {
              const ticket = ticketsData.find((t: { id: string }) => t.id === ticketId);
              return {
                ticketName: ticket ? ticket.name : 'Unknown Ticket',
                stock: ticketStockData[ticketId],
                ticketId
              };
            });

            const totalQuantity = currentObject.details.reduce((sum, ticket) => sum + ticket.quantity, 0);
            setTotalTicketQuantity(totalQuantity);

            setAvailableTickets(ticketDetails);
          }
        } catch (error) {
          console.error('Error fetching ticket stock:', error);
        }
      }
    };

    fetchTicketStock();
  }, [currentObject]);

  useEffect(() => {
    const totalQuantity = Object.values(formik.values.tickets || {}).reduce((sum, qty) => sum + qty, 0);
    setTotalTicketQuantity(totalQuantity);
  }, [formik.values.tickets]);
  const handleQuantityChange = (ticketId: string, value: number) => {
    if (value < 0) value = 0;

    const availableStock = availableTickets.find((ticket) => ticket.ticketId === ticketId)?.stock || 0;
    const totalSelectedQuantity = Object.values(formik.values.tickets).reduce((sum, qty) => sum + qty, 0);

    const remainingQuantity = 100 - totalSelectedQuantity + formik.values.tickets[ticketId];

    const maxQuantity = Math.min(remainingQuantity, availableStock);
    const newQuantity = Math.min(value, maxQuantity);

    formik.setFieldValue(`tickets.${ticketId}`, newQuantity);
  };

  const { isSubmitting, handleChange } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid sx={{ paddingBlock: 3, paddingInline: 5 }}>
        <div
          style={{
            margin: 'auto',
            padding: '20px',
            borderRadius: '50%',
            backgroundColor: '#8057DB',
            width: '76px',
            height: '76px',
            border: '1px solid #434343'
          }}
        >
          <ContainerOutlined
            style={{
              fontSize: '36px',
              height: '36px'
            }}
          />
        </div>
        <DialogTitle sx={{ fontSize: '24px', color: '#F0F0F0', textAlign: 'center' }}>Accept User Request</DialogTitle>
        <DialogContentText sx={{ color: '#BFBFBF', textAlign: 'center' }}>Search for a Cene or Cene+ user</DialogContentText>
        <Divider />
        <DialogContent sx={{ paddingInline: '1px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentObject?.customer_name}
                    onChange={handleChange}
                    sx={{
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        boxShadow: '0 0 0 1px #BBAFDA'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        boxShadow: '0 0 0 1px #BBAFDA'
                      }
                    }}
                  >
                    <MenuItem value={currentObject?.customer_name}>{currentObject?.customer_name}</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Typography>Ticket Request Description</Typography>
                <Typography sx={{ color: '#BBAFDA', textAlign: 'end' }}>Select {totalTicketQuantity}/100</Typography>
                {availableTickets.map(({ ticketName, ticketId, stock }) => (
                  <Stack key={ticketId} sx={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '16px', color: '#BBAFDA' }}>{ticketName}</Typography>
                    <FormControl sx={{ width: '50%', textAlign: 'end' }}>
                      <TextField
                        type="number"
                        name={`tickets.${ticketId}`}
                        value={formik.values.tickets[ticketId] || 0}
                        onChange={(e) => handleQuantityChange(ticketId, Number(e.target.value))}
                        disabled={stock <= 0}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: '0 0 0 1px #BBAFDA'
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                              boxShadow: '0 0 0 1px #BBAFDA'
                            }
                          }
                        }}
                      />
                      <Typography variant="caption" color="textSecondary" mt={1}>
                        {stock} available
                      </Typography>
                    </FormControl>
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF', textAlign: 'center' }}>
          This action is final and cannot be undone
        </DialogContentText>
        <DialogActions sx={{ paddingBlock: 2.5 }}>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                onClick={modalToggler}
                variant="outlined"
                sx={{
                  color: '#fff',
                  borderColor: '#434343',
                  '&:hover': {
                    backgroundColor: '#121212',
                    color: '#fff',
                    borderColor: '#434343'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting || totalTicketQuantity > 100}
                sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
              >
                Send Payment Request
              </Button>
            </Stack>
          </Grid>
        </DialogActions>
      </Grid>
    </form>
  );
};

export default AcceptRequestForm;
