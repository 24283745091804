import { Grid } from '@mui/material';
import Steps from 'components/steps/steps';
import { ReactNode } from 'react';

interface FormStepLayoutProps {
  activeStep: number;
  onBackStep: () => void;
  children: ReactNode;
  sidebarContent?: ReactNode;
}

const FormStepLayout = ({ activeStep, onBackStep, children, sidebarContent }: FormStepLayoutProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        paddingTop: '56px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: activeStep === 3 || activeStep === 2 ? 'start' : 'center'
      }}
    >
      <Grid item sx={{ maxWidth: '460px', display: 'flex', flexDirection: 'column' }}>
        <Steps activeStep={activeStep} onBack={onBackStep} hideBackButton={activeStep === 1}>
          {children}
        </Steps>
      </Grid>

      <Grid
        item
        md={activeStep === 1 ? 6 : 6.4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: activeStep === 3 ? '52px' : 0
        }}
      >
        {sidebarContent}
      </Grid>
    </Grid>
  );
};

export default FormStepLayout;
