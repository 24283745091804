import { Stack } from '@mui/material';
import { DebouncedInput } from 'components/third-party/react-table';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { supabase } from 'supabase/supabase';
import { Partner } from 'types/partner';
import PartnersRequestsTable from './partnerRequests.table';
import PartnerRequestsStatistic from './partnerRequestsStatistic';
import ReusableTabs from 'components/tabs/reusableTabs';

enum ITab {
  ALL = 'All',
  NEW = 'New',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

const PartnerRequestsPage = () => {
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('partner_requests').select('*');

      setPartners(data as Partner[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
  };

  const filterPartnersByStatus = () => {
    if (activeTab === ITab.ALL) {
      return partners;
    }
    return partners.filter((partner) => partner.status?.toLowerCase() === activeTab.toLowerCase());
  };

  const filteredRequestsByStatus = filterPartnersByStatus().filter((partner) =>
    Object.values(partner).join(' ').toLowerCase().includes(filter.toLowerCase())
  );

  const concatenateFields = (partner: Partner) => {
    const metadataFields = Object.values(partner).join(' ');
    const otherFields = 'email' in partner ? partner.email : '';
    return `${metadataFields} ${otherFields}`.toLowerCase();
  };

  const filteredRequests = filteredRequestsByStatus.filter((partner) => concatenateFields(partner).includes(filter.toLowerCase()));

  return (
    <>
      <PartnerRequestsStatistic partners={partners} />
      <Stack
        sx={{
          mt: 3,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderInline: '1px solid #1e1e1e',
          borderTop: '1px solid #1e1e1e',
          padding: '20px'
        }}
      >
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} />

        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search partner request..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <Stack>
        <PartnersRequestsTable partners={filteredRequests} isLoading={isLoading} onSuccess={fetchPartners} />
      </Stack>
    </>
  );
};

export default PartnerRequestsPage;
