import { Alert, Chip, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { categories } from 'constants/categories';
import { useFormikContext } from 'formik';
import { EventSchema } from '../event.schema';
import { genres } from 'constants/genres';
import { interests } from 'constants/interests';
import { useRef } from 'react';

interface IEventAudience {
  onNext: () => void;
  onPrev: () => void;
}

export const EventAudience = ({ onNext, onPrev }: IEventAudience) => {
  const formik = useFormikContext<EventSchema>();
  const tagRef = useRef<HTMLInputElement>();

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];
  const errors = formik.errors[formType];

  const ceneValues = formik.values['cene'];
  const cenePlusValues = formik.values['cene+'];
  const ceneErrors = formik.errors['cene'];
  const cenePlusErrors = formik.errors['cene+'];

  const cenePlusValidation =
    cenePlusValues.tags.length === 0 ||
    cenePlusValues.interest.length === 0 ||
    Boolean(cenePlusErrors?.tags) ||
    Boolean(cenePlusErrors?.interest);

  const ceneValidation =
    ceneValues.tags.length === 0 || ceneValues.interest.length === 0 || Boolean(ceneErrors?.tags) || Boolean(ceneErrors?.interest);

  const validationStepForMultipleTypes = formik.values.eventType === 'both' && (ceneValidation || cenePlusValidation);
  const validationStep = values.tags.length === 0 || Boolean(errors?.tags) || values.interest.length === 0 || Boolean(errors?.interest);

  const handleOnSubmit = (e: any) => {
    if (!tagRef.current) return;

    e.preventDefault();

    if (values.tags.length >= 3) return;

    formik.setFieldValue(`${formType}.tags`, [...values.tags, tagRef.current.value]);

    tagRef.current.value = '';
  };

  const deleteTag = (i: number) =>
    formik.setFieldValue(
      `${formType}.tags`,
      values.tags.filter((_, index) => i !== index)
    );

  const ageOptions = Array.from({ length: 100 }, (_, i) => i + 1);

  const renderTags = values.tags.map((tag, i) => <Chip key={i} label={tag} onDelete={() => deleteTag(i)} />);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Find Your Tribe
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Cene’s powerful AI algorithms connect your events with the people who will love it most. To set this up for {formik.values.name}, we
        just need a few details.
      </Typography>
      <Stack spacing={1} mt={2}>
        <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
          Event Category
        </InputLabel>
        <Select
          onChange={(e) => {
            formik.setFieldValue(`${formType}.category`, e.target.value);
          }}
          value={values.category}
          displayEmpty
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
          If You Had to Summarise the Mood/Vibe.... (Max 3)
        </InputLabel>
        <Stack mt={2}>
          <Alert variant="outlined" severity="info">
            To add a tag, enter a name and press Enter
          </Alert>
        </Stack>
        <form onSubmit={handleOnSubmit}>
          <TextField
            onBlur={() => formik.setFieldTouched(`${formType}.tags`)}
            error={Boolean(errors?.tags)}
            helperText={errors?.tags}
            inputRef={tagRef}
            fullWidth
            placeholder={'Enter tags'}
          />
        </form>

        {renderTags.length !== 0 && (
          <Stack gap={2} flexDirection="row" alignItems="center">
            {renderTags}
          </Stack>
        )}
      </Stack>

      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          What Genres of Music Will Be Playing? (Max 5)
        </InputLabel>
        <Select
          multiple
          onChange={(e) => {
            formik.setFieldValue(`${formType}.genres`, e.target.value);
          }}
          value={values.genres}
          displayEmpty
        >
          <MenuItem disabled>Choose genere</MenuItem>
          {genres.map((category) => (
            <MenuItem
              key={category}
              value={category}
              disabled={values.genres && values.genres?.length >= 5 && !values.genres?.includes(category)}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
          Your Ideal Attendee Would Be Interested In? (Max 5)
        </InputLabel>
        <Select
          multiple
          onChange={(e) => {
            formik.setFieldValue(`${formType}.interest`, e.target.value);
          }}
          value={values.interest}
          displayEmpty
          onClose={() => {
            formik.handleBlur(`${formType}.interest`);
            formik.setFieldTouched(`${formType}.interest`);
          }}
          error={Boolean(errors?.interest)}
        >
          <MenuItem disabled>Choose interest</MenuItem>
          {interests.map((interest) => (
            <MenuItem key={interest} value={interest} disabled={values.interest?.length >= 5 && !values.interest?.includes(interest)}>
              {interest}
            </MenuItem>
          ))}
        </Select>
        {errors?.interest && (
          <Typography color="error" variant="caption">
            {errors.interest}
          </Typography>
        )}
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }}>Do You Have a Strict Age Limit for This Event?t</InputLabel>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Switch
            sx={{ marginLeft: 0 }}
            checked={values.isAge}
            onChange={() => {
              formik.setFieldValue(`${formType}.isAge`, !values.isAge);
            }}
          />
          <Typography color="info">Yes, there is an age limit.</Typography>
        </Stack>
        {values.isAge && (
          <Stack flexDirection="row" gap={2} justifyContent="space-between">
            <Select
              fullWidth
              onChange={(e) => {
                formik.setFieldValue(`${formType}.age_min`, e.target.value);
              }}
              value={values.age_min}
              displayEmpty
            >
              <MenuItem disabled>Min age</MenuItem>
              {ageOptions.map((age) => (
                <MenuItem key={age} value={age}>
                  {age}
                </MenuItem>
              ))}
            </Select>
            <Select
              fullWidth
              onChange={(e) => {
                formik.setFieldValue(`${formType}.age_max`, e.target.value);
              }}
              value={values.age_max}
              displayEmpty
            >
              <MenuItem disabled>Max age</MenuItem>
              {ageOptions.map((age) => (
                <MenuItem key={age} value={age}>
                  {age}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}
      </Stack>
      {formik.values.eventType === 'both' && ((formType === 'cene' && cenePlusValidation) || (formType === 'cene+' && ceneValidation)) && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          To continue, you need to fix the errors in the {formType === 'cene' ? 'Cene+' : 'Cene'} form
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={validationStepForMultipleTypes || validationStep} handleClick={onNext} width="100%">
          Next step
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
