import { useFormikContext } from 'formik';
import { EventSchema } from '../../event.schema';
import { CeneTickets } from './ceneTickets';
import { CenePlusTickets } from './cenePlusTickets';
import { Alert, Stack } from '@mui/material';
import ShapeButton from 'components/button/button';

interface IEventTickets {
  onSubmit: () => void;
  onEdit: () => void;
  onPrev: () => void;
  disabled: boolean;
}

export const EventTickets = ({ onSubmit, onEdit, onPrev, disabled }: IEventTickets) => {
  const formik = useFormikContext<EventSchema>();

  if (disabled)
    return (
      <>
        <Alert severity="info">Event already published you cannot edit tickets</Alert>
        <Stack mt={4} flexDirection="row" gap={2}>
          <ShapeButton handleClick={onPrev} width="100%" isOutlined>
            Previous step
          </ShapeButton>
          <ShapeButton handleClick={onEdit} width="100%">
            Edit event
          </ShapeButton>
        </Stack>
      </>
    );

  if (formik.values.eventType === 'cene' || (formik.values.eventType === 'both' && formik.values.formType === 'cene')) {
    return <CeneTickets onNext={onSubmit} onPrev={onPrev} />;
  }

  return <CenePlusTickets onNext={onSubmit} onPrev={onPrev} />;
};
