import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import MainCard from 'components/MainCard';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { DebouncedInput } from 'components/third-party/react-table';

import { Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';

import { HeaderCells } from 'components/commonTable/headerCells';

import CircularLoader from 'components/CircularLoader';
import { format, parse, parseISO } from 'date-fns';
import ReusableModal from 'components/modal/modal';

import { Venue } from '.';
import VenueForm from './form';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useNavigate } from 'react-router';

export enum VenueColumnId {
  VENUE = 'venue',
  COUNTRY = 'country',
  STATE = 'state',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
  USED_BY = 'used_by',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: VenueColumnId;
  title: string;
  sortable: boolean;
  cell?: (venue: Venue) => React.ReactNode;
}

const venueColumns: TableColumn[] = [
  { id: VenueColumnId.VENUE, title: 'Venue', sortable: true, cell: (venue) => venue.name },
  { id: VenueColumnId.COUNTRY, title: 'Country', sortable: true, cell: (venue) => venue.country },
  { id: VenueColumnId.STATE, title: 'State', sortable: true, cell: (venue) => venue.state },
  { id: VenueColumnId.CREATED_AT, title: 'Created at', sortable: true, cell: (venue) => format(parseISO(venue.created_at), 'dd.MM.yyyy') },
  {
    id: VenueColumnId.CREATED_BY,
    title: 'Created by',
    sortable: true,
    cell: (venue) => `${venue.created_by.first_name} ${venue.created_by.last_name}`
  },
  { id: VenueColumnId.USED_BY, title: 'Used by', sortable: true, cell: (venue) => `${venue.partners_count} partners` },

  {
    id: VenueColumnId.ACTIONS,
    title: 'Actions',
    sortable: false
  }
];

const getColumnValue = (column: TableColumn, venue: Venue): React.ReactNode => {
  return column.cell ? column.cell(venue) : '';
};

interface VenueTableRowsProps {
  venues: Venue[];
  onSuccess?: () => void;
}

const VenueTableRows = ({ venues = [], onSuccess = () => null }: VenueTableRowsProps) => {
  const navigate = useNavigate();

  const renderCellContent = (column: TableColumn, venue: Venue) => {
    const handleViewOpen = () => {
      localStorage.setItem('selectedVenue', JSON.stringify(venue));

      navigate(`/venue/${venue.id}`, { replace: false });
    };
    if (column.id === VenueColumnId.ACTIONS) {
      return (
        <IconButton onClick={handleViewOpen}>
          <EyeOutlined />
        </IconButton>
      );
    }
    return getColumnValue(column, venue);
  };

  return (
    <>
      {venues.map((venue) => (
        <TableRow key={venue.name}>
          {venueColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, venue)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface VenuesTableProps {
  isLoading?: boolean;
  venues?: Venue[];
  onSuccess?: () => void;
}

const VenuesTable = ({ onSuccess = () => null, isLoading = false, venues = [] }: VenuesTableProps) => {
  const { modalToggler, selectedItem, open } = useModalContext();
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: VenueColumnId; direction: 'asc' | 'desc' }>({
    key: VenueColumnId.VENUE,
    direction: 'asc'
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterVenues = venues.filter((venue) =>
    venueColumns.some((column) => {
      const value = getColumnValue(column, venue);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedVenues = [...filterVenues].sort((a, b) => {
    const { key, direction } = sortConfig;
    const column = venueColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    if (key === VenueColumnId.CREATED_AT) {
      const dateA = parse(a.created_at, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.created_at, 'yyyy-MM-dd', new Date());
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: VenueColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: 2, ...(matchDownSM && { '& .MuiOutlinedInput-root, & .MuiFormControl-root': { width: '100%' } }) }}
      >
        <Stack sx={{ width: '300px' }}>
          <DebouncedInput value={filter ?? ''} onFilterChange={(value) => setFilter(String(value))} placeholder="Search..." />{' '}
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: 'auto' } }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={() => modalToggler(null)}
              variant="contained"
              startIcon={<PlusOutlined />}
              sx={{ mt: 4, backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Add new venue
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<VenueColumnId> columns={venueColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={venueColumns.length}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <VenueTableRows onSuccess={onSuccess} venues={sortedVenues} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ReusableModal
        width="600px"
        open={open}
        modalToggler={() => modalToggler(null)}
        onSuccess={onSuccess}
        content={VenueForm}
        currentObject={selectedItem}
      />
    </MainCard>
  );
};

export default VenuesTable;
