import { Typography, Container, IconButton, Stack, Grid, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Outlet, useNavigate, useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ProfileTabs from './profileTabs';
import { ROUTE_PARTNERS } from 'constants/routes';
import { PartnerProfile as Partner } from 'types/partnerProfile';
import { enqueueSnackbar } from 'notistack';

const PartnerProfile = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [partner, setPartner] = useState<Partner | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchPartner();
  }, [id]);

  const fetchPartner = async () => {
    setLoading(false);
    try {
      setLoading(true);
      const { data, error } = await supabase.from('partners').select('*').eq('id', id);
      setPartner(data?.[0] || null);
      if (error?.message) throw Error(error?.message);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Stack sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <IconButton
          onClick={() => {
            navigate(ROUTE_PARTNERS);
          }}
        >
          <ArrowLeftOutlined />
        </IconButton>
        <Typography sx={{ fontSize: '25px' }}>Profile</Typography>
      </Stack>

      {partner ? (
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} md={4}>
            <ProfileTabs partner={partner} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Outlet context={{ partner }} />
          </Grid>
        </Grid>
      ) : (
        <Typography sx={{ marginTop: '16px', fontSize: '20px', color: '#727272' }}>Partner not found</Typography>
      )}
    </Container>
  );
};

export default PartnerProfile;
