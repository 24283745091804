import { Divider } from '@mui/material';
import TicketBuyersStatistic from './statistic';
import TicketBuyersTable from './table';
import { ModalContextProvider } from 'contexts/ReusableModalContext';
// import { useParams } from 'react-router';
// import { useEffect, useState } from 'react';
// import { supabase } from 'supabase/supabase';
// import { fetchCustomerName } from 'supabase/util';
// import { enqueueSnackbar } from 'notistack';
import CircularLoader from 'components/CircularLoader';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import { Event } from '@ceneteam/namespace';




export interface Ticket {
  quantity: number;
  ticket_name: string;
  ticket_name_id: string;
  ticket_is_accessible: boolean | null;
}

export interface Transaction {
  id: string;
  customer_data: { id: string; first_name: string; last_name: string };
  customer_name?: string;
  orderDate: string;
  tickets: Ticket[];
  amount: number;
  currency: string;
  status: string;
  payment: string;
}


export interface ticketBuyersInterface  {
    id: string;
    name: string;
    ticketCategory: string;
    ticketOwned: string;
    ticketsSent: string;
    uniqueGuests: string;
    guestsReceivedQr: string;
  }



const TicketBuyersPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [, setEvent] = useState<Event | null>(null);
  const [ticketBuyers, setTicketBuyers] = useState<ticketBuyersInterface[]>([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchEventByEventId(id);
      fetchTicketPurchases()
    }
  }, [id]);

  const fetchEventByEventId = async (id: string) => {
      const { data: event } = await supabase.from('events').select('*').eq('id', id).single();
      setEvent(event);
  };

  // const fetchNewVsReturning = async () => {
  //   const { error, data } = await supabase
  //       .rpc('get_customer_counts', {partnerid: event?.partner_id});    
  //       if (error) throw error;
  //       return data;
  // };


  const fetchTicketPurchases = async () => {
    try {
      setLoading(true);
      const { error, data } = await supabase.from('transactions').select('*').eq('details_data->>event_id', id).eq('status', 'success');
      const {  error: err, data: failed } = await supabase.from('transactions').select('*').eq('details_data->>event_id', id).not('status', 'eq', 'success');
      console.log({data, failed})
      console.log({error, err})
      if (error) throw error;
 
      const processedData = await Promise.all(
        data?.map(async (transaction) => {
          return {
            id: transaction.id,
            name: `${transaction.customer_data?.first_name || '-'} ${transaction.customer_data?.last_name || '-'}`.replace(/- /g, '-').replace(/ -/g, '-').trim(),
            ticketCategory: transaction.finance_details?.tickets.map((ticket: { ticket_name: any; }) => ticket.ticket_name).join(', ') || '',
            ticketOwned: transaction.finance_details?.tickets.length || 0,
            ticketsSent: 0,
            uniqueGuests:  0,
            guestsReceivedQr: `0 / ${transaction.finance_details?.tickets.length || 0} `,
          };
        }) || []
      );
      setTicketBuyers(processedData as unknown as ticketBuyersInterface[]);
    } catch (error) {
      console.log(error, 'error')
    } finally {
      setLoading(false);
    }
  };

  // const fetchTicketsByEventId = async () => {
  //   try {
  //     setLoading(true);
  //     const { data: event } = await supabase.from('events').select('*').eq('id', id).single();
  //   const { error, data } = await supabase
  //       .rpc('get_customer_counts', {partnerid: event?.partner_id});    
  //       console.log("*********************")
  //       console.log(error, 'error')
  //       console.log(data, 'data')
  //       console.log(event, 'event')
  //       console.log(id, 'id')
  //       console.log("*********************")
  //         if (error) throw error;

  //     //fetch the customerNames before the table is loaded
  //     // const processedData = await Promise.all(
  //     //   data?.map(async (transaction) => {
  //     //     const customerName = await fetchCustomerName(transaction.customer_id);

  //     //     return {
  //     //       id: transaction.id,
  //     //       customer_data: transaction.customer_data || {},
  //     //       customer_name: customerName || '',
  //     //       orderDate: transaction.created_at,
  //     //       tickets: transaction.details_data?.tickets || [],
  //     //       amount: transaction.amount,
  //     //       currency: transaction.currency,
  //     //       status: transaction.status,
  //     //       payment: transaction.provider
  //     //     };
  //     //   }) || []
  //     // );

  //     console.log({data,error});
  //     // await fetchCustomerName('35e9d48f-f2dc-417d-ad3d-a47dd1077f6e');

  //     // setTransactions(processedData as Transaction[]);
  //   } catch (error) {
  //     // enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return isLoading ? (
    <CircularLoader />
  ) : (
    <>
      <TicketBuyersStatistic />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <ModalContextProvider>
        <TicketBuyersTable buyers={ticketBuyers} />
      </ModalContextProvider>
    </>
  );
};

export default TicketBuyersPage;
