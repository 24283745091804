import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { useSupabase } from 'contexts/supabase';
import useGetRolePermissions from 'hooks/useGetRolePermissions';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Member } from 'types/member';

interface PermissionsModalProps {
  open: boolean;
  onClose: () => void;
  member: Member | null;
  onSuccess: () => void;
}

const PermissionsModal = ({ open, onClose, member, onSuccess = () => null }: PermissionsModalProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(member?.permissions || []);
  const [initialPermissions, setInitialPermissions] = useState<string[]>(member?.permissions || []);
  const { session } = useSupabase();
  const { permissions } = useGetRolePermissions(session?.user?.id);

  useEffect(() => {
    const currentPermissions = member?.permissions || [];
    setSelectedPermissions(currentPermissions);
    setInitialPermissions(currentPermissions);
  }, [member]);

  const handleGroupCheckboxChange = (groupName: string) => {
    const groupPermissions = permissions.find((group) => group.group === groupName);
    if (groupPermissions) {
      const perms = groupPermissions.permissions.map((permission) => permission.permission);
      const allSelected = perms.every((permission) => selectedPermissions.includes(permission));
      const newPermissions = allSelected
        ? selectedPermissions.filter((permission) => !perms.includes(permission))
        : [...new Set([...selectedPermissions, ...perms])];
      setSelectedPermissions(newPermissions);
    }
  };

  const handleChildCheckboxChange = (permission: string) => {
    setSelectedPermissions((prev) => (prev.includes(permission) ? prev.filter((item) => item !== permission) : [...prev, permission]));
  };

  const handleSubmit = async () => {
    try {
      const { error } = await supabase
        .from('partner_users')
        .update({ permissions: selectedPermissions })
        .eq('id', member?.id);

      if (error) throw error;

      enqueueSnackbar('Permissions updated successfully', { variant: 'success' });
      onSuccess();
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      onSuccess();
      onClose();
    }
  };

  const handleCancel = () => {
    setSelectedPermissions(initialPermissions);
    onClose();
  };

  const renderPermissionsGroup = () => {
    return permissions.map((group) => (
      <Grid item xs={12} sm={5.85} key={group.group}>
        <Typography
          variant="h6"
          style={{ cursor: 'pointer' }}
          sx={{ fontWeight: 700, letterSpacing: 1, fontSize: '16px', color: '#BBA9E3' }}
        >
          <FormControlLabel
            label={group.group}
            control={
              <Checkbox
                checked={group.permissions.every((permission) => selectedPermissions.includes(permission.permission))}
                indeterminate={
                  group.permissions.some((permission) => selectedPermissions.includes(permission.permission)) &&
                  !group.permissions.every((permission) => selectedPermissions.includes(permission.permission))
                }
                onChange={() => handleGroupCheckboxChange(group.group)}
                sx={{
                  '&.Mui-checked': {
                    color: '#8057DB'
                  },
                  '&.MuiCheckbox-indeterminate': {
                    color: '#8057DB'
                  }
                }}
              />
            }
          />
        </Typography>
        <FormGroup sx={{ ml: '24px' }}>
          {group.permissions.map((permission) => (
            <FormControlLabel
              key={permission.permission}
              control={
                <Checkbox
                  name={permission.permission}
                  checked={selectedPermissions.includes(permission.permission)}
                  onChange={() => handleChildCheckboxChange(permission.permission)}
                  sx={{
                    '&.Mui-checked': {
                      color: '#8057DB'
                    }
                  }}
                />
              }
              label={permission.description}
            />
          ))}
        </FormGroup>
      </Grid>
    ));
  };

  if (!permissions.length) {
    return (
      <Stack sx={{ height: '200px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularLoader />
      </Stack>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          border: '1px solid #434343',
          backgroundImage: 'none',
          backgroundColor: 'black',
          color: 'white',
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
          maxHeight: '100%',
          width: '508px',
          m: 0,
          borderRadius: 0
        }
      }}
    >
      <DialogTitle>
        {member?.metadata?.first_name} {member?.metadata?.last_name}
      </DialogTitle>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogContent>{renderPermissionsGroup()}</DialogContent>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{
            color: '#fff',
            borderColor: '#434343',
            '&:hover': {
              backgroundColor: '#121212',
              color: '#fff',
              borderColor: '#434343'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionsModal;
