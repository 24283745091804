import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  DialogTitle,
  Alert,
  Autocomplete,
  Typography,
  DialogContentText
} from '@mui/material';

import { useFormik, Form, FormikProvider } from 'formik';

import { supabase } from 'supabase/supabase';
import { useEffect, useState } from 'react';
import { useMemberInviteModalContext } from 'contexts/modal/SpecialModalContexts';
import { MemberInvite } from 'types/member';
import memberFormSchema from 'schemas/memberFormSchema';
import { useSupabase } from 'contexts/supabase';
import { Partner } from 'types/partner';
import { enqueueSnackbar } from 'notistack';
import { AutocompleteChangeReason } from '@mui/material';
import useGetRolePermissions from 'hooks/useGetRolePermissions';
import { v4 as uuidv4 } from 'uuid';
import { Permission } from 'types/role';

const getInitialValues = (invite: MemberInvite | null) => {
  if (invite) {
    return {
      first_name: invite.metadata.first_name || '',
      last_name: invite.metadata.last_name || '',
      job_title: invite.metadata.job_title || '',
      phone_number: invite.metadata.phone_number || '',
      email: invite.email || '',
      role_id: invite.metadata.role_id || '',
      permissions: ['partners:r']
    };
  }

  return {
    first_name: '',
    last_name: '',
    job_title: '',
    phone_number: '',
    email: '',
    role_id: '',
    id: '',
    permissions: []
  };
};

export type MemberInviteFormProps = { currentObject: MemberInvite | null; onSuccess: () => void };

const MemberInviteForm = ({ currentObject, onSuccess = () => null }: MemberInviteFormProps) => {
  const [, setLoading] = useState(false);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [supabaseError, setSupabaseError] = useState<string | null>(null);
  const { session } = useSupabase();
  const { tenantId } = useGetRolePermissions(session?.user?.id);
  const { modalToggler } = useMemberInviteModalContext();
  const generatedId = uuidv4();

  const id = session?.user.id;

  useEffect(() => {
    fetchPartnerPermissions();
  }, []);

  useEffect(() => {
    fetchPartners();
  }, [id]);

  const fetchPartnerPermissions = async () => {
    try {
      const { data } = await supabase.from('grouped_admin_permissions').select('permissions');
      const allPermissions = data?.flatMap((item) => item.permissions.map((p: Permission) => p.permission));
      setPermissions(allPermissions as string[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    }
  };

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('partners').select('*').is('deleted_at', null);
      setPartners(data as Partner[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...getInitialValues(currentObject),
      partner_id: currentObject?.partner_id || ''
    },
    validationSchema: memberFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        if (currentObject) {
          const { error } = await supabase
            .from('admin_invitations')
            .update({
              email: values.email,

              partner_id: values.partner_id,
              permissions: values.permissions,
              metadata: {
                first_name: values.first_name,
                last_name: values.last_name,

                job_title: values.job_title,
                phone_number: values.phone_number
              }
            })
            .eq('id', currentObject.id);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Team member updated successfully', { variant: 'success' });
        } else {
          if (!values.email) {
            throw new Error('Email is required.');
          }

          const { error } = await supabase.from('admin_invitations').insert([
            {
              email: values.email,
              partner_id: values.partner_id === '' ? tenantId : values.partner_id,
              id: values.id === '' ? generatedId : values.id,
              permissions: values.permissions,

              metadata: {
                first_name: values.first_name,
                last_name: values.last_name,
                job_title: values.job_title,
                phone_number: values.phone_number,
                role_id: values.role_id
              }
            }
          ]);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Team member invite created successfully', { variant: 'success' });
        }

        onSuccess();
        modalToggler(null);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleEmailSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | Partner | null,
    reason: AutocompleteChangeReason
  ) => {
    if (typeof value === 'object' && value !== null) {
      formik.setFieldValue('first_name', value.first_name);
      formik.setFieldValue('last_name', value.last_name);
      formik.setFieldValue('phone_number', value.phone_number);
      formik.setFieldValue('email', value.email);
      formik.setFieldValue('partner_id', value.id);
      formik.setFieldValue('id', value.id || uuidv4());
      formik.setFieldValue('role_id', '0f814e8f-cdff-4e40-9459-bd50c0e8b514');
      formik.setFieldValue('permissions', permissions);
    } else if (typeof value === 'string') {
      formik.setFieldValue('email', value);
      formik.setFieldValue('partner_id', '');
      formik.setFieldValue('id', generatedId);
      formik.setFieldValue('role_id', '');
    } else {
      formik.setFieldValue('email', '');
      formik.setFieldValue('partner_id', '');
      formik.setFieldValue('id', generatedId);
      formik.setFieldValue('role_id', '');
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {supabaseError && (
            <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
              {supabaseError}
            </Alert>
          )}

          <DialogTitle>Invite Team Member</DialogTitle>
          <Divider sx={{ backgroundColor: '#262626' }} />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="first_name">Team Member First Name</InputLabel>
                      <TextField
                        fullWidth
                        id="first_name"
                        placeholder="Enter first name..."
                        {...getFieldProps('first_name')}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="last_name">Team Member Last Name</InputLabel>
                      <TextField
                        fullWidth
                        id="last_name"
                        placeholder="Enter last name..."
                        {...getFieldProps('last_name')}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="job_title">Position at Cene</InputLabel>
                      <TextField
                        fullWidth
                        id="job_title"
                        placeholder="Enter position..."
                        {...getFieldProps('job_title')}
                        error={Boolean(touched.job_title && errors.job_title)}
                        helperText={touched.job_title && errors.job_title}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Autocomplete
                        id="email"
                        options={partners}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.email || '')}
                        onChange={handleEmailSelect}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter e-mail..."
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
                      <TextField
                        fullWidth
                        id="phone_number"
                        placeholder="Enter phone number..."
                        {...getFieldProps('phone_number')}
                        error={Boolean(touched.phone_number && errors.phone_number)}
                        helperText={touched.phone_number && errors.phone_number}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider sx={{ backgroundColor: '#262626' }} />
          <DialogContentText sx={{ p: 2.5 }}>
            <Typography sx={{ color: '#91DCBE', fontSize: '16px' }}>Send Invite</Typography>
            <Typography sx={{ fontWeight: 300, mt: '8px' }}>
              Once you’ve sent out your invitation, it’s super easy to tweak this user’s permissions across Cene. Just hop back to the Team
              page and click that handy Edit button. You’ve got this!
            </Typography>
          </DialogContentText>
          <Divider sx={{ backgroundColor: '#262626' }} />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  onClick={() => modalToggler(null)}
                  variant="outlined"
                  sx={{
                    color: '#fff',
                    borderColor: '#434343',
                    '&:hover': {
                      backgroundColor: '#121212',
                      color: '#fff',
                      borderColor: '#434343'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                >
                  Invite Team Member to Cene
                </Button>
              </Stack>
            </Grid>
          </DialogActions>
        </Form>
      </FormikProvider>
    </>
  );
};

export default MemberInviteForm;
