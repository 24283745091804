import { Divider, FormLabel, Grid, Stack, Typography } from '@mui/material';

import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import ProfileTab from './profileNavTab';

import { User } from 'types/user';

interface IProps {
  customer: User;
}

const ProfileTabs = ({ customer }: IProps) => {
  return (
    <>
      <MainCard>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Stack spacing={2.5} alignItems="center">
              <FormLabel
                htmlFor="change-avtar"
                sx={{
                  position: 'relative',
                  borderRadius: '20px 0',
                  overflow: 'hidden',
                  '&:hover .MuiBox-root': { opacity: 1 },
                  cursor: 'pointer'
                }}
              >
                <Avatar
                  alt="Customer avatar"
                  variant="square"
                  sx={{
                    width: 124,
                    height: 124,
                    border: '1px solid #424242a4',
                    backgroundColor: '#1818188f',
                    color: '#3b3b3b',
                    borderRadius: '20px 0'
                  }}
                />
              </FormLabel>

              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">
                  {customer.first_name} {customer.last_name}
                </Typography>
                <Typography color="secondary">{customer.company_name}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={3} sx={{ display: { sm: 'block', md: 'none' } }} />
          <Grid item xs={12} sm={6} md={12}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">86</Typography>
                <Typography color="secondary">Events Attened</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">40</Typography>
                <Typography color="secondary">Tickets Bought</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">4.5K</Typography>
                <Typography color="secondary">Guests</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ProfileTab />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default ProfileTabs;
