import { useModalContext } from 'contexts/ReusableModalContext';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import customerInviteFormSchema from 'schemas/customerInviteFormSchema';
import { supabase } from 'supabase/supabase';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Alert,
  MenuItem,
  Select,
  FormControl,
  Divider,
  DialogTitle
} from '@mui/material';
import { DatePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { format } from 'date-fns';

interface Props {
  onSuccess: () => void;
}

const CreateCustomerInvite = ({ onSuccess = () => null }: Props) => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);
  const { modalToggler } = useModalContext();

  const formik = useFormik({
    initialValues: { first_name: '', last_name: '', gender: 'Male', birthdate: null, phone_number: '', email: '' },

    validationSchema: customerInviteFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        const { error } = await supabase.from('customers').insert([
          {
            id: uuidv4(),
            ...values,
            onboarding_finished: false,
            app: 'cene',
            metadata: { redirect_to: `https://cene.xyz/auth/register/cene` }
          }
        ]);

        if (error) return setSupabaseError(error.message);

        enqueueSnackbar('Customer invite created successfully', { variant: 'success' });

        onSuccess();
        setSubmitting(false);
        modalToggler(null);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {supabaseError && (
          <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
            {supabaseError}
          </Alert>
        )}

        <DialogTitle>Create Cene Customer</DialogTitle>
        <Divider sx={{ backgroundColor: '#262626' }} />

        <DialogContent sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstName" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      First Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="firstName"
                      placeholder="Enter first name..."
                      {...getFieldProps('first_name')}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Last Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="lastName"
                      placeholder="Enter last name..."
                      {...getFieldProps('last_name')}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="gender" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Gender
                    </InputLabel>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.gender}
                        onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="date_of_birth" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Date of Birth
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        onChange={(date) => {
                          formik.setFieldValue('birthdate', date ? format(new Date(date as Date), 'yyyy-MM-dd') : null);
                        }}
                        format="dd.MM.yyyy"
                        slotProps={{
                          popper: {
                            sx: {
                              [`.${pickersLayoutClasses.root}`]: {
                                marginBlock: '12px'
                              }
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Email
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="email"
                      placeholder="Enter customer e-mail"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="phone" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Phone number
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="phone"
                      placeholder="Enter customer phone..."
                      {...getFieldProps('phone_number')}
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider sx={{ backgroundColor: '#262626' }} />
        <DialogActions sx={{ p: 2.5 }}>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                onClick={() => modalToggler(null)}
                variant="outlined"
                sx={{
                  color: '#fff',
                  borderColor: '#434343',
                  '&:hover': {
                    backgroundColor: '#121212',
                    color: '#fff',
                    borderColor: '#434343'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                disabled={isSubmitting}
                sx={{ backgroundColor: '#4A7DFF', '&:hover': { backgroundColor: '#4a7dffde' } }}
              >
                Create
              </Button>
            </Stack>
          </Grid>
        </DialogActions>
      </LocalizationProvider>
    </>
  );
};

export default CreateCustomerInvite;
