import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { useNavigate } from 'react-router';
import { MemberCard } from './memberInvite.form';
import { Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import usePartnerId from 'hooks/usePartnerId';
import { v4 as uuidv4 } from 'uuid';
import { memberInvitesSchema } from 'schemas/onboarding/memberInvites';
import { PlusOutlined } from '@ant-design/icons';
import { ROUTE_WELCOME_AFTER_ONBOARDING } from 'constants/routes';
import { supabase } from 'supabase/supabase';

const MembersForm = () => {
  const navigate = useNavigate();
  const maxLength = 5;
  const handleSkip = () => navigate(ROUTE_WELCOME_AFTER_ONBOARDING, { replace: true });
  const partnerId = usePartnerId();
  const roleId = 'bc1f24a2-a299-4d8d-9804-1c953b126eea';

  return (
    <Formik
      initialValues={{
        members: [{ id: uuidv4(), first_name: '', last_name: '', email: '', phone_number: '', job_title: '' }]
      }}
      validationSchema={memberInvitesSchema}
      onSubmit={async (values) => {
        const formattedMembers = values.members.map((member) => ({
          id: member.id,
          email: member.email,
          role_id: roleId,
          partner_id: partnerId,
          metadata: {
            email: member.email,
            last_name: member.last_name,
            first_name: member.first_name
          }
        }));
        try {
          const { error } = await supabase.from('admin_invitations').insert([...formattedMembers]);

          if (error) {
            throw error;
          }

          enqueueSnackbar('Members successfully updated', { variant: 'success' });
          navigate(ROUTE_WELCOME_AFTER_ONBOARDING, { replace: true });
        } catch (error) {
          enqueueSnackbar('Error updating members', { variant: 'error' });
        }
      }}
    >
      {({ values, isValid, setFieldValue, resetForm, dirty, handleSubmit }) => {
        const addMember = () => {
          if (isValid) {
            const currentMembers = values.members ?? [];
            const newMembers = [
              ...currentMembers,
              { id: uuidv4(), first_name: '', last_name: '', email: '', phone_number: '', job_title: '' }
            ];
            setFieldValue('members', newMembers);
            resetForm({
              values: {
                members: newMembers
              }
            });
          }
        };

        const isFirstMemberValid = () => {
          const members = values.members ?? [];
          const firstMember = members[0];
          return firstMember.first_name && firstMember.last_name && firstMember.email && firstMember.phone_number && firstMember.job_title;
        };

        return (
          <Grid container sx={{ marginTop: '32px', flexDirection: 'column', gap: '32px' }}>
            <Stack sx={{ gap: '16px' }}>
              <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#fff' }}>Onboard Your Squad</Typography>
              <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 300, color: '#fff' }}>
                Invite your teammates to collaborate seamlessly—the more minds involved, the more creative and successful your events will
                be!
              </Typography>
            </Stack>

            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBlock: '1px solid #262626',
                padding: 2
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '24px' }}>Add Teammate</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '24px', color: '#8057DB' }}>
                {values.members.length}/{maxLength} {'  '}Spots Available
              </Typography>
            </Stack>

            {values.members.map((member, index) => (
              <MemberCard key={member.id} index={index} />
            ))}

            {(values.members ?? []).length < 1 && (
              <Stack spacing={1} mt={1}>
                <Alert variant="outlined" severity="error">
                  To continue, please add at least one member.
                </Alert>
              </Stack>
            )}

            <Button
              onClick={addMember}
              type="button"
              variant="text"
              disabled={!isFirstMemberValid() || values.members.length >= maxLength}
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '500',
                color: '#8057DB',
                '&:hover': {
                  color: '#8157dbab',
                  backgroundColor: 'transparent'
                },
                '&:disabled': {
                  color: '#7a7a7a',
                  cursor: 'not-allowed'
                }
              }}
            >
              <PlusOutlined />
              Add another
            </Button>

            <Stack gap={2}>
              <ShapeButton width="100%" color="#fff" backgroundColor="#3C3B3C" bgHover="#595959" handleClick={handleSkip}>
                Skip
              </ShapeButton>
              <ShapeButton width="100%" handleClick={handleSubmit} disabled={!isValid || !dirty}>
                Send invites
              </ShapeButton>
            </Stack>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default MembersForm;
