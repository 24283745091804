import { RiseOutlined } from '@ant-design/icons';
import { Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { useState } from 'react';

enum IFilter {
  EVENT_OVERVIEW = 'Event Overview',
  ACTIVE_PROMOTIONS = 'Active Promotions',
  PROMO_PERFORMANCE = 'Promo Performance',
  SCHEDULED_AND_EXPIRED = 'Scheduled and Expired'
}

const activePromotions = [
  { title: 'Buy 2 Get 1 Free', subtitle: 'FREEBIE', expireDate: '23/12/2024', ticketsType: 'All Ticket Types' },
  { title: '20% Off', subtitle: '20Fest', expireDate: '23/12/2024', ticketsType: 'VIP Tickets' },
  { title: 'Buy 2 Get 1 Free', subtitle: 'FREEBIE', expireDate: '23/12/2024', ticketsType: 'All Ticket Types' },
  { title: 'Discount 5%', subtitle: '20FEST', expireDate: '23/12/2024', ticketsType: 'Tables' }
];

const promoPerformance = [
  { title: 'Buy 2 Get 1 Free', subtitle: 'FREEBIE', expireDate: '23/12/2024', usedIn: '55' },
  { title: '20% Off', subtitle: '20Fest', expireDate: '23/12/2024', usedIn: '55' },
  { title: 'Buy 2 Get 1 Free', subtitle: 'FREEBIE', expireDate: '23/12/2024', usedIn: '55' },
  { title: 'Discount 5%', subtitle: '20FEST', expireDate: '23/12/2024', usedIn: '55' }
];

const scheduledAndExpired = [
  { title: 'Buy 2 Get 1 Free', subtitle: 'FREEBIE', expireDate: '23/12/2024', ticketsType: 'All Ticket Types' },
  { title: '20% Off', subtitle: '20Fest', expireDate: '23/12/2024', ticketsType: 'VIP Tickets' },
  { title: 'Buy 2 Get 1 Free', subtitle: 'FREEBIE', expireDate: '23/12/2024', ticketsType: 'All Ticket Types' },
  { title: 'Discount 5%', subtitle: '20FEST', expireDate: '23/12/2024', ticketsType: 'Tables' }
];

const AttendeeSummarySection = () => {
  const [filter, setFilter] = useState<IFilter>(IFilter.EVENT_OVERVIEW);

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as IFilter);
  };
  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Attendee summary</Typography>
        <FormControl sx={{ width: '240px' }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            onChange={handleChange}
            sx={{
              mb: 3,
              '& .MuiSelect-icon': {
                color: '#8C8C8C',
                zIndex: 2
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '& .MuiSelect-select': {
                backgroundColor: '#1E1E1E',
                color: '#FFFFFF'
              }
            }}
          >
            <MenuItem value={IFilter.EVENT_OVERVIEW}>{IFilter.EVENT_OVERVIEW}</MenuItem>
            <MenuItem value={IFilter.ACTIVE_PROMOTIONS}>{IFilter.ACTIVE_PROMOTIONS}</MenuItem>
            <MenuItem value={IFilter.PROMO_PERFORMANCE}>{IFilter.PROMO_PERFORMANCE}</MenuItem>
            <MenuItem value={IFilter.SCHEDULED_AND_EXPIRED}>{IFilter.SCHEDULED_AND_EXPIRED}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Grid container spacing={5}>
        <Grid item md={4} sx={{ textAlign: 'center' }}>
          <MainCard title={IFilter.ACTIVE_PROMOTIONS}>
            {activePromotions.map((item, index) => (
              <div>
                <Grid container key={index} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid sx={{ textAlign: 'start' }}>
                    <Typography sx={{ fontWeight: 500 }}>{item.title}</Typography>
                    <Typography sx={{ color: '#BFBFBF' }}>{item.subtitle}</Typography>
                    <Typography sx={{ color: '#BFBFBF' }}>Expiry Date: {item.expireDate}</Typography>
                  </Grid>
                  <Grid sx={{ textAlign: 'end' }}>
                    <Typography sx={{ fontWeight: 500 }}>{item.ticketsType}</Typography>
                    <Typography
                      sx={{
                        display: 'inline-block',
                        width: 'fit-content',
                        fontWeight: 400,
                        paddingBlock: 0.5,
                        paddingInline: 1,
                        borderRadius: 4,
                        fontSize: '12px',
                        backgroundColor: '#C976FC',
                        color: '#000'
                      }}
                    >
                      Active
                    </Typography>
                  </Grid>
                </Grid>
                {index < activePromotions.length - 1 && <Divider sx={{ backgroundColor: '#262626', marginY: 1 }} />}
              </div>
            ))}
          </MainCard>
        </Grid>
        <Grid item md={4} sx={{ textAlign: 'center' }}>
          <MainCard title={IFilter.PROMO_PERFORMANCE}>
            {promoPerformance.map((item, index) => (
              <div>
                <Grid container key={index} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid sx={{ textAlign: 'start' }}>
                    <Typography sx={{ fontWeight: 500 }}>{item.title}</Typography>
                    <Typography sx={{ color: '#BFBFBF' }}>{item.subtitle}</Typography>
                    <Typography sx={{ color: '#BFBFBF' }}>Expiry Date: {item.expireDate}</Typography>
                  </Grid>
                  <Grid sx={{ textAlign: 'end' }}>
                    <Typography sx={{ fontWeight: 500 }}>Used in</Typography>
                    <Typography sx={{ color: '#A96BDA' }}>
                      <RiseOutlined style={{ color: '#A96BDA', marginRight: '4px' }} />
                      {item.usedIn} Sales
                    </Typography>
                  </Grid>
                </Grid>
                {index < activePromotions.length - 1 && <Divider sx={{ backgroundColor: '#262626', marginY: 1 }} />}
              </div>
            ))}
          </MainCard>
        </Grid>
        <Grid item md={4} sx={{ textAlign: 'center' }}>
          <MainCard title={IFilter.SCHEDULED_AND_EXPIRED}>
            {scheduledAndExpired.map((item, index) => (
              <div>
                <Grid container key={index} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid sx={{ textAlign: 'start' }}>
                    <Typography sx={{ fontWeight: 500 }}>{item.title}</Typography>
                    <Typography sx={{ color: '#BFBFBF' }}>{item.subtitle}</Typography>
                    <Typography sx={{ color: '#BFBFBF' }}>Expiry Date: {item.expireDate}</Typography>
                  </Grid>
                  <Grid sx={{ textAlign: 'end' }}>
                    <Typography sx={{ fontWeight: 500 }}>{item.ticketsType}</Typography>
                    <Typography
                      sx={{
                        display: 'inline-block',
                        width: 'fit-content',
                        fontWeight: 400,
                        paddingBlock: 0.5,
                        paddingInline: 1,
                        borderRadius: 4,
                        fontSize: '12px',
                        backgroundColor: '#5B585C',
                        color: '#fff'
                      }}
                    >
                      Expired
                    </Typography>
                  </Grid>
                </Grid>
                {index < activePromotions.length - 1 && <Divider sx={{ backgroundColor: '#262626', marginY: 1 }} />}
              </div>
            ))}
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default AttendeeSummarySection;
