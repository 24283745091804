import { Divider } from '@mui/material';
import RequestStatistic from './statistic';
import RequestsTable from './table';
import { ModalContextProvider } from 'contexts/ReusableModalContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import CircularLoader from 'components/CircularLoader';
import { TicketRequestsStatus, TicketRequestType, Event } from '@ceneteam/namespace';

export interface Ticket {
  name: string;
  ticket_name_id: string;
  quantity: number;
}

export interface TicketRequest {
  id: string;
  customer_id: string;
  customer_name: string;
  event_id: string;
  app: string;
  details: Ticket[];
  status: TicketRequestsStatus;
  message?: string;
  type: TicketRequestType;
  event?: Event;
  created_at: string;
}

const RequestsPage = () => {
  const { id } = useParams();
  const [requests, setRequests] = useState<TicketRequest[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (id) fetchRequests();
  }, [id]);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('ticket_requests').select('*').eq('event_id', id);
      setRequests(data as TicketRequest[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return isLoading ? (
    <CircularLoader />
  ) : (
    <>
      <RequestStatistic requests={requests} />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <ModalContextProvider>
        <RequestsTable requests={requests} onSuccess={fetchRequests} />
      </ModalContextProvider>
    </>
  );
};

export default RequestsPage;
