import {
  Avatar,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ITicketBuyer } from './table';
import MainCard from 'components/MainCard';
import SimpleBar from 'simplebar-react';
import { Event } from '@ceneteam/namespace';
import {
  DeleteFilled,
  DeleteOutlined,
  DownCircleOutlined,
  InfoCircleOutlined,
  SendOutlined,
  UpCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import ReusableModal from 'components/modal/modal';
import NewGuestForm from './forms/newGuest';

interface Props {
  openModal: boolean;
  onClose: () => void;
  buyer: ITicketBuyer;
  event: Event | undefined;
}

const guestsDetails = [
  {
    guestInvited: { name: 'John Deo', date: '24/07/2024 | 20.23pm' },
    status: 'Pending',
    quantity: { total: 2, scanned: 0 }
  },
  {
    guestInvited: { name: 'Jane Smith', date: '24/07/2024 | 19.45pm' },
    status: 'Pending',
    quantity: { total: 3, scanned: 1 }
  },
  {
    guestInvited: { name: 'Michael Brown', date: '24/07/2024 | 21.10pm' },
    status: 'Pending',
    quantity: { total: 1, scanned: 1 }
  }
];

const BuyerDetails = ({ openModal, onClose, buyer, event }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [open, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal((prevOpen) => !prevOpen);
  };

  const handleDialogToggle = () => {
    setDialogOpen(!isDialogOpen);
  };

  const handleOpen = () => {
    setOpen(!isOpen);
  };
  return (
    <>
      <Modal open={openModal} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <MainCard
          sx={{ width: '508px', minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)', minHeight: '200px' }}
          modal
          content={false}
        >
          <SimpleBar>
            <Dialog
              open={openModal}
              onClose={onClose}
              PaperProps={{
                sx: {
                  border: '1px solid #434343',
                  backgroundImage: 'none',
                  backgroundColor: 'black',
                  color: 'white',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  height: '100%',
                  maxHeight: '100%',
                  width: '508px',
                  m: 0,
                  borderRadius: 0
                }
              }}
            >
              <DialogTitle>{event?.name}</DialogTitle>
              <Divider sx={{ backgroundColor: '#434343' }} />
              <DialogContent>
                <Stack sx={{ width: '100%', alignItems: 'center' }}>
                  <Avatar />
                  <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', mt: 2 }}>
                    <Typography sx={{ fontSize: '30px', fontWeight: 300 }}>{buyer.name}</Typography>
                    <Stack sx={{ flexDirection: 'row', gap: 2 }}>
                      <IconButton onClick={handleDialogToggle}>
                        <DeleteOutlined style={{ color: '#ABA7A7' }} />
                      </IconButton>
                      <IconButton>
                        <WarningOutlined style={{ color: '#ABA7A7' }} />
                      </IconButton>
                    </Stack>
                  </Stack>

                  <Grid container sx={{ justifyContent: 'center', mt: 2 }}>
                    <Grid item sx={{ width: '112px', paddingBlock: 1, textAlign: 'center' }}>
                      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#9977E2' }}>{buyer.ticketOwned}</Typography>
                      <Typography sx={{ color: '#8C8C8C' }}>Bought</Typography>
                    </Grid>
                    <Grid item sx={{ width: '112px', paddingBlock: 1, textAlign: 'center', borderLeft: '1px solid #262626' }}>
                      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#9977E2' }}>{buyer.uniqueGuests}</Typography>
                      <Typography sx={{ color: '#8C8C8C' }}>Guests</Typography>
                    </Grid>
                    <Grid item sx={{ width: '112px', paddingBlock: 1, textAlign: 'center', borderLeft: '1px solid #262626' }}>
                      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#9977E2' }}>{buyer.guestsReceivedQr}</Typography>
                      <Typography sx={{ color: '#8C8C8C' }}>Pending</Typography>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider sx={{ backgroundColor: '#434343', marginY: 3 }} />

                <TableContainer sx={{ width: '100%', backgroundColor: '#141414', border: '1px solid #262626', justifyContent: 'center' }}>
                  <TableBody sx={{ width: '100%' }}>
                    <TableRow>
                      <TableCell sx={{ width: '30%', color: isOpen ? '#D7C7FA' : '#fff' }}>{buyer.ticketCategory} </TableCell>
                      <TableCell sx={{ width: '20%', color: isOpen ? '#D7C7FA' : '#fff' }}>{buyer.ticketOwned} Tickets</TableCell>
                      <TableCell sx={{ width: '20%', color: isOpen ? '#D7C7FA' : '#fff' }}>{buyer.uniqueGuests} Guests</TableCell>
                      <TableCell sx={{ width: '20%', color: isOpen ? '#D7C7FA' : '#fff' }}>
                        {(() => {
                          const [received, total] = buyer.guestsReceivedQr.split('/').map(Number);
                          const pending = total - received;
                          return `${pending} Pending`;
                        })()}
                      </TableCell>
                      <TableCell sx={{ width: '10%' }}>
                        <IconButton onClick={handleOpen}>
                          {isOpen ? <UpCircleOutlined style={{ color: '#D7C7FA' }} /> : <DownCircleOutlined />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={6} sx={{ paddingBlock: 0, paddingInline: '0 !important' }}>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                          <Table
                            size="small"
                            aria-label="guest-details"
                            sx={{
                              borderCollapse: 'collapse'
                            }}
                          >
                            <TableHead sx={{ backgroundColor: '#1F1F1F' }}>
                              <TableRow>
                                <TableCell>Guests Invited</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {guestsDetails.map((guest, guestIndex) => (
                                <TableRow key={guestIndex}>
                                  <TableCell sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>{guest.guestInvited.name}</div>
                                    {guest.guestInvited.date}
                                  </TableCell>

                                  <TableCell>
                                    <Typography sx={{ padding: 1, backgroundColor: '#D7C7FA', color: '#000', borderRadius: 1.5 }}>
                                      {guest.status}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>{guest.quantity.total} Tickets</Typography>
                                    <Typography>{guest.quantity.scanned} Scanned</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <IconButton>
                                      <InfoCircleOutlined />
                                    </IconButton>
                                    <IconButton onClick={handleDialogToggle}>
                                      <DeleteOutlined />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Button
                                    onClick={toggleModal}
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      color: '#9983C9',
                                      backgroundColor: '#1F1F1F',
                                      border: '1px dashed #7B7B7B'
                                    }}
                                  >
                                    Add New Guest to Ticket Type
                                    <SendOutlined style={{ color: '#D7C7FA' }} />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
              </DialogContent>
            </Dialog>
          </SimpleBar>
        </MainCard>
      </Modal>
      <ReusableModal currentObject={null} open={open} modalToggler={toggleModal} content={NewGuestForm} width="488px" />
      <Dialog
        open={isDialogOpen}
        onClick={handleDialogToggle}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        PaperProps={{
          sx: {
            textAlign: 'center',
            border: '1px solid #434343',
            padding: '40px',
            backgroundImage: 'none',
            backgroundColor: 'black',
            color: 'white',
            width: '488px'
          }
        }}
      >
        <div
          style={{
            margin: 'auto',
            padding: '20px',
            borderRadius: '50%',
            backgroundColor: '#ffffff0f',
            width: '76px',
            height: '76px',
            border: '1px solid #434343'
          }}
        >
          <DeleteFilled
            style={{
              fontSize: '36px',
              height: '36px',
              color: '#a61d24e6'
            }}
          />
        </div>

        <DialogTitle id="confirmation-dialog-title" sx={{ fontSize: '24px', lineHeight: '32px', fontWeight: 700, color: '#f0f0f0' }}>
          Heads up!
        </DialogTitle>
        <DialogContent sx={{ padding: 0, mb: '16px' }}>
          <DialogContentText
            id="confirmation-dialog-description"
            sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#BFBFBF' }}
          >
            Removing a guest will revoke their access to the assigned tickets and prevent them from attending the event with those tickets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogToggle}
            variant="outlined"
            sx={{
              color: '#fff',
              borderColor: '#434343',
              '&:hover': {
                backgroundColor: '#121212',
                color: '#fff',
                borderColor: '#434343'
              }
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleDialogToggle} color="error" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuyerDetails;
