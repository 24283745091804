import { RouterProvider } from 'react-router-dom';
import './sentry';
import router from 'routes';
import ThemeCustomization from 'themes';

import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import { SupabaseProvider } from 'contexts/supabase';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

const App = () => {
  return (
    <>
      <ThemeCustomization>
        <RTLLayout>
          <Locales>
            <ScrollTop>
              <SupabaseProvider>
                <Notistack>
                  <RouterProvider router={router} />
                  <Snackbar />
                </Notistack>
              </SupabaseProvider>
            </ScrollTop>
          </Locales>
        </RTLLayout>
      </ThemeCustomization>
    </>
  );
};

export default App;
