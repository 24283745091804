import { Divider, Grid, Stack, Switch, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { useState } from 'react';
import { IMember } from './table';

interface PermissionsModalProps {
  open: boolean;
  onClose: () => void;
  member: IMember;
  onSuccess: () => void;
}

const permissions = [
  { label: 'Add Guests', description: 'Allow add guests to this event' },
  { label: 'Change Guests Details', description: 'Allow change guests details for this event' },
  { label: 'Checkout Guests', description: 'Allow checkout guests at this event' },
  { label: 'Filter or Search for Guests', description: 'Allow filter or search for guests at this event' },
  { label: 'Check-In Blocked Guests', description: 'Allow check-in guests at this event' },
  { label: 'Only See the Scan Window', description: 'Allow to see only scan window this event' },
  { label: 'View Settings', description: 'Allow view settings this event' },
  { label: 'View Check-In Stats', description: 'Allow view check-in stats for this event' },
  { label: 'Switch Between Events', description: 'Allow switch between events' }
];

const PermissionsModal = ({ open, onClose, member, onSuccess = () => null }: PermissionsModalProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const handleSwitchChange = (permission: string) => {
    setSelectedPermissions((prev) => (prev.includes(permission) ? prev.filter((p) => p !== permission) : [...prev, permission]));
  };

  const handleCancel = () => {
    setSelectedPermissions(permissions.map((p) => p.label));
    onClose();
  };

  const renderPermissionsGroup = () => {
    return permissions.map((group) => (
      <>
        <Grid item xs={12} sm={5.85} key={group.label} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack>
            <Typography sx={{ fontWeight: 700, letterSpacing: 1, fontSize: '16px' }}>
              {selectedPermissions.includes(group.label) ? `Can ${group.label}` : `Restrict ${group.label}`}
            </Typography>
            <Typography variant="body2" sx={{ color: '#8C8C8C' }}>
              {group.description}
            </Typography>
          </Stack>
          <Switch
            checked={selectedPermissions.includes(group.label)}
            onChange={() => handleSwitchChange(group.label)}
            color="primary"
            sx={{
              '& .MuiSwitch-track': {
                borderRadius: 22 / 2,
                '&::before, &::after': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 16,
                  height: 16
                }
              },
              '& .MuiSwitch-switchBase': {
                '& .MuiSwitch-thumb': {
                  color: '#fff'
                },
                '&.Mui-checked': {
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#8057DB',
                    opacity: 1
                  }
                }
              }
            }}
          />
        </Grid>
        <Divider sx={{ my: 1, backgroundColor: '#262626' }} />
      </>
    ));
  };

  if (!permissions.length) {
    return (
      <Stack sx={{ height: '200px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularLoader />
      </Stack>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          border: '1px solid #434343',
          backgroundImage: 'none',
          backgroundColor: 'black',
          color: 'white',
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
          maxHeight: '100%',
          width: '508px',
          m: 0,
          borderRadius: 0
        }
      }}
    >
      <DialogTitle>{member.name}</DialogTitle>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogContent>
        <Stack>
          <Typography sx={{ fontWeight: 700, letterSpacing: 1, fontSize: '16px' }}>Permissions</Typography>
          <Typography variant="body2" sx={{ color: '#8C8C8C' }}>
            Configure permissions for this check-in team member
          </Typography>
        </Stack>
        <Divider sx={{ my: 1, backgroundColor: '#262626' }} />
        {renderPermissionsGroup()}
      </DialogContent>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{
            color: '#fff',
            borderColor: '#434343',
            '&:hover': {
              backgroundColor: '#121212',
              color: '#fff',
              borderColor: '#434343'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCancel}
          type="submit"
          variant="contained"
          sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionsModal;
