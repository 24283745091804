import { DollarCircleOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const financials: NavItemType = {
  id: 'group-roles-loading',
  type: 'group',
  children: [
    {
      id: 'group-roles',
      title: 'Financials',
      type: 'collapse',
      icon: DollarCircleOutlined,
      children: [
        {
          permissions: ['*', 'transactions:r'],
          id: 'default',
          title: 'Default',
          type: 'item',
          url: '/default'
        }
      ]
    }
  ]
};

export default financials;
