import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useState } from 'react';

export interface ITableAndGuest {
  zone: string;
  table: string;
  ownedBy: string;
  ticketCategory: string;
  zoneGroup: string;
  seats: string;
  ticketsOwned: string;
  guests: string;

  id: string;
}

export enum TablesAndGuestsColumnId {
  ZONE = 'zone',
  TABLE = 'table',
  OWNED_BY = 'ownedBy',
  TICKET_CATEGORY = 'ticketCategory',
  ZONE_GROUP = 'zoneGroup',
  SEATS = 'seats',
  TICKET_OWNED = 'ticketsOwned',
  GUESTS = 'guests',

  ACTIONS = 'actions'
}

interface TableColumn {
  id: TablesAndGuestsColumnId;
  title: string;
  sortable: boolean;
  cell?: (table: ITableAndGuest) => React.ReactNode;
}

const tableAndGuestColumns: TableColumn[] = [
  { id: TablesAndGuestsColumnId.ZONE, title: 'Zone', sortable: true, cell: (table) => table.zone },
  { id: TablesAndGuestsColumnId.TABLE, title: 'Table', sortable: true, cell: (table) => table.table },
  { id: TablesAndGuestsColumnId.OWNED_BY, title: 'Owned by', sortable: true, cell: (table) => table.ownedBy },
  { id: TablesAndGuestsColumnId.ZONE_GROUP, title: 'Zone group', sortable: true, cell: (table) => table.zoneGroup },
  { id: TablesAndGuestsColumnId.SEATS, title: 'Seats', sortable: true, cell: (table) => table.seats }
  // { id: TablesAndGuestsColumnId.TICKET_OWNED, title: 'Ticket owned', sortable: true, cell: (table) => table.ticketsOwned },
  // { id: TablesAndGuestsColumnId.GUESTS, title: 'Guests', sortable: true, cell: (table) => table.guests },

  // { id: TablesAndGuestsColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const getColumnValue = (column: TableColumn, table: ITableAndGuest): React.ReactNode => {
  return column.cell ? column.cell(table) : '';
};

interface TableAndGuestTableRowsProps {
  tables: ITableAndGuest[];
  onSuccess?: () => void;
}

const TablesAndGuestTableRows = ({ tables = [], onSuccess = () => null }: TableAndGuestTableRowsProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const renderCellContent = (column: TableColumn, table: ITableAndGuest) => {
    if (column.id === TablesAndGuestsColumnId.ACTIONS) {
      return (
        <>
          <Tooltip title="Info">
            <IconButton>
              <InfoCircleOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton onClick={handleOpenModal}>
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="edit-dialog-title"
            aria-describedby="edit-dialog-description"
            PaperProps={{
              sx: {
                textAlign: 'center',
                border: '1px solid #434343',
                padding: '20px',
                backgroundImage: 'none',
                backgroundColor: 'black',
                color: 'white',
                width: '488px'
              }
            }}
          >
            <DialogContent>
              <DialogContentText id="edit-dialog-description" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <div>
                  <Typography sx={{ fontSize: '24px', color: '#F0F0F0' }}>
                    Zone {table.zone} Table {table.table}
                  </Typography>
                  <Typography sx={{ fontSize: '16px', color: '#BFBFBF', mt: 1 }}>Search for a Cene or Cene+ user</Typography>
                </div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value="Owner of Table"
                    sx={{
                      '& .MuiSelect-icon': {
                        color: '#8C8C8C',
                        zIndex: 2
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '& .MuiSelect-select': {
                        backgroundColor: '#1E1E1E',
                        color: '#FFFFFF'
                      }
                    }}
                  >
                    <MenuItem value="Owner of Table">Owner of Table</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value="Select Validating Team Member"
                    sx={{
                      '& .MuiSelect-icon': {
                        color: '#8C8C8C',
                        zIndex: 2
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '& .MuiSelect-select': {
                        backgroundColor: '#1E1E1E',
                        color: '#FFFFFF'
                      }
                    }}
                  >
                    <MenuItem value="Select Validating Team Member">Select Validating Team Member</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ color: '#BFBFBF' }}>This Action Is Final and Cannot Be Undone</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                sx={{
                  color: '#fff',
                  borderColor: '#434343',
                  '&:hover': {
                    backgroundColor: '#121212',
                    color: '#fff',
                    borderColor: '#434343'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
    return getColumnValue(column, table);
  };

  return (
    <>
      {tables.map((table) => (
        <TableRow key={table.id}>
          {tableAndGuestColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, table)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface TablesAndGuestsManagementTableProps {
  isLoading?: boolean;
  tables?: ITableAndGuest[];
  onSuccess?: () => void;
}

const TablesAndGuestsManagementTable = ({
  onSuccess = () => null,
  isLoading = false,
  tables = []
}: TablesAndGuestsManagementTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: TablesAndGuestsColumnId; direction: 'asc' | 'desc' }>({
    key: TablesAndGuestsColumnId.ZONE,
    direction: 'asc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  const filterTables = tables.filter((table) =>
    tableAndGuestColumns.some((column) => {
      const value = getColumnValue(column, table);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const totalPages = Math.ceil(filterTables.length / itemsPerPage);
  const paginatedTablesAndGuests = filterTables.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const sortedTables = [...paginatedTablesAndGuests].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = tableAndGuestColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: TablesAndGuestsColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Manage tables</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Find Table by Zone, Number, Owner"
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<TablesAndGuestsColumnId> columns={tableAndGuestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={tableAndGuestColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : tables.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={tableAndGuestColumns.length}>
                    <Stack textAlign="center">No tables and guests yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TablesAndGuestTableRows onSuccess={onSuccess} tables={sortedTables} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack sx={{ alignItems: 'center', py: 2 }}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
        </Stack>
      </MainCard>
    </>
  );
};

export default TablesAndGuestsManagementTable;
