import { Link } from 'react-router-dom';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as SuccessSVG } from 'svg/icon-success.svg';

// project import
import AuthWrapper from 'sections/auth/AuthWrapper';

// ================================|| CHECK MAIL ||================================ //

const CheckMail = () => {
  return (
    <AuthWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Stack alignItems="center">
              <SuccessSVG />
            </Stack>
            <Typography
              variant="h3"
              sx={{
                mt: 2,
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '44px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              Reset successful
            </Typography>
            <Typography color="secondary" sx={{ mt: 2, textAlign: 'center' }}>
              Password has been successfully updated, please log in with new credentials
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: '30px' }}>
          <Button
            component={Link}
            to="/login"
            disableElevation
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              backgroundColor: '#8057DB',
              '&:hover': {
                backgroundColor: '#8157dbab'
              }
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default CheckMail;
