import { CheckCircleOutlined } from '@ant-design/icons';
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useState } from 'react';

export interface IEntry {
  status: string;
  firstName: string;
  lastName: string;
  ticketCategory: string;
  ticketScanned: string;
  scannedBy: string;

  id: string;
}

export enum EntryColumnId {
  STATUS = 'status',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TICKET_CATEGORY = 'ticketCategory',
  TICKET_SCANNED = 'ticketScanned',
  SCANNED_BY = 'scannedBy'
}

interface TableColumn {
  id: EntryColumnId;
  title: string;
  sortable: boolean;
  cell?: (entry: IEntry) => React.ReactNode;
}

const entryColumns: TableColumn[] = [
  { id: EntryColumnId.STATUS, title: 'Status', sortable: true, cell: (entry) => entry.status },
  { id: EntryColumnId.FIRST_NAME, title: 'First name', sortable: true, cell: (entry) => entry.firstName },
  { id: EntryColumnId.LAST_NAME, title: 'Last name', sortable: true, cell: (entry) => entry.lastName },
  { id: EntryColumnId.TICKET_CATEGORY, title: 'Ticket category', sortable: true, cell: (entry) => entry.ticketCategory },
  { id: EntryColumnId.TICKET_SCANNED, title: 'Ticket scanned', sortable: true, cell: (entry) => entry.ticketScanned },
  { id: EntryColumnId.SCANNED_BY, title: 'Scanned by', sortable: true, cell: (entry) => entry.scannedBy }
];

const getColumnValue = (column: TableColumn, entry: IEntry): React.ReactNode => {
  return column.cell ? column.cell(entry) : '';
};

interface EntryRowsProps {
  entries: IEntry[];
  onSuccess?: () => void;
}

const EntryRows = ({ entries = [], onSuccess = () => null }: EntryRowsProps) => {
  const renderCellContent = (column: TableColumn, entry: IEntry) => {
    if (column.id === EntryColumnId.STATUS) {
      return (
        <>
          <CheckCircleOutlined />
        </>
      );
    }
    return getColumnValue(column, entry);
  };

  return (
    <>
      {entries.map((entry) => (
        <TableRow key={entry.id}>
          {entryColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, entry)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface EntryTableProps {
  isLoading?: boolean;
  entries?: IEntry[];
  onSuccess?: () => void;
}

const EntryManagerTable = ({ onSuccess = () => null, isLoading = false, entries = [] }: EntryTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: EntryColumnId; direction: 'asc' | 'desc' }>({
    key: EntryColumnId.FIRST_NAME,
    direction: 'asc'
  });

  const filterEntries = entries.filter((entry) =>
    entryColumns.some((column) => {
      const value = getColumnValue(column, entry);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedEntries = [...filterEntries].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = entryColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: EntryColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>
          All Attendees (Ticket buyers and guests)
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<EntryColumnId> columns={entryColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={entryColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : entries.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={entryColumns.length}>
                    <Stack textAlign="center">No entries yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <EntryRows onSuccess={onSuccess} entries={sortedEntries} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
    </>
  );
};

export default EntryManagerTable;
