import { Grid, Typography } from '@mui/material';
import LoginForm from 'forms/login';
import { Link } from 'react-router-dom';
import AuthWrapper from 'sections/auth/AuthWrapper';

const Login = () => {
  return (
    <AuthWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '28px',
              color: '#fff'
            }}
          >
            Welcome to Cene
          </Typography>
          <Typography
            component={Link}
            to="https://cene-web.web.app/partner-with-us"
            variant="body1"
            sx={{ textDecoration: 'none', fontSize: '14px', lineHeight: '22px', color: '#8057DB' }}
            color="primary"
          >
            Partner with us
          </Typography>
        </Grid>
        <Grid item xs={12} mt="30px">
          <LoginForm />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
