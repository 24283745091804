import { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import Logo from 'components/logo';
import AuthCard from './AuthCard';

interface Props {
  children: ReactNode;
}

const AuthWrapper = ({ children }: Props) => (
  <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#000' }}>
    <Grid container sx={{ minHeight: '100vh' }}>
      <Grid item xs={12} sx={{ pl: 3.75, pt: 3.75 }}>
        <Logo />
      </Grid>
      <Grid item xs={12} sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
        >
          <Grid item>
            <AuthCard
              sx={{
                backgroundColor: '#141414',
                borderColor: '#262626',
                maxWidth: '476px',
                boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.05)'
              }}
            >
              {children}
            </AuthCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default AuthWrapper;
