import { useNavigate } from 'react-router-dom';
import { Button, FormHelperText, Grid, OutlinedInput, Stack } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { ForgotSchema, forgotSchema } from 'schemas/forgotSchema';
import { AuthError } from '@supabase/supabase-js';
import { supabase } from 'supabase/supabase';

type FormikHelpersType = FormikHelpers<ForgotSchema & { submit: string | null }>;

const ForgotForm = () => {
  const navigate = useNavigate();

  const onSubmit = async (values: ForgotSchema, formikHelpers: FormikHelpersType) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(values.email);

      if (error) throw error;

      navigate('/check-email');
    } catch (error) {
      if (error instanceof AuthError) {
        formikHelpers.setErrors({ submit: error.message });
      }

      console.log(error);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          submit: null
        }}
        validationSchema={forgotSchema}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers as FormikHelpersType)}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    id="email-forgot"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="E-mail..."
                    inputProps={{}}
                    sx={{
                      color: '#fff',
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: '#727272'
                      },

                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#434343'
                      }
                    }}
                  />
                </Stack>
                {touched.email && errors.email && (
                  <FormHelperText error id="helper-text-email-forgot">
                    {errors.email}
                  </FormHelperText>
                )}
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}

              <Grid item xs={12} mt="20px">
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    backgroundColor: '#8057DB',
                    '&:hover': {
                      backgroundColor: '#8157dbab'
                    }
                  }}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ForgotForm;
