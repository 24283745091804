import { BulkRequestsTable } from './bulkRequests.table';

export const BulkRequests = () => {
  return (
    <>
      <BulkRequestsTable
        isLoading={false}
        requests={[
          {
            id: '1',
            message: 'Request 1',
            tickets: 200
          },
          {
            id: '2',
            message: 'Request 2',
            tickets: 10
          }
        ]}
      />
    </>
  );
};
