import { ReactComponent as PaystackSVG } from '../svg/paystack.svg';
import { ReactComponent as FlutterwaveSVG } from '../svg/flutterwave.svg';
import { ReactComponent as StripeSVG } from '../svg/stripe.svg';
import { Stack } from '@mui/material';

interface IPaymentCard {
  method: 'stripe' | 'paystack' | 'flutterwave';
  active?: boolean;
  onChange?: () => void;
}

export const PaymentCard = ({ active, method, onChange = () => null }: IPaymentCard) => {
  return (
    <Stack
      onClick={onChange}
      p={2}
      borderRadius="8px"
      flexDirection="row"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        background: active ? '#8057db' : '#1e1e1e'
      }}
    >
      {method === 'stripe' && <StripeSVG />}
      {method === 'paystack' && <PaystackSVG />}
      {method === 'flutterwave' && <FlutterwaveSVG />}
    </Stack>
  );
};
