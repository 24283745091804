import { DeleteOutlined } from '@ant-design/icons';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useState } from 'react';
import PermissionsModal from './permissions';

export interface IMember {
  name: string;
  email: string;
  phoneNumber: string;
  assignedTo: string;
  invitedBy: string;
  acceptedInviteStart: string;
  acceptedInviteEnd: string;
  scannerType: string;

  id: string;
}

export enum MemberColumnId {
  NAME = 'name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ASSIGNED_TO = 'assignedTo',
  INVITED_BY = 'invitedBy',
  ACCEPTED_INVITE = 'acceptedInvite',
  SCANNER_TYPE = 'scannerType',

  ACTIONS = 'actions'
}

interface TableColumn {
  id: MemberColumnId;
  title: string;
  sortable: boolean;
  cell?: (member: IMember) => React.ReactNode;
}

const memberColumns: TableColumn[] = [
  { id: MemberColumnId.NAME, title: 'Name', sortable: true, cell: (member) => member.name },
  { id: MemberColumnId.EMAIL, title: 'E-mail', sortable: true, cell: (member) => member.email },
  { id: MemberColumnId.PHONE_NUMBER, title: 'Phone number', sortable: true, cell: (member) => member.phoneNumber },
  { id: MemberColumnId.ASSIGNED_TO, title: 'Assigned to', sortable: true, cell: (member) => member.assignedTo },
  { id: MemberColumnId.INVITED_BY, title: 'Invited by', sortable: true, cell: (member) => member.invitedBy },
  { id: MemberColumnId.ACCEPTED_INVITE, title: 'Accepted invite', sortable: true, cell: (member) => member.acceptedInviteStart },
  { id: MemberColumnId.SCANNER_TYPE, title: 'Scanner type', sortable: true, cell: (member) => member.scannerType },

  { id: MemberColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const getColumnValue = (column: TableColumn, member: IMember): React.ReactNode => {
  return column.cell ? column.cell(member) : '';
};

interface MemberTableRowsProps {
  members: IMember[];
  onSuccess?: () => void;
}

const MemberTableRows = ({ members = [], onSuccess = () => null }: MemberTableRowsProps) => {
  const [isPermissionsModalOpen, setPermissionsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setPermissionsModalOpen(true);
  };

  const renderCellContent = (column: TableColumn, member: IMember) => {
    if (column.id === MemberColumnId.ACTIONS) {
      return (
        <>
          <IconButton onClick={handleOpenModal}>
            <AdminPanelSettingsIcon />
          </IconButton>

          <IconButton>
            <DeleteOutlined />
          </IconButton>
          <PermissionsModal
            open={isPermissionsModalOpen}
            onClose={() => setPermissionsModalOpen(false)}
            member={member}
            onSuccess={onSuccess}
          />
        </>
      );
    }
    return getColumnValue(column, member);
  };

  return (
    <>
      {members.map((member) => (
        <TableRow key={member.id}>
          {memberColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, member)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface ManageTeamTableProps {
  isLoading?: boolean;
  members?: IMember[];
  onSuccess?: () => void;
}

const ManageTeamTable = ({ onSuccess = () => null, isLoading = false, members = [] }: ManageTeamTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: MemberColumnId; direction: 'asc' | 'desc' }>({
    key: MemberColumnId.NAME,
    direction: 'asc'
  });

  const filterMembers = members.filter((member) =>
    memberColumns.some((column) => {
      const value = getColumnValue(column, member);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedMembers = [...filterMembers].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = memberColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: MemberColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Manage team</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<MemberColumnId> columns={memberColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={memberColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : members.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={memberColumns.length}>
                    <Stack textAlign="center">No member yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <MemberTableRows onSuccess={onSuccess} members={sortedMembers} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
    </>
  );
};

export default ManageTeamTable;
