export interface PartnerProps {
  modal: boolean;
}

export enum SubscribePlan {
  STARTER = 'Starter',
  PRO = 'Pro',
  PREMIUM = 'Premium'
}

export interface Partner {
  id: string;
  status?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_name: string;
  subscription_plan_id: SubscribePlan;
  website: string;
  message?: string;
  date_founded: string;
  subscribe_newsletters: boolean;
  created_at?: string;
}
