import { useSupabase } from 'contexts/supabase';
import { usePermission } from 'hooks/usePermission';
import { ReactNode } from 'react';
import { Navigate } from 'react-router';

interface PrivateRouteProps {
  allowedPermissions?: string[];
  allowedRoles?: string[];
  children: ReactNode;
}

const PrivateRoute = ({ allowedPermissions, allowedRoles, children }: PrivateRouteProps) => {
  const { permissions, isSuperAdmin } = usePermission();

  const { role } = useSupabase();

  const hasPermission = isSuperAdmin || allowedPermissions?.some((permission) => permissions?.includes(permission));
  const hasRole = !allowedRoles || allowedRoles.includes(role);

  if (!hasPermission || !hasRole) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
