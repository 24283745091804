import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';

interface ITimerProps {
  initial: number;
}

export const Timer = ({ initial }: ITimerProps) => {
  const [seconds, setSeconds] = useState(initial);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [initial]);

  /**
   * Format time
   * @param seconds number
   * @returns string
   */
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <Box display="block" textAlign="center">
      <Typography variant="h6" component="h2" title="Session" sx={{ color: 'white', fontSize: '1.32rem', fontWeight: 300 }}>
        {formatTime(seconds)}
      </Typography>
    </Box>
  );
};
