import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  DialogTitle,
  Select,
  MenuItem,
  Alert
} from '@mui/material';
import { DatePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useFormik, Form, FormikProvider } from 'formik';

import { supabase } from 'supabase/supabase';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Partner, SubscribePlan } from 'types/partner';
import { format, parse } from 'date-fns';

import newPartnerFormSchema from 'schemas/newPartnerFormSchema';

export const partnerSubscribtionPlan = [{ value: SubscribePlan.STARTER }, { value: SubscribePlan.PRO }, { value: SubscribePlan.PREMIUM }];

const getInitialValues = (partner: Partner | null) => {
  const newPartner = {
    ...partner
  };

  return newPartner;
};

export type PartnerFormProps = { currentObject: Partner | null; onSuccess: () => void; modalToggler?: () => void };

const EventOrganizerForm = ({ currentObject, onSuccess = () => null, modalToggler = () => null }: PartnerFormProps) => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      ...getInitialValues(currentObject),
      date_founded: currentObject?.date_founded || format(new Date(), 'yyyy-MM-dd'),
      subscription_plan_id: currentObject?.subscription_plan_id || SubscribePlan.STARTER
    },

    validationSchema: newPartnerFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        if (currentObject) {
          const { error } = await supabase
            .from('partners')
            .update([
              {
                ...values
              }
            ])
            .eq('id', currentObject.id);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Partner updated successfully', { variant: 'success' });
        } else {
          const { error } = await supabase.from('partners').insert([
            {
              ...values
            }
          ]);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Partner created successfully', { variant: 'success' });
        }

        onSuccess();
        modalToggler();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const minDate = new Date();

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {supabaseError && (
              <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
                {supabaseError}
              </Alert>
            )}

            <DialogTitle>{currentObject ? 'Edit Partner' : 'Add Partner'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="company_name">Event Organiser Name </InputLabel>
                        <TextField
                          fullWidth
                          id="company_name"
                          placeholder="Enter company name..."
                          {...getFieldProps('company_name')}
                          error={Boolean(touched.company_name && errors.company_name)}
                          helperText={touched.company_name && errors.company_name}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="first_name">Contact First Name</InputLabel>
                        <TextField
                          fullWidth
                          id="first_name"
                          placeholder="Enter first name..."
                          {...getFieldProps('first_name')}
                          error={Boolean(touched.first_name && errors.first_name)}
                          helperText={touched.first_name && errors.first_name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="last_name">Contact Last Name</InputLabel>
                        <TextField
                          fullWidth
                          id="last_name"
                          placeholder="Enter last name..."
                          {...getFieldProps('last_name')}
                          error={Boolean(touched.last_name && errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          placeholder="Enter e-mail..."
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="phone_number">Contact Phone Number</InputLabel>

                        <TextField
                          fullWidth
                          id="phone_number"
                          placeholder="Enter phone number..."
                          {...getFieldProps('phone_number')}
                          error={Boolean(touched.phone_number && errors.phone_number)}
                          helperText={touched.phone_number && errors.phone_number}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="date_founded">Date founded</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            defaultValue={minDate}
                            value={parse(formik.values.date_founded as string, 'yyyy-MM-dd', new Date())}
                            onChange={(newValue) => {
                              if (newValue) {
                                const formattedDate = format(newValue, 'yyyy-MM-dd');
                                formik.setFieldValue('date_founded', formattedDate);
                              } else {
                                return;
                              }
                            }}
                            format="dd.MM.yyyy"
                            slotProps={{
                              popper: {
                                sx: {
                                  [`.${pickersLayoutClasses.root}`]: {
                                    marginBlock: '12px'
                                  }
                                }
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="subscription_plan_id">Subscription plan</InputLabel>
                        <Select
                          defaultValue={'Starter'}
                          {...getFieldProps('subscription_plan_id')}
                          onChange={(e) => {
                            const value = e.target?.value;

                            formik.setFieldValue('subscription_plan_id', value);
                          }}
                          name="subscription_plan_id"
                        >
                          {partnerSubscribtionPlan.map(({ value }) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    onClick={modalToggler}
                    variant="outlined"
                    sx={{
                      color: '#fff',
                      borderColor: '#434343',
                      '&:hover': {
                        backgroundColor: '#121212',
                        color: '#fff',
                        borderColor: '#434343'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                  >
                    Invite Partner to Cene
                  </Button>
                </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default EventOrganizerForm;
