import { Button, Grid, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import MainCard from 'components/MainCard';
import { ROUTE_PARTNERS } from 'constants/routes';
import usePartnerId from 'hooks/usePartnerId';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { supabase } from 'supabase/supabase';
import { PartnerProfile } from 'types/partnerProfile';

enum DialogType {
  DELETE_PARTNER = 'deletePartner',
  DELETE_AVATAR = 'deleteAvatar',
  BLOCK_PARTNER = 'blockPartner',
  CHANGE_TIER = 'changeTier'
}

const Settings = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType | null>(null);
  const partnerId = usePartnerId();

  const { partner } = useOutletContext<{ partner: PartnerProfile | null }>();
  const navigate = useNavigate();

  const handleResetPassword = () => {
    console.log('Reset Cene Account Owner Password');
  };

  const handleDeleteAvatar = async () => {
    try {
      const { error } = await supabase.from('partners').update({ profile_picture_url: null }).eq('id', partnerId);

      if (error) {
        throw error;
      }

      enqueueSnackbar('Profile avatar deleted successfully.', { variant: 'success' });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later.', { variant: 'error' });
    }
  };

  const handleDeletePartner = async () => {
    if (partner?.id) {
      const { error } = await supabase.from('partners').update({ deleted_at: new Date() }).eq('id', partner.id);

      if (error) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
        return;
      }

      enqueueSnackbar('Partner deleted successfully', { variant: 'success' });
      navigate(ROUTE_PARTNERS);
    }
  };

  const handleOpenDialog = (type: DialogType) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      switch (dialogType) {
        case DialogType.DELETE_PARTNER:
          handleDeletePartner();
          break;

        case DialogType.DELETE_AVATAR:
          await handleDeleteAvatar();
          break;

        case DialogType.BLOCK_PARTNER:
          enqueueSnackbar('Partner blocked successfully', { variant: 'success' });
          break;

        case DialogType.CHANGE_TIER:
          enqueueSnackbar('Subscription tier changed successfully', { variant: 'success' });
          break;

        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    }
    setDialogOpen(false);
  };

  return (
    <MainCard title="Settings">
      <Grid container sx={{ gap: '8px' }}>
        <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>Resend Password</Typography>
          <Button
            variant="contained"
            onClick={handleResetPassword}
            sx={{ backgroundColor: '#141414', color: '#3c89e8', '&:hover': { backgroundColor: '#212121' } }}
          >
            Reset Cene Account Owner Password
          </Button>
        </Stack>
        <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>Delete Account</Typography>
          <Button
            variant="contained"
            onClick={() => handleOpenDialog(DialogType.DELETE_PARTNER)}
            sx={{ backgroundColor: '#141414', color: '#f37370', '&:hover': { backgroundColor: '#212121' } }}
          >
            Delete Partner
          </Button>
        </Stack>
        <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>Delete Avatar</Typography>
          <Button
            variant="contained"
            onClick={() => handleOpenDialog(DialogType.DELETE_AVATAR)}
            sx={{ backgroundColor: '#141414', color: '#f37370', '&:hover': { backgroundColor: '#212121' } }}
          >
            Delete Partner's Avatar
          </Button>
        </Stack>
        <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>Block Account</Typography>
          <Button
            variant="contained"
            onClick={() => handleOpenDialog(DialogType.BLOCK_PARTNER)}
            sx={{ backgroundColor: '#141414', color: '#d89614e6', '&:hover': { backgroundColor: '#212121' } }}
          >
            Block Partner
          </Button>
        </Stack>
        <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>Change Subscription Tier</Typography>
          <Button
            variant="contained"
            onClick={() => handleOpenDialog(DialogType.CHANGE_TIER)}
            sx={{ backgroundColor: '#141414', color: '#13a8a8e6', '&:hover': { backgroundColor: '#212121' } }}
          >
            Change Tier
          </Button>
        </Stack>
      </Grid>

      <ConfirmationDialog
        open={dialogOpen}
        title={
          dialogType === DialogType.DELETE_PARTNER
            ? 'Confirm Deletion'
            : dialogType === DialogType.DELETE_AVATAR
              ? 'Confirm Avatar Deletion'
              : dialogType === DialogType.BLOCK_PARTNER
                ? 'Confirm Block'
                : 'Confirm Change Tier'
        }
        description={
          dialogType === DialogType.DELETE_PARTNER
            ? 'Are you sure you want to delete this partner account? This action cannot be undone.'
            : dialogType === DialogType.DELETE_AVATAR
              ? 'Are you sure you want to delete this avatar?'
              : dialogType === DialogType.BLOCK_PARTNER
                ? 'Are you sure you want to block this partner account?'
                : 'Are you sure you want to change the subscription tier?'
        }
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
    </MainCard>
  );
};

export default Settings;
