import { UserAddOutlined } from '@ant-design/icons';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';

interface FormProps {
  modalToggler?: () => void;
}

const DoorStaffForm = ({ modalToggler }: FormProps) => {
  const formik = useFormik({
    initialValues: {
      existUser: '',
      name: '',
      email: '',
      phone_number: '',
      assigned_zone: '',
      scanner_type: ''
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const { isSubmitting, handleChange, touched, getFieldProps, errors } = formik;

  return (
    <Grid sx={{ paddingBlock: 3, paddingInline: 5 }}>
      <div
        style={{
          margin: 'auto',
          padding: '20px',
          borderRadius: '50%',
          backgroundColor: '#8057DB',
          width: '76px',
          height: '76px',
          border: '1px solid #434343'
        }}
      >
        <UserAddOutlined
          style={{
            fontSize: '36px',
            height: '36px'
          }}
        />
      </div>
      <DialogTitle sx={{ fontSize: '24px', color: '#F0F0F0', textAlign: 'center' }}>Add someone to the Door Crew</DialogTitle>
      <DialogContentText sx={{ color: '#BFBFBF', textAlign: 'center' }}>Pick a Door Staff you’ve used before</DialogContentText>
      <Divider />
      <DialogContent sx={{ paddingInline: '1px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Start typing to find an existing user"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Start typing to find an existing user">Start typing to find an existing user</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px', color: '#BC91DE', textAlign: 'center' }}>Or Grow the team...</Typography>
            <Stack spacing={1}></Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
                fullWidth
                id="name"
                placeholder="Enter full name..."
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
                fullWidth
                id="email"
                placeholder="Enter e-mail..."
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
                fullWidth
                id="phone_number"
                placeholder="Enter phone number..."
                {...getFieldProps('phone_number')}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
              />
            </Stack>
          </Grid>{' '}
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Assigned to type area | Section"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Assigned to type area | Section">Assigned to type area | Section</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Scanner Type"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Scanner Type">Scanner Type</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF', textAlign: 'center' }}>
        This action is final and cannot be undone
      </DialogContentText>
      <DialogActions sx={{ paddingBlock: 2.5 }}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={modalToggler}
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#434343',
                '&:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                  borderColor: '#434343'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              onClick={modalToggler}
              sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Invite New Door Staff
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default DoorStaffForm;
