import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Stack } from '@mui/material';
import { Button } from '@mui/material';
import { CenePlusRequestsModalContextProvider, useCenePlusRequestsModalContext } from 'contexts/modal/SpecialModalContexts';
import CustomersStatistic from './customersStatistic';
import { User } from 'types/user';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import CustomersTable from './customers.table';
import { DebouncedInput } from 'components/third-party/react-table';
import ReusableTabs from 'components/tabs/reusableTabs';
import ReusableModal from 'components/modal/modal';
import CustomerForm from './customerInvite.form';
import { ModalContextProvider, useModalContext } from 'contexts/ReusableModalContext';
import CreateCustomerInvite from './createCustomer';

enum ITab {
  ALL = 'All',
  CENE = 'Cene',
  CENE_PLUS = 'Cene+',
  INCOMPLETE_PROFILES = 'Incomplete Profiles'
}

const CustomersPageLayout = () => {
  const { modalToggler, open } = useCenePlusRequestsModalContext();
  const { modalToggler: modalTogglerCene, open: openCene } = useModalContext();
  const [customers, setCustomers] = useState<User[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('customers').select('*');

      setCustomers(data as User[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const filterCustomers = () => {
    switch (activeTab) {
      case ITab.CENE:
        return customers.filter((customer) => customer.app === 'cene');
      case ITab.CENE_PLUS:
        return customers.filter((customer) => customer.app === 'cene+');
      case ITab.INCOMPLETE_PROFILES:
        return customers.filter((customer) => customer.onboarding_finished === false);
      default:
        return customers;
    }
  };

  const filteredRequestsByStatus = filterCustomers().filter((customer) =>
    Object.values(customer).join(' ').toLowerCase().includes(filter.toLowerCase())
  );

  const concatenateFields = (customer: User) => {
    const metadataFields = Object.values(customer).join(' ');
    const otherFields = 'email' in customer ? customer.email : '';
    return `${metadataFields} ${otherFields}`.toLowerCase();
  };

  const filteredRequests = filteredRequestsByStatus.filter((customer) => concatenateFields(customer).includes(filter.toLowerCase()));

  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const paginatedCustomers = filteredRequests.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          onClick={() => modalTogglerCene(null)}
          variant="contained"
          sx={{
            backgroundColor: '#4A7DFF',
            color: '#000',
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: '8px',
            '&:hover': { backgroundColor: '#4a7dffde' }
          }}
        >
          <PlusOutlined />
          Cene Customer
        </Button>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <PlusOutlined />
          Cene+ request
        </Button>
      </Stack>
      <CustomersStatistic customers={customers} />
      <Stack
        sx={{
          mt: 3,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderInline: '1px solid #1e1e1e',
          borderTop: '1px solid #1e1e1e',
          padding: '20px'
        }}
      >
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} />

        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search customer..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <Stack>
        <CustomersTable customers={paginatedCustomers} isLoading={isLoading} />
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
      </Stack>
      <ReusableModal
        currentObject={null}
        open={openCene}
        modalToggler={() => modalTogglerCene(null)}
        content={CreateCustomerInvite}
        width="520px"
      />
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={CustomerForm} width="600px" />
    </>
  );
};

const CustomersPage = () => {
  return (
    <CenePlusRequestsModalContextProvider>
      <ModalContextProvider>
        <CustomersPageLayout />
      </ModalContextProvider>
    </CenePlusRequestsModalContextProvider>
  );
};

export default CustomersPage;
