import { Partner } from 'types/partner';
import createModalContext from './ModalContext';
import { CenePlusRequest, CustomerInvite, User } from 'types/user';
import { Role } from 'types/role';
import { Member, MemberInvite } from 'types/member';

export const { useModalContext: usePartnerModalContext, ModalContextProvider: PartnerModalContextProvider } = createModalContext<Partner>();

export const { useModalContext: useCustomerModalContext, ModalContextProvider: CustomerModalContextProvider } = createModalContext<User>();

export const { useModalContext: useTeamUserModalContext, ModalContextProvider: TeamUserModalContextProvider } =
  createModalContext<Member>();

export const { useModalContext: useRolesModalContext, ModalContextProvider: RolesModalContextProvider } = createModalContext<Role>();

export const { useModalContext: useCenePlusRequestsModalContext, ModalContextProvider: CenePlusRequestsModalContextProvider } =
  createModalContext<CenePlusRequest>();

export const { useModalContext: useCustomerInviteModalContext, ModalContextProvider: CustomerInviteModalContextProvider } =
  createModalContext<CustomerInvite>();

export const { useModalContext: useMemberInviteModalContext, ModalContextProvider: MemberInviteModalContextProvider } =
  createModalContext<MemberInvite>();
