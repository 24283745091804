import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { Formik } from 'formik';

import IconButton from 'components/@extended/IconButton';

import { strengthColor, strengthIndicator } from 'utils/password-strength';

import { StringColorProps } from 'types/password';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { supabase } from 'supabase/supabase';
import { createNewPasswordSchema } from 'schemas/createNewPasswordSchema';
import { ROUTE_HOME } from 'constants/routes';

interface IProps {
  handleError: (errorCode: string) => void;
}

const CreateNewPasswordForm = ({ handleError }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [level, setLevel] = useState<StringColorProps>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const getInviteId = () => {
    const params = new URLSearchParams(location.search);

    return params.get('invite');
  };

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    setAccessToken(hashParams.get('access_token') || '');
    setRefreshToken(hashParams.get('refresh_token') || '');

    handleError(hashParams.get('error_code') || '');
  }, []);

  useEffect(() => {
    const getSessionWithTokens = async () => {
      if (accessToken && refreshToken) {
        const { error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken
        });

        if (error) enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      }
    };

    if (accessToken && refreshToken) {
      getSessionWithTokens();
    }
  }, [accessToken, refreshToken]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const changePassword = async (value: string) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: ''
      }}
      validationSchema={createNewPasswordSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const { error } = await supabase.auth.updateUser({
          password: values.password
        });
        if (error) throw new Error(error.message);

        try {
          const { error } = await supabase.rpc('accept_admin_invitation', { invitation_id: getInviteId() });

          if (error) throw new Error(error.message);

          await supabase.auth.signOut();
          navigate(ROUTE_HOME, { replace: true });

          setSubmitting(false);
        } catch (err: any) {
          console.error(err);
          if (err) enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password" sx={{ color: '#8C8C8C', fontSize: '14px', lineHeight: '22px' }}>
                  Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    changePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{
                          color: '#8C8C8C',
                          '&:hover': {
                            color: '#7b7b7b',
                            backgroundColor: '#222'
                          }
                        }}
                      >
                        {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Enter password"
                  sx={{
                    color: '#fff',
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: '#727272'
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#434343'
                    }
                  }}
                />
              </Stack>
              {touched.password && errors.password && (
                <FormHelperText error id="helper-text-password-reset">
                  {errors.password}
                </FormHelperText>
              )}
              <FormControl fullWidth sx={{ mt: '10px' }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Grid container gap="4px">
                      {[0, 1, 2].map((index) => (
                        <Grid item key={index}>
                          <Box
                            sx={{
                              bgcolor: level?.color,
                              width: 14,
                              height: 8
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      fontSize="0.75rem"
                      sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '22px', color: '#fff' }}
                    >
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: '20px' }}>
              <Stack spacing={1}>
                <InputLabel htmlFor="confirm-password" sx={{ color: '#8C8C8C', fontSize: '14px', lineHeight: '22px' }}>
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  id="confirm-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirmPassword visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                        sx={{
                          color: '#8C8C8C',
                          '&:hover': {
                            color: '#7b7b7b',
                            backgroundColor: '#222'
                          }
                        }}
                      >
                        {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Enter confirm password"
                  sx={{
                    color: '#fff',
                    '& .MuiOutlinedInput-input::placeholder': {
                      color: '#727272'
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#434343'
                    }
                  }}
                />
              </Stack>
              {touched.confirmPassword && errors.confirmPassword && (
                <FormHelperText error id="helper-text-confirm-password-reset">
                  {errors.confirmPassword}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: '20px' }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  backgroundColor: '#8057DB',
                  '&:hover': {
                    backgroundColor: '#8157dbab'
                  }
                }}
              >
                Create new password
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateNewPasswordForm;
