import { Divider, Stack, Typography } from '@mui/material';
import TablesAndGuestManagementStatistic from './statistic';
import TablesAndGuestsManagementTable from './table';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import ShapeButton from 'components/button/button';
import { ROUTE_EVENT_OFFLINE_ORDER } from 'constants/routes';

export interface tableAndGuestListInterface {
  zone: string;
  table: string;
  ownedBy: string;
  ticketCategory: string;
  zoneGroup: string;
  seats: string;
  ticketsOwned: string;
  guests: string;
  id: string;
}

const TablesAndGuestManagement = () => {
  const [tableAndGuestList, setTableAndGuestList] = useState<tableAndGuestListInterface[]>([]);
  const navigation = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTableAndGuestList();
    }
  }, [id]);

  const fetchTableAndGuestList = async () => {
    try {
      const { error, data } = await supabase.rpc('get_tables', { eventid: id });
      if (error) throw error;

      const processedData = await Promise.all(
        data?.map(async (tickets: { id: any; zone: any; table_id: any; ownedby: any; zonegroup: any }) => {
          return {
            id: tickets.id,
            zone: tickets.zone,
            table: tickets.table_id,
            ownedBy: tickets.ownedby,
            zoneGroup: tickets.zonegroup,
            seats: 10
          };
        }) || []
      );
      setTableAndGuestList(processedData as unknown as tableAndGuestListInterface[]);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  return (
    <>
      <Stack mt={3}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 2 }}>Event actions</Typography>
        <Stack sx={{ flexDirection: 'row', gap: 3 }}>
          <ShapeButton width="212px" backgroundColor="#B6A5DC" handleClick={() => navigation(ROUTE_EVENT_OFFLINE_ORDER.replace(':id', id as string))}>
            Offline order
          </ShapeButton>
        </Stack>
      </Stack>
      <TablesAndGuestManagementStatistic />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <TablesAndGuestsManagementTable tables={tableAndGuestList} />
    </>
  );
};

export default TablesAndGuestManagement;
