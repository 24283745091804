import { ClockCircleOutlined, StarOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Grid } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { isSameWeek, parseISO } from 'date-fns';

import { User } from 'types/user';

interface IProps {
  customers: User[];
}

const CustomersStatistic = ({ customers }: IProps) => {
  const totalRecievedCount = customers.length;
  const ceneCustomers = customers.filter((customer) => customer.app === 'cene').length;
  const cenePlusCustomers = customers.filter((customer) => customer.app === 'cene+').length;

  const now = new Date();
  const thisWeekCount = customers.filter((customer) => {
    if (customer.created_at) {
      return isSameWeek(parseISO(customer.created_at), now, { weekStartsOn: 1 });
    }
    return false;
  }).length;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Total Customers"
          count={totalRecievedCount.toString()}
          icon={<UserOutlined style={{ fontSize: '24px', color: '#2196f3' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="This Week"
          count={thisWeekCount.toString()}
          icon={<ClockCircleOutlined style={{ fontSize: '24px', color: '#ffcd18' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Cene Accounts"
          count={ceneCustomers.toString()}
          icon={<UserAddOutlined style={{ fontSize: '24px', color: '#4caf50' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Cene+ Accounts"
          count={cenePlusCustomers.toString()}
          icon={<StarOutlined style={{ fontSize: '24px', color: '#ff9800' }} />}
        />
      </Grid>
    </Grid>
  );
};

export default CustomersStatistic;
