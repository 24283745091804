import { Button, Grid, List, ListItem, ListItemText, TableCell, TableRow, Typography } from '@mui/material';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import { format } from 'date-fns';
import { usePermission } from 'hooks/usePermission';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Partner } from 'types/partner';

interface IRequestProps {
  partner: Partner;
  onSuccess: () => void;
}

enum IDialogType {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  DELETE = 'DELETE'
}

const RequestDetails = ({ partner, onSuccess = () => null }: IRequestProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<IDialogType | null>(null);
  const { permissions, isSuperAdmin } = usePermission();

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
  };

  const handleConfirm = async () => {
    if (dialogType === IDialogType.DELETE) {
      const { error } = await supabase.from('partner_requests').delete().eq('id', partner.id);
      if (error) return enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      enqueueSnackbar('Partner request deleted successfully', { variant: 'success' });
    } else if (dialogType === IDialogType.APPROVE) {
      const { error } = await supabase.from('partner_requests').update({ status: 'approved' }).eq('id', partner.id);
      if (error) return enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      enqueueSnackbar('Partner request approved successfully', { variant: 'success' });
    } else if (dialogType === IDialogType.REJECT) {
      const { error } = await supabase.from('partner_requests').update({ status: 'rejected' }).eq('id', partner.id);
      if (error) return enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      enqueueSnackbar('Partner request rejected successfully', { variant: 'success' });
    }

    onSuccess();
    handleCloseDialog();
  };
  const RenderActions = () => {
    const handleOpenDialog = (type: IDialogType) => {
      setDialogType(type);
      setDialogOpen(true);
    };

    if (partner.status === 'new') {
      return (
        <>
          {(permissions.includes('partners_requests:u') || isSuperAdmin) && (
            <>
              <Button
                variant="contained"
                onClick={() => handleOpenDialog(IDialogType.APPROVE)}
                sx={{ mr: 2, fontWeight: 400, backgroundColor: '#91DCBE', color: '#000', '&:hover': { backgroundColor: '#91dcbec9' } }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                onClick={() => handleOpenDialog(IDialogType.REJECT)}
                sx={{ mr: 2, fontWeight: 400, backgroundColor: '#DB4848', color: '#fff', '&:hover': { backgroundColor: '#db4848d3' } }}
              >
                Reject
              </Button>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {(permissions.includes('partners_requests:d') || isSuperAdmin) && (
            <Button variant="contained" color="error" onClick={() => handleOpenDialog(IDialogType.DELETE)}>
              Delete
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={7}>
          <List sx={{ py: 0 }}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item sm={5.9}>
                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Contact email:</ListItemText>
                  <Typography>{partner.email}</Typography>
                </ListItem>
                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Contact phone number:</ListItemText>
                  <Typography>{partner.phone_number}</Typography>
                </ListItem>

                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Date founded:</ListItemText>
                  <Typography>{format(new Date(partner.date_founded), 'dd.MM.yyyy')}</Typography>
                </ListItem>

                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Company website:</ListItemText>
                  <Typography>{partner.website}</Typography>
                </ListItem>

                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Subscription plan:</ListItemText>
                  <Typography>{partner.subscription_plan_id}</Typography>
                </ListItem>
              </Grid>
              <Grid item sm={5.9}>
                <ListItem>
                  <RenderActions />
                </ListItem>
                {partner.message !== '' && (
                  <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                    <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Message:</ListItemText>
                    <Typography>{partner.message}</Typography>
                  </ListItem>
                )}
              </Grid>
            </Grid>
          </List>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={dialogOpen}
        title={
          dialogType === IDialogType.DELETE
            ? 'Confirm Deletion'
            : dialogType === IDialogType.APPROVE
              ? 'Confirm Approval'
              : 'Confirm Rejection'
        }
        description={
          dialogType === IDialogType.DELETE
            ? 'Are you sure you want to delete this partner request? This action cannot be undone.'
            : dialogType === IDialogType.APPROVE
              ? 'Are you sure you want to approve this partner request?'
              : 'Are you sure you want to reject this partner request?'
        }
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default RequestDetails;
