import { ApartmentOutlined, BarsOutlined, ContainerOutlined, HistoryOutlined } from '@ant-design/icons';
import { Grid, Stack, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';

const GuestlistsStatisticSection = () => {
  const guestlistTypes = '4';
  const guestlistTickets = '200';
  const assignedTickets = '40';
  const pendingUsers = '20';
  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Guestlist Insights</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Guestlist Types"
            count={guestlistTypes}
            icon={<BarsOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Guestlist Tickets"
            count={guestlistTickets}
            icon={<ApartmentOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Assigned Tickets"
            count={assignedTickets}
            icon={<ContainerOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Pending Users"
            count={pendingUsers}
            icon={<HistoryOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GuestlistsStatisticSection;
