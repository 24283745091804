import { TeamOutlined } from '@ant-design/icons';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useState } from 'react';
import { ReactComponent as EventsSVG } from 'svg/events.svg';
import { ReactComponent as TicketSVG } from 'svg/tickets.svg';

const eventRows = [
  {
    eventName: 'Concert of the Year',
    organizer: 'MusicFest',
    status: 'Received',
    ticketBought: '2 tickets',
    datePurchased: '2024-10-10'
  },
  {
    eventName: 'Art Exhibition',
    organizer: 'Gallery 21',
    status: 'Attended',
    ticketBought: '1 ticket',
    datePurchased: '2024-10-12'
  },
  {
    eventName: 'Tech Conference',
    organizer: 'InnovateTech',
    status: 'Received',
    ticketBought: '3 tickets',
    datePurchased: '2024-10-14'
  },
  {
    eventName: 'Food Festival',
    organizer: 'TasteFest',
    status: 'Attended',
    ticketBought: '1 table',
    datePurchased: '2024-10-16'
  },
  {
    eventName: 'Comedy Night',
    organizer: 'Laugh Out Loud',
    status: 'Received',
    ticketBought: '4 tickets',
    datePurchased: '2024-10-18'
  }
];

const EventsAndPurchases = () => {
  const [filter, setFilter] = useState('');
  const totalEventsAttented = eventRows.filter((event) => event.status === 'Attended').length;
  const totalPartners = new Set(eventRows.map((event) => event.organizer)).size;
  const totalTicketsBought = eventRows.reduce((acc, event) => {
    const [sold] = event.ticketBought.split(' ')[0].split('/').map(Number);
    return acc + (isNaN(sold) ? 0 : sold);
  }, 0);

  const filteredRows = eventRows.filter((event) => event.eventName.toLowerCase().includes(filter.toLowerCase()));

  return (
    <MainCard title="Events & Purchases">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce title="Total Events Attended" count={totalEventsAttented.toString()} icon={<EventsSVG />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce
            title="Total Partners"
            count={totalPartners.toString()}
            icon={<TeamOutlined style={{ fontSize: '32px', color: '#c118ff' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce title="Total Tickets Bought" count={totalTicketsBought.toString()} icon={<TicketSVG />} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} marginTop="16px">
        <DebouncedInput
          fullWidth
          value={filter ?? ''}
          onFilterChange={(value) => setFilter(String(value))}
          placeholder="Search for an event"
        />
      </Grid>
      <TableContainer sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'transparent' }}>
            <TableRow>
              <TableCell>Date Purchased</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Organiser</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Tickets Bought</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length > 0 ? (
              filteredRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.datePurchased}</TableCell>
                  <TableCell>{row.eventName}</TableCell>
                  <TableCell>{row.organizer}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: row.status === 'Received' ? '#4CAF50' : '#2196F3',
                        fontWeight: 300,
                        fontSize: '16px'
                      }}
                    >
                      {row.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.ticketBought}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center" color="textSecondary">
                    No events found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default EventsAndPurchases;
