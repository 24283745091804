import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IconButton, Stack } from '@mui/material';
import { useState } from 'react';

interface IPictureCard {
  url: string;
  alt: string;
  onRemove: () => void;
  onEdit: () => void;
}

export const PictureCard = ({ url, alt, onRemove, onEdit }: IPictureCard) => {
  const [isHover, setHover] = useState(false);

  console.log(isHover, 'isHover');

  return (
    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          background: 'linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(0,0,0,0) 100%)',
          width: '100%',
          height: '50px'
        }
      }}
    >
      <img
        style={{
          borderTopLeftRadius: '20px',
          objectFit: 'cover'
        }}
        width="76"
        height="105"
        src={url}
        alt={alt}
      />
      {isHover && (
        <Stack
          width="100%"
          sx={{
            background: 'black'
          }}
          bottom={0}
          position="absolute"
          zIndex={2}
          direction="row"
          justifyContent="space-between"
        >
          <IconButton color="error" onClick={onRemove}>
            <DeleteOutlined />
          </IconButton>
          <IconButton color="info" onClick={onEdit}>
            <EditOutlined />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};
