import { Alert, Divider, InputLabel, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { EventSchema } from '../../event.schema';
import ShapeButton from 'components/button/button';
import { supabase } from 'supabase/supabase';
import { useCallback, useEffect, useState } from 'react';
import { EventLoading } from '../../event.loading';
import { Floorplan, Zone } from 'types/floorplans';
import { seatsioInstance } from 'api/seatsio';
import { ZoneCard } from 'components/zoneCard/zoneCard';
import { v4 as uuidv4 } from 'uuid';
import { TicketCardPlus } from 'components/ticketCard/ticketCardPlus';

interface ICeneTickets {
  onNext: () => void;
  onPrev: () => void;
}
type TFloorplans = {
  floorplans: Floorplan;
};

export const CenePlusTickets = ({ onNext, onPrev }: ICeneTickets) => {
  const formik = useFormikContext<EventSchema>();
  const [loadingFloorplans, setLoadingFloorplans] = useState(true);
  const [loadingZones, setLoadingZones] = useState(false);

  useEffect(() => {
    fetchFloorplans();
  }, []);

  const values = formik.values['cene+'];

  const fetchFloorplans = async () => {
    try {
      const { data } = await supabase.from('partner_floorplans').select('*, floorplans(*)');

      formik.setFieldValue('floorplans', data as TFloorplans[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFloorplans(false);
    }
  };

  const fetchZones = useCallback(
    async (id: string) => {
      try {
        setLoadingZones(true);

        const { data } = await seatsioInstance.get(`floorplans/${id}/tickets`);

        formik.setFieldValue(
          `cene+.tickets`,
          (data as Zone[]).map((item) => ({
            id: uuidv4(),
            name: item.name,
            floorplan: item.floorplan
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingZones(false);
      }
    },
    [values.floorplan_id]
  );

  const addTicket = () => {
    formik.setFieldValue(`cene+.simpleTickets`, [...values.simpleTickets, { id: uuidv4(), isSimple: true }]);
  };

  const renderZones = values.tickets.map((item, index) => (
    <ZoneCard index={index} key={index} name={item.name} seats={item.floorplan.seats || 0} tables={item.floorplan.tables || 0} />
  ));

  const cenePlusValidation =
    (!values.isFloorplan && !values.isSimpleTicketsForCenePlus) ||
    (values.isFloorplan && Boolean(formik.values['cene+'].tickets.length === 0 || formik.errors['cene+']?.tickets)) ||
    (values.isSimpleTicketsForCenePlus &&
      Boolean(formik.values['cene+'].simpleTickets.length === 0 || formik.errors['cene+']?.simpleTickets));

  const ceneValidation = Boolean(formik.values['cene'].tickets.length === 0 || formik.errors['cene']?.tickets);

  const multipleValiationStep = formik.values.eventType === 'both' && (cenePlusValidation || ceneValidation);

  const validationStep = cenePlusValidation;

  const renderSimpleTickets = values.simpleTickets?.map((ticket, index) => <TicketCardPlus key={ticket?.id} index={index} />);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Because One Size Doesn’t Fit All!
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Create tailored ticket options that cater to different seating preferences and access levels, ensuring that attendees can choose the
        experience that best suits their needs.
      </Typography>
      {loadingFloorplans ? (
        <Stack mt={2}>
          <EventLoading relative />
        </Stack>
      ) : (
        <>
          <Stack spacing={1} mt={2}>
            <InputLabel required sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              Add Seating Floorplan
            </InputLabel>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Switch
                sx={{ marginLeft: 0 }}
                checked={values.isFloorplan}
                onChange={() => {
                  formik.setFieldValue(`cene+.isFloorplan`, !values.isFloorplan);
                }}
              />
              <Typography color="info">Tables or assigned seating needed</Typography>
            </Stack>
            {values.isFloorplan && (
              <>
                <Select
                  onChange={(e) => {
                    formik.setFieldValue(`cene+.floorplan_id`, e.target.value);

                    fetchZones(e.target.value);
                  }}
                  value={values.floorplan_id}
                  displayEmpty
                >
                  <MenuItem disabled>Choose floorplan</MenuItem>
                  {(formik.values.floorplans as TFloorplans[]).map((floorplan) => (
                    <MenuItem key={floorplan.floorplans.id} value={floorplan.floorplans.id}>
                      {floorplan.floorplans.name}
                    </MenuItem>
                  ))}
                </Select>
                {loadingZones && (
                  <Stack mt={2}>
                    <EventLoading relative />
                  </Stack>
                )}
                {(values.tickets as Zone[]).length > 0 && renderZones}
              </>
            )}
          </Stack>
        </>
      )}
      <Divider sx={{ borderColor: '#242424', marginBlock: '20px' }} />
      <Stack spacing={1}>
        <InputLabel required sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
          Are There General Access (non-seating) options?
        </InputLabel>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Switch
            sx={{ marginLeft: 0 }}
            checked={values.isSimpleTicketsForCenePlus}
            onChange={() => {
              formik.setFieldValue(`cene+.isSimpleTicketsForCenePlus`, !values.isSimpleTicketsForCenePlus);
            }}
          />
          <Typography color="info">No other non-seating tickets needed</Typography>
        </Stack>
        {values.isSimpleTicketsForCenePlus && (
          <>
            <Stack
              onClick={addTicket}
              borderRadius={2}
              p={2}
              textAlign="center"
              mt={2}
              mb={2}
              sx={{
                border: '1px dashed #363636',
                background: '#1e1e1e',
                cursor: 'pointer',
                transition: '300ms',

                '&:hover': {
                  borderColor: '#8057db'
                }
              }}
            >
              Add ticket
            </Stack>
            {values.simpleTickets.length === 0 ? (
              <Alert variant="outlined" severity="error">
                Please add at least one ticket
              </Alert>
            ) : (
              <Stack gap={2}>{renderSimpleTickets}</Stack>
            )}
          </>
        )}
      </Stack>
      {formik.values.eventType === 'both' && ceneValidation && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          To continue, you need to fix the errors in the {formik.values.formType === 'cene' ? 'Cene+' : 'Cene'} form
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={multipleValiationStep || validationStep} handleClick={onNext} width="100%">
          Create event
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
