import { Info } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export const FeeItem = ({ label, amount }: { label: string; amount?: string }) => (
  <Box mb={1.5}>
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" mt={0.5}>
        <Info style={{ marginRight: '10px', width: '20px', height: '20px' }} />
        <Typography sx={{ fontSize: '1rem', fontWeight: 300 }} className="text-generic">
          {label}
        </Typography>
      </Box>

      <Typography sx={{ fontSize: '21px', fontWeight: 300 }} className="text-generic">
        {`₦ ${amount}`}
      </Typography>
    </Box>
  </Box>
);
