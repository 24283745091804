import { Divider } from '@mui/material';
import AttendeeSummarySection from './attendeeSummary';
import PromocodeTable from './promocodes.table';
import { mockCoupons } from './mockCoupons';
import { ModalContextProvider } from 'contexts/ReusableModalContext';

const PromocodesPage = () => {
  return (
    <>
      <AttendeeSummarySection />
      <Divider sx={{ backgroundColor: '#262626', marginY: 5 }} />
      <ModalContextProvider>
        <PromocodeTable coupons={mockCoupons} />
      </ModalContextProvider>
    </>
  );
};

export default PromocodesPage;
