import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router';

interface Ticket {
  id: string;
  name: string;
  stock: number;
  is_accessible: boolean;
}

interface Customer {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  app: EventType;
}

interface CreateRequestFormProps {
  onSuccess: () => void;
  modalToggler?: () => void;
}

enum RequestType {
  ACCESSIBLE = 'accessible',
  BULK = 'bulk'
}

enum EventType {
  CENE = 'cene',
  CENE_PLUS = 'cene+'
}

const CreateRequestForm = ({ onSuccess, modalToggler = () => null }: CreateRequestFormProps) => {
  const { id: event_id } = useParams();
  const [availableTickets, setAvailableTickets] = useState<Ticket[]>([]);
  const [requestType, setRequestType] = useState<RequestType>(RequestType.ACCESSIBLE);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [eventType, setEventType] = useState<EventType>(EventType.CENE);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const { data, error } = await supabase.from('events').select('*, tickets').eq('id', event_id).single();

        if (error) throw error;

        if (data) {
          setEventType(data.type as EventType);
          const ticketStockData = data.stock;
          const processedTickets = data.tickets
            .filter((ticket: Ticket) => (requestType === RequestType.ACCESSIBLE ? ticket.is_accessible : true))
            .map((ticket: Ticket) => ({
              id: ticket.id,
              name: ticket?.name as string,
              stock: ticketStockData[ticket.id],
              is_accessible: ticket.is_accessible,
              displayTitle: `${ticket.name} (${ticket.stock} available)`
            }));
          setAvailableTickets(processedTickets);
        }
      } catch (err) {
        console.error('Error fetching event details:', err);
      }
    };

    fetchEventDetails();
  }, [event_id]);

  useEffect(() => {
    const fetchEligibleCustomers = async () => {
      try {
        const { data, error } = await supabase
          .from('customers')
          .select('id, email, first_name, last_name , app')
          .in('app', eventType === 'cene' ? ['cene'] : ['cene+']);

        if (error) throw error;

        setCustomers(data || []);
      } catch (err) {
        console.error('Error fetching customers:', err);
      }
    };

    fetchEligibleCustomers();
  }, [eventType]);

  const formik = useFormik({
    initialValues: {
      tickets: availableTickets.reduce(
        (acc, ticket) => {
          acc[ticket.id] = 0;
          return acc;
        },
        {} as Record<string, number>
      )
    },
    onSubmit: async (values) => {
      try {
        if (!selectedCustomer) {
          enqueueSnackbar('Please select a customer', { variant: 'warning' });
          return;
        }

        const selectedTickets = Object.entries(values.tickets)
          .filter(([_, quantity]) => quantity > 0)
          .map(([ticketId, quantity]) => {
            const ticket = availableTickets.find((t) => t.id === ticketId);
            return {
              name: ticket?.name || 'Unknown',
              quantity,
              ticket_name_id: ticketId
            };
          });

        const requestPayload = {
          event_id,
          details: selectedTickets,
          request_type: requestType,
          customer_id: selectedCustomer.id,
          currency: 'NGN'
        };

        const { error } = await supabase.rpc('send_bulk_ticket_request', requestPayload);

        if (error) {
          enqueueSnackbar('Error creating request', { variant: 'error' });
        } else {
          enqueueSnackbar('Request created successfully', { variant: 'success' });
          modalToggler();
          onSuccess();
        }
      } catch (err) {
        console.error('Error submitting request:', err);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  const totalTicketQuantity = Object.values(formik.values.tickets).reduce((sum, qty) => sum + (qty || 0), 0);

  const handleQuantityChange = (ticketId: string, value: number) => {
    if (value < 0) value = 0;

    const availableStock = availableTickets.find((ticket) => ticket.id === ticketId)?.stock || 0;
    const maxAllowed = requestType === RequestType.ACCESSIBLE ? 4 : availableStock;
    const newQuantity = Math.min(value, maxAllowed);

    formik.setFieldValue(`tickets.${ticketId}`, newQuantity);
  };

  const handleCustomerChange = async (customer: Customer | null) => {
    try {
      if (!customer) {
        setSelectedCustomer(null);
        setRequestType(RequestType.ACCESSIBLE);
        return;
      }

      console.log('Selected customer:', customer);

      setSelectedCustomer(customer);
      setRequestType(customer.app === EventType.CENE ? RequestType.ACCESSIBLE : RequestType.BULK);
    } catch (err) {
      console.error('Error handling customer change:', err);
      enqueueSnackbar('Something went wrong while selecting the customer', { variant: 'error' });
    }
  };

  return (
    <>
      {availableTickets.length === 0 ? (
        <Stack sx={{ width: '488px', height: '200px', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ padding: 2, textAlign: 'center', color: '#BBAFDA' }}>
            Currently, there are no tickets available for creating a request. Please check back later.
          </Typography>
        </Stack>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Create Ticket Request</DialogTitle>
          <Divider sx={{ backgroundColor: '#262626' }} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Typography>Customer Assign</Typography>
                  <Autocomplete
                    options={customers}
                    getOptionLabel={(option) => option.email}
                    value={selectedCustomer}
                    onChange={(_, value) => {
                      handleCustomerChange(value);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter email for search..."
                        sx={{
                          borderRadius: 2,

                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#BBAFDA',
                            boxShadow: '0 0 4px 2px rgba(187, 175, 218, 0.6)'
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#BBAFDA',
                            boxShadow: '0 0 4px 2px rgba(187, 175, 218, 0.8)'
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#BBAFDA'
                          }
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Typography>Request Type</Typography>
                  <FormControl>
                    <Select
                      value={requestType}
                      onChange={(e) => {
                        console.log('Test onChange:', e.target.value);
                        setRequestType(e.target.value as RequestType);
                      }}
                      sx={{
                        borderRadius: 2,

                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#BBAFDA',
                          boxShadow: '0 0 4px 2px rgba(187, 175, 218, 0.6)'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#BBAFDA',
                          boxShadow: '0 0 4px 2px rgba(187, 175, 218, 0.8)'
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#BBAFDA'
                        }
                      }}
                    >
                      <MenuItem value={RequestType.BULK} disabled={eventType === EventType.CENE}>
                        Bulk
                      </MenuItem>
                      <MenuItem value={RequestType.ACCESSIBLE}>Accessible</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Typography>Ticket Request Description</Typography>
                  <Typography sx={{ color: '#BBAFDA', textAlign: 'end' }}>
                    Select {totalTicketQuantity}/{requestType === RequestType.ACCESSIBLE ? 4 : 100}
                  </Typography>
                  {availableTickets.map(({ id, name, stock }) => (
                    <Stack key={id} sx={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                      <Typography sx={{ fontSize: '16px', color: '#BBAFDA' }}>{name}</Typography>
                      <FormControl>
                        <TextField
                          type="number"
                          name={`tickets.${id}`}
                          value={formik.values.tickets[id] || 0}
                          onChange={(e) => handleQuantityChange(id, Number(e.target.value))}
                          disabled={stock <= 0}
                          sx={{
                            borderRadius: 2,

                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#BBAFDA',
                              boxShadow: '0 0 4px 2px rgba(187, 175, 218, 0.6)'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#BBAFDA',
                              boxShadow: '0 0 4px 2px rgba(187, 175, 218, 0.8)'
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#BBAFDA'
                            }
                          }}
                        />
                        <Typography variant="caption">{stock} available</Typography>
                      </FormControl>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider sx={{ backgroundColor: '#262626' }} />
          <DialogActions sx={{ padding: 2 }}>
            <Button
              onClick={modalToggler}
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#434343',
                '&:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                  borderColor: '#434343'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={totalTicketQuantity === 0}
              variant="contained"
              sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Submit Request
            </Button>
          </DialogActions>
        </form>
      )}
    </>
  );
};

export default CreateRequestForm;
