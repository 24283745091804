import { Divider, Stack, Typography } from '@mui/material';
import EntryManagerStatistic from './statistic';
import ShapeButton from 'components/button/button';
import EntryManagerTable, { IEntry } from './table';
import { useNavigate, useParams } from 'react-router';
import { ROUTE_MANAGE_TEAM } from 'constants/routes';

export const entries: IEntry[] = [
  {
    id: '1',
    status: 'Admitted',
    firstName: 'John',
    lastName: 'Doe',
    ticketCategory: 'VIP',
    ticketScanned: '2024-10-20 18:45',
    scannedBy: 'Scanner A'
  },
  {
    id: '2',
    status: 'Pending',
    firstName: 'Jane',
    lastName: 'Smith',
    ticketCategory: 'General',
    ticketScanned: '2024-10-20 19:00',
    scannedBy: 'Scanner B'
  },
  {
    id: '3',
    status: 'Admitted',
    firstName: 'Michael',
    lastName: 'Johnson',
    ticketCategory: 'VIP',
    ticketScanned: '2024-10-20 19:15',
    scannedBy: 'Scanner A'
  },
  {
    id: '4',
    status: 'Rejected',
    firstName: 'Emily',
    lastName: 'Davis',
    ticketCategory: 'General',
    ticketScanned: '2024-10-20 19:30',
    scannedBy: 'Scanner C'
  },
  {
    id: '5',
    status: 'Admitted',
    firstName: 'Chris',
    lastName: 'Brown',
    ticketCategory: 'General',
    ticketScanned: '2024-10-20 20:00',
    scannedBy: 'Scanner B'
  }
];

const EntryManagerPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const exportToCSV = (data: IEntry[], filename: string) => {
    const headers = ['Status', 'First Name', 'Last Name', 'Ticket Category', 'Ticket Scanned', 'Scanned By', 'ID'];

    const csvRows = data.map((entry) =>
      [entry.status, entry.firstName, entry.lastName, entry.ticketCategory, entry.ticketScanned, entry.scannedBy, entry.id].join(',')
    );

    const csvContent = [headers.join(','), ...csvRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  return (
    <>
      <EntryManagerStatistic />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <Stack>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Event actions</Typography>
        <Stack sx={{ flexDirection: 'row', gap: 3 }}>
          <ShapeButton
            width="212px"
            backgroundColor="#B6A5DC"
            handleClick={() => navigate(`${ROUTE_MANAGE_TEAM.replace(':id', id as string)}`)}
          >
            Manage Team
          </ShapeButton>
          <ShapeButton width="212px" isOutlined borderColor="#B6A5DC" handleClick={() => exportToCSV(entries, 'EntryData')}>
            Export Data
          </ShapeButton>
        </Stack>
      </Stack>
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <EntryManagerTable entries={entries} />
    </>
  );
};

export default EntryManagerPage;
