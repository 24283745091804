import { Box, Button, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { SelectableObject } from '@seatsio/seatsio-react';
import ShapeButton from 'components/button/button';
import ReusableModal from 'components/modal/modal';
import { formatPriceString } from 'utils/pricing';

interface ISelectableModal {
  selectableObject: SelectableObject;
  onSelectObject: () => void;
  onCloseModal: () => void;
}

export const SelectableModal = ({ selectableObject, onCloseModal, onSelectObject }: ISelectableModal) => {
  const modalContent = () => (
    <>
      <DialogTitle>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Stack>Your Selection</Stack>
          <Button variant="outlined" onClick={onCloseModal}>
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogContent sx={{ p: 2.5 }}>
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: 'rgba(184,202,217,0.06)',
              border: '1px solid rgba(184,202,217,0.16)',
              py: 2,
              px: 2.5,
              borderRadius: '30px 0 30px 0'
            }}
          >
            <Box textAlign="center">
              <Typography component="label" fontSize="18px">
                Zone
              </Typography>
              <Typography sx={{ color: 'white', marginBottom: 0 }}>{selectableObject.labels.section}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography component="label" fontSize="18px">
                Table
              </Typography>
              <Typography sx={{ color: 'white', marginBottom: 0 }}>
                {selectableObject.objectType === 'Table' ? selectableObject.labels.own : selectableObject.labels.parent}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography component="label" fontSize="18px">
                Seats
              </Typography>
              <Typography sx={{ color: 'white', marginBottom: 0 }}>
                {selectableObject.objectType === 'Table' ? `1-${selectableObject.seats.length}` : selectableObject.labels.own}
              </Typography>
            </Box>
          </Box>
          <Box mt={1.5} mb={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ color: 'gray', fontSize: '14px', fontWeight: 300 }}>
                Price per {selectableObject.objectType === 'Table' ? 'table' : 'seat'}
              </Typography>
              <Typography color="#B6A5DC" sx={{ fontSize: '25px', fontWeight: 300 }}>
                ₦
                {selectableObject.objectType === 'Table'
                  ? formatPriceString((selectableObject as any & { seats: object[] }).pricing.ticketTypes[0].price as number)
                  : formatPriceString((selectableObject.pricing.ticketTypes as any)[0].price as number)}
              </Typography>
            </Box>
          </Box>
          <ShapeButton
            sx={{
              paddingInline: '20px'
            }}
            backgroundColor="#B6A5DC"
            bgHover="#9b8cbd"
            handleClick={() => onSelectObject()}
            width="100%"
            color="black"
          >
            Select {selectableObject.objectType === 'Table' ? 'table' : 'seat'}
          </ShapeButton>
        </>
      </DialogContent>
    </>
  );

  return <ReusableModal currentObject={null} open={true} modalToggler={() => {}} content={modalContent} width="520px" />;
};
