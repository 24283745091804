import { CSSProperties, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { Grid, Typography } from '@mui/material';

import navigation from 'menu-items';

import { NavItemType } from 'types/menu';

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  custom?: boolean;
  heading?: string;
  title?: boolean;
  sx?: object;
}

const Breadcrumbs = ({ card = false, custom = false, heading, title = true, sx }: Props) => {
  const location = useLocation();
  const [item, setItem] = useState<NavItemType>();

  const customLocation = location.pathname;

  useEffect(() => {
    navigation?.items?.map((menu: NavItemType) => {
      if (menu.type && menu.type === 'group') {
        if (menu?.url && menu.url === customLocation) {
          setItem(menu);
        } else {
          getCollapse(menu as { children: NavItemType[]; type?: string });
        }
      }
      return false;
    });
  });

  const getCollapse = (menu: NavItemType) => {
    if (!custom && menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
          if (collapse.url === customLocation) {
            setItem(collapse);
          }
        } else if (collapse.type && collapse.type === 'item') {
          if (customLocation === collapse.url) {
            setItem(collapse);
          }
        }
        return menu;
      });
    }
  };

  return (
    <Grid container direction="column" spacing={2} sx={{ mb: 3 }}>
      {title && item && (
        <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
          <Typography variant="h2">{custom ? heading : item?.title}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Breadcrumbs;
