import { Stack } from '@mui/material';
import { ReactComponent as LoaderSVG } from '../svg/Loader.svg';
import { Category, HoldToken, Pricing, SeatingChart, SelectableObject } from '@seatsio/seatsio-types';
import { useEffect, useState } from 'react';

interface ISeatsioPreview {
  containerId?: string;
  eventId: string;
  pricing: Pricing;
  currencyLabel?: string;
  holdToken: string;
  onChartRendered: (chart: SeatingChart) => void;
  onSessionInitialized: (params: HoldToken) => void;
  onHoldTokenExpired: () => void;
  onObjectClicked: (object: SelectableObject & { confirmSelection: (param: string) => void }) => void;
  onListCategories: (categories: Category[]) => void;
  onObjectDeselected: (obj: SelectableObject) => void;
  onInitSelectableObjects: (selectableObjects: SelectableObject[]) => void;
}

export const SeatsioTables = ({
  containerId = 'seatsio',
  eventId = 'a592c5b4-0869-4ff9-81d9-3e9932087834',
  pricing,
  holdToken,
  currencyLabel = '',
  onSessionInitialized,
  onHoldTokenExpired,
  onInitSelectableObjects,
  onListCategories,
  onObjectClicked,
  onChartRendered,
  onObjectDeselected
}: ISeatsioPreview) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(holdToken, 'holdToken');
    
    if (window.seatsio) {
      const chart = new window.seatsio.SeatingChart({
        divId: containerId,
        workspaceKey: `${process.env.REACT_APP_SEATSIO_WORKSPACE}`,
        event: eventId,
        session: 'manual',
        holdToken: holdToken,
        colorScheme: 'dark',
        pricing,
        priceFormatter: (price) => {
          return currencyLabel + price;
        },
        onChartRendered: async () => {
          const categories = await chart.listCategories();
          const selectedObjects = await chart.listSelectedObjects();

          onInitSelectableObjects(selectedObjects);

          onListCategories(categories);
          setLoading(false);
          onChartRendered(chart);
        },
        onSessionInitialized: (e) => {
          onSessionInitialized(e);
        },
        onObjectDeselected: (e) => {
          onObjectDeselected(e);
        },
        onTicketTypePrompt: (e, confirmSelection) => {
          onObjectClicked({ ...e.objectToSelect, confirmSelection });
          console.log('onTicketTypePrompt', e);
        },
        onHoldTokenExpired
      }).render();

      console.log(chart);
    }
  }, [holdToken]);

  const spinAnimation = {
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    },
    animation: 'spin 1s linear infinite'
  };

  const renderLoader = (
    <Stack
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <Stack sx={{ ...spinAnimation }}>
        <LoaderSVG />
      </Stack>
    </Stack>
  );

  return (
    <Stack position="relative">
      {loading && renderLoader}
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
        id={containerId}
      />
    </Stack>
  );
};
