// material-ui
import { Chip, ChipProps, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// assets
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

// ==============================|| STATISTICS - ECOMMERCE CARD ||============================== //

interface Props {
  title: string;
  count: string;
  percentage?: number;
  isLoss?: boolean;
  color?: ChipProps['color'];
  icon?: ReactNode;
}

const AnalyticEcommerce = ({ color = 'primary', title, count, percentage, isLoss, icon }: Props) => (
  <MainCard contentSX={{ p: 2.25 }}>
    <Stack spacing={0.5}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="h4" color="inherit">
            {count}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
        </Grid>

        <Grid>{icon}</Grid>

        {percentage && (
          <Grid item>
            <Chip
              variant="combined"
              color={color}
              icon={
                <>
                  {!isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                  {isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                </>
              }
              label={`${percentage}%`}
              sx={{ ml: 1.25, pl: 1 }}
              size="small"
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  </MainCard>
);

export default AnalyticEcommerce;
