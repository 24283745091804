import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICropedPicture } from 'hooks/useCrop';
import { EventSchema } from 'pages/eventManagement/event/event.schema';

export const LineupMini = () => {
  const formik = useFormikContext<EventSchema>();

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];

  const artistsNames = values.line_up.map((artist, index) => index < 3 && artist.title).filter((item) => item);

  const renderPicture = values.line_up
    .map((artist, index) => (
      <div
        key={artist.id}
        style={{
          position: 'relative',
          width: '40px',
          height: '40px',
          marginLeft: '-4px'
        }}
      >
        {artist.thumbnail_url?.length === 0 ? (
          <Stack
            sx={{
              background: '#393939',
              width: '40px',
              height: '40px',
              borderRadius: '8px 0',
              position: 'relative',
              zIndex: index + 1
            }}
          />
        ) : (
          <img
            src={(artist.thumbnail_url as ICropedPicture[])[0]?.cropedPicture}
            alt={`Artist`}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '8px 0',
              position: 'relative',
              zIndex: index + 1
            }}
          />
        )}

        <div
          style={{
            content: '',
            position: 'absolute',
            top: '-1px',
            left: '-2px',
            width: '42px',
            height: '41px',
            borderRadius: '8px 0',
            background: '#121212',
            zIndex: index
          }}
        />
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            marginLeft: 0,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #121212 100%)',
            zIndex: index + 1
          }}
        />
      </div>
    ))
    .filter((item) => item);

  const artistCount = values.line_up.length - 5;

  return (
    <Stack>
      <Stack flexDirection="row">
        {renderPicture}
        {artistCount > 0 && (
          <div
            style={{
              position: 'relative',
              width: '40px',
              height: '40px',
              marginLeft: '-4px'
            }}
          >
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '8px 0',
                background: '#222222',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                color: 'white',
                fontSize: '16px',
                fontWeight: 500,
                zIndex: 6
              }}
            >
              + {values.line_up.length - 5}
            </div>
            <div
              style={{
                content: '',
                position: 'absolute',
                top: '-1px',
                left: '-2px',
                width: '42px',
                height: '41px',
                borderRadius: '8px 0',
                background: '#121212',
                zIndex: 5
              }}
            />
            <div
              style={{
                height: '100%',
                width: '42px',
                position: 'absolute',
                bottom: 0,
                marginLeft: 0,
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #121212 100%)',
                zIndex: 6
              }}
            />
          </div>
        )}
      </Stack>
      <Stack mt={2}>{artistsNames.join(', ')}</Stack>
    </Stack>
  );
};
