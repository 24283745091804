import * as Yup from 'yup';
import { passwordRule } from './validationRules';

export const uppercaseRule = Yup.string().matches(/[A-Z]/, 'Password must contain at least one uppercase letter');

export const lowercaseRule = Yup.string().matches(/[a-z]/, 'Password must contain at least one lowercase letter');

export const numberRule = Yup.string().matches(/\d/, 'Password must contain at least one number');

export const specialCharacterRule = Yup.string().matches(/[!@#$%^&*()_+]/, 'Password must contain at least one special character');

export const createNewPasswordSchema = Yup.object().shape({
  password: passwordRule,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Please confirm your password')
});

export type CreateNewPasswordSchema = Yup.InferType<typeof createNewPasswordSchema>;
