import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, FormHelperText, Grid, Link, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import IconButton from 'components/@extended/IconButton';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { supabase } from 'supabase/supabase';
import { LoginSchema, loginSchema } from 'schemas/loginSchema';
import { AuthError } from '@supabase/supabase-js';
import { ROUTE_DASHBOARD } from 'constants/routes';

type FormikHelpersType = FormikHelpers<LoginSchema & { submit: string | null }>;

const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const onSubmit = async (values: LoginSchema, formikHelpers: FormikHelpersType) => {
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password
      });

      if (error) throw error;

      navigate(ROUTE_DASHBOARD, { state: { fromLogin: true } });
    } catch (error) {
      if (error instanceof AuthError) {
        formikHelpers.setErrors({ submit: error.message });
      }

      console.log(error);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={loginSchema}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers as FormikHelpersType)}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login" sx={{ color: '#8C8C8C', fontSize: '14px', lineHeight: '22px' }}>
                    Email Address
                  </InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    sx={{
                      color: '#fff',
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: '#727272'
                      },

                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#434343'
                      }
                    }}
                  />
                </Stack>
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.email}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: '22px' }}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login" sx={{ color: '#8C8C8C', fontSize: '14px', lineHeight: '22px' }}>
                    Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{
                      color: '#fff',
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: '#727272'
                      },

                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#434343'
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{
                            color: '#8C8C8C',
                            '&:hover': {
                              color: '#7b7b7b',
                              backgroundColor: '#222'
                            }
                          }}
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Enter your password"
                  />
                </Stack>
                {touched.password && errors.password && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.password}
                  </FormHelperText>
                )}
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12} sx={{ mt: '20px' }}>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    backgroundColor: '#8057DB',
                    '&:hover': {
                      backgroundColor: '#8157dbab'
                    }
                  }}
                >
                  Login
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ mt: '30px' }}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Link
                    underline="none"
                    component={RouterLink}
                    to="/forgot-password"
                    sx={{ fontSize: '16px', lineHeight: '24px', color: '#fff', textDecoration: 'none' }}
                  >
                    Forgot password?
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
