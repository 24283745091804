import { BellOutlined, CheckCircleOutlined, ClockCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Grid } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { isSameWeek, parseISO } from 'date-fns';
import { CenePlusRequest } from 'types/user';

interface IProps {
  requests: CenePlusRequest[];
}

const CenePlusRequestsStatistic = ({ requests }: IProps) => {
  const totalRecievedCount = requests.length;
  const newRequestsCount = requests.filter((request) => request.status?.toLowerCase() === 'new').length;
  const approvedRequests = requests.filter((request) => request.status === 'approved').length;
  const percentage = totalRecievedCount > 0 ? (approvedRequests / totalRecievedCount) * 100 : 0;

  const now = new Date();
  const thisWeekRequestsCount = requests.filter((request) => {
    if (request.created_at) {
      return isSameWeek(parseISO(request.created_at), now, { weekStartsOn: 1 });
    }
    return false;
  }).length;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Total Received"
          count={totalRecievedCount.toString()}
          icon={<DatabaseOutlined style={{ fontSize: '24px', color: '#c118ff' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="This Week"
          count={thisWeekRequestsCount.toString()}
          icon={<ClockCircleOutlined style={{ fontSize: '24px', color: '#ffcd18' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Approval Rate"
          count={`${percentage.toFixed(0)}%`}
          icon={<CheckCircleOutlined style={{ fontSize: '24px', color: '#20ff18' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="New"
          count={newRequestsCount.toString()}
          icon={<BellOutlined style={{ fontSize: '24px', color: '#1890ff' }} />}
        />
      </Grid>
    </Grid>
  );
};

export default CenePlusRequestsStatistic;
