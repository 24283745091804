import { Button, Grid, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import MainCard from 'components/MainCard';
import { ROUTE_CUSTOMERS } from 'constants/routes';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { supabase } from 'supabase/supabase';
import { User } from 'types/user';

enum DialogType {
  DELETE_CUSTOMER = 'deleteCustomer',
  BLOCK_CUSTOMER = 'blockCustomer',
  CHANGE_TYPE = 'changeType'
}

const buttonStyles = {
  backgroundColor: '#141414',
  '&:hover': { backgroundColor: '#212121' }
};

const CustomerSettings = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType | null>(null);

  const { customer } = useOutletContext<{ customer: User }>();
  const navigate = useNavigate();

  const dialogConfig = {
    [DialogType.DELETE_CUSTOMER]: {
      title: 'Heads up!',
      description: `If you remove ${customer.first_name} ${customer.last_name}, they’ll lose all access to Cene and won’t be able to log in anymore. Just wanted to make sure you’re aware before you proceed!`
    },
    [DialogType.BLOCK_CUSTOMER]: {
      title: 'Confirm Block',
      description: 'Are you sure you want to block this customer account?'
    },
    [DialogType.CHANGE_TYPE]: {
      title: 'Confirm Change Tier',
      description: 'Are you sure you want to change the subscription tier?'
    }
  };

  const handleResetPassword = () => {
    console.log('Reset Cene Account Owner Password');
  };

  const handleDeleteCustomer = async () => {
    if (customer?.id) {
      const { error } = await supabase.from('customers').update({ deleted_at: new Date() }).eq('id', customer.id);

      if (error) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
        return;
      }

      enqueueSnackbar('Customer deleted successfully', { variant: 'success' });
      navigate(ROUTE_CUSTOMERS);
    }
  };

  const handleOpenDialog = (type: DialogType) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      switch (dialogType) {
        case DialogType.DELETE_CUSTOMER:
          await handleDeleteCustomer();
          break;

        case DialogType.BLOCK_CUSTOMER:
          enqueueSnackbar('Customer blocked successfully', { variant: 'success' });
          break;

        case DialogType.CHANGE_TYPE:
          enqueueSnackbar('Account type changed successfully', { variant: 'success' });
          break;

        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setDialogOpen(false);
    }
  };

  const renderButton = (text: string, onClick: () => void, color: string) => (
    <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{text}</Typography>
      <Button variant="contained" onClick={onClick} sx={{ ...buttonStyles, color }}>
        {text}
      </Button>
    </Stack>
  );

  return (
    <MainCard title="Settings">
      <Grid container sx={{ gap: '8px' }}>
        {renderButton('Resend Password', handleResetPassword, '#3c89e8')}
        {renderButton('Delete Account', () => handleOpenDialog(DialogType.DELETE_CUSTOMER), '#f37370')}
        {renderButton('Block Account', () => handleOpenDialog(DialogType.BLOCK_CUSTOMER), '#d89614e6')}
        {renderButton('Change Account Type', () => handleOpenDialog(DialogType.CHANGE_TYPE), '#13a8a8e6')}
      </Grid>

      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig[dialogType!]?.title}
        description={dialogConfig[dialogType!]?.description}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
    </MainCard>
  );
};

export default CustomerSettings;
