import { UserType } from 'types/customer';
import * as Yup from 'yup';
import { emailRule, phoneNumberRule } from './validationRules';

export const customerFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: emailRule,
  phone_number: phoneNumberRule,

  company_name: Yup.string().when('app', (app: any, schema: Yup.StringSchema<string | undefined>) => {
    return app === UserType.CENE_PLUS ? schema.required('Company name is required') : schema.notRequired();
  }),
  job_title: Yup.string().when('app', (app: any, schema: Yup.StringSchema<string | undefined>) => {
    return app === UserType.CENE_PLUS ? schema.required('Job title is required') : schema.notRequired();
  }),
  reference: Yup.string().when('app', (app: any, schema: Yup.StringSchema<string | undefined>) => {
    return app === UserType.CENE_PLUS ? schema.notRequired() : schema.notRequired();
  })
});
