import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography } from '@mui/material';

// third-party
import OtpInput from 'react18-input-otp';

// project import

// types
// import { ThemeMode } from 'types/config';

// ============================|| STATIC - CODE VERIFICATION ||============================ //

const AuthCodeVerification = () => {
  const theme = useTheme();
  const [otp, setOtp] = useState<string>();
  const [isResent, setIsResent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isResent) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResent]);

  const handleResend = () => {
    setIsResent(true);
    setResendTimer(22);

    setTimeout(() => setIsResent(false), 22000);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // const borderColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[200] : theme.palette.grey[300];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OtpInput
          value={otp}
          onChange={(otp: string) => setOtp(otp)}
          numInputs={6}
          containerStyle={{ justifyContent: 'space-between' }}
          inputStyle={{
            width: '100%',
            margin: '8px',
            padding: '10px',
            border: `1px solid #434343`,
            borderRadius: 4,
            background: '#141414',
            color: '#fff',

            ':hover': {
              borderColor: '#8057DB'
            }
          }}
          focusStyle={{
            outline: 'none',
            boxShadow: theme.customShadows.primary,
            border: `1px solid #8057DB`
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {/* <AnimateButton> */}
        <Button
          disableElevation
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            backgroundColor: '#8057DB',
            '&:hover': {
              backgroundColor: '#8157dbab'
            }
          }}
        >
          Continue
        </Button>
        {/* </AnimateButton> */}
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ color: '#fff' }}>Did not receive the email? Check your spam filter, or</Typography>

          <Typography
            onClick={handleResend}
            sx={{
              width: '300px',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              padding: '8px 12px',
              borderRadius: '32px',
              color: isResent ? '#000' : '#8057DB',
              bgcolor: isResent ? '#5e5d5d' : 'transparent'
            }}
          >
            {isResent ? `Resend code in ${formatTime(resendTimer)}` : 'Resend OTP'}
          </Typography>
          {/* {isResent ? (
              <>
                <span>in</span>
                <p className={styles.timer}>{formatTime(resendTimer)}</p>
              </>
            ): ()} */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AuthCodeVerification;
