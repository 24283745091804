import * as Yup from 'yup';
import { phoneNumberRule } from './validationRules';

export const newGuestSchema = Yup.object().shape({
  guestName: Yup.string().required('First name is required'),
  guestEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  phone_number: phoneNumberRule
});

export type NewGuestSchema = Yup.InferType<typeof newGuestSchema>;
