import * as Yup from 'yup';
import { emailRule, phoneNumberRule } from './validationRules';

export const memberFormSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  job_title: Yup.string().required('Job title is required'),
  phone_number: phoneNumberRule,
  email: emailRule
});

export default memberFormSchema;
