import { Stack, Typography } from '@mui/material';
import { ReactComponent as OpenDoorsSVG } from '../../svg/openDoors.svg';
import { ReactComponent as CloseDoorsSVG } from '../../svg/closeDoors.svg';
import { CropedPicture } from 'hooks/useCroper';
import dayjs from 'dayjs';
import { EventSchema } from 'pages/eventManagement/event/event.schema';
import { useFormikContext } from 'formik';
import { ICropedPicture } from 'hooks/useCrop';

export const LineupFull = () => {
  const formik = useFormikContext<EventSchema>();

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];

  const renderArtists = values.line_up
    .sort((a, b) => {
      return dayjs(a.starts_at).isAfter(dayjs(b.starts_at)) ? 1 : -1;
    })
    .map((item) => (
      <Stack flexDirection="row" alignItems="center" gap={2}>
        {item.isDoorsClose ? (
          <Stack flexDirection="row" justifyContent="center" alignItems="center" width="40px" height="40px">
            <CloseDoorsSVG />
          </Stack>
        ) : item.thumbnail_url && (item.thumbnail_url as CropedPicture[])[0] ? (
          <Stack position="relative" sx={{ width: '40px', height: '40px' }}>
            <img
              src={((item.thumbnail_url as ICropedPicture[])[0] as ICropedPicture)?.cropedPicture}
              alt={item.title}
              style={{
                width: '40px',
                height: '40px'
              }}
            />
          </Stack>
        ) : (
          <Stack justifyItems="center" alignItems="center" minWidth="40px" minHeight="40px">
            <div style={{ width: '1px', height: '40px', backgroundColor: 'rgb(34 34 34)' }} />
          </Stack>
        )}

        <Stack>
          <Typography fontSize="16px">{item.title}</Typography>
          <Typography>{item.isWithoutTime ? '-' : dayjs(item.starts_at, 'HH:mm').format('HH:mm')}</Typography>
        </Stack>
      </Stack>
    ));

  return (
    <Stack gap="24px">
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Stack flexDirection="row" justifyContent="center" alignItems="center" width="40px" height="40px">
          <OpenDoorsSVG />
        </Stack>
        <Stack>
          <Typography fontSize="16px">Doors open</Typography>
          <Typography>{dayjs(values.lineup_srart_at, 'HH:mm').format('HH:mm')}</Typography>
        </Stack>
      </Stack>
      {renderArtists}
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Stack flexDirection="row" justifyContent="center" alignItems="center" width="40px" height="40px">
          <OpenDoorsSVG />
        </Stack>
        <Stack>
          <Typography fontSize="16px">Event ends</Typography>
          <Typography>{dayjs(values.lineup_end_at, 'HH:mm').format('HH:mm')}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
