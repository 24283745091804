// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'types/config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */ <svg width="117" height="35" viewBox="0 0 117 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_160_66302)">
        <path
          d="M19.819 6.90723C10.7666 6.90723 6.79651 10.7957 6.79651 17.4132V28.0934H19.819C28.7287 28.0934 32.7844 24.6976 32.7844 18.8358V18.2842H25.988V18.4294C25.988 21.2156 24.0748 22.3178 19.819 22.3178C15.5633 22.3178 13.6225 21.1565 13.6225 17.5874V17.4132C13.6225 13.8432 15.5642 12.6828 19.819 12.6828C24.0739 12.6828 25.988 13.7861 25.988 16.5713V16.7164H32.7844V16.1648C32.7844 10.3021 28.7296 6.90723 19.819 6.90723Z"
          fill="white"
        />
        <path
          d="M46.1395 6.90723C37.1718 6.90723 33.0598 10.7667 33.0598 17.6165V17.8778C33.0598 24.4072 37.1147 28.0934 46.0824 28.0934C55.0501 28.0934 59.0478 24.6395 59.0478 20.1704V20.0833H52.137V20.1413C52.137 22.1727 49.881 23.0727 46.2538 23.0727C42.3132 23.0727 40.2848 21.9414 39.943 18.8939H59.0478V16.6303C59.0478 10.5644 54.7929 6.90819 46.1395 6.90819V6.90723ZM40.0563 15.0916C40.6276 12.6538 42.5979 11.6957 46.0252 11.6957C49.9944 11.6957 51.9085 12.7699 52.2227 15.0916H40.0572H40.0563Z"
          fill="white"
        />
        <path
          d="M74.516 6.90723C68.2909 6.90723 66.0912 10.3031 66.0912 15.469V7.05239H59.3232V27.9483H66.0912V16.4842C66.0912 13.727 68.7185 12.9141 71.6601 12.9141C75.6578 12.9141 77.1434 14.4809 77.1434 17.2671V27.9473H83.9399V16.0487C83.9399 9.7505 80.7126 6.90723 74.516 6.90723Z"
          fill="white"
        />
        <path
          d="M98.2293 6.92368C97.9236 6.914 97.6132 6.90723 97.2951 6.90723C88.3274 6.90723 84.2155 10.7667 84.2155 17.6165V17.8778C84.2155 24.4072 88.2703 28.0934 97.238 28.0934C106.206 28.0934 110.203 24.6395 110.203 20.1704V20.0833H103.293V20.1413C103.293 22.1727 101.037 23.0727 97.4094 23.0727C93.4688 23.0727 91.4405 21.9414 91.0986 18.8939H110.203V6.90723H98.216L98.2293 6.92368ZM91.2129 15.0916C91.7842 12.6538 93.7545 11.6957 97.1818 11.6957C101.151 11.6957 103.065 12.7699 103.379 15.0916H91.2138H91.2129Z"
          fill="white"
        />
        <path d="M117 0H110.203V6.9069H117V0Z" fill="white" />
        <path d="M6.79648 28.0924H0V34.9993H6.79648V28.0924Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_160_66302">
          <rect width="117" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
