import { Divider, InputLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { ExpandedCard } from 'components/expandedCard/expandedCard';
import { getIn, useFormikContext } from 'formik';
import { EventSchema } from 'pages/eventManagement/event/event.schema';
import { ReactComponent as DollarSVG } from '../../svg/dollar.svg';
import { ReactComponent as NairaSVG } from '../../svg/naira.svg';
import { ReactComponent as PoundSVG } from '../../svg/pound.svg';
import { ReactComponent as EuroSVG } from '../../svg/euro.svg';

interface IZoneCard {
  index: number;
  seats: number;
  tables: number;
  name: string;
}

export const ZoneCard = ({ name, tables, seats, index }: IZoneCard) => {
  const formik = useFormikContext<EventSchema>();

  const values = formik.values['cene+'];
  const errors = formik.errors['cene+'];

  return (
    <>
      <ExpandedCard
        title={name}
        expanded={true}
        isDoor={true}
        onRemove={() => {}}
        headerEndComponent={
          <Stack width="100%" gap={2} flexDirection="row">
            <Typography fontSize="16px" color="#E6E9AE">
              Tables {tables}
            </Typography>
            <Typography fontSize="16px" color="#E6E9AE">
              Seats {seats}
            </Typography>
          </Stack>
        }
        children={
          <Stack gap={2}>
            <Stack spacing={1}>
              <InputLabel required sx={{ color: '#8C8C8C' }}>
                International Currency
              </InputLabel>
              <Stack gap={1} mt={2} flexDirection="row" alignItems="baseline">
                <Stack width="100%" display="block">
                  <TextField
                    InputProps={{
                      startAdornment: <DollarSVG />
                    }}
                    error={Boolean(getIn(errors, `tickets[${index}].price_dollar`))}
                    helperText={getIn(errors, `tickets[${index}].price_dollar`)}
                    fullWidth
                    value={values.tickets[index]?.price_dollar}
                    name={`cene+.tickets[${index}].price_dollar`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Dollar"
                    type="number"
                  />
                </Stack>
                <Stack width="100%" display="block">
                  <TextField
                    InputProps={{
                      startAdornment: <PoundSVG />
                    }}
                    error={Boolean(getIn(errors, `tickets[${index}].price_pound`))}
                    helperText={getIn(errors, `tickets[${index}].price_pound`)}
                    fullWidth
                    value={values.tickets[index]?.price_pound}
                    name={`cene+.tickets[${index}].price_pound`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Pound"
                    type="number"
                  />
                </Stack>
                <Stack width="100%" display="block">
                  <TextField
                    InputProps={{
                      startAdornment: <EuroSVG />
                    }}
                    error={Boolean(getIn(errors, `tickets[${index}].price_euro`))}
                    helperText={getIn(errors, `tickets[${index}].price_euro`)}
                    fullWidth
                    value={values.tickets[index]?.price_euro}
                    name={`cene+.tickets[${index}].price_euro`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Euro"
                    type="number"
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={1} mt={1}>
              <InputLabel required sx={{ color: '#8C8C8C' }}>
                Naira Payments
              </InputLabel>
              <Stack spacing={1} width="100%">
                <TextField
                  InputProps={{
                    startAdornment: <NairaSVG />
                  }}
                  error={Boolean(getIn(errors, `tickets[${index}].price_naira`))}
                  helperText={getIn(errors, `tickets[${index}].price_naira`)}
                  fullWidth
                  value={values.tickets[index]?.price_naira}
                  name={`cene+.tickets[${index}].price_naira`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Naira"
                  type="number"
                />
              </Stack>
            </Stack>
            <Divider sx={{ borderColor: '#222222' }} />
            <Stack>
              <Stack>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Switch
                    sx={{ marginLeft: 0 }}
                    checked={values.tickets[index]?.isDescription}
                    onChange={() => {
                      formik.setFieldValue(
                        `cene+.tickets[${index}].isDescription`,
                        !values.tickets[index]?.isDescription
                      );
                    }}
                  />
                  <Typography color="info">This Zone Requires a Description</Typography>
                </Stack>
                {values.tickets[index].isDescription && (
                  <TextField
                    sx={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                    value={values.tickets[index]?.description}
                    name={`cene+.tickets[${index}].description`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="e.g Experience the action up close! This zone offers the best view and an immersive experience putting you directly in front of the action, so you never miss a moment "
                    maxRows={100}
                    minRows={2}
                    multiline
                  />
                )}
              </Stack>
              <Stack mt={2}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Switch
                    sx={{ marginLeft: 0 }}
                    checked={values.tickets[index]?.isInstructions}
                    onChange={() => {
                      formik.setFieldValue(
                        `cene+.tickets[${index}].isInstructions`,
                        !values.tickets[index]?.isInstructions
                      );
                    }}
                  />
                  <Typography color="info">This Zone Requires Access Instructions</Typography>
                </Stack>
                {values.tickets[index].isInstructions && (
                  <TextField
                    sx={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                    value={values.tickets[index]?.instructions}
                    name={`cene+.tickets[${index}].instructions`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="e.g you have priority access via Gate A, with designated parking"
                    maxRows={100}
                    minRows={2}
                    multiline
                  />
                )}
              </Stack>
              <Stack mt={2}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Switch
                    sx={{ marginLeft: 0 }}
                    checked={values.tickets[index]?.isFoodOptions}
                    onChange={() => {
                      formik.setFieldValue(
                        `cene+.tickets[${index}].isFoodOptions`,
                        !values.tickets[index]?.isFoodOptions
                      );
                    }}
                  />
                  <Typography color="info">This Zone Requires Food and Drinks Options</Typography>
                </Stack>
                {values.tickets[index].isFoodOptions && (
                  <TextField
                    sx={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                    value={values.tickets[index]?.food_options}
                    name={`cene+..tickets[${index}].food_options`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="E.g 
Bottle of Whiskey/Cognac
Bottle of Tequila
2 Bottles of Wine
Canapes
"
                    maxRows={100}
                    minRows={2}
                    multiline
                  />
                )}
              </Stack>
              <Stack mt={2}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Switch
                    sx={{ marginLeft: 0 }}
                    checked={values.tickets[index]?.isAddons}
                    onChange={() => {
                      formik.setFieldValue(`cene+.tickets[${index}].isAddons`, !values.tickets[index]?.isAddons);
                    }}
                  />
                  <Typography color="info">There are add-ons available for this zone</Typography>
                </Stack>
                {values.tickets[index].isAddons && (
                  <TextField
                    sx={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                    value={values.tickets[index]?.addons}
                    name={`cene+.tickets[${index}].addons`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="e.g you have access to add-ons such as designated concierge services and food delivery options, complete with pricing details and how to arrange them."
                    maxRows={100}
                    minRows={2}
                    multiline
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
