import { Container } from '@mui/material';
import FloorplanForm from './forms/floorplan';

const FloorplanPage = () => {
  return (
    <Container sx={{ maxWidth: '772px' }}>
      <FloorplanForm />
    </Container>
  );
};

export default FloorplanPage;
