export const mockCoupons = [
  {
    name: 'Buy 2 Get 1 Free',
    code: 'FREEBIE',
    valid_from: '21/12/2024',
    valid_till: '24/12/2024',
    usage: '15',
    created_on: '21/12/2024',
    id: '1'
  },
  {
    name: '20% Off',
    code: '20Fest',
    valid_from: '21/11/2024',
    valid_till: '24/11/2024',
    usage: '13',
    created_on: '21/11/2024',
    id: '2'
  },
  {
    name: 'Buy 2 Get 1 Free',
    code: 'FREEBIE',
    valid_from: '21/10/2024',
    valid_till: '24/10/2024',
    usage: '12',
    created_on: '21/10/2024',
    id: '3'
  },
  {
    name: '20% Off',
    code: '20Fest',
    valid_from: '21/09/2024',
    valid_till: '24/09/2024',
    usage: '3',
    created_on: '21/09/2024',
    id: '4'
  }
];
