import { useSupabase } from 'contexts/supabase';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Member } from 'types/member';

const usePartnerId = () => {
  const [partnerId, setPartnerId] = useState<string | null>(null);
  const { session } = useSupabase();

  useEffect(() => {
    const fetchPartnerId = async () => {
      if (!session?.user.id) return;

      try {
        const { data } = await supabase.from('partner_users').select('*').eq('user_id', session.user.id).single();

        setPartnerId((data as Member)?.partner_id || null);
      } catch (error) {
        console.error('Error fetching partner ID:', error);
        setPartnerId(null);
      }
    };

    fetchPartnerId();
  }, [session?.user.id]);

  return partnerId;
};

export default usePartnerId;
