import { ROUTE_OVERVIEW } from 'constants/routes';
import { useParams, Navigate } from 'react-router-dom';

export const DefaultRedirectPartner = () => {
  const { id } = useParams();
  return <Navigate to={`/partner/${id}/partner-information`} />;
};

export const DefaultRedirectUser = () => {
  const { id } = useParams();
  return <Navigate to={`/profile/${id}/partner-information`} />;
};

export const DefaultRedirectCustomer = () => {
  const { id } = useParams();
  return <Navigate to={`/customer/${id}/user-information`} />;
};

export const DefaultRedirectEventDetails = () => {
  const { id } = useParams();
  return <Navigate to={`/event/${id}${ROUTE_OVERVIEW}`} />;
};

export const DefaultRedirectVenueView = () => {
  const { id } = useParams();
  return <Navigate to={`/venue/${id}/overview`} />;
};
