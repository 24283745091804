import { Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as BackSVG } from '../../svg/arrow-left.svg';
import { Children, ReactNode } from 'react';

interface StepsProps {
  children: ReactNode;
  activeStep: number;
  onBack: () => void;
  onSkip?: () => void;
  hideBackButton?: boolean;
  width?: number;
  size?: string;
}

const Steps = ({ children, onBack, activeStep = 1, hideBackButton = false, width = 100, size = '%' }: StepsProps) => {
  const childrenCount = Children.count(children);
  const itemWidth = width / childrenCount;

  const createArray = Array.from({ length: childrenCount }, (_, index) => index + 1);

  const createStepsElements = createArray.map((item) => {
    const isActive = activeStep === item;

    return <Grid sx={{ width: `${itemWidth}${size}`, height: '1px', backgroundColor: isActive ? '#8057DB' : '#222222' }} key={item}></Grid>;
  });

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid sx={{ width: '100%' }}>
        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {!hideBackButton && (
            <IconButton
              onClick={onBack}
              sx={{
                borderRadius: '12px 0',
                backgroundColor: '#191919 !important',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#333333'
                },
                '&:focus': {
                  backgroundColor: '#555555'
                }
              }}
            >
              <BackSVG style={{ stroke: '#8057DB' }} />
            </IconButton>
          )}
          {hideBackButton && <div />}
        </Grid>

        <Grid container sx={{ height: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid sx={{ width: '90%', height: '2px', display: 'flex', gap: '4px' }}>{createStepsElements}</Grid>{' '}
          <Typography sx={{ color: '#8057DB', fontSize: '12px', fontWeight: 500 }}>{`${activeStep}/${childrenCount}`}</Typography>
        </Grid>
      </Grid>
      {Children.map(children, (child, index) => index + 1 === activeStep && child)}
    </Grid>
  );
};

export default Steps;
