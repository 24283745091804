import { DeleteFilled } from '@ant-design/icons';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { TicketRequest } from '.';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { TicketRequestsStatus } from '@ceneteam/namespace';

interface ModalProps {
  currentObject: TicketRequest | null;
  modalToggler?: () => void;
  onSuccess?: () => void;
}

const RejectModal = ({ currentObject, modalToggler, onSuccess }: ModalProps) => {
  const handleReject = async () => {
    if (!currentObject) return;

    try {
      const { error } = await supabase.from('ticket_requests').update({ status: TicketRequestsStatus.Rejected }).eq('id', currentObject.id);

      if (error) {
        throw error;
      }

      onSuccess?.();
      modalToggler?.();
      enqueueSnackbar('Request rejected successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Try again later.', { variant: 'error' });
    }
  };

  return (
    <Stack sx={{ padding: 3, textAlign: 'center' }}>
      <div
        style={{
          margin: 'auto',
          padding: '20px',
          borderRadius: '50%',
          backgroundColor: '#ffffff0f',
          width: '76px',
          height: '76px',
          border: '1px solid #434343'
        }}
      >
        <DeleteFilled
          style={{
            fontSize: '36px',
            height: '36px',
            color: '#a61d24e6'
          }}
        />
      </div>

      <DialogTitle id="confirmation-dialog-title" sx={{ fontSize: '24px', lineHeight: '32px', fontWeight: 700, color: '#f0f0f0' }}>
        Heads up!
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '0px !important' }}>
        <DialogContentText
          id="confirmation-dialog-description"
          sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#BFBFBF' }}
        >
          If you reject {currentObject?.customer_name} request for {currentObject?.type} they will be notified and this order will be
          cancelled.
        </DialogContentText>
      </DialogContent>
      <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF', textAlign: 'center', marginBlock: 3 }}>
        This action is final and cannot be undone
      </DialogContentText>
      <DialogActions>
        <Button
          onClick={modalToggler}
          variant="outlined"
          sx={{
            color: '#fff',
            borderColor: '#434343',
            '&:hover': {
              backgroundColor: '#121212',
              color: '#fff',
              borderColor: '#434343'
            }
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleReject} color="error" variant="contained" autoFocus>
          Reject Request
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default RejectModal;
