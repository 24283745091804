import { DeleteOutlined, ToolOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import { supabase } from 'supabase/supabase';
import { Member } from 'types/member';
import { Partner } from 'types/partner';
import { PermissionsGroup } from 'types/role';

const formatDate = (dateString: string | null) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB');
};

const Team = () => {
  const { partner } = useOutletContext<{ partner: Partner | null }>();
  const [filter, setFilter] = useState('');
  const [permissions, setPermissions] = useState<PermissionsGroup[]>([]);
  const [teamUsers, setTeamUsers] = useState<Member[]>([]);
  const [adminUsers, setAdminUsers] = useState<Member[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [deleteTarget, setDeleteTarget] = useState<{ type: 'user' | 'team'; id: string | null }>({ type: 'user', id: null });
  useEffect(() => {
    if (partner?.id) {
      fetchTeamUsers();
    }
  }, [partner?.id]);

  const totalMembers = adminUsers.length + teamUsers.length;

  const fetchTeamUsers = async () => {
    try {
      setLoading(true);

      const { data: permissions } = await supabase.from('grouped_super_admin_permissions').select('*');

      setPermissions(permissions as PermissionsGroup[]);

      const { data } = await supabase.from('partner_users').select('*').eq('partner_id', partner?.id);

      const adminUsers = data?.filter((item) => item.role_name === 'Admin');
      const nonAdminUsers = data?.filter((item) => item.role_name !== 'Admin' && item.role_id !== '00000000-0000-0000-0000-000000000000');

      setAdminUsers(adminUsers || []);
      setTeamUsers(nonAdminUsers as Member[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const deleteTeamUser = async () => {
    const { type, id } = deleteTarget;
    let error;

    if (type === 'team' && id) {
      ({ error } = await supabase.from('partner_users').delete().eq('partner_id', id));
    } else if (type === 'user' && id) {
      ({ error } = await supabase.from('partner_users').delete().eq('id', id));
    }

    if (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      return;
    }

    enqueueSnackbar('User(s) deleted successfully', { variant: 'success' });
    fetchTeamUsers();
    handleCloseDialog();
  };

  const handleOpenDialog = (type: 'user' | 'team', id: string | null) => {
    setDeleteTarget({ type, id });
  };

  const handleCloseDialog = () => {
    setDeleteTarget({ type: 'user', id: null });
  };

  const getGroupsForPermissions = (userPermissions: string[]) => {
    const groups = new Set<string>();

    permissions.forEach((group) => {
      group.permissions.forEach((perm) => {
        if (userPermissions.includes(perm.permission)) {
          groups.add(group.group);
        }
      });
    });

    return Array.from(groups).join(', ');
  };

  const filteredRows = [...adminUsers, ...teamUsers].filter((member) => {
    const fullName = `${member.metadata.first_name} ${member.metadata.last_name}`.toLowerCase();
    return fullName.includes(filter.toLowerCase());
  });

  return (
    <>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <MainCard title="Team">
          <Grid container gap="8px" sx={{ gap: '8px' }}>
            <Grid item xs={12} sm={5.9} md={3.75}>
              <AnalyticEcommerce title="All members" count={totalMembers.toString()} icon={<UsergroupAddOutlined />} />
            </Grid>

            <Grid item xs={12} sm={5.9} md={3.75}>
              <AnalyticEcommerce title="Usage" count={`70%`} icon={<ToolOutlined />} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} marginTop="16px">
            <DebouncedInput fullWidth value={filter ?? ''} onFilterChange={(value) => setFilter(String(value))} placeholder="Search..." />
          </Grid>
          <TableContainer sx={{ marginTop: '16px' }}>
            <Table>
              <TableHead sx={{ backgroundColor: 'transparent' }}>
                <TableRow>
                  <TableCell>Team Member Name</TableCell>
                  <TableCell>Last Seen</TableCell>
                  <TableCell>Permissions</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{`${user.metadata.first_name} ${user.metadata.last_name}`}</TableCell>
                      <TableCell>{formatDate(user.updated_at)}</TableCell>
                      <TableCell sx={{ maxWidth: 200 }}>{getGroupsForPermissions(user.permissions)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOpenDialog('user', user.id)} color="error">
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography align="center" color="textSecondary">
                        No team members found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      )}

      <ConfirmationDialog
        open={!!deleteTarget.id}
        title={deleteTarget.type === 'team' ? 'Confirm Delete Team' : 'Confirm Delete User'}
        description={
          deleteTarget.type === 'team'
            ? 'Are you sure you want to delete this team and all its members?'
            : 'Are you sure you want to delete this user?'
        }
        onClose={handleCloseDialog}
        onConfirm={deleteTeamUser}
      />
    </>
  );
};

export default Team;
