// import { Divider } from '@mui/material';
// import SalesAndTransactionsStatistic from './statistic';
import SalesAndTransactionsTable from './table';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CircularLoader from 'components/CircularLoader';
import { Stack } from '@mui/material';
import { fetchCustomerName } from 'supabase/util';

export interface Ticket {
  quantity: number;
  ticket_name: string;
  ticket_name_id: string;
  ticket_is_accessible: boolean | null;
}

export interface Transaction {
  id: string;
  customer_data: { id: string; first_name: string; last_name: string };
  customer_name?: string;
  orderDate: string;
  tickets: Ticket[];
  amount: number;
  currency: string;
  status: string;
  payment: string;
}

const SalesAndTransactionsPage = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) fetchTransactionsById();
  }, [id]);

  const fetchTransactionsById = async () => {
    try {
      setLoading(true);
      const { error, data } = await supabase.from('transactions').select('*').eq('details_data->>event_id', id);
      if (error) throw error;

      //fetch the customerNames before the table is loaded
      const processedData = await Promise.all(
        data?.map(async (transaction) => {
          const customerName = await fetchCustomerName(transaction.customer_id);

          return {
            id: transaction.id,
            customer_data: transaction.customer_data || {},
            customer_name: customerName || '',
            orderDate: transaction.created_at,
            tickets: transaction.details_data?.tickets || [],
            amount: transaction.amount,
            currency: transaction.currency,
            status: transaction.status,
            payment: transaction.provider
          };
        }) || []
      );

      setTransactions(processedData as Transaction[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return isLoading ? (
    <CircularLoader />
  ) : (
    <Stack sx={{ py: 3 }}>
      {/* <SalesAndTransactionsStatistic />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} /> */}
      <SalesAndTransactionsTable transactions={transactions} />
    </Stack>
  );
};

export default SalesAndTransactionsPage;
