import { Button, Typography, Card, Stack } from '@mui/material';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useCallback, DragEvent, useRef, useState, SyntheticEvent } from 'react';

interface IDragAndDropProps {
  onUpload: (files: File[]) => void;
  error?: string | null;
}

export const DragAndDrop = ({ onUpload, error }: IDragAndDropProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragEnter, setDragEnter] = useState(false);

  const openUploadWindow = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;

    if (files) {
      const filesArray = Array.from(files);

      onUpload(filesArray);
    }
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragEnter(false);

    const files = Array.from(e.dataTransfer.files);

    onUpload(files);
  };

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragEnter(true);
  };

  const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragEnter(false);
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    setDragEnter(true);
    e.preventDefault();
  };

  return (
    <div onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} onDragEnter={onDragEnter}>
      <Card
        sx={{
          zIndex: isDragEnter ? -1 : 0,
          position: 'relative',
          padding: '20px',
          border: error ? '1px solid #c84d4d' : isDragEnter ? '1px solid #1568db' : '1px solid #595959'
        }}
        variant="outlined"
      >
        <Stack display="flex" flexDirection="column" gap={2} alignItems="center">
          <CloudUploadOutlined style={{ fontSize: '50px' }} />
          <Typography sx={{ opacity: 0.6 }} fontSize="13px">
            Click upload or drag the image here
          </Typography>
          {error && (
            <Typography color="red" sx={{ opacity: 0.6 }} fontSize="11px">
              {error}
            </Typography>
          )}
          <Button variant="light" color="info" onClick={openUploadWindow}>
            Upload
          </Button>
        </Stack>
      </Card>
      <input onChange={onChange} accept="image/*" multiple type="file" style={{ display: 'none' }} ref={inputRef} />
    </div>
  );
};
