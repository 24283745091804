import { ContainerOutlined } from '@ant-design/icons';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useFormik } from 'formik';

const GuestlistForm = () => {
  const formik = useFormik({
    initialValues: {
      existUser: '',
      ticketStock: '',
      quantity: 1,
      teamMember: ''
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const { modalToggler } = useModalContext();
  const { isSubmitting, handleChange } = formik;

  return (
    <Grid sx={{ paddingBlock: 3, paddingInline: 5 }}>
      <div
        style={{
          margin: 'auto',
          padding: '20px',
          borderRadius: '50%',
          backgroundColor: '#8057DB',
          width: '76px',
          height: '76px',
          border: '1px solid #434343'
        }}
      >
        <ContainerOutlined
          style={{
            fontSize: '36px',
            height: '36px'
          }}
        />
      </div>
      <DialogTitle sx={{ fontSize: '24px', color: '#F0F0F0', textAlign: 'center' }}>Add Someone to GuestList</DialogTitle>
      <DialogContentText sx={{ color: '#BFBFBF', textAlign: 'center' }}>Search for a Cene or Cene+ user</DialogContentText>
      <Divider />
      <DialogContent sx={{ paddingInline: '1px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Start typing to find an existing user"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Start typing to find an existing user">Start typing to find an existing user</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Pick a ticket stock to remove tickets from"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Pick a ticket stock to remove tickets from">Pick a ticket stock to remove tickets from</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <TextField
                id="quantity"
                type="number"
                placeholder="Enter Quantity from stock Selected"
                onChange={handleChange}
                inputProps={{ min: 1 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Validated by Choose Team Member"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Validated by Choose Team Member">Validated by Choose Team Member</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF', textAlign: 'center' }}>
        This action is final and cannot be undone
      </DialogContentText>
      <DialogActions sx={{ paddingBlock: 2.5 }}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={() => modalToggler(null)}
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#434343',
                '&:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                  borderColor: '#434343'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => modalToggler(null)}
              sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Assign Tickets to User
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default GuestlistForm;
