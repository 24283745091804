import { TeamOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const partners: NavItemType = {
  id: 'group-partners-loading',
  type: 'group',
  children: [
    {
      id: 'group-partners',
      title: 'Cene Partners',
      type: 'collapse',
      icon: TeamOutlined,
      children: [
        {
          permissions: ['*', 'partners:r', 'partners:u', 'partners:d'],
          id: 'partners',
          title: 'Approved Partners',
          type: 'item',
          url: '/partners'
        },
        {
          permissions: ['*', 'partners_requests:r', 'partners_requests:u', 'partners_requests:d'],
          id: 'partners-requests',
          title: 'Pending Applications',
          type: 'item',
          url: '/partners-requests'
        }
      ]
    }
  ]
};

export default partners;
