import MainCard from 'components/MainCard';
import { useState } from 'react';

import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { HeaderCells } from 'components/commonTable/headerCells';

import CircularLoader from 'components/CircularLoader';
import { Partner, SubscribePlan } from 'types/partner';
import { format, parse, parseISO } from 'date-fns';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import RequestDetails from './requestDetails';

enum IStatus {
  NEW = 'new',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum PartnerRequestColumnId {
  DATE_APPLIED = 'dateApplied',
  EVENT_COMPANY = 'eventCompany',
  CONTACT_NAME = 'contactName',
  SUBSCRIPTION_PLAN = 'subscriptionPlan',
  STATUS = 'status',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: PartnerRequestColumnId;
  title: string;
  sortable: boolean;
  cell?: (partner: Partner) => React.ReactNode;
}

const partnerRequestColumns: TableColumn[] = [
  { id: PartnerRequestColumnId.DATE_APPLIED, title: 'Date applied', sortable: true },
  { id: PartnerRequestColumnId.EVENT_COMPANY, title: 'Event company', sortable: true },
  { id: PartnerRequestColumnId.CONTACT_NAME, title: 'Contact name', sortable: true },
  { id: PartnerRequestColumnId.SUBSCRIPTION_PLAN, title: 'Subscription plan', sortable: true },
  { id: PartnerRequestColumnId.STATUS, title: 'Status', sortable: true },

  { id: PartnerRequestColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const columnConfig: Record<PartnerRequestColumnId, keyof Partner | ((partner: Partner) => string | number)> = {
  [PartnerRequestColumnId.DATE_APPLIED]: (partner) => format(parseISO(partner.created_at as string), 'dd.MM.yyyy'),
  [PartnerRequestColumnId.EVENT_COMPANY]: (partner) => partner.company_name,
  [PartnerRequestColumnId.CONTACT_NAME]: (partner) => `${partner.first_name} ${partner.last_name}`,
  [PartnerRequestColumnId.SUBSCRIPTION_PLAN]: (partner) => partner.subscription_plan_id,
  [PartnerRequestColumnId.STATUS]: (partner) => partner.status as string,

  [PartnerRequestColumnId.ACTIONS]: () => ''
};

const getColumnValue = (columnId: PartnerRequestColumnId, partner: Partner): string | number => {
  const config = columnConfig[columnId];
  return typeof config === 'function' ? config(partner) : (partner[config] as string | number);
};

interface PartnerTableRowsProps {
  partners: Partner[];
  onSuccess?: () => void;
}

const PartnerRequestTableRows = ({ partners = [], onSuccess = () => null }: PartnerTableRowsProps) => {
  const [expandedContactId, setExpandedContactId] = useState<string | null>(null);

  const handleOpenView = (id: string) => {
    setExpandedContactId(expandedContactId === id ? null : id);
  };

  const renderCellContent = (column: TableColumn, partner: Partner) => {
    if (column.id === PartnerRequestColumnId.ACTIONS) {
      return (
        <IconButton onClick={() => handleOpenView(partner.id)}>
          {expandedContactId === partner.id ? <EyeInvisibleOutlined style={{ color: '#91DCBE' }} /> : <EyeOutlined />}
        </IconButton>
      );
    }
    return getColumnValue(column.id, partner);
  };

  return (
    <>
      {partners.map((partner) => (
        <>
          <TableRow key={partner.id}>
            {partnerRequestColumns.map((column) => (
              <TableCell key={column.id}>{renderCellContent(column, partner)}</TableCell>
            ))}
          </TableRow>
          {expandedContactId === partner.id && <RequestDetails partner={partner} onSuccess={onSuccess} />}
        </>
      ))}
    </>
  );
};

interface PartnersTableProps {
  isLoading?: boolean;
  partners?: Partner[];
  onSuccess?: () => void;
}

const PartnersRequestsTable = ({ onSuccess = () => null, isLoading = false, partners = [] }: PartnersTableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: PartnerRequestColumnId; direction: 'asc' | 'desc' }>({
    key: PartnerRequestColumnId.CONTACT_NAME,
    direction: 'asc'
  });

  const sortedPartners = [...partners].sort((a, b) => {
    const { key, direction } = sortConfig;
    const valueA = getColumnValue(key, a);
    const valueB = getColumnValue(key, b);

    const compareValues = (valA: string | number, valB: string | number) => {
      if (typeof valA === 'number' && typeof valB === 'number') {
        return direction === 'asc' ? valA - valB : valB - valA;
      }
      return direction === 'asc' ? String(valA).localeCompare(String(valB)) : String(valB).localeCompare(String(valA));
    };

    if (key === PartnerRequestColumnId.STATUS) {
      const statusOrder = [IStatus.NEW, IStatus.APPROVED, IStatus.REJECTED];
      const indexA = statusOrder.indexOf(valueA as IStatus);
      const indexB = statusOrder.indexOf(valueB as IStatus);
      return direction === 'asc' ? indexA - indexB : indexB - indexA;
    }

    if (key === PartnerRequestColumnId.SUBSCRIPTION_PLAN) {
      const plansOrder = [SubscribePlan.STARTER, SubscribePlan.PRO, SubscribePlan.PREMIUM];
      const indexA = plansOrder.indexOf(valueA as SubscribePlan);
      const indexB = plansOrder.indexOf(valueB as SubscribePlan);
      return direction === 'asc' ? indexA - indexB : indexB - indexA;
    }

    if (key === PartnerRequestColumnId.CONTACT_NAME) {
      const fullNameA = `${a.first_name} ${a.last_name}`.trim();
      const fullNameB = `${b.first_name} ${b.last_name}`.trim();
      return compareValues(fullNameA, fullNameB);
    }

    if (key === PartnerRequestColumnId.DATE_APPLIED) {
      const dateA = parse(a.created_at as string, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.created_at as string, 'yyyy-MM-dd', new Date());
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    return compareValues(valueA, valueB);
  });

  const requestSort = (key: PartnerRequestColumnId) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<PartnerRequestColumnId> columns={partnerRequestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : partners.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Stack textAlign="center">No Partners requests yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <PartnerRequestTableRows onSuccess={onSuccess} partners={sortedPartners} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default PartnersRequestsTable;
