// material-ui
import { Box, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import { ReactNode } from 'react';

// assets

// ==============================|| STATISTICS - ECOMMERCE CARD ||============================== //

interface Props {
  title: string;
  count: string;
  icon: ReactNode;
}

const AnalyticsDataCard = ({ title, count, icon }: Props) => (
  <MainCard content={false}>
    <Box sx={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="h4" color="inherit">
            {count}
          </Typography>
          {icon}
        </Stack>
      </Stack>
    </Box>
  </MainCard>
);

export default AnalyticsDataCard;
