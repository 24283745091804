import CompanyInfoForm from './companyInfo.form';
import SocialMediaForm from './socialMedia.form';
// import SubscriptionPlanForm from './subscriptionPlan.form';
import { useCallback, useEffect, useState } from 'react';
import MembersForm from './members.form';
import FormStepLayout from '../formStep.layout';
import AvatarSection from '../sections/avatar.section';
// import SubscriptionSection from '../sections/subscriptions.section';
import EventOrganizerSection from '../sections/eventOrganizer.section';
import { supabase } from 'supabase/supabase';
import usePartnerId from 'hooks/usePartnerId';
import { PartnerProfile } from 'types/partnerProfile';
import TeamSection from '../sections/team.section';

const OnboardingForm = () => {
  const [activeStep, setStep] = useState(1);
  const [partner, setPartner] = useState<PartnerProfile>();
  const [socialMediaLinks, setSocialMediaLinks] = useState({ website: '', twitter: '', instagram: '', facebook: '', tiktok: '' });
  // const [checked, setChecked] = useState(true);
  const partnerId = usePartnerId();

  useEffect(() => {
    if (partnerId) fetchPartner();
  }, [partnerId]);

  const fetchPartner = async () => {
    try {
      const { data, error } = await supabase.from('partners').select('*').eq('id', partnerId).single();

      setPartner(data);

      if (error) {
        throw error;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextStep = useCallback(() => {
    setStep(activeStep + 1);
  }, [activeStep]);

  const onBackStep = useCallback(() => {
    setStep(activeStep - 1);
  }, [activeStep]);

  const getSidebarContent = () => {
    switch (activeStep) {
      case 1:
        return <AvatarSection partner={partner} />;
      // case 2:
      //   return <SubscriptionSection onNext={nextStep} checked={checked} />;
      case 2:
        return <EventOrganizerSection partner={partner} socialMediaLinks={socialMediaLinks} />;
      case 3:
        return <TeamSection />;
      default:
        return;
    }
  };

  return (
    <FormStepLayout activeStep={activeStep} onBackStep={onBackStep} sidebarContent={getSidebarContent()}>
      {activeStep === 1 && <CompanyInfoForm onNext={nextStep} onSuccess={fetchPartner} />}
      {/* {activeStep === 2 && <SubscriptionPlanForm onNext={nextStep} switchChecked={setChecked} />} */}
      {activeStep === 2 && <SocialMediaForm onNext={nextStep} handleLinks={setSocialMediaLinks} onSuccess={fetchPartner} />}
      {activeStep === 3 && <MembersForm />}
    </FormStepLayout>
  );
};

export default OnboardingForm;
