import { Stack, Typography } from '@mui/material';

interface ReusableTabsProps<T extends { [key: string]: string }> {
  tabs: T;
  activeTab: T[keyof T];
  onTabClick: (tab: T[keyof T]) => void;
  color?: string;
}

const ReusableTabs = <T extends { [key: string]: string }>({
  tabs,
  activeTab,
  onTabClick,
  color = '#91DCBE',
}: ReusableTabsProps<T>) => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
      <>
        {Object.values(tabs).map((tab) => (
          <Typography
            key={tab}
            onClick={() => onTabClick(tab as T[keyof T])}
            sx={{
              padding: 0.5,
              cursor: 'pointer',
              color: activeTab === tab ? color : '#fff',
              fontWeight: activeTab === tab ? 500 : 400,
              borderBottom: '1px solid',
              borderColor: activeTab === tab ? color : 'transparent'
            }}
          >
            {tab}
          </Typography>
        ))}
      </>
    </Stack>
  );
};

export default ReusableTabs;
