import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Row } from './partners';
import { format } from 'date-fns';
import MainCard from 'components/MainCard';
import { EyeOutlined, DeleteOutlined, DeleteFilled } from '@ant-design/icons';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  rows: Row[];
  onSuccess: () => void;
  venueId?: string;
}

const AssignPartnerTable = ({ rows, onSuccess, venueId }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleNavigateToFloorplan = (id: string) => {
    return navigate(`/venue/${venueId}/floorplan/${id}/view`, { replace: true });
  };

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase.from('partner_floorplans').delete().eq('id', id);

      if (error) throw error;

      enqueueSnackbar('Floorplan unassigned successfully', { variant: 'success' });
      toggleDialog();
      onSuccess();
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    }
  };

  return (
    <MainCard>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="partner floorplan table">
          <TableHead>
            <TableRow>
              <TableCell>Partner</TableCell>
              <TableCell>Floorplan Name</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Used For</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.partner || 'Unknown'}</TableCell>
                <TableCell>{row.floorplanName || 'Unknown'}</TableCell>
                <TableCell>{format(new Date(row.createdAt), 'dd.MM.yyyy')}</TableCell>
                <TableCell>{row.createdBy || 'Unknown'}</TableCell>
                <TableCell>{row.usedFor || 'Unknown'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleNavigateToFloorplan(row.floorplanId)}>
                    <EyeOutlined />
                  </IconButton>
                  <IconButton sx={{ ml: 2 }} onClick={toggleDialog}>
                    <DeleteOutlined />
                  </IconButton>
                </TableCell>
                <Dialog
                  open={isDialogOpen}
                  onClose={toggleDialog}
                  aria-labelledby="confirmation-dialog-title"
                  aria-describedby="confirmation-dialog-description"
                  PaperProps={{
                    sx: {
                      textAlign: 'center',
                      border: '1px solid #434343',
                      padding: '40px',
                      backgroundImage: 'none',
                      backgroundColor: 'black',
                      color: 'white',
                      width: '488px'
                    }
                  }}
                >
                  <div
                    style={{
                      margin: 'auto',
                      padding: '20px',
                      borderRadius: '50%',
                      backgroundColor: '#ffffff0f',
                      width: '76px',
                      height: '76px',
                      border: '1px solid #434343'
                    }}
                  >
                    <DeleteFilled
                      style={{
                        fontSize: '36px',
                        height: '36px',
                        color: '#a61d24e6'
                      }}
                    />
                  </div>

                  <DialogTitle
                    id="confirmation-dialog-title"
                    sx={{ fontSize: '24px', lineHeight: '32px', fontWeight: 700, color: '#f0f0f0' }}
                  >
                    Heads up!
                  </DialogTitle>
                  <DialogContent sx={{ padding: 0, mb: '16px' }}>
                    <DialogContentText
                      id="confirmation-dialog-description"
                      sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#BFBFBF' }}
                    >
                      If you delete this floorplan {row.partner} will no longer be able to use this for events, even active ones.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={toggleDialog}
                      variant="outlined"
                      sx={{
                        color: '#fff',
                        borderColor: '#434343',
                        '&:hover': {
                          backgroundColor: '#121212',
                          color: '#fff',
                          borderColor: '#434343'
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button onClick={() => handleDelete(row.id)} color="error" variant="contained" autoFocus>
                      Floorplan unassigned
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default AssignPartnerTable;
