import { ComponentType } from 'react';

import { Modal } from '@mui/material';

import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';

interface Props<T> {
  open: boolean;
  modalToggler: () => void;
  content: ComponentType<{ currentObject: T | null; onSuccess: () => void; modalToggler?: () => void }>;
  currentObject: T | null;
  onSuccess?: () => void;
  width?: string;
}

const ReusableModal = <T extends {}>({
  open,
  modalToggler = () => null,
  onSuccess = () => null,
  content: ContentComponent,
  currentObject,
  width = '472px'
}: Props<T>) => {
  return (
    <Modal open={open} onClose={() => modalToggler()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <MainCard
        sx={{ width: width, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)', minHeight: '200px' }}
        modal
        content={false}
      >
        <SimpleBar
          sx={{
            maxHeight: `calc(100vh - 48px)`,
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          <ContentComponent onSuccess={onSuccess} currentObject={currentObject} modalToggler={modalToggler} />
        </SimpleBar>
      </MainCard>
    </Modal>
  );
};

export default ReusableModal;
