import { Stack, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { useFormikContext } from 'formik';
import { EventSchema } from '../event.schema';

interface IEventTypeProps {
  onNext: () => void;
}

export const EventType = ({ onNext }: IEventTypeProps) => {
  const formik = useFormikContext<EventSchema>();

  const eventTypes = [
    {
      type: 'cene',
      pictureUrl: '/pictures/eventTypes/cene.png',
      title: 'Cene',
      description: 'Our accessible app that gives you an increased audience reach.'
    },
    {
      type: 'cene+',
      pictureUrl: '/pictures/eventTypes/cene+.png',
      title: 'Cene +',
      description: 'Events tailored for our VIP Members-only platform. '
    },
    {
      type: 'both',
      pictureUrl: '/pictures/eventTypes/both.png',
      title: 'Cene and Cene+',
      description: 'Perfect if you want to target both the Cene and Cene+ audience.'
    }
  ];

  const renderEventTypes = eventTypes.map((item) => (
    <Stack
      onClick={() => {
        formik.setFieldValue('eventType', item.type);

        if (item.type === 'both') {
          formik.setFieldValue('formType', 'cene');
        }
      }}
      maxWidth="265px"
      sx={{
        cursor: 'pointer',
        background: formik.values.eventType === item.type ? '#8057DB' : 'transparent',
        transition: '300ms',
        '&:hover': {
          background: formik.values.eventType === item.type ? '#8057DB' : '#121212'
        }
      }}
      p={2}
      border="1px solid #262626"
      borderRadius={2}
      key={item.type}
      gap={2}
      alignItems="center"
    >
      <Typography textAlign="center">{item.title}</Typography>
      <img width="219" height="165" src={item.pictureUrl} alt={item.type} />
      <Typography textAlign="center" color={formik.values.eventType === item.type ? 'white' : '#8C8C8C'}>
        {item.description}
      </Typography>
    </Stack>
  ));

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography fontWeight="300" lineHeight="64px" fontSize="34px" color="#8057DB" textAlign="center">
        Choose Your Cene
      </Typography>
      <Typography maxWidth="689px" fontWeight="300" fontSize="16px" textAlign="center">
        Whether you’re aiming for the vibrant community of Cene, the distinctive feel of Cene+, or a mix of both, knowing your target
        audience allows your events go from idea to impact.
      </Typography>
      <Stack mt={4} flexDirection="row" gap={2}>
        {renderEventTypes}
      </Stack>
      <Stack mt={4}>
        <ShapeButton width="300px" handleClick={onNext}>
          Continue
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
