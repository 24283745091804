import { SettingOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const team: NavItemType = {
  id: 'group-team-loading',
  type: 'group',
  children: [
    {
      permissions: ['*', 'partner_users:c', 'partner_users:d', 'partner_users:r', 'partner_users:u'],
      id: 'members',
      title: 'Team',
      type: 'item',
      url: '/members',
      icon: SettingOutlined
    }
  ]
};

export default team;
