import { Button, Grid, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import MainCard from 'components/MainCard';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

enum DialogType {
  STOP_SALES = 'stopSales',
  BLOCK_EVENT = 'blockEvent',
  DELETE_EVENT = 'deleteEvent'
}

const buttonStyles = {
  backgroundColor: '#141414',
  '&:hover': { backgroundColor: '#212121' }
};

const ManageEventVisibilityPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType | null>(null);

  const dialogConfig = {
    [DialogType.STOP_SALES]: {
      title: 'Stop Sales',
      description: 'Are you sure you want to stop sales for this event? This action will prevent any further ticket purchases.'
    },
    [DialogType.BLOCK_EVENT]: {
      title: 'Block Event',
      description:
        'Are you sure you want to hide this event from users? This action will make the event invisible to users on the platform.'
    },
    [DialogType.DELETE_EVENT]: {
      title: 'Delete Event',
      description:
        'Are you sure you want to delete this event? This action is irreversible, and all associated data will be removed permanently.'
    }
  };

  const handleOpenDialog = (type: DialogType) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    try {
      switch (dialogType) {
        case DialogType.STOP_SALES:
          enqueueSnackbar('Sales have been successfully stopped for this event.', { variant: 'success' });
          break;

        case DialogType.BLOCK_EVENT:
          enqueueSnackbar('The event is now hidden from users.', { variant: 'success' });
          break;

        case DialogType.DELETE_EVENT:
          enqueueSnackbar('The event has been successfully deleted.', { variant: 'success' });
          break;

        default:
          break;
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setDialogOpen(false);
    }
  };

  const renderButton = (text: string, onClick: () => void, color: string) => (
    <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ fontSize: '14px', color: '#B36CEA' }}>{text}</Typography>
      <Button variant="contained" onClick={onClick} sx={{ ...buttonStyles, color }}>
        {text}
      </Button>
    </Stack>
  );
  return (
    <Stack sx={{ py: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Event actions</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between',
              gap: 2.5
            }}
          >
            {renderButton('Stop Sales on this Event', () => handleOpenDialog(DialogType.STOP_SALES), '#CFFFF6')}
            {renderButton('Hide Event from Cene Users', () => handleOpenDialog(DialogType.BLOCK_EVENT), '#d89614e6')}
            {renderButton('Delete Event', () => handleOpenDialog(DialogType.DELETE_EVENT), '#FF4D4F')}

            <ConfirmationDialog
              open={dialogOpen}
              title={dialogConfig[dialogType!]?.title}
              description={dialogConfig[dialogType!]?.description}
              onClose={handleCloseDialog}
              onConfirm={handleConfirm}
            />
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ManageEventVisibilityPage;
