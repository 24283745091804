import { useSupabase } from 'contexts/supabase';

export const usePermission = () => {
  const { permissions } = useSupabase();

  const isSuperAdmin = permissions?.includes('*');

  return {
    permissions,
    isSuperAdmin
  };
};
