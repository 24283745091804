import { Grid, Stack, Typography } from '@mui/material';
import ForgotForm from 'forms/forgot';
import AuthWrapper from 'sections/auth/AuthWrapper';

const Forgot = () => {
  return (
    <AuthWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '28px',
                color: '#fff'
              }}
            >
              Forgot Password
            </Typography>
          </Stack>
          <Typography sx={{ mt: '8px', fontSize: '14px', lineHeight: '22px', fontWeight: 500, color: '#595959' }}>
            Input your email to get the request for a reset password
          </Typography>
        </Grid>
        <Grid item xs={12} mt="30px">
          <ForgotForm />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Forgot;
