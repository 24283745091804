import axios from 'axios';
import { supabase } from 'supabase/supabase';

export const seatsioInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
});

seatsioInstance.interceptors.request.use(async (request) => {
  const session = await supabase.auth.getSession();

  if (session) {
    request.headers.Authorization = `Bearer ${session.data.session?.access_token}`;
  }
  return request;
});
