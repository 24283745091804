import { Grid, Typography } from '@mui/material';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import ShapeButton from 'components/button/button';
import { useNavigate } from 'react-router';
import { useSupabase } from 'contexts/supabase';
import { ROUTE_ONBOARDING } from 'constants/routes';

const WelcomePage = () => {
  const navigate = useNavigate();

  const { session } = useSupabase();

  const handleClick = () => {
    navigate(ROUTE_ONBOARDING, { replace: true });
  };
  return (
    <Grid container sx={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container sx={{ alignItems: 'center', textAlign: 'center', flexDirection: 'column', gap: '28px' }}>
        <LogoSVG style={{ fill: '#8057DB', width: '216px', height: '64px' }} />
        <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#fff', whiteSpace: 'wrap', maxWidth: '600px' }}>
          Welcome aboard, {session?.user.user_metadata.first_name} {session?.user.user_metadata.last_name}!
        </Typography>
        <Typography sx={{ width: '504px', fontSize: '18px', fontWeight: 300, color: '#fff' }}>
          You’re on your way to event innovation, attendee personalisation, and brand elevation. Let’s get started on building your profile!
        </Typography>

        <ShapeButton width="416px" handleClick={handleClick}>
          Get Started
        </ShapeButton>
      </Grid>
    </Grid>
  );
};

export default WelcomePage;
