import { UserOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

import { useGetMenu } from 'api/menu';
import { FormattedMessage } from 'react-intl';

const customers: NavItemType = {
  id: 'group-customers-loading',
  type: 'group',
  children: [
    {
      id: 'group-customers',
      title: 'User Management',
      type: 'collapse',
      icon: UserOutlined,
      children: [
        {
          permissions: ['*', 'customers:c', 'customers:r', 'customers:u', 'customers:d'],
          id: 'customers',
          title: 'Verified Users',
          type: 'item',
          url: '/customers'
        },
        {
          permissions: ['*', 'ceneplus_requests:c', 'ceneplus_requests:r', 'ceneplus_requests:u', 'ceneplus_requests:d'],
          id: 'cene-plus-requests',
          title: 'Cene+ Requests',
          type: 'item',
          url: '/cene-plus-requests'
        }
        // {
        //   id: 'customer-invites',
        //   title: 'Customer Invites',
        //   type: 'item',
        //   url: '/customer-invites'
        // }
      ]
    }
  ]
};

export const MenuFromAPI = () => {
  const { menu, menuLoading } = useGetMenu();

  if (menuLoading) return customers;

  const subChildrenList = (children: NavItemType[]) => {
    return children?.map((subList: NavItemType) => {
      return fillItem(subList);
    });
  };

  const itemList = (subList: NavItemType) => {
    let list = fillItem(subList);

    if (subList.type === 'collapse') {
      list.children = subChildrenList(subList.children!);
    }
    return list;
  };

  const childrenList: NavItemType[] | undefined = menu?.children?.map((subList: NavItemType) => {
    return itemList(subList);
  });

  let menuList = fillItem(menu, childrenList);
  return menuList;
};

function fillItem(item: NavItemType, children?: NavItemType[] | undefined) {
  return {
    ...item,
    title: <FormattedMessage id={`${item?.title}`} />,
    // @ts-ignore
    icon: icons[item?.icon],
    ...(children && { children })
  };
}

export default customers;
