import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { EventSchema } from './event.schema';
import { ReactComponent as UploadSVG } from '../../../svg/upload-cloud.svg';
import { ReactComponent as MapSVG } from '../../../svg/map.svg';
import { ReactComponent as ScheduleSVG } from '../../../svg/schedule.svg';

import { ReactComponent as PaystackSVG } from '../../../svg/paystack.svg';
import { ReactComponent as FlutterwaveSVG } from '../../../svg/flutterwave.svg';
import { ReactComponent as StripeSVG } from '../../../svg/stripe.svg';

import { Divider } from '@mui/material';
import { ICropedPicture, useCrop } from 'hooks/useCrop';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { LineupFull } from 'components/lineupList/lineupFull';
import { LineupMini } from 'components/lineupList/lineupMini';
import TextButton from 'components/textButton/textButton';
import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { PictureCard } from 'components/pictureCard';
import ImageCropper from 'components/imageCropper/imageCropper';
import { Crop } from 'react-image-crop';

export const EventPreview = () => {
  const formik = useFormikContext<EventSchema>();
  const [showAll, setShowAll] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [isEditPicture, setEditPicture] = useState<ICropedPicture | null>(null);

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];

  const event_pictures = formik.values[formType].event_pictures as ICropedPicture[];

  console.log(values, 'values');
  
  
  const { choosePictures, initialCrop } = useCrop({
    aspect: 3 / 4,
    onChange: (values) => {
      formik.setFieldValue(`${formType}.event_pictures`, [...event_pictures, ...values]);
    }
  });

  const removePicture = (id: string) => {
    if (window.confirm('Are you sure you want to delete picture ?')) {
      formik.setFieldValue(
        `${formType}.event_pictures`,
        event_pictures.filter((item) => item.id !== id)
      );
    }
  };

  const gradient = formType === 'cene' ? 'rgba(128,87,219,1)' : 'rgba(184,202,217,1)';

  const handleToggleLineup = () => setShowAll(!showAll);

  const onNextMedia = () => {
    if (mediaIndex + 1 >= event_pictures.length) return setMediaIndex(0);
    setMediaIndex(mediaIndex + 1);
  };

  const onPrevMedia = () => {
    if (mediaIndex === 0) return setMediaIndex(event_pictures.length - 1);
    setMediaIndex(mediaIndex - 1);
  };

  const renderPictures = event_pictures.map((item) => (
    <PictureCard
      key={item.id}
      url={item.cropedPicture}
      alt={item.id}
      onEdit={() => setEditPicture(item)}
      onRemove={() => removePicture(item.id)}
    />
  ));

  const handleCropPicture = async (id: string, picture: string, crop: Crop) => {
    const response = await initialCrop(picture, crop);

    formik.setFieldValue(
      `${formType}.event_pictures`,
      event_pictures.map((item) => {
        if (item.id === id) {
          item.cropedPicture = response.cropedImage;
          item.crop = response.crop;
        }

        return item;
      })
    );

    setEditPicture(null);
  };

  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <Stack>
        {event_pictures.length === 0 ? (
          <Stack
            onClick={() =>
              choosePictures(5, (message) => {
                enqueueSnackbar(message, {
                  variant: 'error',
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  }
                });
              })
            }
            sx={{
              opacity: 0.9,
              justifyContent: 'center',
              alignItems: 'center',
              borderTopLeftRadius: '32px',
              width: '300px',
              height: '413px',
              cursor: 'pointer',
              transition: '300ms',
              background: `linear-gradient(0deg, rgba(0,0,0,1) 0%, ${gradient} 85%)`,
              '&:hover': {
                opacity: 1
              }
            }}
          >
            <UploadSVG />
            <Typography mt={2} textAlign="center" paddingInline={2}>
              Click here to upload an image or video related to *Electrik Nights*
            </Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              position: 'relative',

              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                background: 'linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(0,0,0,0) 100%)',
                width: '100%',
                height: '150px'
              }
            }}
          >
            <img
              style={{
                objectFit: 'cover',
                borderTopLeftRadius: '32px'
              }}
              width="300"
              height="413"
              src={event_pictures[mediaIndex].cropedPicture}
              alt={event_pictures[mediaIndex].id}
            />
          </Stack>
        )}
        {event_pictures.length !== 0 && (
          <Stack
            justifyContent="space-between"
            alignItems="center"
            paddingInline={1}
            width="100%"
            mt="-20px"
            zIndex={2}
            display="flex"
            flexDirection="row"
          >
            <Stack display="flex" flexDirection="row" gap="8px">
              <IconButton disabled={values.event_pictures?.length === 0} onClick={onPrevMedia}>
                <LeftOutlined />
              </IconButton>
              <IconButton disabled={values.event_pictures?.length === 0} onClick={onNextMedia}>
                <RightOutlined />
              </IconButton>
            </Stack>
            <Stack>
              <Typography variant="caption" fontSize="16px" color="white">
                {mediaIndex + 1}{' '}
                <Typography variant="caption" style={{ color: '#727272' }} fontSize="10px">
                  / {values.event_pictures?.length}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{
            scrollbarColor: '#222 black'
          }}
          mt={4}
          flexDirection="row"
          gap={2}
          width="300px"
          overflow="overlay"
        >
          {event_pictures.length > 0 && (
            <Stack
              onClick={() =>
                choosePictures(5, (message) => {
                  enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'top'
                    }
                  });
                })
              }
              sx={{
                opacity: 0.9,
                justifyContent: 'center',
                alignItems: 'center',
                borderTopLeftRadius: '32px',
                minWidth: '76px',
                height: '105px',
                cursor: 'pointer',
                transition: '300ms',
                background: `linear-gradient(0deg, rgba(0,0,0,1) 0%, ${gradient} 85%)`,
                '&:hover': {
                  opacity: 1
                }
              }}
            >
              <UploadSVG width="35px" height="35px" />
            </Stack>
          )}

          {renderPictures}
        </Stack>
        {values.event_pictures?.length === 0 && (
          <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
            Please add at least one event picture
          </Alert>
        )}
      </Stack>

      <Stack ml="40px">
        <Typography mb="8px" textTransform="uppercase" color="#707070" fontSize="15px">
          {values.category}
        </Typography>
        <Typography mb="32px" fontWeight={300} color="white" fontSize="30px">
          {formik.values.name}
        </Typography>
        <Typography mb="16px" textTransform="uppercase" color="#B8CAD9" fontSize="15px">
          About this event
        </Typography>
        <Typography color="#B8B8B8" fontSize="16px" fontWeight={300}>
          {formik.values.description}
        </Typography>
        <Stack mt="32px" flexDirection="row" alignItems="center" gap={4}>
          <ScheduleSVG style={iconStyles} />
          <Typography color="white" fontSize="15px">
            {dayjs(values.starts_at || dayjs(), 'DD.MM.YYYY HH:mm').format('dddd, MMMM D · HH:mm')} -{' '}
            {dayjs(values.ends_at || dayjs(), 'DD.MM.YYYY HH:mm').format('dddd, MMMM D · HH:mm')} {values.timezone}
          </Typography>
        </Stack>
        <Stack mb="32px" mt="24px" flexDirection="row" alignItems="center" gap={4}>
          <MapSVG style={iconStyles} />
          <Typography color="white" fontSize="15px">
            {values.eventLocationName || 'Event location'}
          </Typography>
        </Stack>
        <Divider sx={{ borderColor: '#222222' }} />
        <Typography mb="21px" mt="21px" textTransform="uppercase" color="#B8CAD9" fontSize="15px">
          Doors & Lineup
        </Typography>
        {values.line_up.length > 0 && <Stack marginBottom={2}>{showAll ? <LineupFull /> : <LineupMini />}</Stack>}

        {values.line_up.length > 0 ? (
          <Stack mb={2}>
            <TextButton
              handleClick={handleToggleLineup}
              label={showAll ? 'Hide' : 'Show more'}
              icon={showAll ? <UpOutlined style={{ fontSize: '12px' }} /> : <DownOutlined style={{ fontSize: '12px' }} />}
            />
          </Stack>
        ) : (
          <Typography mb={2} sx={{ opacity: 0.5 }}>
            To see the list add at least one performer
          </Typography>
        )}
        <Divider sx={{ borderColor: '#222222' }} />
        <Typography mb="21px" mt="21px" textTransform="uppercase" color="#B8CAD9" fontSize="15px">
          Payment Gateways
        </Typography>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          {values.isPaymentNaira && <StripeSVG />}

          {values.isPaymentOutsideNigeria && (
            <>
              {values.paymentOutsideNigeria === 'paystack' && <PaystackSVG />}
              {values.paymentOutsideNigeria === 'flutterwave' && <FlutterwaveSVG height="25px" />}
            </>
          )}
        </Stack>
      </Stack>
      {isEditPicture && (
        <ImageCropper
          crop={isEditPicture.crop}
          src={isEditPicture.originalPicture}
          onClose={() => setEditPicture(null)}
          onCropComplete={(croped) => {
            handleCropPicture(isEditPicture.id, isEditPicture.originalPicture, croped as Crop);
            console.log(croped);
          }}
          aspect={3 / 4}
        />
      )}
    </Stack>
  );
};

const iconStyles = { minWidth: '32px', height: '32px', stroke: '#fff' };
