import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { PieChart, Pie, Cell, Tooltip, LabelProps } from 'recharts';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FemaleIcon from '@mui/icons-material/Female';
import GroupIcon from '@mui/icons-material/Group';
import { IEventInsightsSectionProp } from '..';
import { useEffect, useState } from 'react';
import { handleCalculateSoldTicketsByName } from 'supabase/util';

const calculatePercentage = (part: number, total: number): string => {
  if (total === 0 || part === 0) return '0';
  return ((part / total) * 100).toFixed(1);
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number }[];
}

interface CustomizedLabelProps extends LabelProps {
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
}

interface StatCardProps {
  icon: React.ReactNode;
  value: number | string;
  label: string;
  percentage: number;
  progressLabel: string;
}

const StatCard = ({ icon, value, label, percentage, progressLabel }: StatCardProps) => (
  <Stack sx={{ flexDirection: 'column', gap: 1 }}>
    <Stack sx={{ flexDirection: 'row', gap: 3 }}>
      <Box
        sx={{
          width: '44px',
          height: '44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '12px',
          backgroundColor: '#B4A2DC'
        }}
      >
        {icon}
      </Box>
      <Stack>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{value}</Typography>
        <Typography sx={{ fontSize: '12px', color: '#8C8C8C' }}>{label}</Typography>
      </Stack>
    </Stack>
    <Stack sx={{ justifyContent: 'space-between', gap: 1 }}>
      <Typography sx={{ fontSize: '12px' }}>{progressLabel}</Typography>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            width: '90%',
            height: '10px',
            backgroundColor: '#E0E0E0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#9977E2'
            }
          }}
        />
        <Typography>{percentage.toFixed(1)}%</Typography>
      </Stack>
    </Stack>
  </Stack>
);

const EventInsightsSectionCenePlus = ({ insights, eventId, tickets }: IEventInsightsSectionProp) => {
  const [data, setData] = useState<{ name: string; value: number }[]>([]);
  const getColor = (index: number) => {
    const colors = ['#6A4AB0', '#B6A5DC', '#9977E2', '#411E8D'];
    return colors[index % colors.length];
  };
  const totalTicketsSold = data.reduce((sum, item) => sum + item.value, 0);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const result = await Promise.all(
          tickets.map(async (ticket) => {
            const soldTickets = await handleCalculateSoldTicketsByName(eventId, ticket.name);
            return {
              name: ticket.name,
              value: soldTickets || 0
            };
          })
        );

        setData(result);
      } catch (error) {
        console.error('Error fetching graph data:', error);
        setData([]);
      }
    };
    if (eventId && tickets.length > 0) {
      fetchGraphData();
    } else {
      setData([]); // Reset data if no tickets or eventId
    }
  }, [tickets, eventId]);

  //format money
  function formatMoney(amount: number, currency = 'NGN', locale = 'en-US') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  }

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: CustomizedLabelProps) => {
    if (cx === undefined || cy === undefined) return null;

    const total = data.reduce((acc, entry) => acc + entry.value, 0);
    const percent = ((value / total) * 100).toFixed(1);

    const radius = innerRadius + (outerRadius - innerRadius) / 2;
    const x = (cx as number) + radius * Math.cos((-Math.PI / 180) * midAngle);
    const y = (cy as number) + radius * Math.sin((-Math.PI / 180) * midAngle);

    return (
      <text x={x} y={y} fill="#000" textAnchor="middle" dominantBaseline="middle">
        {` ${percent}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      const total = data.reduce((acc, entry) => acc + entry.value, 0);
      const percent = ((value / total) * 100).toFixed(1);

      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="body2" sx={{ color: '#B6A5DC' }}>{`${name}: ${value} (${percent}%)`}</Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>EVENT INSIGHTS</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<ConfirmationNumberIcon style={{ width: '24px', height: '24px' }} />}
                  value={insights.ticketsSold || 0}
                  label="Tickets Sold"
                  percentage={Number(calculatePercentage(insights.ticketsSold, insights.numOfTickets))}
                  progressLabel="% Sold"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<AttachMoneyIcon style={{ width: '24px', height: '24px' }} />}
                  value={formatMoney(insights.revenueGenerated || 0)}
                  label="Revenue Generated"
                  percentage={Number(calculatePercentage(insights.revenueGenerated, insights.allTicketsCost))}
                  progressLabel="% of Sales Target"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<FemaleIcon style={{ width: '24px', height: '24px' }} />}
                  value={insights.ticketsAvailable || 0}
                  label="Tickets Available"
                  percentage={Number(calculatePercentage(insights.ticketsAvailable, insights.numOfTickets || 0))}
                  progressLabel="% Available"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<GroupIcon style={{ width: '24px', height: '24px' }} />}
                  value={insights.ticketsShared || 0}
                  label="Tickets Shared"
                  percentage={Number(calculatePercentage(100, 100))}
                  progressLabel="% Shared"
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <p>Sales by ticket type</p>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
              {totalTicketsSold === 0 ? (
                <Typography sx={{ color: '#8C8C8C', py: 8 }}>No tickets have been sold yet</Typography>
              ) : (
                <>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PieChart width={232} height={232}>
                      <Pie data={data} cx={116} cy={116} fill="#8884d8" dataKey="value" labelLine={false} label={renderCustomizedLabel}>
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getColor(index)} />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  </Grid>
                  <Grid item>
                    {data.map((item, index) => (
                      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, mt: 3 }} key={item.name}>
                        <Box
                          sx={{
                            width: '8px',
                            height: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '12px',
                            backgroundColor: getColor(index)
                          }}
                        ></Box>
                        <Typography>{item.name}</Typography>
                      </Stack>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EventInsightsSectionCenePlus;
