import { DashboardOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const dashboardItem: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardOutlined
    }
  ]
};

export default dashboardItem;
