import { Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import AuthWrapper from 'sections/auth/AuthWrapper';
import CreateNewPasswordForm from 'sections/auth/auth-forms/AuthCreateNewPassword';

const CreateNewPassword = () => {
  const [error, setError] = useState('');

  const handleError = (errorCode: string) => {
    setError(errorCode);
  };

  return (
    <AuthWrapper>
      <Grid container>
        {error === '403' ? (
          <Typography>Email link is invalid or has expired</Typography>
        ) : (
          <>
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 400,
                    lineHeight: '28px',
                    color: '#fff'
                  }}
                >
                  Create new password
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt="30px">
              <CreateNewPasswordForm handleError={handleError} />
            </Grid>
          </>
        )}
      </Grid>
    </AuthWrapper>
  );
};

export default CreateNewPassword;
