import { Alert, Button, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { EventSchema } from '../event.schema';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ArtistCard } from 'components/artistCard/artistCard';
import ShapeButton from 'components/button/button';

interface IEventLineup {
  onNext: () => void;
  onPrev: () => void;
}

export const EventLineup = ({ onNext, onPrev }: IEventLineup) => {
  const formik = useFormikContext<EventSchema>();
  const inputRef = useRef<HTMLInputElement>();

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];
  const errors = formik.errors[formType];

  const validationStepForMultipleTypes =
    formik.values.eventType === 'both' &&
    Boolean(
      // formik.values.cene.line_up.length === 0 ||
      // formik.values['cene+'].line_up.length === 0 ||
        formik.errors.cene?.line_up ||
        formik.errors['cene+']?.line_up
    );

  const validationStep =  Boolean(errors?.line_up)

  const cenePlusValidation = Boolean(formik.values['cene+'].line_up.length === 0 || formik.errors['cene+']?.line_up);

  const ceneValidation = Boolean(formik.values.cene.line_up.length === 0 || formik.errors.cene?.line_up);

  const handleAddArtist = (e: any) => {
    if (!inputRef.current) return;

    e.preventDefault();

    formik.setFieldValue(`${formType}.line_up`, [
      ...values.line_up,
      {
        id: uuidv4(),
        starts_at: dayjs().format('HH:mm'),
        title: inputRef.current.value,
        thumbnail_url: [],
        isWithoutTime: false,
        isDoorsClose: false
      }
    ]);

    inputRef.current.value = '';
  };

  const handleAddDoors = () => {
    formik.setFieldValue(`${formType}.line_up`, [
      ...values.line_up,
      {
        id: uuidv4(),
        starts_at: dayjs().format('HH:mm'),
        title: 'Doors close',
        thumbnail_url: [],
        isWithoutTime: false,
        isDoorsClose: true
      }
    ]);
  };

  const renderLineup = values.line_up.map((item, i) => <ArtistCard id={item.id as string} key={item.id} />);

  const isDoorsCloseLineUp = values.line_up.find((item) => item.isDoorsClose);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Doors and Lineup
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Time is a precious resource! Share the accurate timing details and performer lineup to ensure {formik.values.name} is a success.
      </Typography>
      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          Doors Open and Last Entry
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker.RangePicker
            // value={[dayjs(values.lineup_srart_at, 'HH:mm'), dayjs(values.lineup_end_at, 'HH:mm')]}
            onChange={(_, t) => {
              formik.setFieldValue(`${formType}.lineup_srart_at`, t[0] || dayjs().format('HH:mm'));
              formik.setFieldValue(`${formType}.lineup_end_at`, t[1] || dayjs().format('HH:mm'));
            }}
            onBlur={() => {
              formik.handleBlur(`${formType}.lineup_srart_at`);
              formik.handleBlur(`${formType}.lineup_end_at`);
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode?.parentNode as HTMLElement;
            }}
            separator={false}
            color="white"
            style={{
              padding: '8.6px 14px',
              borderRadius: '4px',
              border: '1px solid ',
              borderColor: '#595959',
              backgroundColor: 'transparent',
              color: 'white'
            }}
            variant="borderless"
            dropdownClassName="dark-theme-dropdown"
            format="HH:mm"
          />
        </LocalizationProvider>
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          Add Performer to this Event
        </InputLabel>
        <Stack mt={2}>
          <Alert variant="outlined" severity="info">
            To add a performer, enter a name and press Enter
          </Alert>
        </Stack>
        <form onSubmit={handleAddArtist}>
          <TextField inputRef={inputRef} fullWidth placeholder={'Add Another Performer to the lineup'} />
        </form>
      </Stack>

      {values.line_up?.length >= 1 && (
        <Stack mt={2} gap={2}>
          {renderLineup}
          <Button
            disabled={Boolean(isDoorsCloseLineUp)}
            sx={{
              marginTop: '10px'
            }}
            onClick={handleAddDoors}
          >
            Add Doors close time
          </Button>
        </Stack>
      )}
      {formik.values.eventType === 'both' && ((formType === 'cene' && cenePlusValidation) || (formType === 'cene+' && ceneValidation)) && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          To continue, you need to fix the errors in the {formType === 'cene' ? 'Cene+' : 'Cene'} form
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={validationStepForMultipleTypes || validationStep} handleClick={onNext} width="100%">
          Next step
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
