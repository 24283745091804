import { Button, DialogActions, DialogContent, Divider, Grid, InputLabel, Stack, TextField, DialogTitle, Alert } from '@mui/material';

import { useFormik, Form, FormikProvider } from 'formik';

import { supabase } from 'supabase/supabase';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTeamUserModalContext } from 'contexts/modal/SpecialModalContexts';
import { Member } from 'types/member';
import memberFormSchema from 'schemas/memberFormSchema';

const getInitialValues = (user: Member | null) => {
  if (user) {
    return {
      first_name: user.metadata.first_name || '',
      last_name: user.metadata.last_name || '',

      job_title: user.metadata.job_title || '',
      phone_number: user.metadata.phone_number || '',
      email: user.metadata.email || ''
    };
  }

  return {
    first_name: '',
    last_name: '',

    job_title: '',
    phone_number: '',
    email: ''
  };
};

export type MemberFormProps = { currentObject: Member | null; onSuccess: () => void };

const MemberForm = ({ currentObject, onSuccess = () => null }: MemberFormProps) => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      ...getInitialValues(currentObject)
    },

    validationSchema: memberFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        if (currentObject) {
          const { error } = await supabase
            .from('partner_users')
            .update([
              {
                metadata: {
                  email: values.email,
                  first_name: values.first_name,
                  last_name: values.last_name,

                  job_title: values.job_title,
                  phone_number: values.phone_number
                }
              }
            ])
            .eq('id', currentObject.id);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Team member updated successfully', { variant: 'success' });
        } else {
          if (!values.email) {
            throw new Error('Email is required.');
          }
        }

        onSuccess();
        setSubmitting(false);
        modalToggler(null);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { modalToggler } = useTeamUserModalContext();

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {supabaseError && (
            <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
              {supabaseError}
            </Alert>
          )}

          <DialogTitle>{currentObject ? 'Edit Team Member' : 'Add Team Member'}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="first_name">Team Member First Name</InputLabel>
                      <TextField
                        fullWidth
                        id="first_name"
                        placeholder="Enter first name..."
                        {...getFieldProps('first_name')}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="last_name">Team Member Last Name</InputLabel>
                      <TextField
                        fullWidth
                        id="last_name"
                        placeholder="Enter last name..."
                        {...getFieldProps('last_name')}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="job_title">Position at Cene</InputLabel>
                      <TextField
                        fullWidth
                        id="job_title"
                        placeholder="Enter position at Cene..."
                        {...getFieldProps('job_title')}
                        error={Boolean(touched.job_title && errors.job_title)}
                        helperText={touched.job_title && errors.job_title}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        placeholder="Enter e-mail..."
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phone_number">Phone number</InputLabel>

                      <TextField
                        fullWidth
                        id="phone_number"
                        placeholder="Enter phone number..."
                        {...getFieldProps('phone_number')}
                        error={Boolean(touched.phone_number && errors.phone_number)}
                        helperText={touched.phone_number && errors.phone_number}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  onClick={() => modalToggler(null)}
                  variant="outlined"
                  sx={{
                    color: '#fff',
                    borderColor: '#434343',
                    '&:hover': {
                      backgroundColor: '#121212',
                      color: '#fff',
                      borderColor: '#434343'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                >
                  {currentObject ? 'Update Details' : 'Add'}
                </Button>
              </Stack>
            </Grid>
          </DialogActions>
        </Form>
      </FormikProvider>
    </>
  );
};

export default MemberForm;
