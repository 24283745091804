import { Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import CircleIcon from '@mui/icons-material/Circle';
import ShapeButton from 'components/button/button';
import { FormValues } from '.';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface FormProps {
  onNext: () => void;
  formik: FormikProps<FormValues>;
}

const NameStep = ({ onNext, formik }: FormProps) => {
  const { getFieldProps, touched, errors, values } = formik;

  useEffect(() => {
    const quantity = parseInt(formik.values.quantityZones) || 0;
    const nomenclature = formik.values.nomenclature;

    const zones = Array.from({ length: quantity }, (_, i) => ({
      id: uuidv4(),
      zoneName: nomenclature === 'alphabetical' ? `Zone ${String.fromCharCode(65 + i)}` : `Zone ${i + 1}`,
      tablesInZone: '1',
      tableShapes: 'circular',
      zoneColor: '#ffffff',
      tablesType: 'normal',
      numberOfSeatsPerTable: '1',
      seatsIdentification: 'alphabetical',
      tables: []
    }));

    formik.setFieldValue('zones', zones);
  }, [formik.values.quantityZones, formik.values.nomenclature, formik.setFieldValue]);

  const isNameValid = formik.values.name ? !formik.errors.name : false;
  const isDescriptionValid = formik.values.description ? !formik.errors.description : false;
  const isQuantityZonesValid = formik.values.quantityZones ? !formik.errors.quantityZones : false;
  const isNomenclatureValid = formik.values.nomenclature ? !formik.errors.nomenclature : false;

  const isFormValid = isNameValid && isDescriptionValid && isQuantityZonesValid && isNomenclatureValid;

  return (
    <Grid sx={{ maxWidth: '688px', textAlign: 'center', mt: 4, mx: 'auto' }}>
      <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#8057DB' }}>Let’s Create Your FloorPlan</Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: 300 }}>
        Welcome to the Floorplan Setup! Before we dive into the fun of arranging tables and chairs, we need to check a few boxes.
      </Typography>
      <Grid container spacing={3} sx={{ maxWidth: '532px', my: 4, mx: 'auto' }}>
        <Grid item sm={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="name" sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              FloorPlan Name
            </InputLabel>
            <TextField
              id="name"
              autoComplete="off"
              variant="standard"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="E.g Elektrik Nights"
            />
          </Stack>
        </Grid>
        <Grid item sm={12}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor="description"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}
            >
              FloorPlan Description
            </InputLabel>
            <TextField
              id="description"
              autoComplete="off"
              variant="standard"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="This is a floorplan with zones A-B created by Bidemi"
            />
          </Stack>
        </Grid>
        <Grid item sm={12}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor="quantityZones"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}
            >
              How Many Zones are in this floorplan
            </InputLabel>
            <TextField
              id="quantityZones"
              type="number"
              inputProps={{ inputProps: { min: 0, max: 20 } }}
              autoComplete="off"
              variant="standard"
              {...getFieldProps('quantityZones')}
              error={Boolean(touched.quantityZones && errors.quantityZones)}
              helperText={touched.quantityZones && errors.quantityZones}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="How Many Zones are in this floorplan"
            />
          </Stack>
        </Grid>
        <Grid item sm={12}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor="nomenclature"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}
            >
              The nomenclature...
            </InputLabel>
            <RadioGroup
              id="nomenclature"
              row
              {...getFieldProps('nomenclature')}
              onChange={(event) => formik.setFieldValue('nomenclature', event.target.value)}
              value={values.nomenclature}
            >
              <FormControlLabel
                value="alphabetical"
                control={
                  <Radio
                    checkedIcon={
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          border: '1px solid #8057DB',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <CircleIcon style={{ width: '12px', height: '12px', color: '#8057DB' }} />
                      </Box>
                    }
                    sx={{
                      color: '#4C4C4C'
                    }}
                  />
                }
                label="Alphabetical: A-Z"
              />
              <FormControlLabel
                value="numerical"
                control={
                  <Radio
                    checkedIcon={
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          border: '1px solid #8057DB',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <CircleIcon style={{ width: '12px', height: '12px', color: '#8057DB' }} />
                      </Box>
                    }
                    sx={{
                      color: '#4C4C4C'
                    }}
                  />
                }
                label="Numerical: 1-20"
              />
            </RadioGroup>
          </Stack>
        </Grid>
      </Grid>
      <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <ShapeButton width="464px" handleClick={onNext} disabled={!isFormValid}>
          Create Floorplan
        </ShapeButton>
      </Stack>
    </Grid>
  );
};

export default NameStep;
