import { useState } from 'react';
import AssignAfterCreateForm from './forms/assignAfterCreate';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CustomLoader } from 'components/loader/loader';
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ShapeButton from 'components/button/button';
import { Partner } from 'types/partner';
import { useNavigate } from 'react-router';
import { ROUTER_VENUE_FLOORPLAN_VIEW } from 'constants/routes';
import { PostgrestError } from '@supabase/supabase-js';

const AssignFloorplanPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [partnerName, setPartnerName] = useState('');
  const [floorplanName, setFloorplanName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const resetState = () => {
    setIsSubmitted(false);
    setSuccess(false);
    setPartnerName('');
    setFloorplanName('');
  };

  const handleSubmit = async (values: { partner: Partner; floorplan: string }, error: PostgrestError | null) => {
    setLoading(true);
    setIsSubmitted(true);

    if (error) {
      setSuccess(false);
    } else {
      setSuccess(true);
    }

    setPartnerName(values.partner.company_name);
    setFloorplanName(values.floorplan);

    setLoading(false);
  };

  return (
    <Grid
      container
      sx={{ width: '100%', height: 'calc(100vh - 200px)', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      {!isSubmitted ? (
        <AssignAfterCreateForm onSubmit={handleSubmit} />
      ) : isLoading ? (
        <>
          <Typography sx={{ fontSize: '32px', fontWeight: 300, color: '#8057DB' }}>Encrypting and sending FloorPlan to</Typography>
          <Typography sx={{ fontSize: '32px', fontWeight: 300, color: '#F9F9F9', mb: 6 }}>{partnerName}</Typography>
          <CustomLoader timeout={3000} onComplete={() => setLoading(false)} />
        </>
      ) : isSuccess ? (
        <Stack sx={{ width: '616px', textAlign: 'center', gap: 6, justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '54px',
              height: '54px',
              borderRadius: '50%',
              backgroundColor: '#91DCBE'
            }}
          >
            <CheckOutlined style={{ fontSize: '24px', color: '#000' }} />
          </Box>
          <Typography sx={{ fontSize: '32px', fontWeight: 300, color: '#91DCBE' }}>
            {floorplanName} has successfully been assigned to {partnerName}
          </Typography>
          <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <ShapeButton
              width="464px"
              backgroundColor="#91DCBE"
              bgHover="#91DCBE"
              handleClick={() => navigate(ROUTER_VENUE_FLOORPLAN_VIEW)}
            >
              View Floorplan
            </ShapeButton>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ width: '616px', textAlign: 'center', gap: 6, justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '54px',
              height: '54px',
              borderRadius: '50%',
              backgroundColor: '#AE3B3B'
            }}
          >
            <InfoCircleOutlined style={{ fontSize: '24px' }} />
          </Box>
          <Typography sx={{ fontSize: '32px', fontWeight: 300 }}>
            Oops! Something went wrong. We were unable to assign the floor plan to the specified partner.
          </Typography>
          <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <ShapeButton width="464px" handleClick={resetState}>
              Try Again
            </ShapeButton>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
};

export default AssignFloorplanPage;
