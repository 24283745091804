import { Divider } from '@mui/material';
import GuestlistsStatisticSection from './guestlistsStatistic';
import GuestlistsTable, { IGuest } from './guestlists.table';
import { ModalContextProvider } from 'contexts/ReusableModalContext';

const mockGuestlists: IGuest[] = [
  {
    firstName: 'Bidemi',
    lastName: 'Bidemi',
    tickets: 'Photographer',
    date_assigned: '21/10/2024',
    invited_by: 'Dr Seun',
    status: 'Invited',
    id: '1'
  },
  {
    firstName: 'Dotun',
    lastName: 'Dotun',
    tickets: 'Artist',
    date_assigned: '21/09/2024',
    invited_by: 'Dr Seun',
    status: 'Pending',
    id: '2'
  },
  {
    firstName: 'Isioma',
    lastName: 'Isioma',
    tickets: 'Press',
    date_assigned: '21/11/2024',
    invited_by: 'Dr Seun',
    status: 'Invited',
    id: '3'
  }
];

const GuestlistsPage = () => {
  return (
    <>
      <GuestlistsStatisticSection />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <ModalContextProvider>
        <GuestlistsTable guests={mockGuestlists} />
      </ModalContextProvider>
    </>
  );
};

export default GuestlistsPage;
