export const timezones = [
  {
    label: 'Cape Verde Time Zone',
    value: 'CVT'
  },
  {
    label: 'Greenwich Mean Time',
    value: 'GMT/UTC'
  },
  {
    label: 'West Africa Time',
    value: 'WAT'
  },
  {
    label: 'Central Africa Time',
    value: 'CAT'
  },
  {
    label: 'East Africa Time',
    value: 'EAT'
  }
];
