import { useState } from 'react';
import { TicketRequest } from '.';
import { getColumnValue, getStatusBackgroundColor, RequestColumnId, requestColumns, TableColumn } from './table';
import { Box, Button, Collapse, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import ReusableModal from 'components/modal/modal';
import AcceptRequestForm from './forms/acceptRequest';
import RejectModal from './reject.modal';
import { supabase } from 'supabase/supabase';
import { User } from 'types/user';
import { format } from 'date-fns';

interface RequestRowsProps {
  requests: TicketRequest[];
  onSuccess?: () => void;
}

const RequestTableRows = ({ requests = [], onSuccess = () => null }: RequestRowsProps) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [openApproveModal, setApproveModal] = useState(false);
  const [openRejectModal, setRejectModal] = useState(false);
  const [customerDetails, setCustomerDetails] = useState<User | null>(null);
  const [currentRequest, setCurrentRequest] = useState<TicketRequest | null>(null);

  const fetchCustomerDetails = async (customerId: string) => {
    try {
      const { data } = await supabase.from('customers').select('*').eq('id', customerId).single();
      setCustomerDetails(data);
    } catch (error) {
      console.error('Error fetching customer details', error);
    }
  };

  const handleInfoClick = (request: TicketRequest) => {
    setExpandedRow(expandedRow === request.id ? null : request.id);
    fetchCustomerDetails(request.customer_id);
  };

  const handleToggleApproveModal = (request?: TicketRequest) => {
    setApproveModal(!openApproveModal);
    setCurrentRequest(request || null);
  };

  const handleToggleRejectModal = (request?: TicketRequest) => {
    setRejectModal(!openRejectModal);
    setCurrentRequest(request || null);
  };

  const renderCellContent = (column: TableColumn, request: TicketRequest) => {
    if (column.id === RequestColumnId.STATUS) {
      return (
        <Typography
          sx={{
            width: 'fit-content',
            minWidth: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px',
            fontWeight: 400,
            fontSize: '12px',
            backgroundColor: getStatusBackgroundColor(request.status),
            color: '#000',
            borderRadius: '4px'
          }}
        >
          {request.status}
        </Typography>
      );
    } else if (column.id === RequestColumnId.ACTIONS) {
      return (
        <>
          <IconButton onClick={() => handleInfoClick(request)}>
            <InfoCircleOutlined />
          </IconButton>
          <IconButton>
            <UserOutlined />
          </IconButton>
        </>
      );
    }
    return getColumnValue(column, request);
  };

  return (
    <>
      {requests.map((request) => (
        <>
          <TableRow key={request.id}>
            {requestColumns.map((column) => (
              <TableCell key={column.id}>{renderCellContent(column, request)}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Collapse in={expandedRow === request.id} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                <Grid container margin={3} spacing={3} sx={{ alignItems: 'center', maxWidth: '100%' }}>
                  <Grid item md={6} sx={{ paddingTop: '0px !important' }}>
                    <Stack sx={{ gap: 2.5 }}>
                      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: '#8C8C8C' }}>Email: </Typography>
                        <Typography>{customerDetails?.email}</Typography>
                      </Stack>
                      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: '#8C8C8C' }}>Phone: </Typography>
                        <Typography>{customerDetails?.phone_number}</Typography>
                      </Stack>
                      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: '#8C8C8C' }}>Date Joined: </Typography>
                        <Typography>
                          {customerDetails?.created_at ? format(new Date(customerDetails.created_at), 'dd.MM.yyyy') : 'Invalid Date'}
                        </Typography>
                      </Stack>
                      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: '#8C8C8C' }}>Request Details: </Typography>
                        <Typography sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {request?.details?.map(({ name, quantity }, index) => (
                            <span key={name + index} style={{ marginRight: 8 }}>
                              <strong>{name}</strong> x <span style={{ fontWeight: 400 }}>{quantity}</span>
                              {index < request.details.length - 1 && ', '}
                            </span>
                          ))}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item md={6} sx={{ paddingTop: '0px !important' }}>
                    {request.message && (
                      <Stack sx={{ gap: 1 }} mb={3}>
                        <Typography sx={{ color: '#8C8C8C' }}>Message:</Typography>
                        <Typography maxWidth={'90%'}>{request.message}</Typography>
                      </Stack>
                    )}
                    <Box>
                      <Button
                        onClick={() => handleToggleApproveModal(request)}
                        variant="contained"
                        sx={{
                          mr: 2,
                          fontWeight: 400,
                          backgroundColor: '#91DCBE',
                          color: '#000',
                          '&:hover': { backgroundColor: '#91dcbec9' }
                        }}
                      >
                        Approve Request
                      </Button>
                      <Button
                        onClick={() => handleToggleRejectModal(request)}
                        variant="contained"
                        sx={{
                          mr: 2,
                          fontWeight: 400,
                          backgroundColor: '#DB4848',
                          color: '#fff',
                          '&:hover': { backgroundColor: '#db4848d3' }
                        }}
                      >
                        Reject Request
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Collapse>
            </TableCell>
          </TableRow>
          <ReusableModal
            currentObject={currentRequest}
            open={openApproveModal}
            modalToggler={() => handleToggleApproveModal()}
            content={AcceptRequestForm}
            onSuccess={onSuccess}
          />
          <ReusableModal
            currentObject={currentRequest}
            open={openRejectModal}
            modalToggler={() => handleToggleRejectModal()}
            content={RejectModal}
            onSuccess={onSuccess}
          />
        </>
      ))}
    </>
  );
};

export default RequestTableRows;
