import { FormControlLabel } from '@mui/material';
import { Checkbox, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const interestsCene = [
  'Art',
  'Beauty',
  'Celebrities',
  'Events',
  'Gaming',
  'Lifestyle',
  'Movies & TV',
  'Music',
  'Podcast',
  'Pop culture',
  'Sports',
  'Travel'
];

export const interestsCenePlus = [
  'Art',
  'Beauty',
  'Cars',
  'Entertainment',
  'Events',
  'Fine Dining',
  'Movies/TV',
  'Music',
  'Podcasts',
  'Pop Culture',
  'Sports',
  'Travel'
];

interface IProps {
  type: string;
  selectedInterests: string[];
  onInterestChange: (updatedInterests: string[]) => void;
}

const Interests = ({ type, selectedInterests, onInterestChange }: IProps) => {
  const [checkedInterests, setCheckedInterests] = useState<string[]>(selectedInterests);

  useEffect(() => {
    setCheckedInterests(selectedInterests);
  }, [selectedInterests]);

  const interests = type === 'cene' ? interestsCene : interestsCenePlus;

  const handleInterestChange = (interest: string) => {
    const newCheckedInterests = checkedInterests.includes(interest)
      ? checkedInterests.filter((i) => i !== interest)
      : [...checkedInterests, interest];

    setCheckedInterests(newCheckedInterests);
    onInterestChange(newCheckedInterests);
  };

  return (
    <Stack spacing={1} sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 2, alignItems: 'baseline' }}>
      {interests.map((interest) => (
        <FormControlLabel
          key={interest}
          sx={{ padding: '12px', border: '1px solid #434343', borderRadius: '20px 0' }}
          control={
            <Checkbox
              checked={checkedInterests.includes(interest as string)}
              onChange={() => handleInterestChange(interest as string)}
              sx={{
                '&.Mui-checked': {
                  color: '#8057DB'
                }
              }}
            />
          }
          label={<Typography>{interest}</Typography>}
        />
      ))}
    </Stack>
  );
};

export default Interests;
