import { BellOutlined, CheckCircleOutlined, ClockCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Grid } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { isSameWeek, parseISO } from 'date-fns';
import { Partner } from 'types/partner';

interface IProps {
  partners: Partner[];
}

const PartnerRequestsStatistic = ({ partners }: IProps) => {
  const totalRecievedCount = partners.length;
  const newRequestsCount = partners.filter((partner) => partner.status?.toLowerCase() === 'new').length;
  const approvedPartners = partners.filter((partner) => partner.status === 'approved').length;
  const percentage = totalRecievedCount > 0 ? (approvedPartners / totalRecievedCount) * 100 : 0;

  const now = new Date();
  const thisWeekRequestsCount = partners.filter((partner) => {
    if (partner.created_at) {
      return isSameWeek(parseISO(partner.created_at), now, { weekStartsOn: 1 });
    }
    return false;
  }).length;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Total Received"
          count={totalRecievedCount.toString()}
          icon={<DatabaseOutlined style={{ fontSize: '24px', color: '#c118ff' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="This Week"
          count={thisWeekRequestsCount.toString()}
          icon={<ClockCircleOutlined style={{ fontSize: '24px', color: '#ffcd18' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Approval Rate"
          count={`${percentage.toFixed(0)}%`}
          icon={<CheckCircleOutlined style={{ fontSize: '24px', color: '#20ff18' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="New"
          count={newRequestsCount.toString()}
          icon={<BellOutlined style={{ fontSize: '24px', color: '#1890ff' }} />}
        />
      </Grid>
    </Grid>
  );
};

export default PartnerRequestsStatistic;
