import { Divider, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, LabelProps } from 'recharts';

const data = [
  { name: '', value: 300 },
  { name: 'Admitted', value: 1200 }
];
const entryData = [
  { name: '18:30', entry: 10 },
  { name: '19:00', entry: 15 },
  { name: '19:30', entry: 8 },
  { name: '20:00', entry: 20 },
  { name: '20:30', entry: 25 },
  { name: '21:00', entry: 30 },
  { name: '21:30', entry: 12 }
];

const startTime = new Date('2024-12-25T18:58:00');
const endTime = new Date('2024-12-26T03:28:00');

const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  return date.toLocaleDateString('en-US', options).replace(',', '');
};

const activities = [
  {
    id: 1,
    name: 'John Doe',
    email: 'john.doe@example.com',
    quantity: 300
  },
  {
    id: 2,
    name: 'Jane Smith',
    email: 'jane.smith@example.com',
    quantity: 400
  },
  {
    id: 3,
    name: 'Michael Johnson',
    email: 'michael.johnson@example.com',
    quantity: 289
  }
];

interface CustomizedLabelProps extends LabelProps {
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: CustomizedLabelProps) => {
  if (cx === undefined || cy === undefined) return null;

  const total = data.reduce((acc, entry) => acc + entry.value, 0);
  const percent = ((value / total) * 100).toFixed(1);

  const radius = innerRadius + (outerRadius - innerRadius) / 2;
  const x = (cx as number) + radius * Math.cos((-Math.PI / 180) * midAngle);
  const y = (cy as number) + radius * Math.sin((-Math.PI / 180) * midAngle);

  return (
    <text x={x} y={y} fill="#000" textAnchor="middle" dominantBaseline="middle">
      {` ${percent}%`}
    </text>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number }[];
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    const total = data.reduce((acc, entry) => acc + entry.value, 0);
    const percent = ((value / total) * 100).toFixed(1);

    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
        <Typography variant="body2" sx={{ color: '#B6A5DC' }}>{`${name}: ${value} (${percent}%)`}</Typography>
      </div>
    );
  }
  return null;
};

const EntryManagerStatistic = () => {
  const total = data.reduce((acc, entry) => acc + entry.value, 0);
  const getValueByName = (name: string) => {
    const item = data.find((item) => item.name === name);
    return item ? item.value : 0;
  };

  const totalTicketsAdmitted = getValueByName('Admitted');

  const getColor = (index: number) => {
    const colors = ['#B6A5DC', '#6A4AB0'];
    return colors[index % colors.length];
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>summary</Typography>
        <FormControl sx={{ width: '240px' }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value="Event Overview"
            sx={{
              mb: 3,
              '& .MuiSelect-icon': {
                color: '#8C8C8C',
                zIndex: 2
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '& .MuiSelect-select': {
                backgroundColor: '#1E1E1E',
                color: '#FFFFFF'
              }
            }}
          >
            <MenuItem value="Event Overview">Event Overview</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h6">Attendance Manger</Typography>
            <Grid container sx={{ height: '90%', justifyContent: 'space-between', alignItems: 'center' }}>
              <PieChart width={200} height={200}>
                <Pie data={data} cx={100} cy={100} fill="#8884d8" dataKey="value" labelLine={false} label={renderCustomizedLabel}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(index)} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
              <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '20px', color: '#B6A5DC' }}>
                  {totalTicketsAdmitted}/{total}
                </Typography>
                <Typography sx={{ color: '#b6b6b6' }}>Admitted</Typography>
              </Stack>
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h6" mb={5}>
              Entry and Check-Ins
            </Typography>
            <LineChart width={300} height={200} data={entryData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid stroke="#262626" />
              <Line type="monotone" dataKey="entry" stroke="#9977E2" strokeWidth={2} />
            </LineChart>

            <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Stack>
                <Typography sx={{ fontSize: '24px', color: '#9977E2' }}>6</Typography>
                <Typography sx={{ color: '#8C8C8C' }}>Average sales per minute</Typography>
              </Stack>
              <Stack>
                <Typography sx={{ color: '#B7A1F5', fontWeight: 700 }}>Start date:</Typography>
                <Typography>{formatDate(startTime)}</Typography>
                <Typography sx={{ color: '#B7A1F5', fontWeight: 700 }}>End date:</Typography>
                <Typography>{formatDate(endTime)}</Typography>
              </Stack>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h6" mb={5}>
              Team Member Activity
            </Typography>
            {activities.map((activity) => (
              <Grid sx={{ width: '100%' }} key={activity.id}>
                <Grid container sx={{ textAlign: 'start', width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
                  <Typography sx={{ color: '#BFBFBF', fontWeight: 500 }}>{activity.name}</Typography>
                  <Typography sx={{ color: '#BFBFBF', fontSize: '14px' }}>{activity.email}</Typography>
                </Grid>
                <Grid container sx={{ textAlign: 'end', width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        paddingBlock: 0.5,
                        paddingInline: 1,
                        borderRadius: 4,
                        fontSize: '14px',
                        backgroundColor: '#C976FC',
                        color: '#000',
                        marginRight: 1
                      }}
                    >
                      {`${activity.quantity} / ${total}`}
                    </Typography>
                    <Typography sx={{ fontWeight: 400, color: '#C976FC' }}>{((activity.quantity / total) * 100).toFixed(0)}%</Typography>
                  </Typography>
                </Grid>
                <Divider sx={{ backgroundColor: '#262626', marginY: 1 }} />
              </Grid>
            ))}
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EntryManagerStatistic;
