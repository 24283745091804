import { Grid, Stack } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PendingIcon from '@mui/icons-material/Pending';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import PercentIcon from '@mui/icons-material/Percent';
import { TicketRequest } from '.';
import { TicketRequestsStatus, TicketRequestType } from '@ceneteam/namespace';

interface Props {
  requests: TicketRequest[] | null;
}

const RequestStatistic = ({ requests }: Props) => {
  const totalRequests = requests?.length;
  const pendingRequests = requests?.filter((request) => request.status === TicketRequestsStatus.New).length;
  const bulkRequests = requests?.filter((request) => request.type === TicketRequestType.Bulk).length;
  const accessibleRequests = requests?.filter((request) => request.type === TicketRequestType.Accessible).length;
  const ticketsIssuedPercentage =
    (totalRequests ?? 0) > 0
      ? Math.floor(
          ((requests?.filter((request) => request?.status === TicketRequestsStatus.Approved).length ?? 0) / (totalRequests ?? 1)) * 100
        )
      : 0;

  const requestStatisticList = [
    { title: 'Total Requests', count: totalRequests, icon: <SummarizeIcon style={{ fontSize: '24px', color: '#AA7AE7' }} /> },
    { title: 'Pending Requests', count: pendingRequests, icon: <PendingIcon style={{ fontSize: '24px', color: '#AA7AE7' }} /> },
    { title: 'Bulk Requests', count: bulkRequests, icon: <InventoryIcon style={{ fontSize: '24px', color: '#AA7AE7' }} /> },
    { title: 'Accessible Requests', count: accessibleRequests, icon: <AccessibilityIcon style={{ fontSize: '24px', color: '#AA7AE7' }} /> },
    { title: 'Tickets Issued', count: ticketsIssuedPercentage, icon: <PercentIcon style={{ fontSize: '24px', color: '#AA7AE7' }} /> }
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        {requestStatisticList.map(({ title, count, icon }) => (
          <Grid item xs={12} sm={6} md={2.4}>
            <AnalyticEcommerce title={title} count={(count ?? 0).toString()} icon={icon} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default RequestStatistic;
