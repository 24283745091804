import { Stack, SxProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { EventSchema } from './event.schema';

export const ChooseEventType = () => {
  const formik = useFormikContext<EventSchema>();

  return (
    <Stack
      gap={2}
      mb="32px"
      sx={{
        width: '100%',
        height: '48px',
        padding: '4px',
        background: '#222222',
        borderTopLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        flexDirection: 'row',
        alignContent: 'center'
      }}
    >
      <Stack
        onClick={() => formik.setFieldValue('formType', 'cene')}
        sx={{
          ...tabStyle,
          color: formik.values.formType === 'cene' ? 'black' : 'white',
          background: formik.values.formType === 'cene' ? '#8760DE' : '#222222',
          '&:hover': {
            background: formik.values.formType === 'cene' ? '#8760DE' : '#333'
          }
        }}
      >
        Cene
      </Stack>

      <Stack
        onClick={() => formik.setFieldValue('formType', 'cene+')}
        sx={{
          ...tabStyle,
          color: formik.values.formType === 'cene+' ? 'black' : 'white',
          background: formik.values.formType === 'cene+' ? '#B8CAD9' : '#222222',
          '&:hover': {
            background: formik.values.formType === 'cene+' ? '#B8CAD9' : '#333'
          }
        }}
      >
        Cene+
      </Stack>
    </Stack>
  );
};

const tabStyle: SxProps = {
  width: '100%',
  height: '100%',
  padding: '4px',
  fontWeight: 500,
  borderTopLeftRadius: '16px',
  borderBottomRightRadius: '16px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',
  cursor: 'pointer',
  transition: '300ms'
};
