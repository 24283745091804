import { FormHelperText, InputLabel, Stack, Switch, Typography } from '@mui/material';
import { TimePicker } from 'antd';
import { CustomInput } from 'components/customInput/customInput';
import { ExpandedCard } from 'components/expandedCard/expandedCard';
import { useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { EventSchema } from 'pages/eventManagement/event/event.schema';
import { ReactComponent as UploadSVG } from '../../svg/upload-cloud.svg';
import { ICropedPicture, useCrop } from 'hooks/useCrop';
import { PictureCard } from 'components/pictureCard';
import { Crop } from 'react-image-crop';
import ImageCropper from 'components/imageCropper/imageCropper';

interface IArtistCard {
  id: string;
  isDoor?: boolean;
}

export const ArtistCard = ({ id, isDoor = false }: IArtistCard) => {
  const formik = useFormikContext<EventSchema>();
  const [isEditPicture, setEditPicture] = useState<ICropedPicture | null>(null);

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];

  const index = values.line_up.findIndex((item) => item.id === id);

  const { choosePictures, initialCrop } = useCrop({
    onChange: (pictures) => {
      formik.setFieldValue(`${formType}.line_up[${index}].thumbnail_url`, pictures);
    }
  });

  const errors = formik.errors[formType];

  const removeArtist = () => {
    if (window.confirm('Are you sure you want to delete the performer?')) {
      const index = values.line_up.findIndex((item) => item.id === id);

      formik.setFieldValue(
        `${formType}.line_up`,
        values.line_up.filter((_, i) => i !== index)
      );
    }
  };

  const removePicture = () => {
    if (window.confirm('Are you sure you want to delete picture ?')) {
      formik.setFieldValue(`${formType}.line_up[${index}].thumbnail_url`, []);
    }
  };

  const handleCropPicture = async (picture: string, crop: Crop) => {
    const response = await initialCrop(picture, crop);

    formik.setFieldValue(
      `${formType}.line_up[${index}].thumbnail_url`,
      (values.line_up[index].thumbnail_url || []).map((item) => {
        item.cropedPicture = response.cropedImage;
        item.crop = response.crop;

        return item;
      })
    );

    setEditPicture(null);
  };

  const gradient = formType === 'cene' ? 'rgba(128,87,219,1)' : 'rgba(184,202,217,1)';

  const isDoors = values.line_up[index].isDoorsClose;

  return (
    <>
      <ExpandedCard
        key={id}
        title={values.line_up[index]?.title || `Artist ${index}`}
        expanded={true}
        isDoor={isDoor}
        onRemove={removeArtist}
        children={
          <Stack flexDirection="row" gap={2}>
            {!isDoors && (
              <Stack mt={1}>
                {(values.line_up[index]?.thumbnail_url || [])[0]?.cropedPicture ? (
                  <PictureCard
                    onRemove={removePicture}
                    onEdit={() => setEditPicture((values.line_up[index]?.thumbnail_url || [])[0] as ICropedPicture)}
                    url={(values.line_up[index]?.thumbnail_url || [])[0]?.cropedPicture}
                    alt={(values.line_up[index]?.thumbnail_url || [])[0]?.id}
                  />
                ) : (
                  <Stack
                    onClick={() => choosePictures(1)}
                    sx={{
                      opacity: 0.9,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderTopLeftRadius: '21px',
                      borderBottomRightRadius: '21px',
                      minWidth: '76px',
                      height: '105px',
                      cursor: 'pointer',
                      transition: '300ms',
                      background: `linear-gradient(0deg, transparent 0%, ${gradient} 85%)`,
                      '&:hover': {
                        opacity: 1
                      }
                    }}
                  >
                    <UploadSVG width="35px" height="35px" />
                  </Stack>
                )}
              </Stack>
            )}
            <Stack width="100%">
              {!isDoors && (
                <Stack spacing={1}>
                  <CustomInput
                    placeholder="Name"
                    required
                    name={`${formType}.line_up[${index}].title`}
                    label="Artist name"
                    value={values.line_up[index]?.title}
                    error={getIn(errors, `${formType}.line_up[${index}].title`)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Stack>
              )}
              <Stack spacing={1} mt={isDoors ? 0 : 2}>
                {!isDoors && (
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Switch
                      sx={{ marginLeft: 0 }}
                      checked={values.line_up[index].isWithoutTime}
                      onChange={() => {
                        formik.setFieldValue(`${formType}.line_up[${index}].isWithoutTime`, !values.line_up[index].isWithoutTime);
                      }}
                    />
                    <Typography color="info">Without time</Typography>
                  </Stack>
                )}
                {!values.line_up[index].isWithoutTime && (
                  <>
                    <InputLabel required>Time</InputLabel>
                    <TimePicker
                      format="HH:mm"
                      color="white"
                      getPopupContainer={(triggerNode) => {
                        return triggerNode?.parentNode as HTMLElement;
                      }}
                      style={{
                        padding: '8.6px 14px',
                        borderRadius: '4px',
                        border: getIn(formik.errors, `${formType}.line_up[${index}].starts_at`) ? '1px solid #a61d24' : '1px solid #595959',
                        backgroundColor: 'transparent',
                        color: 'white'
                      }}
                      variant="borderless"
                      value={dayjs(values.line_up[index].starts_at || dayjs(), 'HH:mm')}
                      onBlur={() => formik.setFieldTouched(`${formType}.line_up[${index}].starts_at`, true)}
                      onCalendarChange={(_, time) => formik.setFieldValue(`${formType}.line_up[${index}].starts_at`, time)}
                    />
                    {getIn(errors, `line_up[${index}].starts_at`) && getIn(formik.touched, `line_up[${index}].starts_at`) && (
                      <FormHelperText sx={{ color: '#a61d24' }}>{getIn(errors, `line_up[${index}].starts_at`)}</FormHelperText>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        }
      />
      {isEditPicture && (
        <ImageCropper
          key={index}
          crop={isEditPicture.crop}
          src={isEditPicture.originalPicture}
          onClose={() => setEditPicture(null)}
          onCropComplete={(croped) => {
            handleCropPicture(isEditPicture.originalPicture, croped as Crop);
          }}
          aspect={1}
        />
      )}
    </>
  );
};
