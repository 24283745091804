import { InfoCircleOutlined } from '@ant-design/icons';
import { Grid, Stack, Typography } from '@mui/material';

const TeamSection = () => {
  return (
    <Grid container>
      <Grid sx={{ width: '536px', height: '536px', position: 'relative', borderRadius: '30px 0' }}>
        <img
          src="/pictures/team.png"
          alt="Team"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            borderRadius: '30px 0'
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
            borderRadius: '30px 0',
            background: `
          linear-gradient(180deg, rgba(0, 0, 0, 0), #000)
        `
          }}
        ></div>
      </Grid>

      <Stack sx={{ flexDirection: 'row', gap: '12px', mt: '76px', maxWidth: '556px' }}>
        <InfoCircleOutlined style={{ fontSize: '24px', height: '24px', color: '#8057DB' }} />
        <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 300, color: '#b2b2b2' }}>
          On your Cene Dashboard, you can customise permissions for each team mate, making sure everyone has access to what’s key for their
          role in elevating your brand.
        </Typography>
      </Stack>
    </Grid>
  );
};

export default TeamSection;
