import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Floorplan } from '.';

interface FloorplanTableProps {
  floorplanData: Floorplan | null;
  owner?: string;
  eventsUsed?: string[];
}

const FloorplanTable = ({ floorplanData, owner, eventsUsed }: FloorplanTableProps) => {
  return (
    <Stack sx={{ gap: 3 }}>
      <Stack sx={{ fontSize: '15px', color: '#B6A5DC' }}>Seating chart</Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Owner</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Table</TableCell>
              <TableCell>Seats</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Events Used</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {floorplanData?.zones.map((zone) =>
              zone.tables.map((table, index) => (
                <TableRow key={table.id}>
                  <TableCell>Owner</TableCell>
                  <TableCell>{zone.zoneName}</TableCell>
                  <TableCell>{table.id}</TableCell>
                  <TableCell>{table.numberOfSeatsPerTable}</TableCell>
                  <TableCell>{zone.tablesType}</TableCell>
                  <TableCell>{'N/A'}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default FloorplanTable;
