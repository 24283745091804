import { ReactComponent as EventsSVG } from 'svg/events.svg';
import { ReactComponent as TicketSVG } from 'svg/tickets.svg';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useState } from 'react';

const eventRows = [
  {
    eventName: 'Rock Fest 2024',
    audience: 'Cene+',
    status: 'Selling Now',
    stock: '5000/10000',
    eventDate: '2024-10-15'
  },
  {
    eventName: 'Jazz Night',
    audience: 'Cene/Cene+',
    status: 'On the Way',
    stock: '2000/8000',
    eventDate: '2024-09-20'
  },
  {
    eventName: 'Classical Evening',
    audience: 'Cene+',
    status: 'Sold Out',
    stock: '0/4000',
    eventDate: '2024-11-05'
  },
  {
    eventName: 'Pop Hits Live',
    audience: 'Cene +',
    status: 'Selling Now',
    stock: '3000/12000',
    eventDate: '2024-10-25'
  },
  {
    eventName: 'Alte Vibes',
    audience: 'Cene',
    status: 'On the Way',
    stock: '5000/5000',
    eventDate: '2024-09-30'
  },
  {
    eventName: 'Country Fest',
    audience: 'Cene',
    status: 'Selling Now',
    stock: '1000/6000',
    eventDate: '2024-10-10'
  },
  {
    eventName: 'Electronic Beats',
    audience: 'Cene+',
    status: 'Selling Now',
    stock: '8000/18000',
    eventDate: '2024-12-01'
  },
  {
    eventName: 'Hip Hop Night',
    audience: 'Cene+',
    status: 'Sold Out',
    stock: '0/14000',
    eventDate: '2024-11-20'
  },
  {
    eventName: 'Reggae Summer',
    audience: 'Cene+',
    status: 'On the Way',
    stock: '2000/7000',
    eventDate: '2024-08-28'
  }
];

const Events = () => {
  const [filter, setFilter] = useState('');
  const totalEvents = eventRows.length;

  const totalStock = eventRows.reduce(
    (acc, event) => {
      const [sold, total] = event.stock.split('/').map(Number);
      acc.sold += sold;
      acc.total += total;
      return acc;
    },
    { sold: 0, total: 0 }
  );

  const filteredRows = eventRows.filter((event) => event.eventName.toLowerCase().includes(filter.toLowerCase()));

  return (
    <MainCard title="Events">
      <Grid container gap="8px" sx={{ gap: '8px' }}>
        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticEcommerce title="Total Events" count={totalEvents.toString()} icon={<EventsSVG />} />
        </Grid>

        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticEcommerce title="Total Stock" count={`${totalStock.sold}/${totalStock.total}`} icon={<TicketSVG />} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} marginTop="16px">
        <DebouncedInput fullWidth value={filter ?? ''} onFilterChange={(value) => setFilter(String(value))} placeholder="Search..." />
      </Grid>
      <TableContainer sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'transparent' }}>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell>Audience</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Stock</TableCell>
              <TableCell>Event Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length > 0 ? (
              filteredRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.eventName}</TableCell>
                  <TableCell>{row.audience}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: row.status === 'Sold Out' ? '#727272' : row.status === 'On the Way' ? '#4A7DFF' : '#EC67F7',
                        fontWeight: 300,
                        fontSize: '16px'
                      }}
                    >
                      {row.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.stock}</TableCell>
                  <TableCell>{row.eventDate}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center" color="textSecondary">
                    No events found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default Events;
