import { Alert, InputLabel, Stack, Typography } from '@mui/material';
import { TicketCard } from 'components/ticketCard/ticketCard';
import { useFormikContext } from 'formik';
import { EventSchema } from '../../event.schema';
import { v4 as uuidv4 } from 'uuid';
import ShapeButton from 'components/button/button';

interface ICeneTickets {
  onNext: () => void;
  onPrev: () => void;
}

export const CeneTickets = ({ onNext, onPrev }: ICeneTickets) => {
  const formik = useFormikContext<EventSchema>();

  const values = formik.values[formik.values.formType];
  // const errors = formik.errors[formik.values.formType];

  const addTicket = () => {
    formik.setFieldValue(`${formik.values.formType}.tickets`, [...values.tickets, { id: uuidv4() }]);
  };

  const cenePlusValidation =
    (!formik.values['cene+'].isFloorplan && !formik.values['cene+'].isSimpleTicketsForCenePlus) ||
    (formik.values['cene+'].isFloorplan && Boolean(formik.values['cene+'].tickets.length === 0 || formik.errors['cene+']?.tickets)) ||
    (formik.values['cene+'].isSimpleTicketsForCenePlus &&
      Boolean(formik.values['cene+'].simpleTickets.length === 0 || formik.errors['cene+']?.simpleTickets));

  const ceneValidation = Boolean(formik.values.cene.tickets.length === 0 || formik.errors.cene?.tickets);

  const multipleValiationStep = formik.values.eventType === 'both' && (cenePlusValidation || ceneValidation);

  const validationStep = Boolean(formik.values['cene'].tickets.length === 0 || formik.errors['cene']?.tickets);

  const renderTickets = values.tickets?.map((ticket, index) => <TicketCard key={ticket?.id} index={index} />);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Because One Size Doesn’t Fit All!
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Create tailored ticket options that cater to different seating preferences and access levels, ensuring that attendees can choose the
        experience that best suits their needs.
      </Typography>
      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          Click on the add ticket button create a ticket type
        </InputLabel>
      </Stack>
      <Stack
        onClick={addTicket}
        borderRadius={2}
        p={2}
        textAlign="center"
        mt={2}
        mb={2}
        sx={{
          border: '1px dashed #363636',
          background: '#1e1e1e',
          cursor: 'pointer',
          transition: '300ms',

          '&:hover': {
            borderColor: '#8057db'
          }
        }}
      >
        Add ticket
      </Stack>
      {values.tickets.length === 0 ? (
        <Alert variant="outlined" severity="error">
          Please add at least one ticket
        </Alert>
      ) : (
        <Stack gap={2}>{renderTickets}</Stack>
      )}
      {formik.values.eventType === 'both' && cenePlusValidation && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          To continue, you need to fix the errors in the {formik.values.formType === 'cene' ? 'Cene+' : 'Cene'} form
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={multipleValiationStep || validationStep} handleClick={onNext} width="100%">
          Create event
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
