import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MainCard from 'components/MainCard';
import { Transaction } from '..';
import { User } from 'types/user';

export const products = [
  { id: 1, name: 'Product A', description: 'Description of Product A', quantity: 2, unitPrice: 50 },
  { id: 2, name: 'Product B', description: 'Description of Product B', quantity: 1, unitPrice: 80 },
  { id: 3, name: 'Product C', description: 'Description of Product C', quantity: 3, unitPrice: 40 }
];

export const calculateSubTotal = () => {
  return products.reduce((total, product) => total + product.quantity * product.unitPrice, 0);
};

export const calculateTax = (subTotal: number) => {
  return subTotal * 0.1;
};

export const calculateDiscount = (subTotal: number) => {
  return subTotal * 0.05;
};

export const calculateTotal = (subTotal: number, tax: number, discount: number): number => {
  return subTotal + tax - discount;
};

interface Props {
  transaction: Transaction | undefined;
  customer: User | null;
  isLoading: boolean;
}

const DetailsPage = ({ transaction, customer, isLoading }: Props) => {
  const subTotal = calculateSubTotal();
  const tax = calculateTax(subTotal);
  const discount = calculateDiscount(subTotal);
  const total = calculateTotal(subTotal, tax, discount);

  if (isLoading) return <CircularProgress />;

  return (
    <Stack sx={{ mt: 5, padding: 4, backgroundColor: '#000' }} id="receipt-container">
      <Grid container spacing={2}>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>Name:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>
                {customer?.first_name} {customer?.last_name}
              </Typography>
              <AccountCircleIcon />
            </Stack>
          </MainCard>
        </Grid>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>Placed on:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{transaction?.orderDate}</Typography>
              <CalendarTodayIcon />
            </Stack>
          </MainCard>
        </Grid>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>Phone number:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{customer?.phone_number}</Typography>
              <PhoneIcon />
            </Stack>
          </MainCard>
        </Grid>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>E-mail:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{customer?.email}</Typography>
              <EmailIcon />
            </Stack>
          </MainCard>
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <Grid container spacing={5}>
        <Grid item md={3}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Payment method</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography sx={{ color: '#B6A5DC' }}>Credit Card</Typography>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Transaction id:</Typography>
              <Typography>{transaction?.id}</Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Amount:</Typography>
              <Typography>{transaction?.amount}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Shipping method</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography sx={{ color: '#B6A5DC' }}>{transaction?.payment}</Typography>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Tracking Code:</Typography>
              <Typography>FX-012345-6</Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Date:</Typography>
              <Typography>12/12/2021</Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Fulfillment status:</Typography>
              <Typography
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 8px',
                  fontWeight: 400,
                  fontSize: '12px',
                  backgroundColor: '#A4DB6C',
                  color: '#000',
                  borderRadius: '4px'
                }}
              >
                Delivered
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Payment status:</Typography>
              <Typography
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 8px',
                  fontWeight: 400,
                  fontSize: '12px',
                  backgroundColor: '#B79BF1',
                  color: '#000',
                  borderRadius: '4px'
                }}
              >
                Paid
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Bill to</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography>Joseph William</Typography>
            <Grid>
              <Typography>4898 Joanne Lane street, Boston, Massachusetts, United States, 02110</Typography>
              <Typography>+1 (070) 123-4567</Typography>
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Sent to</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography>Sara Soudan</Typography>
            <Grid>
              <Typography>4898 Joanne Lane street, Boston, Massachusetts, United States, 02110</Typography>
              <Typography>+1 (070) 123-4567</Typography>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <TableContainer sx={{ mt: 3 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#000' }}>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>${product.unitPrice.toFixed(2)}</TableCell>
                <TableCell>${(product.quantity * product.unitPrice).toFixed(2)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Subtotal:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">${subTotal.toFixed(2)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Taxes (10%):</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">${tax.toFixed(2)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Discount (5%):</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">${discount.toFixed(2)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="h6" sx={{ color: '#B79BF1' }}>
                  Total:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: '#B79BF1' }}>
                  ${total.toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DetailsPage;
