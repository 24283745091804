import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';

interface IExpandedCard {
  expanded?: boolean;
  title: string;
  children: ReactNode;
  onRemove: () => void;
  isDoor?: boolean;
  headerEndComponent?: ReactNode;
}

export const ExpandedCard = ({ expanded = false, headerEndComponent, isDoor = false, onRemove, children, title }: IExpandedCard) => {
  const [isExpanded, setExpanded] = useState(expanded);

  return (
    <Box
      onClick={() => setExpanded(!isExpanded)}
      component="section"
      sx={{
        minHeight: '56px',
        cursor: 'pointer',
        padding: '10px',
        borderRadius: '8px',
        backgroundColor: '#0b0b0b'
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={2}>
          {isExpanded ? <DownCircleOutlined /> : <UpCircleOutlined />}

          <Typography variant="h4">{title}</Typography>
        </Stack>

        <Stack flexDirection="row-reverse" alignItems="center">
          {headerEndComponent}
          <Button
            sx={{ opacity: isDoor ? 0 : 1 }}
            disabled={isDoor}
            onClick={(e) => {
              onRemove();
              e.stopPropagation();
            }}
            color="error"
          >
            Remove
          </Button>
        </Stack>
      </Stack>
      {isExpanded && (
        <div onClick={(e) => e.stopPropagation()}>
          <Stack mt={1}>
            <Divider />
            <Stack pt={1}>{children}</Stack>
          </Stack>
        </div>
      )}
    </Box>
  );
};
