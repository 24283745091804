import { Box, Grid, Stack, Typography } from '@mui/material';
import { Floorplan } from '.';
import { LayersOutlined, ChairOutlined, TableChartOutlined } from '@mui/icons-material';

interface Props {
  floorplanData: Floorplan | null;
}

const FloorplanInfo = ({ floorplanData }: Props) => {
  const totalTables = (floorplanData?.zones ?? []).reduce((sum, zone) => sum + zone.tables.length, 0);

  const totalSeats = (floorplanData?.zones ?? []).reduce(
    (sum, zone) => sum + zone.tables.reduce((tableSum, table) => tableSum + parseInt(table.numberOfSeatsPerTable || '0', 10), 0),
    0
  );

  return (
    <Grid container sx={{ flexDirection: 'row' }} spacing={6}>
      <Grid item sm={6}>
        <Typography sx={{ fontSize: '24px' }}>Owner</Typography>
        <Typography mt={3}>{floorplanData?.description}</Typography>
        <Stack sx={{ gap: 2, mt: 3 }}>
          <Typography sx={{ color: '#8C8C8C' }}>In this floorplan</Typography>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <LayersOutlined />
              <Typography sx={{ fontSize: '17px' }}>Zones</Typography>
            </Stack>
            <Typography sx={{ color: '#8C8C8C' }}>{floorplanData?.quantityZones}</Typography>
          </Stack>

          <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <TableChartOutlined />
              <Typography sx={{ fontSize: '17px' }}>Tables</Typography>
            </Stack>
            <Typography sx={{ color: '#8C8C8C' }}>{totalTables}</Typography>
          </Stack>

          <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <ChairOutlined />
              <Typography sx={{ fontSize: '17px' }}>Seats</Typography>
            </Stack>
            <Typography sx={{ color: '#8C8C8C' }}>{totalSeats}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item sm={6}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#B6A5DC',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography sx={{ color: '#000' }}>An image of the floor plan from Seats.io will be displayed here</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FloorplanInfo;
