import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const genresCene = [
  'Afrobeats',
  'Alté',
  'Amapiano',
  'Country',
  'Dancehall',
  'Grill',
  'Electronic',
  'Hip-Hop',
  'House',
  'Jazz',
  'Pop',
  'Rap',
  'RnB',
  'Rock',
  'Soul',
  'Trap'
];

export const genresCenePlus = [
  'Afrobeats',
  'Altérnative',
  'Amapiano',
  'Classical',
  'Country',
  'Dancehall',
  'Electronic',
  'Hip-Hop',
  'House',
  'Jazz',
  'Neo-Soul',
  'Pop',
  'R&B',
  'Rap',
  'Reggae',
  'Rock'
];

interface IProps {
  type: string;
  selectedGenres: string[];
  onGenreChange: (updatedGenres: string[]) => void;
}

const MusicGenres = ({ type, selectedGenres, onGenreChange }: IProps) => {
  const [checkedGenres, setCheckedGenres] = useState<string[]>(selectedGenres);

  useEffect(() => {
    setCheckedGenres(selectedGenres);
  }, [selectedGenres]);

  const genres = type === 'cene' ? genresCene : genresCenePlus;

  const handleGenreChange = (genre: string) => {
    const newCheckedGenres = checkedGenres.includes(genre) ? checkedGenres.filter((g) => g !== genre) : [...checkedGenres, genre];

    setCheckedGenres(newCheckedGenres);
    onGenreChange(newCheckedGenres);
  };

  return (
    <Stack spacing={1} sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: 2, alignItems: 'baseline' }}>
      {genres.map((genre) => (
        <FormControlLabel
          sx={{ padding: '12px', border: '1px solid #434343', borderRadius: '20px 0' }}
          key={genre}
          control={
            <Checkbox
              checked={checkedGenres.includes(genre as string)}
              onChange={() => handleGenreChange(genre as string)}
              sx={{
                '&.Mui-checked': {
                  color: '#91DCBE'
                }
              }}
            />
          }
          label={<Typography>{genre}</Typography>}
        />
      ))}
    </Stack>
  );
};

export default MusicGenres;
