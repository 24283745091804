import MainCard from 'components/MainCard';
import { useState } from 'react';

import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { HeaderCells } from 'components/commonTable/headerCells';

import CircularLoader from 'components/CircularLoader';
import { format, parse, parseISO } from 'date-fns';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import RequestDetails from './requestDetails';
import { CenePlusRequest } from 'types/user';

enum IStatus {
  NEW = 'new',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum CenePlusRequestColumnId {
  DATE_APPLIED = 'dateApplied',
  NAME = 'contactName',
  COMPANY_NAME = 'companyName',
  REFERRED_BY = 'referredBy',
  STATUS = 'status',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: CenePlusRequestColumnId;
  title: string;
  sortable: boolean;
  cell?: (request: CenePlusRequest) => React.ReactNode;
}

const cenePlusRequestColumns: TableColumn[] = [
  { id: CenePlusRequestColumnId.DATE_APPLIED, title: 'Date applied', sortable: true },
  { id: CenePlusRequestColumnId.NAME, title: 'Name', sortable: true },
  { id: CenePlusRequestColumnId.COMPANY_NAME, title: 'Company name', sortable: true },
  { id: CenePlusRequestColumnId.REFERRED_BY, title: 'Referred by', sortable: true },
  { id: CenePlusRequestColumnId.STATUS, title: 'Status', sortable: true },
  { id: CenePlusRequestColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const columnConfig: Record<CenePlusRequestColumnId, keyof CenePlusRequest | ((request: CenePlusRequest) => string | number)> = {
  [CenePlusRequestColumnId.DATE_APPLIED]: (request) => format(parseISO(request.created_at as string), 'dd.MM.yyyy'),
  [CenePlusRequestColumnId.NAME]: (request) => `${request.first_name} ${request.last_name}`,
  [CenePlusRequestColumnId.COMPANY_NAME]: (request) => request.company_name,
  [CenePlusRequestColumnId.REFERRED_BY]: (request) => request.reference || '-',
  [CenePlusRequestColumnId.STATUS]: (request) => request.status as string,

  [CenePlusRequestColumnId.ACTIONS]: () => ''
};

const getColumnValue = (columnId: CenePlusRequestColumnId, request: CenePlusRequest): string | number => {
  const config = columnConfig[columnId];
  return typeof config === 'function' ? config(request) : (request[config] as string | number);
};

interface CenePlusTableRowsProps {
  requests: CenePlusRequest[];
  onSuccess?: () => void;
}

const CenePlusRequestTableRows = ({ requests = [], onSuccess = () => null }: CenePlusTableRowsProps) => {
  const [expandedContactId, setExpandedContactId] = useState<string | null>(null);

  const handleOpenView = (id: string) => {
    setExpandedContactId(expandedContactId === id ? null : id);
  };

  const renderCellContent = (column: TableColumn, request: CenePlusRequest) => {
    if (column.id === CenePlusRequestColumnId.ACTIONS) {
      return (
        <IconButton onClick={() => handleOpenView(request.id)}>
          {expandedContactId === request.id ? <EyeInvisibleOutlined style={{ color: '#91DCBE' }} /> : <EyeOutlined />}
        </IconButton>
      );
    }
    return getColumnValue(column.id, request);
  };

  return (
    <>
      {requests.map((request) => (
        <>
          <TableRow key={request.id}>
            {cenePlusRequestColumns.map((column) => (
              <TableCell key={column.id}>{renderCellContent(column, request)}</TableCell>
            ))}
          </TableRow>
          {expandedContactId === request.id && <RequestDetails request={request} onSuccess={onSuccess} />}
        </>
      ))}
    </>
  );
};

interface RequestsTableProps {
  isLoading?: boolean;
  requests?: CenePlusRequest[];
  onSuccess?: () => void;
}

const CenePlusRequestsTable = ({ onSuccess = () => null, isLoading = false, requests = [] }: RequestsTableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: CenePlusRequestColumnId; direction: 'asc' | 'desc' }>({
    key: CenePlusRequestColumnId.NAME,
    direction: 'asc'
  });

  const sortedRequests = [...requests].sort((a, b) => {
    const { key, direction } = sortConfig;
    const valueA = getColumnValue(key, a);
    const valueB = getColumnValue(key, b);

    const compareValues = (valA: string | number, valB: string | number) => {
      if (typeof valA === 'number' && typeof valB === 'number') {
        return direction === 'asc' ? valA - valB : valB - valA;
      }
      return direction === 'asc' ? String(valA).localeCompare(String(valB)) : String(valB).localeCompare(String(valA));
    };

    if (key === CenePlusRequestColumnId.STATUS) {
      const statusOrder = [IStatus.NEW, IStatus.APPROVED, IStatus.REJECTED];
      const indexA = statusOrder.indexOf(valueA as IStatus);
      const indexB = statusOrder.indexOf(valueB as IStatus);
      return direction === 'asc' ? indexA - indexB : indexB - indexA;
    }

    if (key === CenePlusRequestColumnId.NAME) {
      const fullNameA = `${a.first_name} ${a.last_name}`.trim();
      const fullNameB = `${b.first_name} ${b.last_name}`.trim();
      return compareValues(fullNameA, fullNameB);
    }

    if (key === CenePlusRequestColumnId.DATE_APPLIED) {
      const dateA = parse(a.created_at as string, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.created_at as string, 'yyyy-MM-dd', new Date());
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    return compareValues(valueA, valueB);
  });

  const requestSort = (key: CenePlusRequestColumnId) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<CenePlusRequestColumnId> columns={cenePlusRequestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : requests.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Stack textAlign="center">No Cene+ requests yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <CenePlusRequestTableRows onSuccess={onSuccess} requests={sortedRequests} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default CenePlusRequestsTable;
