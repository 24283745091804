import { Divider } from '@mui/material';
import FloorplanActions from './actions';
import FloorplanInfo from './info';
import FloorplanTable from './table';
import { useEffect, useState } from 'react';
import { Zone } from '../forms/floorplan';

export interface Floorplan {
  description: string;
  hasStage: boolean;
  id: string;
  includeBestAvailableSeats: string;
  name: string;
  nomenclature: string;
  quantityZones: string;
  venueId: string;
  zones: Zone[] | [];
}

const ViewFloorplanPage = () => {
  const [floorplanData, setFloorplanData] = useState<Floorplan | null>(null);

  useEffect(() => {
    const savedFloorplan = localStorage.getItem('floorplanData');
    if (savedFloorplan) {
      setFloorplanData(JSON.parse(savedFloorplan));
    }
  }, []);

  return (
    <>
      <FloorplanActions floorplanData={floorplanData} />
      <Divider sx={{ backgroundColor: '#262626', my: 3 }} />
      <FloorplanInfo floorplanData={floorplanData} />
      <Divider sx={{ backgroundColor: '#262626', my: 3 }} />
      <FloorplanTable floorplanData={floorplanData} />
    </>
  );
};

export default ViewFloorplanPage;
