import { Grid, Stack } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Venue } from '.';

interface Props {
  array: Venue[];
}

const VenuesAndFloorplansStatistic = ({ array }: Props) => {
  const statisticList = [
    {
      title: 'Venues',
      count: array.length,
      icon: <LocationOnIcon style={{ fontSize: '24px', color: '#AA7AE7' }} />
    },
    {
      title: 'Floorplans',
      count: array.reduce((acc, venue) => acc + (venue.floorplans || 0), 0),
      icon: <MapIcon style={{ fontSize: '24px', color: '#AA7AE7' }} />
    },
    {
      title: 'Active',
      count: array.filter((venue) => venue.isActive).length,
      icon: <CheckCircleIcon style={{ fontSize: '24px', color: '#AA7AE7' }} />
    },
    {
      title: 'Unused',
      count: array.filter((venue) => !venue.isActive).length,
      icon: <HighlightOffIcon style={{ fontSize: '24px', color: '#AA7AE7' }} />
    }
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        {statisticList.map(({ title, count, icon }) => (
          <Grid item xs={12} sm={6} md={3}>
            <AnalyticEcommerce title={title} count={count.toString()} icon={icon} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default VenuesAndFloorplansStatistic;
