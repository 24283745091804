import { Typography, Container, IconButton, Stack, Grid, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Outlet, useNavigate, useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ProfileTabs from './profileTabs';
import { ROUTE_CUSTOMERS } from 'constants/routes';
import { enqueueSnackbar } from 'notistack';
import { User } from 'types/user';

const CustomerProfile = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchCustomer();
  }, [id]);

  const fetchCustomer = async () => {
    setLoading(false);
    try {
      setLoading(true);
      const { data, error } = await supabase.from('customers').select('*').eq('id', id);
      setCustomer(data?.[0] || null);
      if (error?.message) throw Error(error?.message);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Stack sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <IconButton
          onClick={() => {
            navigate(ROUTE_CUSTOMERS);
          }}
        >
          <ArrowLeftOutlined />
        </IconButton>
        <Typography sx={{ fontSize: '25px' }}>Profile</Typography>
      </Stack>

      {customer ? (
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} md={4}>
            <ProfileTabs customer={customer} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Outlet context={{ customer }} />
          </Grid>
        </Grid>
      ) : (
        <Typography sx={{ marginTop: '16px', fontSize: '20px', color: '#727272' }}>Customer not found</Typography>
      )}
    </Container>
  );
};

export default CustomerProfile;
