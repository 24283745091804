import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import ReusableModal from 'components/modal/modal';
import ReusableTabs from 'components/tabs/reusableTabs';
import { DebouncedInput } from 'components/third-party/react-table';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useState } from 'react';
import { TicketRequest, Ticket } from '.';
import { TicketRequestsStatus, TicketRequestType } from '@ceneteam/namespace';
import { format } from 'date-fns';
import RequestTableRows from './requestRows';
import CreateRequestForm from './forms/createRequest';

export enum RequestColumnId {
  CONTACT_NAME = 'contactName',
  DATE_APPLIED = 'dateApplied',
  REQUEST_TYPE = 'requestType',
  CHANNEL = 'channel',
  QUANTITY_REQ = 'quantityReq',
  STATUS = 'status',

  ACTIONS = 'actions'
}

export interface TableColumn {
  id: RequestColumnId;
  title: string;
  sortable: boolean;
  cell?: (request: TicketRequest) => React.ReactNode;
}

enum ITab {
  ALL = 'All',
  APPROVED = 'Approved',
  PENDING = 'Decision Pending',
  REJECTED = 'Rejected'
}

export const mapTabToStatus = (tab: ITab): TicketRequestsStatus | null => {
  switch (tab) {
    case ITab.APPROVED:
      return TicketRequestsStatus.Approved;
    case ITab.PENDING:
      return TicketRequestsStatus.New;
    case ITab.REJECTED:
      return TicketRequestsStatus.Rejected;
    default:
      return null;
  }
};

export const requestColumns: TableColumn[] = [
  { id: RequestColumnId.CONTACT_NAME, title: 'Contact name', sortable: true, cell: (request) => request.customer_name },
  {
    id: RequestColumnId.DATE_APPLIED,
    title: 'Date applied',
    sortable: true,
    cell: (request) => format(new Date(request.created_at), 'dd.MM.yyyy')
  },
  {
    id: RequestColumnId.REQUEST_TYPE,
    title: 'Request type',
    sortable: true,
    cell: (request) => (request.type === TicketRequestType.Bulk ? 'Bulk Order' : 'Accessible Request')
  },
  { id: RequestColumnId.CHANNEL, title: 'Channel', sortable: true, cell: (request) => (request.app === 'cene+' ? 'Cene+' : 'Cene') },
  {
    id: RequestColumnId.QUANTITY_REQ,
    title: 'Quantity req',
    sortable: true,
    cell: (request: { details: Ticket[] }) => request.details?.reduce((total, ticket) => total + (ticket.quantity ?? 0), 0)
  },
  { id: RequestColumnId.STATUS, title: 'Status', sortable: true, cell: (request) => request.status },

  { id: RequestColumnId.ACTIONS, title: 'Actions', sortable: false }
];

export const getColumnValue = (column: TableColumn, request: TicketRequest): React.ReactNode => {
  return column.cell ? column.cell(request) : '';
};

export const getStatusBackgroundColor = (status: string) => {
  switch (status) {
    case TicketRequestsStatus.New:
      return '#ffe500';
    case TicketRequestsStatus.Approved:
      return '#3bde40';
    case TicketRequestsStatus.Rejected:
      return '#ff4f61';
    default:
      return '#f2f2f2';
  }
};

interface RequestsTableProps {
  isLoading?: boolean;
  requests?: TicketRequest[];
  onSuccess?: () => void;
}

const RequestsTable = ({ onSuccess = () => null, isLoading = false, requests = [] }: RequestsTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: RequestColumnId; direction: 'asc' | 'desc' }>({
    key: RequestColumnId.CONTACT_NAME,
    direction: 'asc'
  });
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
  };
  const { open, modalToggler } = useModalContext();

  const filterRequests = requests.filter((request) => {
    const statusMatches = activeTab === ITab.ALL || request.status === mapTabToStatus(activeTab);

    const matchesFilter = requestColumns.some((column) => {
      const value = getColumnValue(column, request);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    });

    return statusMatches && matchesFilter;
  });

  const sortedRequests = [...filterRequests]?.sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = requestColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    if (key === RequestColumnId.DATE_APPLIED) {
      const dateA = new Date(String(valueA));
      const dateB = new Date(String(valueB));
      return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    }

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: RequestColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>
          Requests (Accessible and bulk orders)
        </Typography>
      </Stack>
      <MainCard content={false}>
        <Stack sx={{ padding: 3 }}>
          <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />
        </Stack>

        <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 3.5, paddingInline: 3 }}>
          <Stack width={300}>
            <DebouncedInput
              value={filter ?? ''}
              onFilterChange={(value) => setFilter(String(value))}
              placeholder="Search by Name of User "
              sx={{ width: '300px !important' }}
            />
          </Stack>
          <Button
            onClick={() => modalToggler(null)}
            variant="contained"
            sx={{
              backgroundColor: '#8057DB',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              '&:hover': { backgroundColor: '#8157dbab' }
            }}
          >
            Create User Request
          </Button>
        </Stack>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<RequestColumnId> columns={requestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={requestColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : requests?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={requestColumns.length}>
                    <Stack textAlign="center">No requests yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <RequestTableRows onSuccess={onSuccess} requests={sortedRequests} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={CreateRequestForm} width="488px" />
    </>
  );
};

export default RequestsTable;
