import { Outlet, useNavigate, useParams } from 'react-router';
import { ReactComponent as BackSVG } from '../../../../svg/arrow-left.svg';
import { Divider, Grid, IconButton, Stack } from '@mui/material';
import {
  ROUTER_EVENT_EDIT,
  ROUTE_ENTRY_MANAGER,
  ROUTE_EVENT,
  ROUTE_EVENTS,
  ROUTE_GUESTLISTS,
  ROUTE_MANAGE_EVENT_VISIBILITY,
  ROUTE_OVERVIEW,
  ROUTE_PROMO_CODES,
  ROUTE_REQUESTS,
  ROUTE_SALES_AND_TRANSACTIONS,
  ROUTE_TABLES_AND_GUESTS_MANAGEMENT,
  ROUTE_TICKET_BUYERS
} from 'constants/routes';
import EventDetails from './eventDetails';
import { supabase } from 'supabase/supabase';
import { Event } from '@ceneteam/namespace';
import { useEffect, useState } from 'react';
import ReusableTabs from 'components/tabs/reusableTabs';
import ShapeButton from 'components/button/button';
import { EventLoading } from 'pages/eventManagement/event/event.loading';
import { enqueueSnackbar } from 'notistack';

enum ITab {
  EVENT_OVERVIEW = 'Event Overview',
  TICKET_BUYERS = 'Attendees',
  TABLED_AND_GUEST_MANAGEMENT = 'Tables & Guest Management',
  ENTRY_MANAGER = 'Entry Manager',
  REQUESTS = 'Requests',
  SALES_AND_TRANSACTIONS = 'Sales & Transactions',
  GUEST_LISTS = 'Guestlists',
  PROMO_CODES = 'Promo Codes',
  MANAGE_EVENT_VISIBILITY = 'Manage Event Visibility'
}

const isValidTab = (tab: string): tab is ITab => {
  return Object.values(ITab).includes(tab as ITab);
};

const EventDetailsView = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [activeTab, setActiveTab] = useState<ITab>(ITab.EVENT_OVERVIEW);
  const [loading, setLoading] = useState(true);

  const getAvailableTabs = (): { [key: string]: string } => {
    if (event?.type === 'cene') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { TABLED_AND_GUEST_MANAGEMENT, ...ceneTabsOnly } = ITab;
      return ceneTabsOnly;
    }
    return ITab;
  };

  useEffect(() => {
    if (id) {
      fetchEvent();
    }
  }, [id]);

  const updateNavigationState = () => {
    if (!event || !id) return;

    const navigationState = {
      eventType: event.type
    };

    const currentPath = window.location.pathname;
    let currentTab = ITab.EVENT_OVERVIEW;

    if (currentPath.includes(ROUTE_TICKET_BUYERS)) {
      currentTab = ITab.TICKET_BUYERS;
    } else if (currentPath.includes(ROUTE_TABLES_AND_GUESTS_MANAGEMENT)) {
      currentTab = ITab.TABLED_AND_GUEST_MANAGEMENT;
    } else if (currentPath.includes(ROUTE_ENTRY_MANAGER)) {
      currentTab = ITab.ENTRY_MANAGER;
    } else if (currentPath.includes(ROUTE_REQUESTS)) {
      currentTab = ITab.REQUESTS;
    } else if (currentPath.includes(ROUTE_SALES_AND_TRANSACTIONS)) {
      currentTab = ITab.SALES_AND_TRANSACTIONS;
    } else if (currentPath.includes(ROUTE_GUESTLISTS)) {
      currentTab = ITab.GUEST_LISTS;
    } else if (currentPath.includes(ROUTE_PROMO_CODES)) {
      currentTab = ITab.PROMO_CODES;
    } else if (currentPath.includes(ROUTE_MANAGE_EVENT_VISIBILITY)) {
      currentTab = ITab.MANAGE_EVENT_VISIBILITY;
    }

    setActiveTab(currentTab);

    navigate(currentPath, {
      replace: true,
      state: navigationState
    });
  };

  const onBack = () => {
    navigate(ROUTE_EVENTS, { replace: true });
  };

  const gotoEvent = (id: string) => {
    navigate(ROUTER_EVENT_EDIT.replaceAll(':id', id), { replace: true });
  };

  const handleTabClick = (tabValue: string) => {
    if (!event) return;

    if (!isValidTab(tabValue)) return;
    const tab = tabValue as ITab;

    if (event.type === 'cene' && tab === ITab.TABLED_AND_GUEST_MANAGEMENT) {
      return;
    }

    const navigationState = {
      eventType: event.type
    };

    setActiveTab(tab);
    switch (tab) {
      case ITab.EVENT_OVERVIEW:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_OVERVIEW}`, { replace: true, state: navigationState });
        break;
      case ITab.TICKET_BUYERS:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_TICKET_BUYERS}`, { replace: true, state: navigationState });
        break;
      case ITab.TABLED_AND_GUEST_MANAGEMENT:
        if (event.type !== 'cene') {
          navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_TABLES_AND_GUESTS_MANAGEMENT}`, {
            replace: true,
            state: navigationState
          });
        }
        break;
      case ITab.ENTRY_MANAGER:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_ENTRY_MANAGER}`, { replace: true, state: navigationState });
        break;
      case ITab.REQUESTS:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_REQUESTS}`, { replace: true, state: navigationState });
        break;
      case ITab.SALES_AND_TRANSACTIONS:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_SALES_AND_TRANSACTIONS}`, { replace: true, state: navigationState });
        break;
      case ITab.GUEST_LISTS:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_GUESTLISTS}`, { replace: true, state: navigationState });
        break;
      case ITab.PROMO_CODES:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_PROMO_CODES}`, { replace: true, state: navigationState });
        break;
      case ITab.MANAGE_EVENT_VISIBILITY:
        navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_MANAGE_EVENT_VISIBILITY}`, {
          replace: true,
          state: navigationState
        });
        break;
      default:
        break;
    }
  };

  const fetchEvent = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('events').select('*').eq('id', id).single();
      setEvent(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const publishEvent = async () => {
    try {
      setLoading(true);

      const { error } = await supabase
        .from('events')
        .update({
          status: 'published'
        })
        .eq('id', id);

      if (error) throw error;

      enqueueSnackbar(`The event published successfully.`, {
        variant: 'success',
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        }
      });

      fetchEvent();
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    if (event && id) {
      updateNavigationState();
    }
  }, [event, id]);

  if (loading) return <EventLoading />;

  return (
    <>
      <Stack flexDirection="row" alignItems="center" gap={2} sx={{ position: 'fixed', top: '6px', right: '20px', zIndex: 9999, gap: 2 }}>
        <ShapeButton handleClick={() => gotoEvent(`${id}`)} width="182px" color="black">
          Edit Event
        </ShapeButton>
        {event?.status !== 'published' && (
          <ShapeButton backgroundColor="#a4db6c" bgHover="#81af51" handleClick={publishEvent} width="182px" color="black">
            Publish
          </ShapeButton>
        )}
      </Stack>

      <Grid mb={6}>
        <Grid container>
          <Grid item md={1}>
            <IconButton
              onClick={onBack}
              sx={{
                borderRadius: '12px 0',
                backgroundColor: '#191919 !important',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#333333'
                },
                '&:focus': {
                  backgroundColor: '#555555'
                }
              }}
            >
              <BackSVG style={{ stroke: '#AF96E6' }} />
            </IconButton>
          </Grid>
          <Grid item md={10}>
            <EventDetails event={event} />
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: '#262626', mt: 3, mb: 2 }} />
        {activeTab && event && event.id && (
          <ReusableTabs tabs={getAvailableTabs()} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />
        )}
        <Outlet />
      </Grid>
    </>
  );
};

export default EventDetailsView;
