import { Box, CircularProgress, Grid, LinearProgress, Rating, Stack, Typography } from '@mui/material';
import { supabase } from 'supabase/supabase';
import { useParams } from 'react-router';
import { useEffect, useState} from 'react';
import MainCard from 'components/MainCard';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import FiberNewIcon from '@mui/icons-material/FiberNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ReactComponent as MaleFilledSVG } from '../../../../../../svg/male-filled.svg';
import { ReactComponent as MaleOutlinedSVG } from '../../../../../../svg/male-outlined.svg';
import { ReactComponent as FemaleFilledSVG } from '../../../../../../svg/female-filled.svg';
import { ReactComponent as FemaleOutlinedSVG } from '../../../../../../svg/female-outlined.svg';
import { convertToScaledValues } from 'utils/scaledPercentage';

export interface IGenders {
  Female: number;
  Male: number;
  Unknown: number;
}

const TicketBuyersStatistic =  () => {
  const [genderDistribution, setGenderDistribution] = useState<IGenders>({
    Female: 0,
    Male: 0,
    Unknown: 0
  });
  const { id } = useParams();


  useEffect(() => {
    if (id) {
      fetchStatDistribution()
    }
  }, [id]);

  
  const total = 735 + 225;
  const soldPercentage = (735 / total) * 100;



      const fetchStatDistribution = async () => {
        try {
          const { data: get_event_gender_distribution } = await supabase.rpc('get_event_gender_distribution', {eventid: id});

          const genderDistrIbutionPercentage = await convertToScaledValues(get_event_gender_distribution);
          setGenderDistribution({...genderDistrIbutionPercentage, Unknown: 40});
        } catch (error) {
          console.log(error, 'error')
        }
      };

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>summary</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'center'
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 3, textAlign: 'center' }}>New Vs Returning</Typography>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={100}
                size={184}
                thickness={5}
                sx={{ color: '#B4A2DC', position: 'absolute' }}
              />
              <CircularProgress variant="determinate" value={soldPercentage} size={184} thickness={5} sx={{ color: '#9977E2' }} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  inset: 0
                }}
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>{`${Math.round(soldPercentage)}%`}</Typography>
                <Typography sx={{ fontSize: '12px', color: '#8C8C8C' }}>Returning</Typography>
              </Box>
            </Box>
            <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                    backgroundColor: '#9977E2'
                  }}
                >
                  <CheckCircleIcon style={{ width: '16px', height: '16px' }} />
                </Box>
                <Typography>Returning</Typography>
              </Stack>
              <Typography>735</Typography>
            </Stack>
            <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                    backgroundColor: '#B4A2DC'
                  }}
                >
                  <FiberNewIcon style={{ width: '16px', height: '16px' }} />
                </Box>
                <Typography>New</Typography>
              </Stack>
              <Typography>225</Typography>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <MainCard
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'center'
            }}
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'center', mb: 6 }}>Ticket Buyers by Gender</Typography>
            <Stack sx={{ gap: 6 }}>
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>Male</Typography>
                <Rating value={genderDistribution.Male} max={10} icon={<MaleFilledSVG />} emptyIcon={<MaleOutlinedSVG />} readOnly sx={{ gap: 1 }} />
              </Stack>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>Female</Typography>
                <Rating value={genderDistribution.Female} max={10} icon={<FemaleFilledSVG />} emptyIcon={<FemaleOutlinedSVG />} readOnly sx={{ gap: 1 }} />
              </Stack>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>Unknown</Typography>
                <Rating
                  value={genderDistribution.Unknown}
                  max={10}
                  icon={<PersonIcon style={{ color: '#B4A2DC' }} />}
                  emptyIcon={<PersonOutlineOutlinedIcon sx={{ color: '#fff', strokeWidth: 0.75 }} />}
                  readOnly
                  sx={{ gap: 1 }}
                />
              </Stack>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MainCard
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'center'
            }}
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'center', mb: 6 }}>Ticket Buyers by Age Group</Typography>
            <Stack sx={{ gap: 3 }}>
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>55+</Typography>
                <LinearProgress
                  variant="determinate"
                  value={15}
                  sx={{
                    width: '200px',
                    height: '10px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#9977E2'
                    }
                  }}
                />
              </Stack>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>45-54</Typography>
                <LinearProgress
                  variant="determinate"
                  value={15}
                  sx={{
                    width: '200px',
                    height: '10px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#9977E2'
                    }
                  }}
                />
              </Stack>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>35-44</Typography>
                <LinearProgress
                  variant="determinate"
                  value={25}
                  sx={{
                    width: '200px',
                    height: '10px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#9977E2'
                    }
                  }}
                />
              </Stack>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>25-34</Typography>
                <LinearProgress
                  variant="determinate"
                  value={25}
                  sx={{
                    width: '200px',
                    height: '10px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#9977E2'
                    }
                  }}
                />
              </Stack>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', color: '#8C8C8C' }}>18-24</Typography>
                <LinearProgress
                  variant="determinate"
                  value={20}
                  sx={{
                    width: '200px',
                    height: '10px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#9977E2'
                    }
                  }}
                />
              </Stack>
            </Stack>
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TicketBuyersStatistic;
