import * as Yup from 'yup';
import { emailRule } from './validationRules';

export const loginSchema = Yup.object().shape({
  email: emailRule,
  password:
    Yup.string()
    .required('Password is required')
});

export type LoginSchema = Yup.InferType<typeof loginSchema>;
