import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import { Member, MemberInvite } from 'types/member';

import { DeleteOutlined } from '@ant-design/icons';
import { HeaderCells } from 'components/commonTable/headerCells';
import CircularLoader from 'components/CircularLoader';
import MainCard from 'components/MainCard';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';

export enum MemberInvitesColumnId {
  TEAM_MEMBER_NAME = 'teamMemberName',
  POSITION = 'position',
  EMAIL = 'email',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: MemberInvitesColumnId;
  title: string;
  sortable: boolean;
  cell?: (member: Member) => React.ReactNode;
}

const memberInviteColumns: TableColumn[] = [
  { id: MemberInvitesColumnId.TEAM_MEMBER_NAME, title: 'Team Member Name', sortable: true },
  { id: MemberInvitesColumnId.POSITION, title: 'Position', sortable: true },
  { id: MemberInvitesColumnId.EMAIL, title: 'Email', sortable: true },
  { id: MemberInvitesColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const columnConfig: Record<MemberInvitesColumnId, keyof MemberInvite | ((memberInvite: MemberInvite) => string | number)> = {
  [MemberInvitesColumnId.TEAM_MEMBER_NAME]: (member) => `${member.metadata.first_name} ${member.metadata.last_name}`,
  [MemberInvitesColumnId.POSITION]: (member) => member.metadata.job_title,
  [MemberInvitesColumnId.EMAIL]: (member) => member.email,
  [MemberInvitesColumnId.ACTIONS]: () => ''
};

const getColumnValue = (columnId: MemberInvitesColumnId, memberInvite: MemberInvite): string | number => {
  const config = columnConfig[columnId];
  return typeof config === 'function' ? config(memberInvite) : (memberInvite[config] as string | number);
};

interface MemberInvitesTableRowsProps {
  memberInvites: MemberInvite[];
  onSuccess?: () => void;
}

const MemberInvitesTableRows = ({ memberInvites = [], onSuccess = () => null }: MemberInvitesTableRowsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedInviteId, setSelectedInviteId] = useState<string | null>(null);

  const handleOpenDialog = (inviteId: string) => {
    setSelectedInviteId(inviteId);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedInviteId(null);
  };

  const handleDeleteInvite = async (inviteId: string) => {
    try {
      const { error } = await supabase.from('admin_invitations').delete().eq('id', inviteId);

      if (error) throw error;

      enqueueSnackbar('Invite deleted successfully', { variant: 'success' });
      onSuccess();
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      handleCloseDialog();
    }
  };

  const renderCellContent = (column: TableColumn, memberInvite: MemberInvite) => {
    if (column.id === MemberInvitesColumnId.ACTIONS) {
      return (
        <>
          <IconButton onClick={() => handleOpenDialog(memberInvite.id)}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    }
    return getColumnValue(column.id, memberInvite);
  };

  return (
    <>
      {memberInvites.map((memberInvite) => (
        <TableRow key={memberInvite.id}>
          {memberInviteColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, memberInvite)}</TableCell>
          ))}
        </TableRow>
      ))}

      <ConfirmationDialog
        title="Confirm Deletion"
        description="Are you sure you want to delete this invite?"
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={() => selectedInviteId && handleDeleteInvite(selectedInviteId)}
      />
    </>
  );
};

interface ITableProps {
  memberInvites: MemberInvite[];
  isLoading: boolean;
  onSuccess: () => void;
}

const MemberInvitesTable = ({ isLoading = false, memberInvites = [], onSuccess }: ITableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: MemberInvitesColumnId; direction: 'asc' | 'desc' }>({
    key: MemberInvitesColumnId.TEAM_MEMBER_NAME,
    direction: 'asc'
  });

  const sortedInvites = [...memberInvites].sort((a, b) => {
    const { key, direction } = sortConfig;
    const valueA = getColumnValue(key, a);
    const valueB = getColumnValue(key, b);

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: MemberInvitesColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<MemberInvitesColumnId> columns={memberInviteColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={memberInviteColumns.length}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : memberInvites.length === 0 ? (
            <TableRow>
              <TableCell colSpan={memberInviteColumns.length}>
                <Stack textAlign="center">No Members yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <MemberInvitesTableRows memberInvites={sortedInvites} onSuccess={onSuccess} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default MemberInvitesTable;
