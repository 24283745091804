import { Button, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface IButtonProps {
  children: ReactNode;
  handleClick?: () => void;
  isOutlined?: boolean;
  width: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  bgHover?: string;
  fontSize?: string;
  lineHeight?: string;
  sx?: SxProps<Theme>;
}

const ShapeButton = ({
  children,
  type = 'button',
  handleClick = () => null,
  isOutlined = false,
  width,
  disabled = false,
  color = '#000',
  backgroundColor = '#8057DB',
  borderColor = '#8057DB',
  bgHover = '#8157dbab',
  fontSize = '16px',
  lineHeight = '24px',
  sx = {}
}: IButtonProps) => {
  return (
    <Button
      type={type}
      variant={isOutlined ? 'outlined' : 'contained'}
      onClick={handleClick}
      disabled={disabled}
      sx={{
        width,
        paddingBlock: '12px',
        paddingInline: 0,
        borderRadius: '20px 0',
        borderColor: isOutlined ? borderColor : 'transparent',
        fontSize,
        lineHeight,
        fontWeight: '500',
        backgroundColor: isOutlined ? 'transparent' : backgroundColor,
        color: isOutlined ? '#fff' : color,
        '&:hover': {
          backgroundColor: isOutlined ? '#121212' : bgHover,
          color: isOutlined ? '#fff' : color,
          borderColor: isOutlined ? '#8157dbab' : 'transparent'
        },
        '&:disabled': {
          backgroundColor: '#191919',
          color: '#8057DB',
          borderColor: isOutlined ? '#8057DB' : 'transparent',
          cursor: 'not-allowed',
          '&:hover': {
            backgroundColor: '#191919',
            borderColor: isOutlined ? '#8057DB' : 'transparent'
          }
        },
        ...sx
      }}
    >
      {children}
    </Button>
  );
};

export default ShapeButton;
