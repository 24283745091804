import { isValidNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const emailRule = Yup.string()
  .email('E-mail is not valid')
  .required('E-mail is required')
  .matches(
    /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Invalid e-mail format. Please enter a valid e-mail address.'
  );

export const phoneNumberRule = Yup.string()
  .transform((value) => (value && !value.startsWith('+') ? `+${value}` : value))
  .test('is-valid-phone', 'Phone number is invalid', (_, context) => {
    const phoneNumber = context.parent.phone_number;
    if (!phoneNumber) return false;

    return isValidNumber(phoneNumber as string);
  })
  .required('Phone number is required');

export const uppercaseRule = Yup.string().matches(/[A-Z]/, 'Password must contain at least one uppercase letter');

export const lowercaseRule = Yup.string().matches(/[a-z]/, 'Password must contain at least one lowercase letter');

export const numberRule = Yup.string().matches(/\d/, 'Password must contain at least one number');

export const specialCharacterRule = Yup.string().matches(/[!@#$%^&*()_+]/, 'Password must contain at least one special character');

export const passwordRule = Yup.string()
  .concat(uppercaseRule)
  .concat(lowercaseRule)
  .concat(numberRule)
  .concat(specialCharacterRule)
  .min(8, 'Password must be at least 8 characters long')
  .max(16, 'Password cannot exceed 16 characters')
  .required('Password is required');
