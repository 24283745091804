import { Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import ShapeButton from 'components/button/button';
import { useSupabase } from 'contexts/supabase';
import { useNavigate } from 'react-router';
import { ROUTE_DASHBOARD } from 'constants/routes';

const SplashPage = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(ROUTE_DASHBOARD, { replace: true });
  };
  return (
    <Grid container sx={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
      <img
        src="/pictures/onboarding-bg.png"
        alt="onboarding background"
        style={{ position: 'absolute', left: 0, right: 0, width: '100%', height: '100%', zIndex: -2 }}
      />

      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          background: `
          linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 100%),
          linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%),
          linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000 100%)
        `
        }}
      ></div>
      <Grid sx={{ position: 'absolute', left: '64px', top: '32px' }}>
        <LogoSVG style={{ fill: '#fff', width: '164px', height: '48px' }} />
      </Grid>
      <Grid container sx={{ width: '460px', alignItems: 'center', textAlign: 'center', flexDirection: 'column', gap: '36px' }}>
        <Stack sx={{ gap: '16px' }}>
          <Typography sx={{ fontSize: '48px', lineHeight: '64px', fontWeight: 700, color: '#8057DB' }}>ALL SET!</Typography>
          <Typography sx={{ fontSize: '18px', fontWeight: 300, color: '#fff' }}>
            You're all set, {session?.user.user_metadata.first_name} {session?.user.user_metadata.last_name}! You’re ready to innovate,
            personalise, and elevate your events on Cene.
          </Typography>
          <Typography sx={{ fontSize: '18px', fontWeight: 300, color: '#fff' }}>
            Dive into creating your first event or explore our resources for more inspiration.
          </Typography>
        </Stack>
        <Stack width={336} sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <ShapeButton width="160px" handleClick={handleClick}>
            Go to Dashboard
          </ShapeButton>

          <ShapeButton width="160px" handleClick={handleClick} isOutlined>
            Explore Resources
          </ShapeButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SplashPage;
