import { Autocomplete, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { Partner } from 'types/partner';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { supabase } from 'supabase/supabase';
import { useParams } from 'react-router';
import { PostgrestError } from '@supabase/supabase-js';
import assignFormSchema from 'schemas/assignFormSchema';

interface AssignAfterCreateFormProps {
  onSubmit: (values: { partner: Partner; floorplan: string }, error: PostgrestError | null) => void;
}

const AssignAfterCreateForm = ({ onSubmit }: AssignAfterCreateFormProps) => {
  const { floorplanId } = useParams<{ floorplanId: string }>();
  const [, setLoading] = useState(false);
  const [floorplan, setFloorplan] = useState<{ id: string; name: string } | null>(null);
  const [partners, setPartners] = useState<Partner[]>([]);

  const formik = useFormik({
    initialValues: { partner: {} as Partner, floorplan: '' },
    validationSchema: assignFormSchema,
    onSubmit: async (values) => {
      if (!formik.isValid) {
        // Якщо форма не є валідною, не відправляємо
        return;
      }
      try {
        const { error } = await supabase
          .from('partner_floorplans')
          .insert({ partner_id: values.partner.id, floorplan_id: values.floorplan });

        onSubmit({ partner: values.partner, floorplan: floorplan?.name || '' }, error);
      } catch (error) {
        enqueueSnackbar('Something went wrong. Try again later.', { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (floorplanId) {
      fetchFloorplanData(floorplanId);
    }
    fetchPartners();
  }, [floorplanId]);

  const fetchFloorplanData = async (floorplanId: string) => {
    try {
      const { data, error } = await supabase.from('floorplans').select('*').eq('id', floorplanId).single();

      if (error) {
        console.error(error);
        enqueueSnackbar('Error fetching floorplan data', { variant: 'error' });
      } else {
        setFloorplan({ id: data.id, name: data.name });
        formik.setFieldValue('floorplan', data.id);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong while fetching floorplan', { variant: 'error' });
    }
  };

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('partners').select('*').is('deleted_at', null);
      setPartners(data as Partner[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSelect = (event: React.SyntheticEvent<Element, Event>, value: string | Partner | null) => {
    if (value === null) {
      formik.setFieldValue('partner', {});
    } else if (typeof value === 'object') {
      formik.setFieldValue('partner', value);
    }
  };

  const handleFloorplanSelect = (event: React.SyntheticEvent<Element, Event>, value: { id: string; name: string } | null) => {
    if (value) {
      formik.setFieldValue('floorplan', value.id);
    } else {
      formik.setFieldValue('floorplan', '');
    }
  };

  const { touched, errors, getFieldProps } = formik;

  return (
    <Grid sx={{ maxWidth: '688px', textAlign: 'center', mt: 4, mx: 'auto' }}>
      <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#8057DB' }}>You’re Almost Done!</Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: 300 }}>Please review the following information before proceeding</Typography>
      <Grid container spacing={3} sx={{ maxWidth: '540px', my: 4, mx: 'auto', textAlign: 'start' }}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel sx={{ color: '#BFBFBF' }}>Floor Plan Name/Title</InputLabel>
            <Autocomplete
              id="floorplan"
              options={floorplan ? [floorplan] : []}
              getOptionLabel={(option) => option?.name || ''}
              onChange={handleFloorplanSelect}
              value={floorplan}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Start typing the name of a floorplan and press enter"
                  {...getFieldProps('floorplan')}
                  error={Boolean(touched.floorplan && errors.floorplan)}
                  helperText={touched.floorplan && errors.floorplan}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel sx={{ color: '#BFBFBF' }}>Assigned to Partner</InputLabel>
            <Autocomplete
              id="partner"
              options={partners}
              value={formik.values.partner || null}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.company_name || '')}
              onChange={handleEmailSelect}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Add partner"
                  {...getFieldProps('partner')}
                  error={Boolean(touched.partner && errors.partner)}
                  helperText={touched.partner && errors.partner ? (errors.partner as { company_name: string }).company_name : ''}
                />
              )}
            />
          </Stack>
        </Grid>

        <Grid item sm={12}>
          <Typography>
            This action is final and cannot be reversed. Once you confirm, this floor plan will be permanently assigned to the user and, and
            any unconfirmed changes will be lost.
          </Typography>
        </Grid>
      </Grid>

      <Stack sx={{ alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        <ShapeButton width="464px" handleClick={formik.handleSubmit} disabled={!formik.isValid}>
          Confirm Action
        </ShapeButton>
        <ShapeButton width="464px" backgroundColor="#1E1E1E" bgHover="#1E1E1E" color="#fff">
          Save to Drafts
        </ShapeButton>
      </Stack>
    </Grid>
  );
};

export default AssignAfterCreateForm;
