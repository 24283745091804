import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface ITextButton {
  handleClick: () => void;
  icon: ReactNode;
  label: string;
}

const TextButton = ({ handleClick = () => null, icon, label }: ITextButton) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontFamily: 'Roobert, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        color: '#91DBBF',
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      {label}
      {icon}
    </Typography>
  );
};

export default TextButton;
