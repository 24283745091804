import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';

import { useFormik } from 'formik';
import { newGuestSchema } from 'schemas/newGuestSchema';

interface FormProps {
  modalToggler?: () => void;
}

const NewGuestForm = ({ modalToggler }: FormProps) => {
  const formik = useFormik({
    initialValues: {
      guestName: '',
      guestEmail: '',
      phone_number: '',
      ticketStatus: ''
    },
    validationSchema: newGuestSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const { isSubmitting, handleChange, getFieldProps, touched, errors } = formik;

  return (
    <Grid>
      <DialogTitle>Add New Guest to Ticket Type</DialogTitle>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="guestName">Guest Name</InputLabel>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
                fullWidth
                id="guestName"
                placeholder="Enter full name..."
                {...getFieldProps('guestName')}
                error={Boolean(touched.guestName && errors.guestName)}
                helperText={touched.guestName && errors.guestName}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="guestEmail">Guest E-mail</InputLabel>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
                fullWidth
                id="guestEmail"
                placeholder="Enter guest e-mail..."
                {...getFieldProps('guestEmail')}
                error={Boolean(touched.guestEmail && errors.guestEmail)}
                helperText={touched.guestEmail && errors.guestEmail}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="phone_number">Guest Phone Number</InputLabel>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
                fullWidth
                id="phone_number"
                placeholder="Enter phone number..."
                {...getFieldProps('phone_number')}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="demo-simple-select">Ticket Status</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Pending"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogActions sx={{ paddingBlock: 2.5 }}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={modalToggler}
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#434343',
                '&:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                  borderColor: '#434343'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              onClick={modalToggler}
              sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Update Details
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default NewGuestForm;
