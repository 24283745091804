import MainCard from 'components/MainCard';
import { useOutletContext } from 'react-router';
import PartnerForm from '../partner.form';
import { PartnerProfile } from 'types/partnerProfile';

const PartnerInformation = () => {
  const { partner } = useOutletContext<{ partner: PartnerProfile | null }>();
  return (
    <MainCard title="Partner Information">
      <PartnerForm currentObject={partner} />
    </MainCard>
  );
};

export default PartnerInformation;
