import { CoffeeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import ReusableModal from 'components/modal/modal';
import { useModalContext } from 'contexts/ReusableModalContext';
import AssignPartnerForm from './forms/assignPartner';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import AssignPartnerTable from './partnerAssign.table';

export interface Row {
  id: string;
  scene: string;
  partner: string;
  floorplanName: string;
  floorplanId: string;
  createdAt: string;
  createdBy: string;
  usedFor: string;
}

const PartnersSection = () => {
  const { id } = useParams();
  const { open, modalToggler } = useModalContext();
  const [rows, setRows] = useState<Row[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchPartnerAssigment();
  }, [id]);

  const fetchPartnerAssigment = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('partner_floorplans')
        .select('*, partners(*), floorplans(*)')
        .eq('floorplans.venue_id', id);

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        const formattedData = data.map((item, index) => ({
          id: item.id as string,
          scene: (item.floorplans?.name as string) || 'Unknown',
          partner: (item.partners?.company_name as string) || 'Unknown',
          floorplanName: (item.floorplans?.name as string) || 'Unknown',
          floorplanId: (item.floorplans?.id as string) || 'Unknown',
          createdAt: item.created_at as string,
          createdBy: (item.created_by as string) || 'Unknown',
          usedFor: (item.used_for as string) || 'Unknown'
        }));
        setRows(formattedData);
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) <CircularProgress />;

  return (
    <Stack sx={{ mt: 3 }}>
      {rows.length < 1 ? (
        <Grid container sx={{ height: 'calc(100vh - 200px)', alignItems: 'center', justifyContent: 'center' }}>
          <Stack sx={{ width: '460px', alignItems: 'center' }}>
            <div
              style={{
                margin: 'auto',
                padding: '20px',
                borderRadius: '50%',
                backgroundColor: '#8057DB',
                width: '76px',
                height: '76px',
                border: '1px solid #434343'
              }}
            >
              <CoffeeOutlined
                style={{
                  fontSize: '36px',
                  height: '36px'
                }}
              />
            </div>
            <Typography sx={{ fontSize: '22px', fontWeight: 300, color: '#8057DB', mt: 3 }}>
              No Partners have been assigned to this venue
            </Typography>
            <Typography sx={{ fontSize: '15px', mt: 2, width: '340px', textAlign: 'center' }}>
              Add a Verified Partner to this venue to proceed
            </Typography>
            <Button
              onClick={() => modalToggler(null)}
              variant="contained"
              startIcon={<PlusOutlined />}
              sx={{ mt: 4, backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Add Partner to Venue
            </Button>
          </Stack>
          <ReusableModal
            width="600px"
            open={open}
            modalToggler={() => modalToggler(null)}
            content={AssignPartnerForm}
            currentObject={null}
          />
        </Grid>
      ) : (
        <AssignPartnerTable rows={rows} onSuccess={fetchPartnerAssigment} venueId={id} />
      )}
    </Stack>
  );
};

export default PartnersSection;
