import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface ImageCropperProps {
  crop: Crop | undefined;
  src: string | undefined;
  onCropComplete: (croppedImage: Crop | undefined) => void;
  onClose: () => void;
  aspect?: number;
}

const ImageCropper = ({ crop, src, onCropComplete, onClose, aspect = 1 }: ImageCropperProps) => {
  const [_crop, setCrop] = useState<Crop | undefined>(crop);
  const [completedCrop, setCompletedCrop] = useState<Crop | undefined>();

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="center">
          <ReactCrop
            aspect={aspect}
            crop={_crop}
            ruleOfThirds
            onComplete={(_, crop) => setCompletedCrop(crop)}
            onChange={(c) => setCrop(c)}
          >
            <img alt="CropedPicture" src={src} style={{ width: '100%', display: 'block' }} />
          </ReactCrop>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onCropComplete(completedCrop)} color="primary">
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropper;
