import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useGetMenuMaster } from 'api/menu';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { supabase } from 'supabase/supabase';
import { useSupabase } from 'contexts/supabase';
import { useEffect, useState } from 'react';

const NavUser = () => {
  const [id, setId] = useState('');

  const theme = useTheme();
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  const { session, role } = useSupabase();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPartner();
  }, [session?.user.id]);

  const fetchPartner = async () => {
    const { data } = await supabase.from('partner_users').select('*').eq('user_id', session?.user.id).single();
    setId(data?.partner_id as string);
  };
  const handleProfileOpen = () => {
    navigate(`/profile/${id}`, { replace: false });
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();

      navigate(`/`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#000', p: 1.25, px: !drawerOpen ? 1.25 : 3, borderTop: `2px solid ${theme.palette.divider}` }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        {drawerOpen && (
          <Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 300, color: 'rgba(255, 255, 255, 0.87)' }}>
              {session?.user.user_metadata.first_name} {session?.user.user_metadata.last_name}
            </Typography>

            <Typography sx={{ fontSize: '12px', fontWeight: 300, color: 'rgba(255, 255, 255, 0.45)' }}>
              {session?.user.user_metadata.job_title}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 300, color: 'rgba(255, 255, 255, 0.87)' }}>{session?.user?.email}</Typography>
          </Grid>
        )}
        <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
          {role === 'Partner' && (
            <IconButton onClick={handleProfileOpen}>
              <UserOutlined />
            </IconButton>
          )}
          <IconButton onClick={handleLogout}>
            <LogoutOutlined />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavUser;
