import { CalendarOutlined } from '@ant-design/icons';
import { ROUTE_VENUES_AND_FLOORPLANS } from 'constants/routes';
// import { ROUTE_BULK_REQUESTS } from 'constants/routes';
import { NavItemType } from 'types/menu';

const events: NavItemType = {
  id: 'group-events-loading',
  type: 'group',
  children: [
    {
      id: 'group-events',
      title: 'Events',
      type: 'collapse',
      icon: CalendarOutlined,
      children: [
        // {
        //   permissions: ['*', 'events:c', 'events:r', 'events:u', 'events:d'],
        //   id: 'bulk-requests',
        //   title: 'Event Requests',
        //   type: 'item',
        //   url: ROUTE_BULK_REQUESTS
        // },
        {
          permissions: ['*', 'events:c', 'events:r', 'events:u', 'events:d'],
          id: 'events',
          title: 'Cene Events',
          type: 'item',
          url: '/events'
        },
        {
          permissions: ['*', 'floorplans:c', 'floorplans:u', 'floorplans:d'],
          id: 'venues-and-floorplans',
          title: 'Venues & Floorplans',
          type: 'item',
          url: ROUTE_VENUES_AND_FLOORPLANS,
          breadcrumbs: false
        },
        {
          permissions: ['*', 'floorplans:c', 'floorplans:u', 'floorplans:d'],
          id: 'floorplan-requests',
          title: 'Floorplan Requests',
          type: 'item',
          url: '/floorplan-requests',
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default events;
