import { Button, Grid, List, ListItem, ListItemText, TableCell, TableRow, Typography } from '@mui/material';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import { usePermission } from 'hooks/usePermission';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { supabase } from 'supabase/supabase';
import { CenePlusRequest } from 'types/user';

interface IRequestProps {
  request: CenePlusRequest;
  onSuccess: () => void;
}

enum IDialogType {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  DELETE = 'DELETE'
}

const RequestDetails = ({ request, onSuccess = () => null }: IRequestProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<IDialogType | null>(null);
  const { permissions, isSuperAdmin } = usePermission();

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
  };

  const handleConfirm = async () => {
    if (dialogType === IDialogType.DELETE) {
      const { error } = await supabase.from('cene_plus_requests').delete().eq('id', request.id);
      if (error) return enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      enqueueSnackbar('Cene+ request deleted successfully', { variant: 'success' });
    } else if (dialogType === IDialogType.APPROVE) {
      const { error } = await supabase.from('cene_plus_requests').update({ status: 'approved' }).eq('id', request.id);
      if (error) return enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      enqueueSnackbar('Cene+ request approved successfully', { variant: 'success' });
    } else if (dialogType === IDialogType.REJECT) {
      const { error } = await supabase.from('cene_plus_requests').update({ status: 'rejected' }).eq('id', request.id);
      if (error) return enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      enqueueSnackbar('Cene+ request rejected successfully', { variant: 'success' });
    }

    handleCloseDialog();
    onSuccess();
  };
  const RenderActions = () => {
    const handleOpenDialog = (type: IDialogType) => {
      setDialogType(type);
      setDialogOpen(true);
    };

    if (request.status === 'new') {
      return (
        <>
          {(permissions.includes('ceneplus_requests:u') || isSuperAdmin) && (
            <>
              <Button
                variant="contained"
                onClick={() => handleOpenDialog(IDialogType.APPROVE)}
                sx={{ mr: 2, fontWeight: 400, backgroundColor: '#91DCBE', color: '#000', '&:hover': { backgroundColor: '#91dcbec9' } }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                onClick={() => handleOpenDialog(IDialogType.REJECT)}
                sx={{ mr: 2, fontWeight: 400, backgroundColor: '#DB4848', color: '#fff', '&:hover': { backgroundColor: '#db4848d3' } }}
              >
                Reject
              </Button>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {(permissions.includes('ceneplus_requests:d') || isSuperAdmin) && (
            <Button variant="contained" color="error" onClick={() => handleOpenDialog(IDialogType.DELETE)}>
              Delete
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={7}>
          <List sx={{ py: 0 }}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item sm={5.9}>
                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Contact email:</ListItemText>
                  <Typography>{request.email}</Typography>
                </ListItem>
                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Contact phone number:</ListItemText>
                  <Typography>{request.phone_number}</Typography>
                </ListItem>
                <ListItem>
                  <ListItemText sx={{ fontWeight: 700, letterSpacing: 1 }}>Job title:</ListItemText>
                  <Typography>{request.job_title}</Typography>
                </ListItem>
              </Grid>
              <Grid item sm={5.9}>
                <ListItem>
                  <RenderActions />
                </ListItem>
              </Grid>
            </Grid>
          </List>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={dialogOpen}
        title={
          dialogType === IDialogType.DELETE
            ? 'Confirm Deletion'
            : dialogType === IDialogType.APPROVE
            ? 'Confirm Approval'
            : 'Confirm Rejection'
        }
        description={
          dialogType === IDialogType.DELETE
            ? 'Are you sure you want to delete this Cene+ request? This action cannot be undone.'
            : dialogType === IDialogType.APPROVE
            ? 'Are you sure you want to approve this Cene+ request?'
            : 'Are you sure you want to reject this Cene+ request?'
        }
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default RequestDetails;
