import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import ReusableModal from 'components/modal/modal';
import { DebouncedInput } from 'components/third-party/react-table';
import { useState } from 'react';
import PromocodeForm from './promocode.form';
import { useModalContext } from 'contexts/ReusableModalContext';

export interface ICoupon {
  name: string;
  code: string;
  valid_from: string;
  valid_till: string;
  usage: string;
  created_on: string;
  id: string;
}

export enum CouponColumnId {
  COUPON_NAME = 'couponName',
  COUPON_CODE = 'couponCode',
  VALID_FROM_AND_TILL = 'validFromAndTill',
  USAGE = 'usage',
  CREATED_ON = 'createOn',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: CouponColumnId;
  title: string;
  sortable: boolean;
  cell?: (coupon: ICoupon) => React.ReactNode;
}

const couponColumns: TableColumn[] = [
  { id: CouponColumnId.COUPON_NAME, title: 'Coupon Name', sortable: true },
  { id: CouponColumnId.COUPON_CODE, title: 'Coupon Code', sortable: true },
  { id: CouponColumnId.VALID_FROM_AND_TILL, title: 'Valid from - till', sortable: true },
  { id: CouponColumnId.USAGE, title: 'Usage', sortable: true },
  { id: CouponColumnId.CREATED_ON, title: 'Create on', sortable: true },

  { id: CouponColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const columnConfig: Record<CouponColumnId, keyof ICoupon | ((coupon: ICoupon) => string | number)> = {
  [CouponColumnId.COUPON_NAME]: (coupon) => coupon.name,
  [CouponColumnId.COUPON_CODE]: (coupon) => coupon.name,
  [CouponColumnId.VALID_FROM_AND_TILL]: (coupon) => `${coupon.valid_from} - ${coupon.valid_till}`,
  [CouponColumnId.USAGE]: (coupon) => coupon.usage,
  [CouponColumnId.CREATED_ON]: (coupon) => coupon.created_on,
  [CouponColumnId.ACTIONS]: () => ''
};

const getColumnValue = (columnId: CouponColumnId, coupon: ICoupon): string | number => {
  const config = columnConfig[columnId];
  return typeof config === 'function' ? config(coupon) : (coupon[config] as string | number);
};

interface CouponTableRowsProps {
  coupons: ICoupon[];
  onSuccess?: () => void;
}

const CouponTableRows = ({ coupons = [], onSuccess = () => null }: CouponTableRowsProps) => {
  const renderCellContent = (column: TableColumn, coupon: ICoupon) => {
    if (column.id === CouponColumnId.ACTIONS) {
      return (
        <>
          <Tooltip title="View Promo Code">
            <IconButton>
              <EyeInvisibleOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Update Promo Code">
            <IconButton>
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Promo Code">
            <IconButton>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </>
      );
    }
    return getColumnValue(column.id, coupon);
  };

  return (
    <>
      {coupons.map((coupon) => (
        <TableRow key={coupon.id}>
          {couponColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, coupon)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface CouponsTableProps {
  isLoading?: boolean;
  coupons?: ICoupon[];
  onSuccess?: () => void;
}

const PromocodeTable = ({ onSuccess = () => null, isLoading = false, coupons = [] }: CouponsTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: CouponColumnId; direction: 'asc' | 'desc' }>({
    key: CouponColumnId.COUPON_NAME,
    direction: 'asc'
  });
  const { open, modalToggler } = useModalContext();

  const filterCoupons = coupons.filter((partner) =>
    couponColumns.some((column) => {
      const value = getColumnValue(column.id, partner);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedCoupons = [...filterCoupons].sort((a, b) => {
    const { key, direction } = sortConfig;
    const valueA = getColumnValue(key, a);
    const valueB = getColumnValue(key, b);

    const isDateField = ['valid_from', 'valid_till', 'created_on'].includes(key);

    if (isDateField) {
      const dateA = new Date(valueA as string);
      const dateB = new Date(valueB as string);

      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: CouponColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>All coupon</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search coupon..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <PlusOutlined />
          Create a New Coupon
        </Button>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<CouponColumnId> columns={couponColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={couponColumns.length}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : coupons.length === 0 ? (
              <TableRow>
                <TableCell colSpan={couponColumns.length}>
                  <Stack textAlign="center">No Coupons yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                <CouponTableRows onSuccess={onSuccess} coupons={sortedCoupons} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={PromocodeForm} width="600px" />
    </>
  );
};

export default PromocodeTable;
