import { useEffect, useState } from 'react';
import { ReactComponent as EventsSVG } from 'svg/events.svg';
import { ReactComponent as TicketSVG } from 'svg/tickets.svg';
import { ReactComponent as UncomingEventsSVG } from 'svg/uncoming-events.svg';
import { ReactComponent as TicketsSoldSVG } from 'svg/tickets-sold.svg';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import MainCard from 'components/MainCard';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import IncomeAreaChart from 'sections/dashboard/default/IncomeAreaChart';
import MonthlyBarChart from 'sections/dashboard/default/MonthlyBarChart';
import { useLocation, useNavigate } from 'react-router';
import { useSupabase } from 'contexts/supabase';
import { ROUTE_WELCOME } from 'constants/routes';
import usePartnerId from 'hooks/usePartnerId';
import { supabase } from 'supabase/supabase';

const DashboardDefault = () => {
  const [slot, setSlot] = useState('week');
  const [isOnboardingFinished, setOnboardingFinished] = useState<boolean | null>(null);
  const { role, session } = useSupabase();
  const location = useLocation();
  const navigate = useNavigate();

  const partnerId = usePartnerId();

  const fromLogin = location.state?.fromLogin || false;

  useEffect(() => {
    if (fromLogin && partnerId && role === 'Partner') {
      fetchPartner();
    }
  }, [fromLogin, role, partnerId]);

  useEffect(() => {
    if (session && fromLogin && role === 'Partner' && isOnboardingFinished === false) {
      navigate(ROUTE_WELCOME);
    }
  }, [fromLogin, role, session, isOnboardingFinished]);

  const fetchPartner = async () => {
    const { data } = await supabase.from('partners').select('*').eq('id', partnerId);

    if (data && data.length > 0 && data[0].is_onboarding_finished !== null) {
      setOnboardingFinished(data[0].is_onboarding_finished as boolean);
    }
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce icon={<EventsSVG />} title="No. events published" count="4,42,236" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce icon={<TicketSVG />} title="No. of tickets sold" count="78,250" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce icon={<UncomingEventsSVG />} title="No. of upcoming events." count="18,800" isLoss color="warning" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce icon={<TicketsSoldSVG />} title="Value of Tickets sold." count="$35,078" isLoss color="warning" />
      </Grid>

      <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Unique Visitor</Typography>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Button
                size="small"
                onClick={() => setSlot('month')}
                color={slot === 'month' ? 'primary' : 'secondary'}
                variant={slot === 'month' ? 'outlined' : 'text'}
              >
                Month
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('week')}
                color={slot === 'week' ? 'primary' : 'secondary'}
                variant={slot === 'week' ? 'outlined' : 'text'}
              >
                Week
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <MainCard content={false} sx={{ mt: 1.5 }}>
          <Box sx={{ pt: 1, pr: 2 }}>
            <IncomeAreaChart slot={slot} />
          </Box>
        </MainCard>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Income Overview</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <Box sx={{ p: 3, pb: 0 }}>
            <Stack spacing={2}>
              <Typography variant="h6" color="textSecondary">
                This Week Statistics
              </Typography>
              <Typography variant="h3">$7,650</Typography>
            </Stack>
          </Box>
          <MonthlyBarChart />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DashboardDefault;
