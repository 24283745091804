import { Avatar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { useState } from 'react';

export interface IAsset {
  avatar: string;
  tag: string;
  platform: string;
  affinityGroup: string;
  lastUpdated: string;
  id: string;
}

export enum AssetColumnId {
  AVATAR = 'avatar',
  TAG = 'tag',
  PLATFORM = 'platform',
  AFFINITY_GROUP = 'affinityGroup',
  LAST_UPDATED = 'lastUpdated'
}

interface TableColumn {
  id: AssetColumnId;
  title: string;
  sortable: boolean;
  cell?: (asset: IAsset) => React.ReactNode;
}

const assetColumns: TableColumn[] = [
  { id: AssetColumnId.AVATAR, title: '', sortable: true, cell: (asset) => asset.avatar },
  { id: AssetColumnId.TAG, title: 'Tag', sortable: true, cell: (asset) => asset.tag },
  { id: AssetColumnId.PLATFORM, title: 'Platform', sortable: true, cell: (asset) => asset.platform },
  { id: AssetColumnId.AFFINITY_GROUP, title: 'Affinity group', sortable: true, cell: (asset) => asset.affinityGroup },
  { id: AssetColumnId.LAST_UPDATED, title: 'Last updated', sortable: true, cell: (asset) => asset.lastUpdated }
];

const getColumnValue = (column: TableColumn, asset: IAsset): React.ReactNode => {
  return column.cell ? column.cell(asset) : '';
};

interface AssetRowsProps {
  assets: IAsset[];
  onSuccess?: () => void;
}

const AssetRows = ({ assets = [], onSuccess = () => null }: AssetRowsProps) => {
  const renderCellContent = (column: TableColumn, asset: IAsset) => {
    if (column.id === AssetColumnId.AVATAR) {
      return (
        <>
          <Avatar
            alt="Customer avatar"
            variant="square"
            sx={{
              width: 40,
              height: 40,
              border: '1px solid #424242a4',
              backgroundColor: '#1818188f',
              color: '#3b3b3b',
              borderRadius: '20px 0'
            }}
          />
        </>
      );
    }
    return getColumnValue(column, asset);
  };

  return (
    <>
      {assets.map((asset) => (
        <TableRow key={asset.id}>
          {assetColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, asset)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface AssetsTableProps {
  isLoading?: boolean;
  assets?: IAsset[];
  onSuccess?: () => void;
}

const AssetsTable = ({ onSuccess = () => null, isLoading = false, assets = [] }: AssetsTableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: AssetColumnId; direction: 'asc' | 'desc' }>({
    key: AssetColumnId.TAG,
    direction: 'asc'
  });

  const sortedAssets = [...assets].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = assetColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: AssetColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}></Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<AssetColumnId> columns={assetColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={assetColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : assets.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={assetColumns.length}>
                    <Stack textAlign="center">No assets yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <AssetRows onSuccess={onSuccess} assets={sortedAssets} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
    </>
  );
};

export default AssetsTable;
