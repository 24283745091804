import { useEffect, useState } from 'react';
import { Event } from '@ceneteam/namespace';
import { supabase } from 'supabase/supabase';
import { useNavigate, useParams } from 'react-router';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as BackSVG } from '../../../../../../../svg/arrow-left.svg';
import ShapeButton from 'components/button/button';
import { ROUTE_ENTRY_MANAGER, ROUTE_EVENT } from 'constants/routes';
import ManageTeamTable, { IMember } from './table';
import ReusableModal from 'components/modal/modal';
import DoorStaffForm from './form';

const members: IMember[] = [
  {
    id: '1',
    name: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    phoneNumber: '+1234567890',
    assignedTo: 'VIP',
    invitedBy: 'John Doe',
    acceptedInviteStart: '26-09-2024 | 12:24pm',
    acceptedInviteEnd: '26-09-2024 | 12:45pm',
    scannerType: 'Cene Scanner'
  },
  {
    id: '2',
    name: 'Bob Smith',
    email: 'bob.smith@example.com',
    phoneNumber: '+1234567891',
    assignedTo: 'General Admission',
    invitedBy: 'Jane Doe',
    acceptedInviteStart: '26-09-2024 | 1:15pm',
    acceptedInviteEnd: '26-09-2024 | 1:45pm',
    scannerType: 'Phone'
  },
  {
    id: '3',
    name: 'Carol Lee',
    email: 'carol.lee@example.com',
    phoneNumber: '+1234567892',
    assignedTo: 'VIP',
    invitedBy: 'Alice Johnson',
    acceptedInviteStart: '26-09-2024 | 3:05pm',
    acceptedInviteEnd: '26-09-2024 | 3:20pm',
    scannerType: 'Cene Scanner'
  },
  {
    id: '4',
    name: 'David Brown',
    email: 'david.brown@example.com',
    phoneNumber: '+1234567893',
    assignedTo: 'Standard',
    invitedBy: 'John Doe',
    acceptedInviteStart: '26-09-2024 | 4:10pm',
    acceptedInviteEnd: '26-09-2024 | 4:35pm',
    scannerType: 'Phone'
  },
  {
    id: '5',
    name: 'Eva White',
    email: 'eva.white@example.com',
    phoneNumber: '+1234567894',
    assignedTo: 'VIP',
    invitedBy: 'Jane Doe',
    acceptedInviteStart: '26-09-2024 | 5:00pm',
    acceptedInviteEnd: '26-09-2024 | 5:15pm',
    scannerType: 'Cene Scanner'
  },
  {
    id: '6',
    name: 'Frank Wilson',
    email: 'frank.wilson@example.com',
    phoneNumber: '+1234567895',
    assignedTo: 'VIP',
    invitedBy: 'Carol Lee',
    acceptedInviteStart: '26-09-2024 | 6:30pm',
    acceptedInviteEnd: '26-09-2024 | 6:45pm',
    scannerType: 'Phone'
  }
];

const ManageTeamPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) fetchEvent();
  }, [id]);

  const fetchEvent = async () => {
    const { data } = await supabase.from('events').select('*').eq('id', id).single();
    setEvent(data);
  };

  const onBack = () => {
    navigate(`${ROUTE_EVENT.replace(':id', id!)}${ROUTE_ENTRY_MANAGER}`, { replace: true });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Grid sx={{ position: 'relative' }}>
        <Stack sx={{ position: 'absolute', top: 3 }}>
          <IconButton
            onClick={onBack}
            sx={{
              borderRadius: '12px 0',
              backgroundColor: '#191919 !important',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#333333'
              },
              '&:focus': {
                backgroundColor: '#555555'
              }
            }}
          >
            <BackSVG style={{ stroke: '#AF96E6' }} />
          </IconButton>
        </Stack>
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#8057DB' }}>Check-In Team for {event?.name}</Typography>
          <Typography>Use this section to add members to your door team for this event.</Typography>
          <Stack sx={{ mt: 3.5 }}>
            <ShapeButton width="212px" backgroundColor="#B6A5DC" handleClick={handleToggle}>
              Invite New Door Staff
            </ShapeButton>
          </Stack>
        </Stack>
      </Grid>
      <ManageTeamTable members={members} />

      <ReusableModal currentObject={null} open={open} modalToggler={handleToggle} content={DoorStaffForm} width="488px" />
    </>
  );
};

export default ManageTeamPage;
