import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSupabase } from 'contexts/supabase';

const RootLayout = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!session) {
      navigate('/', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
  }, [session, navigate, location]);

  return <Outlet />;
};

export default RootLayout;
