import { Button, DialogActions, DialogContent, Divider, Grid, InputLabel, Stack, TextField, DialogTitle, Alert } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useFormik, Form, FormikProvider } from 'formik';

import { CenePlusRequest } from 'types/user';
import { supabase } from 'supabase/supabase';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useCenePlusRequestsModalContext } from 'contexts/modal/SpecialModalContexts';
import cenePlusRequestsFormSchema from 'schemas/cenePlusRequestFormSchema';

const getInitialValues = (user: CenePlusRequest | null) => {
  const newUser = {
    ...user
  };

  return newUser;
};

export type CenePlusRequestFormProps = { currentObject: CenePlusRequest | null; onSuccess: () => void };

const CenePlusRequestForm = ({ currentObject, onSuccess = () => null }: CenePlusRequestFormProps) => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: getInitialValues(currentObject),

    validationSchema: cenePlusRequestsFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        if (currentObject) {
          const { error } = await supabase
            .from('cene_plus_requests')
            .update([
              {
                ...values
              }
            ])
            .eq('id', currentObject.id);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Customer updated successfully', { variant: 'success' });
        } else {
          const { error } = await supabase.from('cene_plus_requests').insert([
            {
              ...values,
              redirect_to: `https://cene.xyz/auth/register/cene-plus/continue`
            }
          ]);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Customer created successfully', { variant: 'success' });
        }

        onSuccess();
        setSubmitting(false);
        modalToggler(null);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { modalToggler } = useCenePlusRequestsModalContext();
  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {supabaseError && (
              <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
                {supabaseError}
              </Alert>
            )}

            <DialogTitle>{currentObject ? 'Edit Cene+ Request' : 'Add Cene+ Request'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="firstName">Customer First Name</InputLabel>
                        <TextField
                          fullWidth
                          id="firstName"
                          placeholder="Enter first name..."
                          {...getFieldProps('first_name')}
                          error={Boolean(touched.first_name && errors.first_name)}
                          helperText={touched.first_name && errors.first_name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="lastName">Customer Last Name</InputLabel>
                        <TextField
                          fullWidth
                          id="lastName"
                          placeholder="Enter last name..."
                          {...getFieldProps('last_name')}
                          error={Boolean(touched.last_name && errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email">Contact Email</InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          placeholder="Enter customer e-mail"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="phone">Contact Phone number</InputLabel>
                        <TextField
                          fullWidth
                          id="phone"
                          placeholder="Enter customer phone..."
                          {...getFieldProps('phone_number')}
                          error={Boolean(touched.phone_number && errors.phone_number)}
                          helperText={touched.phone_number && errors.phone_number}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="company_name">Company name</InputLabel>
                        <TextField
                          fullWidth
                          id="company_name"
                          placeholder="Enter company name..."
                          {...getFieldProps('company_name')}
                          error={Boolean(touched.company_name && errors.company_name)}
                          helperText={touched.company_name && errors.company_name}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="job_title">Job title</InputLabel>
                        <TextField
                          fullWidth
                          id="job_title"
                          placeholder="Enter job title..."
                          {...getFieldProps('job_title')}
                          error={Boolean(touched.job_title && errors.job_title)}
                          helperText={touched.job_title && errors.job_title}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="reference">Reference</InputLabel>
                        <TextField
                          fullWidth
                          id="reference"
                          placeholder="Enter reference..."
                          {...getFieldProps('reference')}
                          error={Boolean(touched.reference && errors.reference)}
                          helperText={touched.reference && errors.reference}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    onClick={() => modalToggler(null)}
                    variant="outlined"
                    sx={{
                      color: '#fff',
                      borderColor: '#434343',
                      '&:hover': {
                        backgroundColor: '#121212',
                        color: '#fff',
                        borderColor: '#434343'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                  >
                    Invite Cene+
                  </Button>
                </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default CenePlusRequestForm;
