import * as Yup from 'yup';
import { SubscribePlan } from 'types/partner';
import { emailRule, phoneNumberRule } from './validationRules';

export const newPartnerFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: emailRule,
  phone_number: phoneNumberRule,
  company_name: Yup.string().required('Event company name is required'),
  date_founded: Yup.date(),
  subscription_plan_id: Yup.string()
    .oneOf([SubscribePlan.STARTER, SubscribePlan.PRO, SubscribePlan.PREMIUM], 'Invalid subscription plan')
    .required('Subscription plan is required')
});

export default newPartnerFormSchema;
