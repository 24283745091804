// import { EditOutlined, EyeInvisibleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { useEffect, useState } from 'react';
import { handleCalculateAvailableTicketsByName } from 'supabase/util';
// import { format, parseISO } from 'date-fns';

export interface ITicketsSold {
  id: string;
  EUR: number;
  GBP: number;
  NGN: number;
  USD: number;
  name: string;
  quantity: number;
  is_visible: boolean;
  description: string;
  is_accessible: boolean;
  is_sale_active: boolean;
  sale_starts_at: string | null;
  sale_ends_at: string | null;
}

export enum TicketsSoldColumnId {
  NAME = 'name',
  QUANTITY = 'quantity',
  CATEGORY = 'category',
  PRICE = 'price',
  IS_ACCESSIBLE = 'is_accessible',
  IS_SALE_ACTIVE = 'is_sale_active',
  SALE_STARTS_AT = 'sale_starts_at',
  SALE_ENDS_AT = 'sale_ends_at',
  ACTIONS = 'action',
  SOLD_PERCENTAGE = 'sold_percentage'
}

//format money
function formatMoney(amount: string, currency = 'NGN', locale = 'en-US') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(Number(amount));
}

interface TableColumn {
  id: TicketsSoldColumnId;
  title: string;
  sortable: boolean;
  cell?: (sold: ITicketsSold) => React.ReactNode;
}

const getColumnValue = (column: TableColumn, sold: ITicketsSold): React.ReactNode => {
  return column.cell ? column.cell(sold) : '';
};

interface TicketsSoldTableRowsProps {
  solds: ITicketsSold[];
  onSuccess?: () => void;
  eventId: string;
}

export const getStatusBackgroundColor = (status: boolean) => {
  // switch (status) {
  //   case 'Active':
  //     return '#A4DB6C';
  //   case 'Sold out':
  //     return '#787878';
  //   case 'Scheduled':
  //     return '#FFEDFC';

  //   default:
  //     return 'transparent';
  // }
  return status ? '#A4DB6C' : '#787878';
};

const AvailableTicketsCell = ({ eventId, ticketName }: { eventId: string; ticketName: string }) => {
  const [availableTickets, setAvailableTickets] = useState<number | null>(null);

  useEffect(() => {
    const fetchAvailableTickets = async () => {
      try {
        const count = await handleCalculateAvailableTicketsByName(eventId, ticketName);
        setAvailableTickets(count || 0);
      } catch (error) {
        console.error('Error fetching available tickets:', error);
        setAvailableTickets(0);
      }
    };

    fetchAvailableTickets();
  }, []);

  if (availableTickets === null) {
    return <>{'null'}</>;
  }

  return <Typography>{availableTickets}</Typography>;
};

const ticketSoldColumns = (eventId: string) => {
  const result: TableColumn[] = [
    { id: TicketsSoldColumnId.NAME, title: 'Title', sortable: true, cell: (sold) => sold.name },
    { id: TicketsSoldColumnId.QUANTITY, title: 'Tickets Released', sortable: true, cell: (sold) => sold.quantity },
    {
      id: TicketsSoldColumnId.SOLD_PERCENTAGE,
      title: 'Available Tickets',
      sortable: true,
      cell: (sold) => <AvailableTicketsCell eventId={eventId} ticketName={sold.name} />
    },
    { id: TicketsSoldColumnId.PRICE, title: 'Price', sortable: true, cell: (sold) => `${formatMoney(sold.NGN.toString())}` },
    { id: TicketsSoldColumnId.IS_ACCESSIBLE, title: 'Accessible', sortable: true, cell: (sold) => sold.is_accessible },
    { id: TicketsSoldColumnId.IS_SALE_ACTIVE, title: 'Selling Now', sortable: true, cell: (sold) => sold.is_sale_active },
    // {
    //   id: TicketsSoldColumnId.SALE_STARTS_AT,
    //   title: 'Sale Start',
    //   sortable: true,
    //   cell: (sold) => {
    //     const unformattedDate = parseISO(sold.sale_starts_at as string);
    //     const formattedStart = format(unformattedDate, 'dd-MM-yyyy hh:mmaaa');

    //     return (
    //       <>
    //         <Typography>{formattedStart} -</Typography>
    //       </>
    //     );
    //   }
    // },
    // {
    //   id: TicketsSoldColumnId.SALE_ENDS_AT,
    //   title: 'Sale Ends',
    //   sortable: true,
    //   cell: (sold) => {
    //     const unformattedDate = parseISO(sold.sale_ends_at as string);
    //     const formattedEnd = format(unformattedDate, 'dd-MM-yyyy hh:mmaaa');

    //     return (
    //       <>
    //         <Typography>{formattedEnd} -</Typography>
    //       </>
    //     );
    //   }
    // },
    { id: TicketsSoldColumnId.ACTIONS, title: 'Actions', sortable: false }
  ];

  return result;
};

const TicketsSoldTableRows = ({ solds = [], onSuccess = () => null, eventId }: TicketsSoldTableRowsProps) => {
  const renderCellContent = (column: TableColumn, sold: ITicketsSold) => {
    if (column.id === TicketsSoldColumnId.IS_ACCESSIBLE) {
      return (
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 8px',
            fontWeight: 400,
            fontSize: '12px',
            color: '#fff',
            borderRadius: '4px'
          }}
        >
          {sold.is_accessible ? 'True' : 'False'}
        </Typography>
      );
    } else if (column.id === TicketsSoldColumnId.IS_SALE_ACTIVE) {
      return (
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 8px',
            fontWeight: 400,
            fontSize: '12px',
            backgroundColor: getStatusBackgroundColor(sold.is_sale_active),
            color: '#000',
            borderRadius: '4px'
          }}
        >
          {sold.is_sale_active ? 'True' : 'False'}
        </Typography>
      );
    } else if (column.id === TicketsSoldColumnId.ACTIONS) {
      return (
        <>
          {/* <IconButton>
            <InfoCircleOutlined />
          </IconButton> */}

          {/* <IconButton>
            <EyeInvisibleOutlined />
          </IconButton> */}

          <IconButton>
            <EditOutlined />
          </IconButton>
        </>
      );
    }
    return getColumnValue(column, sold);
  };

  return (
    <>
      {solds.map((sold) => (
        <TableRow key={sold.id}>
          {ticketSoldColumns(eventId).map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, sold)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface TicketsSoldsTableProps {
  isLoading?: boolean;
  solds?: ITicketsSold[];
  onSuccess?: () => void;
  eventId: string;
}

const EventOverviewTable = ({ onSuccess = () => null, isLoading = false, solds = [], eventId }: TicketsSoldsTableProps) => {
  const [filter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: TicketsSoldColumnId; direction: 'asc' | 'desc' }>({
    key: TicketsSoldColumnId.NAME,
    direction: 'asc'
  });

  const filterTicketsSolds = solds.filter((sold) =>
    ticketSoldColumns(eventId).some((column: TableColumn) => {
      const value = getColumnValue(column, sold);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedTicketSolds = [...filterTicketsSolds].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = ticketSoldColumns(eventId).find((col: TableColumn) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    if (key === TicketsSoldColumnId.QUANTITY) {
      const soldA = typeof valueA === 'string' && valueA ? Number(valueA.split('/')[0]) : 0;
      const soldB = typeof valueB === 'string' && valueB ? Number(valueB.split('/')[0]) : 0;

      if (direction === 'asc') {
        return soldA - soldB;
      } else {
        return soldB - soldA;
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: TicketsSoldColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ mb: 3 }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Tickets info</Typography>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<TicketsSoldColumnId>
                  columns={ticketSoldColumns(eventId)}
                  sortConfig={sortConfig}
                  onRequestSort={requestSort}
                />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={ticketSoldColumns(eventId).length}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : solds.length === 0 ? (
              <TableRow>
                <TableCell colSpan={ticketSoldColumns(eventId).length}>
                  <Stack textAlign="center">No Tickets Sold yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                <TicketsSoldTableRows eventId={eventId} onSuccess={onSuccess} solds={sortedTicketSolds} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
    </>
  );
};

export default EventOverviewTable;
