import { PartnerModalContextProvider } from 'contexts/modal/SpecialModalContexts';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Partner } from 'types/partner';
import { enqueueSnackbar } from 'notistack';
import PartnersTable from './partners.table';

const PartnersPage = () => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('partners').select('*').is('deleted_at', null);
      setPartners(data as Partner[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PartnerModalContextProvider>
        <PartnersTable onSuccess={fetchPartners} isLoading={isLoading} partners={partners} />
      </PartnerModalContextProvider>
    </>
  );
};

export default PartnersPage;
