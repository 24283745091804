import { InfoCircleOutlined } from '@ant-design/icons';
import { Grid, Stack, Typography } from '@mui/material';
import UserAvatar from 'pages/profile/userProfile/userAvatar';
import { PartnerProfile } from 'types/partnerProfile';

interface IProps {
  partner?: PartnerProfile;
}

const AvatarSection = ({ partner }: IProps) => {
  return (
    <Grid sx={{ width: '412px' }}>
      <UserAvatar width="404px" partner={partner} />
      <Stack sx={{ flexDirection: 'row', gap: '12px', mt: '76px' }}>
        <InfoCircleOutlined style={{ fontSize: '24px', height: '24px', color: '#8057DB' }} />
        <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 300, color: '#fff' }}>
          By personalising your organisation’s Information, you’ll resonate more with your audience and make an impression that lasts.
        </Typography>
      </Stack>
    </Grid>
  );
};

export default AvatarSection;
