import MainCard from 'components/MainCard';
import { useState } from 'react';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { HeaderCells } from 'components/commonTable/headerCells';
import CircularLoader from 'components/CircularLoader';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { Event } from '@ceneteam/namespace';
import { format, parseISO } from 'date-fns';

enum IStatus {
  ACTIVE = 'Active',
  PAST_EVENT = 'Past event',
  DRAFTS = 'Drafts',
  PUBLISHED = 'Published'
}

export const getStatusStyles = (status: string) => {
  const statusStyles: Record<string, { backgroundColor: string; color: string }> = {
    [IStatus.ACTIVE]: { backgroundColor: '#A4DB6C', color: '#000000' },
    [IStatus.PAST_EVENT]: { backgroundColor: '#787878', color: '#FFFFFF' },
    [IStatus.DRAFTS]: { backgroundColor: '#FFEDFC', color: '#981AC4' },
    [IStatus.PUBLISHED]: { backgroundColor: '#9175D0', color: '#fff' }
  };

  return statusStyles[status] || { backgroundColor: '#000', color: '#fff' };
};

export const getEventDisplayStatus = (event: Event): string => {
  let displayStatus = '';

  if (event.status === 'coming_soon') {
    displayStatus = 'Drafts';
  } else if (event.status === 'published') {
    const currentDate = new Date();
    const startDate = parseISO(event.starts_at as string);
    const endDate = parseISO(event.ends_at as string);

    if (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()) {
      displayStatus = 'Active';
    } else if (currentDate > endDate) {
      displayStatus = 'Past event';
    } else {
      displayStatus = 'Published';
    }
  } else {
    displayStatus = capitalize(event.status);
  }

  return displayStatus;
};

export enum EventColumnId {
  EVENT_NAME = 'contactName',
  HOST = 'eventOrganizer',
  LOCATION = 'location',
  EVENT_TYPE = 'type',
  EVENT_STATUS = 'status',
  EVENT_DATE = 'eventDate',
  TICKET_STOCK = 'ticketStock',
  CATEGORY = 'category',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: EventColumnId;
  title: string;
  sortable: boolean;
  cell?: (event: Event) => React.ReactNode;
}

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// interface HostCellProps {
//   partnerId: string;
// }

// const HostCell = ({ partnerId }: HostCellProps) => {
//   return <>-</>;
// };

const eventColumns: TableColumn[] = [
  { id: EventColumnId.EVENT_NAME, title: 'Event Name', sortable: true, cell: (event) => event.name },
  // {
  //   id: EventColumnId.HOST,
  //   title: 'Host',
  //   sortable: true,
  //   cell: (event) => <HostCell partnerId={event.partner_id} />
  // },
  { id: EventColumnId.LOCATION, title: 'Location', sortable: true, cell: (event) => `${event.country}, ${event.state}, ${event.address}` },
  { id: EventColumnId.EVENT_TYPE, title: 'Event type', sortable: true, cell: (event) => capitalize(event.type as string) },
  {
    id: EventColumnId.EVENT_STATUS,
    title: 'Event status',
    sortable: true,
    cell: (event) => {
      const displayStatus = getEventDisplayStatus(event);
      const statusStyles = getStatusStyles(displayStatus);

      return (
        <span
          style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px',
            fontWeight: 400,
            fontSize: '12px',
            backgroundColor: statusStyles.backgroundColor,
            color: statusStyles.color,
            borderRadius: '4px'
          }}
        >
          {displayStatus}
        </span>
      );
    }
  },
  {
    id: EventColumnId.EVENT_DATE,
    title: 'Event date',
    sortable: true,
    cell: (event) => {
      const startDate = parseISO(event.starts_at as string);
      const endDate = parseISO(event.ends_at as string);
      const formattedStart = format(startDate, 'dd-MM-yyyy hh:mmaaa');
      const formattedEnd = format(endDate, 'dd-MM-yyyy hh:mmaaa zzz');

      return (
        <>
          <Typography>{formattedStart} -</Typography>
          <Typography>{formattedEnd}</Typography>
        </>
      );
    }
  },
  // { id: EventColumnId.TICKET_STOCK, title: 'Ticket stock', sortable: true, cell: (event) => '200/600' },
  { id: EventColumnId.CATEGORY, title: 'Category', sortable: true, cell: (event) => event.category },

  {
    id: EventColumnId.ACTIONS,
    title: 'Actions',
    sortable: false
  }
];

const getColumnValue = (column: TableColumn, event: Event): React.ReactNode => {
  return column.cell ? column.cell(event) : '';
};

interface EventsTableRowsProps {
  events?: Event[];
  onSuccess?: () => void;
}

const EventTableRows = ({ events = [], onSuccess = () => null }: EventsTableRowsProps) => {
  const navigate = useNavigate();

  const handleProfileOpen = (id: string) => {
    navigate(`/event/${id}`, { replace: false });
  };

  const renderCellContent = (column: TableColumn, event: Event) => {
    if (column.id === EventColumnId.ACTIONS) {
      return (
        <IconButton onClick={() => handleProfileOpen(event.id)}>
          <EyeOutlined />
        </IconButton>
      );
    }
    return getColumnValue(column, event);
  };

  return (
    <>
      {events.map((event) => (
        <>
          <TableRow key={event.id}>
            {eventColumns.map((column) => (
              <TableCell key={column.id}>{renderCellContent(column, event)}</TableCell>
            ))}
          </TableRow>
        </>
      ))}
    </>
  );
};

interface EventsTableProps {
  isLoading?: boolean;
  events?: Event[];
  onSuccess?: () => void;
}

const EventsTable = ({ onSuccess = () => null, isLoading = false, events = [] }: EventsTableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: EventColumnId; direction: 'asc' | 'desc' }>({
    key: EventColumnId.EVENT_NAME,
    direction: 'asc'
  });

  const sortedEvents = [...events].sort((a, b) => {
    const { key, direction } = sortConfig;
    const column = eventColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const compareValues = (valA: string | number, valB: string | number) => {
      if (typeof valA === 'number' && typeof valB === 'number') {
        return direction === 'asc' ? valA - valB : valB - valA;
      }
      return direction === 'asc' ? String(valA).localeCompare(String(valB)) : String(valB).localeCompare(String(valA));
    };

    if (key === EventColumnId.EVENT_NAME) {
      const fullNameA = a.name.trim().toLowerCase();
      const fullNameB = b.name.trim().toLowerCase();
      return direction === 'asc' ? fullNameA.localeCompare(fullNameB) : fullNameB.localeCompare(fullNameA);
    }

    if (key === EventColumnId.EVENT_STATUS) {
      const statusOrder = ['Active', 'Published', 'Past Event', 'Drafts'];
      const statusA = getEventDisplayStatus(a).trim();
      const statusB = getEventDisplayStatus(b).trim();
      const indexA = statusOrder.indexOf(statusA);
      const indexB = statusOrder.indexOf(statusB);
      return direction === 'asc' ? indexA - indexB : indexB - indexA;
    }

    if (key === EventColumnId.EVENT_DATE) {
      const dateA = parseISO(a.starts_at as string);
      const dateB = parseISO(b.starts_at as string);
      return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    }

    return compareValues(valueA as string | number, valueB as string | number);
  });

  const eventSort = (key: EventColumnId) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<EventColumnId> columns={eventColumns} sortConfig={sortConfig} onRequestSort={eventSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : events.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Stack textAlign="center">No events yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <EventTableRows onSuccess={onSuccess} events={sortedEvents} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default EventsTable;
