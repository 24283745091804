import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import MainCard from 'components/MainCard';

interface IBulkRequestsTable {
  isLoading: boolean;
  requests: {
    id: string;
    message: string;
    tickets: number;
  }[];
}

export const BulkRequestsTable = ({ requests, isLoading }: IBulkRequestsTable) => {
  const headerLabels = ['Tickets', 'Message', 'Actions'];

  const renderHeader = headerLabels.map((item, index) => (
    <TableCell key={index}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Stack sx={{ width: '100%', textAlign: index + 1 === headerLabels.length ? 'end' : 'start' }}>{item}</Stack>
      </Stack>
    </TableCell>
  ));

  const renderBody = requests.map((item) => (
    <TableRow key={item.id}>
      <TableCell>{item.tickets}</TableCell>
      <TableCell>{item.message}</TableCell>

      <TableCell style={{ float: 'right' }}>
        <Button color="primary">Edit</Button>
        <Button color="info">Approve</Button>
        <Button color="error">Reject</Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <Stack marginTop={2}>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>{renderHeader}</TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : requests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Stack textAlign="center">No venues yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>{renderBody}</TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
    </Stack>
  );
};
