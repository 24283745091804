import { Stack, Typography } from '@mui/material';
import { ReactComponent as LoaderSVG } from '../../../svg/Loader.svg';

interface IEventLoading {
  title?: string;
  relative?: boolean;
}

export const EventLoading = ({ title, relative = false }: IEventLoading) => {
  const spinAnimation = {
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    },
    animation: 'spin 1s linear infinite'
  };

  return (
    <Stack position={relative ? 'relative' : 'absolute'} width="100%" height="100%" justifyContent="center" alignItems="center">
      {title && (
        <Typography mb="16px" fontWeight="300" fontSize="21px" color="#8057DB">
          {title}
        </Typography>
      )}
      <Stack sx={{ ...spinAnimation }}>
        <LoaderSVG />
      </Stack>
    </Stack>
  );
};
