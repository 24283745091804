import { EditOutlined } from '@ant-design/icons';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { useOutletContext } from 'react-router';
import { Venue } from '..';

const OverviewSection = () => {
  const { venue } = useOutletContext<{ venue: Venue | null }>();

  const locationList = [
    { label: 'Address: ', value: venue?.address },
    { label: 'Country: ', value: venue?.country },
    { label: 'State: ', value: venue?.state }
  ];

  const venueSpecsList = [
    { label: 'FloorSpace', value: `${venue?.floorspace} Square Meters` },
    { label: 'Capacity Details', value: venue?.capacity_details }
  ];

  return (
    <MainCard sx={{ mt: 3 }}>
      <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '16px', color: '#B0B0B0' }}>Location</Typography>
        <IconButton>
          <EditOutlined />
        </IconButton>
      </Stack>
      <Stack sx={{ gap: 2, mt: 3 }}>
        {locationList.map(({ label, value }) => (
          <Stack key={label} sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ width: '20%', color: '#8C8C8C' }}>{label}</Typography>
            <Typography>{value}</Typography>
          </Stack>
        ))}
      </Stack>
      <Divider sx={{ backgroundColor: '#262626', my: 3 }} />
      <Stack sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '16px', color: '#B0B0B0' }}>Venue Specs</Typography>
        <IconButton>
          <EditOutlined />
        </IconButton>
      </Stack>
      <Stack sx={{ gap: 2, mt: 3 }}>
        {venueSpecsList.map(({ label, value }) => (
          <Stack key={label} sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ width: '20%', color: '#8C8C8C' }}>{label}</Typography>
            <Typography>{value}</Typography>
          </Stack>
        ))}
      </Stack>
    </MainCard>
  );
};

export default OverviewSection;
