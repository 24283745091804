import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as BackSVG } from '../../../../../svg/arrow-left.svg';
import { Floorplan } from '.';

interface Props {
  floorplanData: Floorplan | null;
}

const FloorplanActions = ({ floorplanData }: Props) => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <IconButton
          sx={{
            borderRadius: '12px 0',
            backgroundColor: '#191919 !important',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#333333'
            },
            '&:focus': {
              backgroundColor: '#555555'
            }
          }}
        >
          <BackSVG style={{ stroke: '#AF96E6' }} />
        </IconButton>
        <Typography sx={{ fontSize: '30px' }}>{floorplanData?.name}</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 3 }}>
        <Button variant="contained" sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}>
          Edit this Floorplan
        </Button>
        <Button variant="contained" sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}>
          Clone this Floorplan
        </Button>
      </Stack>
    </Stack>
  );
};

export default FloorplanActions;
