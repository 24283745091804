import { getStatusBackgroundColor } from '../table';
import {
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ReactComponent as LogoSVG } from '../../../../../../../svg/logo.svg';
import { calculateDiscount, calculateSubTotal, calculateTax, calculateTotal, products } from './details';
import { Transaction } from '..';
import { User } from 'types/user';

interface Props {
  transaction: Transaction | undefined;
  customer: User | null;
  isLoading: boolean;
}

const InvoicePage = ({ transaction, customer, isLoading }: Props) => {
  const subTotal = calculateSubTotal();
  const tax = calculateTax(subTotal);
  const discount = calculateDiscount(subTotal);
  const total = calculateTotal(subTotal, tax, discount);

  return (
    <Paper sx={{ backgroundColor: '#121212', backgroundImage: 'none', paddingBlock: 10, mt: 3 }}>
      <Container id="invoice-container" sx={{ width: '964px', border: '1px solid #262626', backgroundColor: '#000' }}>
        <Grid container sx={{ height: '76px', justifyContent: 'space-between', alignItems: 'center', padding: 3 }}>
          <Grid item>
            <LogoSVG fill="#fff" />
          </Grid>
          <Grid item>
            <Typography sx={{ color: '#8057DB' }}>INV - #125863478945</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'end', padding: 3 }}>
          <Grid item md={3} sx={{ textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <Stack sx={{ gap: 0.5 }}>
              <Typography>Demo Company Inc.</Typography>
              <Typography>065 Mandan Road, Columbia MO, Missouri. (123)-65202</Typography>
              <Typography>demo@company.com</Typography>
              <Typography>(+91) 9999 999 999</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />

        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item md={4}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Customer</Typography>
            <Stack sx={{ gap: 1.5, mt: 4 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Name</Typography>
                <Typography>
                  {customer?.first_name} {customer?.last_name}
                </Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Address</Typography>
                <Typography sx={{ textAlign: 'end' }}>1065 Mandan Road, Columbia MO,Missouri. (123)-65202</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Phone no.</Typography>
                <Typography>{customer?.phone_number}</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>E-mail</Typography>
                <Typography>{customer?.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={3}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Order Details</Typography>
            <Stack sx={{ gap: 1.5, mt: 4 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Date</Typography>
                <Typography>November 14</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Status</Typography>
                <Typography
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px 12px',
                    fontWeight: 400,
                    fontSize: '12px',
                    backgroundColor: getStatusBackgroundColor(transaction?.status as string),
                    color: '#000',
                    borderRadius: '4px'
                  }}
                >
                  {transaction?.status}
                </Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Payment Gateway</Typography>
                <Typography>#146859</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Payment ID</Typography>
                <Typography>#146859</Typography>
              </Stack>

              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Cene Transaction ID</Typography>
                <Typography>#146859</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer sx={{ mt: 10 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#000' }}>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>${product.unitPrice.toFixed(2)}</TableCell>
                  <TableCell>${(product.quantity * product.unitPrice).toFixed(2)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="body1">Subtotal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: '#91DBBF' }}>
                    ${subTotal.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="body1">Taxes (10%):</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: '#91DBBF' }}>
                    ${tax.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="body1">Discount (5%):</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: '#91DBBF' }}>
                    ${discount.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography sx={{ fontSize: '22px', color: '#8057DB' }}>Total:</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: '22px', color: '#8057DB' }}>${total.toFixed(2)}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Paper>
  );
};

export default InvoicePage;
