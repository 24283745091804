import { Pagination, Stack, Typography } from '@mui/material';
import ReusableTabs from 'components/tabs/reusableTabs';
import { useState } from 'react';
import AssetsTable from './table';
import { genresCene, genresCenePlus } from 'pages/customersManagement/customers/profile/musicGenres';
import { interestsCene, interestsCenePlus } from 'pages/customersManagement/customers/profile/interests';
import { v4 as uuidv4 } from 'uuid';
import { DebouncedInput } from 'components/third-party/react-table';

enum ITab {
  ALL = 'All',
  CENE = 'Cene',
  CENE_PLUS = 'Cene+'
}

const allAssets = [
  ...genresCene.map((genre) => ({
    avatar: '',
    tag: genre,
    platform: 'Cene',
    affinityGroup: 'Music',
    lastUpdated: '2024-10-28',
    id: uuidv4()
  })),
  ...genresCenePlus.map((genre) => ({
    avatar: '',
    tag: genre,
    platform: 'Cene+',
    affinityGroup: 'Music',
    lastUpdated: '2024-10-28',
    id: uuidv4()
  })),
  ...interestsCene.map((interest) => ({
    avatar: '',
    tag: interest,
    platform: 'Cene',
    affinityGroup: 'Interests',
    lastUpdated: '2024-10-28',
    id: uuidv4()
  })),
  ...interestsCenePlus.map((interest) => ({
    avatar: '',
    tag: interest,
    platform: 'Cene+',
    affinityGroup: 'Interests',
    lastUpdated: '2024-10-28',
    id: uuidv4()
  }))
];

const AssetLibraryPage = () => {
  const [filter, setFilter] = useState('');

  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const filteredAssets = allAssets
    .filter((asset) => (activeTab === ITab.ALL ? true : asset.platform === activeTab))
    .filter(
      (asset) => asset.tag.toLowerCase().includes(filter.toLowerCase()) || asset.affinityGroup.toLowerCase().includes(filter.toLowerCase())
    );

  const totalPages = Math.ceil(filteredAssets.length / itemsPerPage);
  const paginatedAssets = filteredAssets.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Stack>
        <Typography>
          The Assets Library page is a management hub where you can organize and update the music genres and audience interests available on
          the platform, ensuring a customized and engaging experience for users
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} />{' '}
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <AssetsTable assets={paginatedAssets} />
      <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
    </>
  );
};

export default AssetLibraryPage;
