export interface UserProps {
  modal: boolean;
}

export enum UserType {
  CENE = 'cene',
  CENE_PLUS = 'cene+'
}

export interface ICustomerTypes {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  userType: UserType;
}
