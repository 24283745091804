import { DeleteOutlined, PlusOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useState } from 'react';
import GuestlistForm from './guesrlist.form';
import ReusableModal from 'components/modal/modal';

export interface IGuest {
  firstName: string;
  lastName: string;
  tickets: string;
  date_assigned: string;
  invited_by: string;
  status: string;
  id: string;
}

export enum GuestColumnId {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TICKETS = 'tickets',
  DATE_ASSIGNED = 'dateAssigned',
  INVITED_BY = 'invitedBy',
  STATUS = 'status',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: GuestColumnId;
  title: string;
  sortable: boolean;
  cell?: (guest: IGuest) => React.ReactNode;
}

const guestColumns: TableColumn[] = [
  { id: GuestColumnId.FIRST_NAME, title: 'First name', sortable: true, cell: (guest) => guest.firstName },
  { id: GuestColumnId.LAST_NAME, title: 'Last name', sortable: true, cell: (guest) => guest.lastName },
  { id: GuestColumnId.TICKETS, title: 'Tickets', sortable: true, cell: (guest) => guest.tickets },
  { id: GuestColumnId.DATE_ASSIGNED, title: 'Date assigned', sortable: true, cell: (guest) => guest.date_assigned },
  { id: GuestColumnId.INVITED_BY, title: 'Invited by', sortable: true, cell: (guest) => guest.invited_by },
  {
    id: GuestColumnId.STATUS,
    title: 'Status',
    sortable: true,
    cell: (guest) => {
      return (
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px',
            fontWeight: 400,
            fontSize: '12px',
            backgroundColor: guest.status === 'Pending' ? '#575856' : '#A4DB6C',
            color: guest.status === 'Pending' ? '#fff' : '#000',
            borderRadius: '4px'
          }}
        >
          {guest.status}
        </Typography>
      );
    }
  },

  { id: GuestColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const getColumnValue = (column: TableColumn, guest: IGuest): React.ReactNode => {
  return column.cell ? column.cell(guest) : '';
};

interface GuestTableRowsProps {
  guests: IGuest[];
  onSuccess?: () => void;
}

const GuestTableRows = ({ guests = [], onSuccess = () => null }: GuestTableRowsProps) => {
  const renderCellContent = (column: TableColumn, guest: IGuest) => {
    if (column.id === GuestColumnId.ACTIONS) {
      return (
        <>
          <Tooltip title="Block guest">
            <IconButton>
              <WarningOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete guest">
            <IconButton>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Update guest">
            <IconButton>
              <UserOutlined />
            </IconButton>
          </Tooltip>
        </>
      );
    }
    return getColumnValue(column, guest);
  };

  return (
    <>
      {guests.map((guest) => (
        <TableRow key={guest.id}>
          {guestColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, guest)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface GuestsTableProps {
  isLoading?: boolean;
  guests?: IGuest[];
  onSuccess?: () => void;
}

const GuestlistsTable = ({ onSuccess = () => null, isLoading = false, guests = [] }: GuestsTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: GuestColumnId; direction: 'asc' | 'desc' }>({
    key: GuestColumnId.FIRST_NAME,
    direction: 'asc'
  });

  const { open, modalToggler } = useModalContext();

  const filterGuests = guests.filter((guest) =>
    guestColumns.some((column) => {
      const value = getColumnValue(column, guest);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedGuests = [...filterGuests].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = guestColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isDateField = ['date_assigned'].includes(key);

    if (isDateField) {
      const dateA = new Date(valueA as string);
      const dateB = new Date(valueB as string);

      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: GuestColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Event guestlist</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search guest..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <PlusOutlined />
          Add Someone to Guestlist
        </Button>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<GuestColumnId> columns={guestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={guestColumns.length}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : guests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={guestColumns.length}>
                  <Stack textAlign="center">No Guestlists yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                <GuestTableRows onSuccess={onSuccess} guests={sortedGuests} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={GuestlistForm} width="488px" />
    </>
  );
};

export default GuestlistsTable;
