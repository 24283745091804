import { FormHelperText, InputLabel, TextField } from '@mui/material';

interface ICustomInputProps {
  name: string;
  label?: string;
  error?: string | null | undefined;
  placeholder: string;
  required?: boolean;
  multiline?: boolean;
  value?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
}

export const CustomInput = ({
  multiline = false,
  required = false,
  placeholder,
  name,
  onChange,
  value,
  onBlur,
  label,
  error
}: ICustomInputProps) => {
  return (
    <>
      {label && (
        <InputLabel sx={{ color: '#9983C9' }} required={required} htmlFor={name}>
          {label}
        </InputLabel>
      )}

      <TextField
        maxRows={100}
        minRows={4}
        value={value}
        error={Boolean(error)}
        multiline={multiline}
        onBlur={onBlur}
        onChange={onChange}
        required
        name={name}
        fullWidth
        id={name}
        placeholder={placeholder}
      />
      {error && <FormHelperText sx={{ color: '#a61d24' }}>{error}</FormHelperText>}
    </>
  );
};
