import { ReactNode, createContext, useContext, useState } from 'react';

interface ModalContextProps<T> {
  modalToggler: (item: T | null) => void;
  open: boolean;
  selectedItem: T | null;
}

const ModalContext = createContext<ModalContextProps<any> | undefined>(undefined);

export const useModalContext = () => {
  const ctx = useContext(ModalContext);
  if (!ctx) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }
  return ctx as ModalContextProps<any>;
};

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);

  const modalToggler = (item: any | null) => {
    setSelectedItem(item);
    setOpen((prevOpen) => !prevOpen);
  };

  const contextValue: ModalContextProps<any> = {
    modalToggler,
    open,
    selectedItem
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};
