import { Alert, Autocomplete, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from 'antd';
import { useFormikContext } from 'formik';
import { timezones } from 'constants/timezones';
import { EventSchema } from '../event.schema';
import { useCallback, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import dayjs from 'dayjs';
import ShapeButton from 'components/button/button';

interface IWhenAndWhereStep {
  onNext: () => void;
  onPrev: () => void;
}

export const WhenAndWhereStep = ({ onNext, onPrev }: IWhenAndWhereStep) => {
  const formik = useFormikContext<EventSchema>();
  const [places, setPlaces] = useState<[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPlace = useCallback(
    _.debounce(async (q: string | null) => {
      if (!q) return setPlaces([]);

      try {
        setLoading(true);
        const { data } = await axios.get(`https://nominatim.openstreetmap.org/search?q=${q}&format=json`);

        setPlaces([...(data.map((item: any) => ({ ...item, label: item.display_name })) as [])]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    []
  );

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];
  const errors = formik.errors[formType];

  const validationStepForMultipleTypes =
    formik.values.eventType === 'both' &&
    (!formik.values['cene'].location ||
      !formik.values['cene+'].location ||
      Boolean(formik.errors['cene+']?.location) ||
      Boolean(formik.errors['cene']?.location));

  const validationStep = !values.location || Boolean(errors?.location);

  const isLocationCenePlusError = Boolean(formType === 'cene' && formik.errors['cene+']?.location);
  const isLocationCeneError = Boolean(formType === 'cene+' && formik.errors['cene']?.location);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Event Details – When and Where
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Help us set the stage for your event. Please provide the following details:
      </Typography>
      <Stack spacing={1} mt={2}>
        <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
          Start / End Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker.RangePicker
            showTime
            value={[dayjs(values.starts_at, 'DD.MM.YYYY HH:mm'), dayjs(values.ends_at, 'DD.MM.YYYY HH:mm')]}
            onChange={(_, t) => {
              formik.setFieldValue(`${formType}.starts_at`, t[0] || dayjs().format('DD.MM.YYYY HH:mm'));
              formik.setFieldValue(`${formType}.ends_at`, t[1] || dayjs().format('DD.MM.YYYY HH:mm'));
            }}
            onBlur={() => {
              formik.handleBlur(`${formType}.starts_at`);
              formik.handleBlur(`${formType}.ends_at`);
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode?.parentNode as HTMLElement;
            }}
            separator={false}
            color="white"
            style={{
              padding: '8.6px 14px',
              borderRadius: '4px',
              border: '1px solid ',
              borderColor: '#595959',
              backgroundColor: 'transparent',
              color: 'white'
            }}
            variant="borderless"
            dropdownClassName="dark-theme-dropdown"
            format="DD.MM.YYYY HH:mm"
          />
        </LocalizationProvider>
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
          Timezone of the Event
        </InputLabel>
        <Select
          onChange={(e) => {
            formik.setFieldValue(`${formType}.timezone`, e.target.value);
          }}
          value={values.timezone}
          displayEmpty
        >
          {timezones.map((time) => (
            <MenuItem key={time.value} value={time.value}>
              {time.label} ({time.value})
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} required>
          What is the Event Venue Address?
        </InputLabel>
        <Autocomplete
          loading={loading}
          filterOptions={(options) => options}
          key={formType}
          value={values.eventLocationName}
          onChange={(e, value: any) => {
            formik.setFieldValue(`${formType}.eventLocationName`, value ? value.label : '');
            formik.setFieldValue(`${formType}.location`, value ? `(${value?.lat},${value?.lon})` : '');
          }}
          onInputChange={(_, value) => {
            fetchPlace(value);
          }}
          options={places as []}
          renderInput={(params) => (
            <TextField
              value={values.eventLocationName}
              onBlur={() => formik.handleBlur(`${formType}.location`)}
              error={Boolean(errors?.location)}
              helperText={errors?.location}
              placeholder="Event location"
              sx={{ width: '100%' }}
              {...params}
            />
          )}
        />
      </Stack>
      {formik.values.eventType === 'both' && (isLocationCenePlusError || isLocationCeneError) && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          Event location for {isLocationCeneError ? 'Cene' : 'Cene+'} is required
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={validationStepForMultipleTypes || validationStep} handleClick={onNext} width="100%">
          Next step
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
