import { Link } from 'react-router-dom';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

// project import
import AuthWrapper from 'sections/auth/AuthWrapper';
import { MailOutlined } from '@ant-design/icons';

// ================================|| CHECK MAIL ||================================ //

const CheckMail = () => {
  return (
    <AuthWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Stack justifyContent="center">
              <MailOutlined style={{ fontSize: '36px', color: '#8057DB', marginInline: 'auto' }} />
            </Stack>
            <Typography
              variant="h3"
              sx={{
                mt: 2,
                fontSize: '32px',
                fontWeight: 400,
                lineHeight: '44px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              Please check your e-mail
            </Typography>
            <Typography color="secondary" sx={{ mt: 2, textAlign: 'center' }}>
              A reset password link has been sent to the specified email address. Please follow the instructions in this email. Once the
              reset password procedure is completed you may continue with the login process.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: '30px' }}>
          <Button
            component={Link}
            to="/"
            disableElevation
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              backgroundColor: '#8057DB',
              '&:hover': {
                backgroundColor: '#8157dbab'
              }
            }}
          >
            Back to login
          </Button>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default CheckMail;
