import { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { v4 as uuidv4 } from 'uuid';

export interface ICropedPicture {
  id: string;
  originalPicture: string;
  cropedPicture: string;
  crop: Crop;
}

interface IUseCrop {
  aspect?: number;
  onChange: (params: ICropedPicture[]) => void;
}

export const useCrop = ({ aspect = 1, onChange }: IUseCrop) => {
  const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });
  };

  const choosePictures = (maxCount: number = 1, onError: (message: string) => void = () => {}) => {
    const fakeInput = document.createElement('input');
    fakeInput.type = 'file';

    if (maxCount > 1) {
      fakeInput.multiple = true;
    }

    fakeInput.accept = 'image/png, image/jpeg';

    fakeInput.click();

    fakeInput.onchange = (e) => {
      const target = e.target as HTMLInputElement;

      if (!target.files) return;

      if (maxCount > 1 && target.files?.length > maxCount) {
        onError(`You can upload maximum ${maxCount} images`);
        return;
      }

      const promises = Array.from(target.files).map(async (file) => {
        const data = await initialCrop(URL.createObjectURL(file));

        const originalPicture = await blobToBase64(file);

        return {
          id: uuidv4(),
          originalPicture: originalPicture,
          cropedPicture: data.cropedImage,
          crop: data.crop
        };
      });

      Promise.all(promises).then((pictures) => {
        onChange(pictures);
      });
    };
  };

  const initialCrop = (url: string, crop?: Crop) => {
    return new Promise<{ cropedImage: string; crop: Crop }>((resolve, reject) => {
      const image = new Image();
      image.src = url;

      image.onload = () => {
        const croped = crop
          ? crop
          : centerCrop(
              makeAspectCrop(
                {
                  unit: '%',
                  width: image.width
                },
                aspect,
                image.width,
                image.height
              ),
              image.width,
              image.height
            );

        resolve({ cropedImage: getCropedImage(image, croped), crop: croped });
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getCropedImage = (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropWidth = (crop.width / 100) * image.naturalWidth;
    const cropHeight = (crop.height / 100) * image.naturalHeight;
    const cropX = (crop.x / 100) * image.naturalWidth;
    const cropY = (crop.y / 100) * image.naturalHeight;
    canvas.width = Math.floor(cropWidth * scaleX * pixelRatio);
    canvas.height = Math.floor(cropHeight * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    return canvas.toDataURL('image/jpeg');
  };

  return {
    choosePictures,
    initialCrop
  };
};
