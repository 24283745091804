import { supabase } from '../supabase/supabase';
import { Session } from '@supabase/supabase-js';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface SupabaseContextProps {
  session: Session | null;
  permissions: string[];
  role: string;
}

const SupabaseContext = createContext<SupabaseContextProps>({
  session: null,
  permissions: [],
  role: ''
});

export const SupabaseProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [permissions, setPermissions] = useState<SupabaseContextProps['permissions']>([]);
  const [role, setRole] = useState<SupabaseContextProps['role']>('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getSession();
    onAuthStateChange();
  }, []);

  const fetchPermission = async (session: Session) => {
    try {
      /* Get permissions */
      const { data } = await supabase.from('partner_users').select('permissions, role_name').eq('user_id', session?.user.id).single();

      setPermissions(data?.permissions);
      setRole(data?.role_name);
    } catch (error) {
      console.log(error);
    }
  };

  const getSession = async () => {
    try {
      setLoading(true);

      const session = await supabase.auth.getSession();

      if (session.data.session) {
        await fetchPermission(session.data.session);
        setSession(session.data.session);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onAuthStateChange = async () => {
    supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session) {
        setSession(session);
        fetchPermission(session);
      }

      if (_event === 'SIGNED_OUT') {
        setSession(null);
      }
    });
  };

  return (
    <SupabaseContext.Provider
      value={{
        session,
        permissions,
        role
      }}
    >
      {isLoading ? null : children}
    </SupabaseContext.Provider>
  );
};

export const useSupabase = () => {
  const context = useContext(SupabaseContext);

  if (context === undefined) {
    throw new Error('useSupabaseSession must be used within a SupabaseSessionProvider');
  }
  return context;
};
