import { ReactNode, createContext, useContext, useState } from 'react';

interface ModalContextProps<T> {
  modalToggler: (item: T | null) => void;
  open: boolean;
  selectedItem: T | null;
}

const createModalContext = <T,>() => {
  const context = createContext<ModalContextProps<T> | undefined>(undefined);

  const useModalContext = () => {
    const ctx = useContext(context);
    if (!ctx) {
      throw new Error('useModalContext must be used within a ModalContextProvider');
    }
    return ctx;
  };

  const ModalContextProvider = ({ children }: { children: ReactNode }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<T | null>(null);

    const modalToggler = (item: T | null) => {
      setSelectedItem(item);
      setOpen((prevOpen) => !prevOpen);
    };

    const contextValue: ModalContextProps<T> = {
      modalToggler,
      open,
      selectedItem
    };

    return <context.Provider value={contextValue}>{children}</context.Provider>;
  };

  return { useModalContext, ModalContextProvider };
};

export default createModalContext;
