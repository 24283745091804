import { DeleteOutlined } from '@ant-design/icons';
import { Grid, TextField } from '@mui/material';
import { Card, IconButton, InputLabel, Stack } from '@mui/material';
import { getIn, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { MemberInvitesSchema } from 'schemas/onboarding/memberInvites';

interface IMember {
  index: number;
}

export const MemberCard = ({ index }: IMember) => {
  const formik = useFormikContext<MemberInvitesSchema>();

  const members = formik.values?.members || [];

  const removeMember = useCallback(() => {
    if (!members) {
      return;
    }
    formik.setFieldValue(
      'members',
      members.filter((_, i: number) => i !== index)
    );
  }, [index, members, formik]);

  return (
    <Card
      variant="outlined"
      sx={{ padding: 4, borderRadius: '20px 0', position: 'relative', backgroundColor: 'transparent', borderColor: '#262626' }}
    >
      {index !== 0 && (
        <IconButton sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }} onClick={removeMember}>
          <DeleteOutlined />
        </IconButton>
      )}
      <Stack spacing={2}>
        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }} htmlFor={`members[${index}].first_name`}>
              First Name
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              name={`members[${index}].first_name`}
              value={members[index]?.first_name || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter first name..."
              error={getIn(formik.errors, `members[${index}].first_name`)}
              helperText={getIn(formik.errors, `members[${index}].first_name`)}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>

        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }} htmlFor={`members[${index}].last_name`}>
              Last Name
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              name={`members[${index}].last_name`}
              value={members[index]?.last_name || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter last name..."
              error={getIn(formik.errors, `members[${index}].last_name`)}
              helperText={getIn(formik.errors, `members[${index}].last_name`)}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>

        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }} htmlFor={`members[${index}].email`}>
              E-mail
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              name={`members[${index}].email`}
              value={members[index]?.email || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter e-mail..."
              error={getIn(formik.errors, `members[${index}].email`)}
              helperText={getIn(formik.errors, `members[${index}].email`)}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>

        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }} htmlFor={`members[${index}].phone_number`}>
              Phone Number
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              name={`members[${index}].phone_number`}
              value={members[index]?.phone_number || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter phone number..."
              error={getIn(formik.errors, `members[${index}].phone_number`)}
              helperText={getIn(formik.errors, `members[${index}].phone_number`)}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>

        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }} htmlFor={`members[${index}].job_title`}>
              Job Title
            </InputLabel>
            <TextField
              variant="standard"
              autoComplete="off"
              name={`members[${index}].job_title`}
              value={members[index]?.job_title || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter job title..."
              error={getIn(formik.errors, `members[${index}].job_title`)}
              helperText={getIn(formik.errors, `members[${index}].job_title`)}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>
      </Stack>
    </Card>
  );
};
