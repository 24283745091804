import * as Yup from 'yup';
import { emailRule, phoneNumberRule } from './validationRules';

export const customerInviteFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: emailRule,
  phone_number: phoneNumberRule
});

export default customerInviteFormSchema;
