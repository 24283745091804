import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useFormik } from 'formik';
import { DatePicker as RangeDatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormControlLabel } from '@mui/material';
import { useState } from 'react';

interface IPromocode {
  name: string;
  description: string;
  userType: string;
  typeOfDiscount: string;
  ticketCategory: string;
  numberOfOrders: number;
  validityPeriod: [Dayjs | null, Dayjs | null];
  minimumOrderAmount: number;
  isActive: boolean;
}

interface PromocodeFormProps {
  currentObject: IPromocode | null;
}

const PromocodeForm = ({ currentObject }: PromocodeFormProps) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      userType: '',
      typeOfDiscount: '',
      ticketCategory: 'All Ticket Categories',
      numberOfOrders: 0,
      validityPeriod: [null, null] as [Dayjs | null, Dayjs | null],
      minimumOrderAmount: 0,
      isActive: false
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const [orderCount, setOrderCount] = useState(1);
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(1);

  const { modalToggler } = useModalContext();
  const { RangePicker } = RangeDatePicker;

  const { getFieldProps, touched, errors, isSubmitting, handleChange, values } = formik;

  return (
    <Grid>
      <DialogTitle>{currentObject ? 'Update Promo Code' : 'Add New Promo Code'}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="promocode_name" sx={{ color: '#BBAFDA' }}>
                What should this promo code be called
              </InputLabel>
              <TextField
                fullWidth
                id="promocode_name"
                placeholder="e.g Cene20"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="internal_notes" sx={{ color: '#BBAFDA' }}>
                Internal Notes
              </InputLabel>
              <TextField
                fullWidth
                id="internal_notes"
                placeholder="Add Promo Description or additional details for other admin users"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="userType" sx={{ color: '#BBAFDA' }}>
                User Type
              </InputLabel>
              <RadioGroup
                row
                {...getFieldProps('userType')}
                onChange={(event) => formik.setFieldValue('userType', event.target.value)}
                value={formik.values.userType}
              >
                <FormControlLabel value="All" control={<Radio />} label="All" />
                <FormControlLabel value="Cene" control={<Radio />} label="Cene" />
                <FormControlLabel value="Cene+" control={<Radio />} label="Cene+" />
              </RadioGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="typeOfDiscount" sx={{ color: '#BBAFDA' }}>
                Type of Discount
              </InputLabel>
              <RadioGroup
                row
                {...getFieldProps('typeOfDiscount')}
                onChange={(event) => formik.setFieldValue('typeOfDiscount', event.target.value)}
                value={formik.values.typeOfDiscount}
              >
                <FormControlLabel value="Amount" control={<Radio />} label="Amount" />
                <FormControlLabel value="Percentage" control={<Radio />} label="Percentage" />
              </RadioGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="ticket_category" sx={{ color: '#BBAFDA' }}>
                Choose Ticket Category
              </InputLabel>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.ticketCategory}
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="All Ticket Categories">All Ticket Categories</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="order_count" sx={{ color: '#BBAFDA' }}>
                Number of Orders before cancellation
              </InputLabel>
              <TextField
                id="order_count"
                type="number"
                value={orderCount}
                onChange={(e) => setOrderCount(Math.max(1, Number(e.target.value)))}
                inputProps={{ min: 1 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="promocode_name" sx={{ color: '#BBAFDA' }}>
                Enable Validity period for tickets:
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <RangePicker
                  separator={false}
                  color="white"
                  style={{
                    padding: '8.6px 14px',
                    borderRadius: '4px',
                    border: '1px solid #595959',
                    backgroundColor: 'transparent',
                    color: 'white'
                  }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode?.parentNode as HTMLElement;
                  }}
                  variant="borderless"
                  onChange={(_, t) => {
                    formik.setFieldValue('validityPeriod', t[0]);
                    formik.setFieldValue('validityPeriod', t[1]);
                  }}
                  format="DD.MM.YYYY HH:mm"
                />
              </LocalizationProvider>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="promocode_name" sx={{ color: '#BBAFDA' }}>
                Minimum Order Amount
              </InputLabel>
              <TextField
                id="order_count"
                type="number"
                value={minimumOrderAmount}
                onChange={(e) => setMinimumOrderAmount(Math.max(1, Number(e.target.value)))}
                inputProps={{ min: 1 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="promocode_active" sx={{ color: '#BBAFDA' }}>
                Make Promo Code Active
              </InputLabel>
              <FormControlLabel
                control={<Switch id="promocode_active" {...getFieldProps('isActive')} color="primary" />}
                label="This code is active and can be used"
                sx={{
                  '& .MuiSwitch-track': {
                    borderRadius: 22 / 2,
                    '&::before, &::after': {
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: 16,
                      height: 16
                    }
                  },
                  '& .MuiSwitch-switchBase': {
                    '& .MuiSwitch-thumb': {
                      color: '#fff'
                    },
                    '&.Mui-checked': {
                      '& + .MuiSwitch-track': {
                        backgroundColor: '#8057DB',
                        opacity: 1
                      }
                    }
                  }
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2.5 }}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={() => modalToggler(null)}
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#434343',
                '&:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                  borderColor: '#434343'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => modalToggler(null)}
              sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default PromocodeForm;
