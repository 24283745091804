import partners from './partners';
import customers from './customers';
import dashboardItem from './dashboard-item';

// types
import { NavItemType } from 'types/menu';
import team from './team';
import events from './events';
import financials from './financials';
import assets from './assets';

const menuItems: { items: NavItemType[] } = {
  items: [dashboardItem, partners, customers, events, assets, financials, team]
};

export default menuItems;
