export const interests = [
  'Fashion',
  'Beauty',
  'Pop culture',
  'Arts',
  'Travel',
  'Lifestyle',
  'Podcast',
  'Celebrities',
  'Events',
  'Shopping',
  'Entertainment',
  'Movies',
  'TV',
  'Music'
];
