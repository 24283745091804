import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { useState } from 'react';
import { Member } from 'types/member';

import { format, parse, parseISO } from 'date-fns';

import { DeleteFilled, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { HeaderCells } from 'components/commonTable/headerCells';
import CircularLoader from 'components/CircularLoader';
import MainCard from 'components/MainCard';
import MemberForm from './member.form';
import ReusableModal from 'components/modal/modal';
import { useTeamUserModalContext } from 'contexts/modal/SpecialModalContexts';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import PermissionsModal from './permissions';
import useFetchPermissions from './usePermissions.hook';

export enum MemberColumnId {
  JOINED_TEAM = 'joinedTeam',
  TEAM_MEMBER_NAME = 'teamMemberName',
  POSITION = 'position',
  LAST_SEEN = 'lastSeen',
  PERMISSIONS = 'permissions',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: MemberColumnId;
  title: string;
  sortable: boolean;
  cell?: (member: Member) => React.ReactNode;
}

const memberColumns: TableColumn[] = [
  { id: MemberColumnId.JOINED_TEAM, title: 'Joined team', sortable: true },
  { id: MemberColumnId.TEAM_MEMBER_NAME, title: 'Team Member Name', sortable: true },
  { id: MemberColumnId.POSITION, title: 'Position', sortable: true },
  { id: MemberColumnId.LAST_SEEN, title: 'Last Seen', sortable: true },
  { id: MemberColumnId.PERMISSIONS, title: 'Permissions', sortable: true },
  { id: MemberColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const columnConfig: Record<MemberColumnId, keyof Member | ((member: Member) => string | number)> = {
  [MemberColumnId.JOINED_TEAM]: (member) => format(parseISO(member.updated_at as string), 'dd.MM.yyyy'),
  [MemberColumnId.TEAM_MEMBER_NAME]: (member) => `${member.metadata.first_name} ${member.metadata.last_name}`,
  [MemberColumnId.POSITION]: (member) => member.metadata.job_title,
  [MemberColumnId.LAST_SEEN]: (member) => format(parseISO(member.updated_at as string), 'dd.MM.yyyy'),
  [MemberColumnId.PERMISSIONS]: (member) => {
    const permissions = useFetchPermissions();
    const memberPermissions = new Set(member.permissions);

    const getGroupsForPermissions = () => {
      return permissions
        .filter((group) => group.permissions.some((perm) => memberPermissions.has(perm.permission)))
        .map((group) => group.group)
        .join(', ');
    };

    return getGroupsForPermissions();
  },
  [MemberColumnId.ACTIONS]: () => ''
};

const getColumnValue = (columnId: MemberColumnId, member: Member): string | number => {
  const config = columnConfig[columnId];
  return typeof config === 'function' ? config(member) : (member[config] as string | number);
};

interface MemberTableRowsProps {
  members: Member[];
  onSuccess?: () => void;
}

const MemberTableRows = ({ members = [], onSuccess = () => null }: MemberTableRowsProps) => {
  const { modalToggler, open, selectedItem } = useTeamUserModalContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPermissionsModalOpen, setPermissionsModalOpen] = useState(false);
  const [selectedInviteId, setSelectedInviteId] = useState<string | null>(null);

  const handleOpenDialog = (inviteId: string) => {
    setSelectedInviteId(inviteId);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedInviteId(null);
  };

  const handleDeleteMember = async (memberId: string) => {
    try {
      const { error } = await supabase.from('partner_users').delete().eq('id', memberId);

      if (error) throw error;

      enqueueSnackbar('Member deleted successfully', { variant: 'success' });
      onSuccess();
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      onSuccess();
      handleCloseDialog();
    }
  };

  const renderCellContent = (column: TableColumn, member: Member) => {
    if (column.id === MemberColumnId.ACTIONS) {
      return (
        <>
          <Tooltip title="Update and view member">
            <IconButton
              onClick={() => {
                modalToggler(member);
              }}
            >
              <EyeOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Update and view permissions">
            <IconButton onClick={() => setPermissionsModalOpen(true)}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete member">
            <IconButton onClick={() => handleOpenDialog(member.id)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </>
      );
    }
    return getColumnValue(column.id, member);
  };

  return (
    <>
      {members.map((member) => (
        <>
          <TableRow key={member.id}>
            {memberColumns.map((column) => (
              <TableCell key={column.id}>{renderCellContent(column, member)}</TableCell>
            ))}
          </TableRow>
          <ReusableModal
            width="600px"
            open={open}
            modalToggler={() => modalToggler(null)}
            onSuccess={onSuccess}
            content={MemberForm}
            currentObject={selectedItem}
          />
          <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
            PaperProps={{
              sx: {
                textAlign: 'center',
                border: '1px solid #434343',
                padding: '40px',
                backgroundImage: 'none',
                backgroundColor: 'black',
                color: 'white',
                width: '488px'
              }
            }}
          >
            <div
              style={{
                margin: 'auto',
                padding: '20px',
                borderRadius: '50%',
                backgroundColor: '#ffffff0f',
                width: '76px',
                height: '76px',
                border: '1px solid #434343'
              }}
            >
              <DeleteFilled
                style={{
                  fontSize: '36px',
                  height: '36px',
                  color: '#a61d24e6'
                }}
              />
            </div>

            <DialogTitle id="confirmation-dialog-title" sx={{ fontSize: '24px', lineHeight: '32px', fontWeight: 700, color: '#f0f0f0' }}>
              Heads up!
            </DialogTitle>
            <DialogContent sx={{ padding: 0, mb: '16px' }}>
              <DialogContentText
                id="confirmation-dialog-description"
                sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#BFBFBF' }}
              >
                If you remove {member.metadata.first_name} {member.metadata.last_name} they’ll lose all access to Cene and won’t be able to
                log in anymore. Just wanted to make sure you're aware before you proceed!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
                variant="outlined"
                sx={{
                  color: '#fff',
                  borderColor: '#434343',
                  '&:hover': {
                    backgroundColor: '#121212',
                    color: '#fff',
                    borderColor: '#434343'
                  }
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => selectedInviteId && handleDeleteMember(selectedInviteId)} color="error" variant="contained" autoFocus>
                Delete from Workspace
              </Button>
            </DialogActions>
          </Dialog>
          <PermissionsModal
            open={isPermissionsModalOpen}
            onClose={() => setPermissionsModalOpen(false)}
            member={member}
            onSuccess={onSuccess}
          />
        </>
      ))}
    </>
  );
};

interface ITableProps {
  members: Member[];
  isLoading: boolean;
  onSuccess: () => void;
}

const MembersTable = ({ isLoading = false, members = [], onSuccess = () => null }: ITableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: MemberColumnId; direction: 'asc' | 'desc' }>({
    key: MemberColumnId.TEAM_MEMBER_NAME,
    direction: 'asc'
  });

  const sortedMembers = [...members].sort((a, b) => {
    const { key, direction } = sortConfig;
    const valueA = getColumnValue(key, a);
    const valueB = getColumnValue(key, b);

    if (key === MemberColumnId.JOINED_TEAM) {
      const dateA = parse(a.updated_at, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.updated_at, 'yyyy-MM-dd', new Date());
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: MemberColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<MemberColumnId> columns={memberColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={memberColumns.length}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : members.length === 0 ? (
            <TableRow>
              <TableCell colSpan={memberColumns.length}>
                <Stack textAlign="center">No Members yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <MemberTableRows members={sortedMembers} onSuccess={onSuccess} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default MembersTable;
