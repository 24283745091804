import { Button, Divider, Stack, Typography } from '@mui/material';
import ReusableTabs from 'components/tabs/reusableTabs';
import { ROUTE_VENUE } from 'constants/routes';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { Venue } from '..';
import { PlusOutlined } from '@ant-design/icons';
import { ModalContextProvider, useModalContext } from 'contexts/ReusableModalContext';
import ReusableModal from 'components/modal/modal';
import AssignPartnerForm from './forms/assignPartner';

enum ITab {
  VENUE_OVERVIEW = 'Venue Overview',
  PARTNERS = 'Partners'
}

const VenueDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<ITab>(ITab.VENUE_OVERVIEW);
  const navigate = useNavigate();
  const [venue, setVenue] = useState<Venue | null>(null);
  const location = useLocation();
  const { open, modalToggler } = useModalContext();

  useEffect(() => {
    const venueFromState = location.state?.venue;
    if (venueFromState) {
      setVenue(venueFromState);
      localStorage.setItem('selectedVenue', JSON.stringify(venueFromState));
    } else {
      const savedVenue = localStorage.getItem('selectedVenue');
      if (savedVenue) {
        setVenue(JSON.parse(savedVenue));
      }
    }
  }, [location.state]);

  if (!venue) return <div>Loading...</div>;

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    switch (tab) {
      case ITab.VENUE_OVERVIEW:
        navigate(`${ROUTE_VENUE.replace(':id', id!)}/overview`);
        break;
      case ITab.PARTNERS:
        navigate(`${ROUTE_VENUE.replace(':id', id!)}/partners`);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '30px' }}>{venue?.name}</Typography>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          startIcon={<PlusOutlined />}
          sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
        >
          Assign this Venue to a Partner
        </Button>
      </Stack>
      <Divider sx={{ backgroundColor: '#262626', my: 3 }} />
      <Stack sx={{ gap: 2.5, mb: 5 }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>VENUE OVERVIEW</Typography>
        <Typography
          sx={{
            width: 'fit-content',
            padding: '4px 8px',
            borderRadius: '12px',
            backgroundColor: '#8057DB',
            display: 'flex',
            fontSize: '12px'
          }}
        >
          {venue?.isActive ? 'Active' : 'Unusable'}
        </Typography>
        <Typography>The Eko Convention Centre is the largest multipurpose conference; banquet and exhibition centre in Nigeria.</Typography>
        <Typography>
          The Hall can be divided into five separate sections for smaller conferences and exhibitions and comes with built in sound systems,
          professional lighting and roll away stage with carpeting to enhance sound effects.
        </Typography>
      </Stack>
      <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />
      <Outlet context={{ venue }} />
      <ReusableModal width="488px" open={open} modalToggler={() => modalToggler(null)} content={AssignPartnerForm} currentObject={venue} />
    </>
  );
};

const VenueDetails = () => {
  return (
    <ModalContextProvider>
      <VenueDetailsPage />
    </ModalContextProvider>
  );
};

export default VenueDetails;
