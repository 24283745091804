import * as Yup from 'yup';

const assignFormSchema = Yup.object({
  partner: Yup.object()
    .shape({
      id: Yup.string().required('Partner is required'),
      company_name: Yup.string().required('Partner name is required')
    })
    .nullable()
    .required('Partner selection is required')
});

export default assignFormSchema;
