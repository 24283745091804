export const genres = [
  'Pop',
  'Hip-hop',
  'Afrobeats',
  'Amapiano',
  'R & B',
  'Disco',
  'Jazz',
  'Reggae',
  'Rock',
  'Electronic',
  'Rap',
  'Soul',
  'House',
  'EDM'
];
