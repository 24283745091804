import OnbordingForm from './forms/index.form';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { Grid } from '@mui/material';

const OnboardingPage = () => {
  return (
    <Grid sx={{ padding: '32px 90px', width: '100%' }}>
      <LogoSVG style={{ fill: '#fff' }} />
      <OnbordingForm />
    </Grid>
  );
};

export default OnboardingPage;
