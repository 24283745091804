import { FallOutlined, LineChartOutlined, RiseOutlined } from '@ant-design/icons';
import { Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnalyticsDataCard from 'components/cards/statistics/AnalyticsDataCard';
import MainCard from 'components/MainCard';
import { SubscribePlan } from 'types/partner';

const rows = [
  {
    date: '11.06.2024',
    subscription: SubscribePlan.STARTER,
    status: 'Successful',
    amount: '$10.00',
    renewsOn: '11.07.2024'
  },
  {
    date: '11.06.2024',
    subscription: SubscribePlan.PREMIUM,
    status: 'Failed',
    amount: '$30.00',
    renewsOn: '11.07.2024'
  },
  {
    date: '12.06.2024',
    subscription: SubscribePlan.PRO,
    status: 'Successful',
    amount: '$20.00',
    renewsOn: '12.07.2024'
  },
  {
    date: '13.06.2024',
    subscription: SubscribePlan.STARTER,
    status: 'Successful',
    amount: '$10.00',
    renewsOn: '13.07.2024'
  },
  {
    date: '14.06.2024',
    subscription: SubscribePlan.PREMIUM,
    status: 'Failed',
    amount: '$30.00',
    renewsOn: '14.07.2024'
  },
  {
    date: '15.06.2024',
    subscription: SubscribePlan.STARTER,
    status: 'Successful',
    amount: '$10.00',
    renewsOn: '15.07.2024'
  },
  {
    date: '16.06.2024',
    subscription: SubscribePlan.PRO,
    status: 'Successful',
    amount: '$20.00',
    renewsOn: '16.07.2024'
  },
  {
    date: '17.06.2024',
    subscription: SubscribePlan.STARTER,
    status: 'Successful',
    amount: '$10.00',
    renewsOn: '17.07.2024'
  },
  {
    date: '18.06.2024',
    subscription: SubscribePlan.PREMIUM,
    status: 'Failed',
    amount: '$30.00',
    renewsOn: '18.07.2024'
  },
  {
    date: '19.06.2024',
    subscription: SubscribePlan.STARTER,
    status: 'Successful',
    amount: '$10.00',
    renewsOn: '19.07.2024'
  },
  {
    date: '20.06.2024',
    subscription: SubscribePlan.PRO,
    status: 'Successful',
    amount: '$20.00',
    renewsOn: '20.07.2024'
  }
];

const Subscriptions = () => {
  return (
    <MainCard title="Subscription History">
      <Grid container gap="8px" sx={{ gap: { sm: '8px', md: '0' }, justifyContent: { md: 'space-between' } }}>
        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticsDataCard
            title="Subscriptions"
            count="11"
            icon={
              <Chip
                label="100%"
                icon={<LineChartOutlined color="#25dc25" />}
                sx={{ backgroundColor: '#e6ffe8', color: '#25dc25', borderRadius: '8px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticsDataCard
            title="Successful"
            count="8"
            icon={
              <Chip
                label="72.7%"
                icon={<RiseOutlined color="#1890FF" />}
                sx={{ backgroundColor: '#E6F7FF', color: '#1890FF', borderRadius: '8px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticsDataCard
            title="Failed"
            count="3"
            icon={
              <Chip
                label="27.3%"
                icon={<FallOutlined color="#FAAD14" />}
                sx={{ backgroundColor: '#FFF7E6', color: '#FAAD14', borderRadius: '8px' }}
              />
            }
          />
        </Grid>
      </Grid>
      <TableContainer sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'transparent' }}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Renews on</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.subscription}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: row.status === 'Successful' ? '#93FF91' : '#ffae91',
                        fontWeight: 300,
                        fontSize: '16px'
                      }}
                    >
                      {row.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.renewsOn}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center" color="textSecondary">
                    No subscribtions found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default Subscriptions;
