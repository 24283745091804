import { Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { useFormik } from 'formik';
import usePartnerId from 'hooks/usePartnerId';
import { enqueueSnackbar } from 'notistack';
import { companyInformation, CompanyInformationSchema } from 'schemas/onboarding/companyInformation';
import { supabase } from 'supabase/supabase';

interface FormProps {
  onNext: () => void;
  onSuccess: () => void;
}

const CompanyInfoForm = ({ onNext = () => null, onSuccess = () => null }: FormProps) => {
  const partnerId = usePartnerId();

  const formik = useFormik<CompanyInformationSchema>({
    initialValues: {
      company_name: '',
      description: ''
    },
    validationSchema: companyInformation,
    onSubmit: async (values) => {
      try {
        const { error } = await supabase
          .from('partners')
          .update({ ...values })
          .eq('id', partnerId);

        if (error) {
          throw error;
        }

        onNext();
        onSuccess();
      } catch (error) {
        enqueueSnackbar('Error updating company information', { variant: 'error' });
      }
    }
  });

  const { getFieldProps, handleSubmit, errors, touched, isValid } = formik;

  const checkCompany = Boolean(errors.company_name || !touched.company_name);
  const checkDescription = Boolean(errors.description || !touched.description);

  const checked = checkCompany || checkDescription;

  return (
    <>
      <Grid container sx={{ marginTop: '32px', flexDirection: 'column', gap: '32px' }}>
        <Stack sx={{ gap: '16px' }}>
          <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#fff' }}>Your Cene. Your Stage</Typography>
          <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 300, color: '#fff' }}>
            At Cene, we believe in the power of a consistent brand to elevate your events and achieve your goals. Let’s take the first step
            together!
          </Typography>
        </Stack>

        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>Organisation Name</InputLabel>
            <TextField
              {...getFieldProps('company_name')}
              variant="standard"
              autoComplete="off"
              placeholder="e.g. Artful Minds"
              error={touched.company_name && Boolean(errors.company_name)}
              helperText={touched.company_name && errors.company_name}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>

        <Grid>
          <Stack>
            <InputLabel sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>A Brief Description</InputLabel>
            <TextField
              {...getFieldProps('description')}
              variant="standard"
              autoComplete="off"
              multiline
              placeholder="‘We are a local art collective dedicated to showcasing emerging artists through immersive, engaging events’"
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
            />
          </Stack>
        </Grid>

        <ShapeButton width="100%" type="submit" disabled={!isValid || checked} handleClick={handleSubmit}>
          Continue
        </ShapeButton>
      </Grid>
    </>
  );
};

export default CompanyInfoForm;
