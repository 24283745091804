import { Grid, Typography } from '@mui/material';
import { CustomLoader } from 'components/loader/loader';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

const LoadingPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { floorplanId } = useParams<{ floorplanId: string }>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading) {
      if (floorplanId) {
        navigate(`/venue/${id}/floorplan/${floorplanId}/assign`);
      }
    }
  }, [loading, navigate]);

  return (
    <Grid
      container
      sx={{ width: '100%', height: 'calc(100vh - 200px)', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography sx={{ fontSize: '32px', fontWeight: 300, color: '#F9F9F9' }}>Engineering Your Layout...</Typography>
      <Typography sx={{ fontSize: '32px', fontWeight: 300, color: '#F9F9F9', mb: 6 }}>
        We’re hard at work on your ideal floor plan!
      </Typography>
      <CustomLoader timeout={3000} onComplete={() => setLoading(false)} />
    </Grid>
  );
};

export default LoadingPage;
