// import { EditOutlined, EyeInvisibleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { useState } from 'react';
// import { handleCalculateAvailableTicketsByName } from 'supabase/util';
// import { format, parseISO } from 'date-fns';

export interface IFloorPlan {
  EUR: number;
  GBP: number;
  NGN: number;
  USD: number;
  description: string;
  floorplan: { seats: number; tables: number };
  id: string;
  is_visible: boolean;
  name: string;
}

export enum TicketsSoldColumnId {
  ZONE_NAME = 'zone_name',
  TABLES = 'tables',
  SEATS = 'seats',
  TICKET_STATUS = 'ticket_status',
  TABLES_SOLD = 'table_sold',
  SOLD = 'sold',
  SEATS_SOLD = 'seats_sold',
  ACTIONS = 'action'
}

//format money
// function formatMoney(amount: string, currency = 'NGN', locale = 'en-US') {
//   return new Intl.NumberFormat(locale, {
//     style: 'currency',
//     currency: currency,
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 0
//   }).format(Number(amount));
// }

interface TableColumn {
  id: TicketsSoldColumnId;
  title: string;
  sortable: boolean;
  cell?: (sold: IFloorPlan) => React.ReactNode;
}

const getColumnValue = (column: TableColumn, sold: IFloorPlan): React.ReactNode => {
  return column.cell ? column.cell(sold) : '';
};

interface TicketsSoldTableRowsProps {
  solds: IFloorPlan[];
  onSuccess?: () => void;
  eventId: string;
}

export const getStatusBackgroundColor = (status: boolean) => {
  return status ? '#A4DB6C' : '#787878';
};

// const AvailableTicketsCell = ({ eventId, ticketName }: { eventId: string; ticketName: string }) => {
//   const [availableTickets, setAvailableTickets] = useState<number | null>(null);

//   useEffect(() => {
//     const fetchAvailableTickets = async () => {
//       try {
//         const count = await handleCalculateAvailableTicketsByName(eventId, ticketName);
//         setAvailableTickets(count || 0);
//       } catch (error) {
//         console.error('Error fetching available tickets:', error);
//         setAvailableTickets(0);
//       }
//     };

//     fetchAvailableTickets();
//   }, []);

//   if (availableTickets === null) {
//     return <>{'null'}</>;
//   }

//   return <Typography>{availableTickets}</Typography>;
// };

const ticketSoldColumns = (eventId: string) => {
  const result: TableColumn[] = [
    { id: TicketsSoldColumnId.ZONE_NAME, title: 'Zone Name', sortable: true, cell: (sold) => sold.name },
    { id: TicketsSoldColumnId.TABLES, title: 'Tables', sortable: true, cell: (sold) => sold.floorplan.tables },
    {
      id: TicketsSoldColumnId.SEATS,
      title: 'Seats',
      sortable: true,
      cell: (sold) => sold.floorplan.seats
    },
    { id: TicketsSoldColumnId.TICKET_STATUS, title: 'Ticket Status', sortable: true },
    { id: TicketsSoldColumnId.TABLES_SOLD, title: 'Tables Sold', sortable: true, cell: (sold) => `0/${sold.floorplan.tables}` },
    { id: TicketsSoldColumnId.SEATS_SOLD, title: 'Seats Sold', sortable: true, cell: (sold) => `0/${sold.floorplan.seats}` },
    { id: TicketsSoldColumnId.SOLD, title: '% Sold', sortable: true },
    { id: TicketsSoldColumnId.ACTIONS, title: 'Actions', sortable: false }
  ];

  return result;
};

const TicketsSoldTableRows = ({ solds = [], onSuccess = () => null, eventId }: TicketsSoldTableRowsProps) => {
  const renderCellContent = (column: TableColumn, sold: IFloorPlan) => {
    if (column.id === TicketsSoldColumnId.TICKET_STATUS) {
      return (
        <Typography
          sx={{
            width: '55%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px',
            fontWeight: 400,
            fontSize: '10px',
            backgroundColor: getStatusBackgroundColor(sold.is_visible),
            color: '#000',
            borderRadius: '4px',
            height: 'fit-content'
          }}
        >
          {sold.is_visible ? 'Active' : 'Not Available'}
        </Typography>
      );
    } else if (column.id === TicketsSoldColumnId.SOLD) {
      return (
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '8px 8px',
            fontWeight: 400,
            fontSize: '12px',
            color: '#FFF',
            borderRadius: '4px'
          }}
        >
          {'0%'}
        </Typography>
      );
    } else if (column.id === TicketsSoldColumnId.ACTIONS) {
      return (
        <>
          {/* <IconButton>
            <InfoCircleOutlined />
          </IconButton> */}

          {/* <IconButton>
            <EyeInvisibleOutlined />
          </IconButton> */}

          <IconButton>
            <EditOutlined />
          </IconButton>
        </>
      );
    }
    return getColumnValue(column, sold);
  };

  return (
    <>
      {solds.map((sold) => (
        <TableRow key={sold.id}>
          {ticketSoldColumns(eventId).map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, sold)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface TicketsSoldsTableProps {
  isLoading?: boolean;
  solds?: IFloorPlan[];
  onSuccess?: () => void;
  eventId: string;
}

const EventOverviewTableCenePlus = ({ onSuccess = () => null, isLoading = false, solds = [], eventId }: TicketsSoldsTableProps) => {
  const [filter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: TicketsSoldColumnId; direction: 'asc' | 'desc' }>({
    key: TicketsSoldColumnId.ZONE_NAME,
    direction: 'asc'
  });

  const filterTicketsSolds = solds.filter((sold) =>
    ticketSoldColumns(eventId).some((column: TableColumn) => {
      const value = getColumnValue(column, sold);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedTicketSolds = [...filterTicketsSolds].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = ticketSoldColumns(eventId).find((col: TableColumn) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    if (key === TicketsSoldColumnId.SOLD) {
      const soldA = typeof valueA === 'string' && valueA ? Number(valueA.split('/')[0]) : 0;
      const soldB = typeof valueB === 'string' && valueB ? Number(valueB.split('/')[0]) : 0;

      if (direction === 'asc') {
        return soldA - soldB;
      } else {
        return soldB - soldA;
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: TicketsSoldColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ mb: 3 }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Tickets info</Typography>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<TicketsSoldColumnId>
                  columns={ticketSoldColumns(eventId)}
                  sortConfig={sortConfig}
                  onRequestSort={requestSort}
                />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={ticketSoldColumns(eventId).length}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : solds.length === 0 ? (
              <TableRow>
                <TableCell colSpan={ticketSoldColumns(eventId).length}>
                  <Stack textAlign="center">No Tickets Sold yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                <TicketsSoldTableRows eventId={eventId} onSuccess={onSuccess} solds={sortedTicketSolds} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
    </>
  );
};

export default EventOverviewTableCenePlus;
